import { useContext, useState, useEffect, useRef, useCallback } from "react";
import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.png";
import {
  EnvironmentConfig,
  EnvironmentConfigContext,
  SubscriptionPeriod,
} from "../../../config/Environment";
import {
  GA4AnalyticsContext,
  MixPanelAnalyticsContext,
} from "../../analytics/Analytics";
import { useNavigate } from "react-router-dom";
import ApiInstance from "../../../lib/ApiFunction";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { DeviceSessionContext } from "../../analytics/DeviceContext";
import TagManager from "react-gtm-module";
import { Context, navigateTo } from "../../../config/UserAuthContext";
import UserMenu from "../UserMenu/UserMenu";
import UserIcon from "../../../global-assets/assets/User_Icon.svg";
import Button from "../../common/Button";
import { useRemoteConfig } from "../../../config/FBRemoteConfigContext";

interface RequestPayload {
  priceId: string | null | undefined;
  successUrl: string;
  cancelUrl: string;
  promoCode?: string | null | undefined;
}

interface NavBarProps {
  isAuthenticated: any;
  emailAddress: any;
}

const GiftASubNavbar = ({ isAuthenticated, emailAddress }: NavBarProps) => {
  const navigate = useNavigate();
  const firebaseFlags = useRemoteConfig();
  const config = useContext(EnvironmentConfigContext) as EnvironmentConfig;
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const { GenerateStripeGiftLink } = ApiInstance();
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const osType = deviceCtx.os.name;
  const ga4Context = useContext(GA4AnalyticsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const GlobalUserId = Cookies.get("GlobalUserId");

  // Ref for User Icon circle, used to anchor the menu at large screen widths
  const userIconRef = useRef<HTMLInputElement>(null);
  // User Icon circle element's positions, used to determine menu width
  const userIconPositions = useRef<DOMRect | undefined>();
  // Updates the User Icon circle position as the screen width changes
  const updateUserIconPos = useCallback(() => {
    userIconPositions.current = userIconRef.current?.getBoundingClientRect();
  }, []);

  useEffect(() => {
    if (userIconPositions.current === undefined) {
      userIconPositions.current = userIconRef.current?.getBoundingClientRect();
    }
    window.addEventListener("resize", updateUserIconPos);
    return () => window.removeEventListener("resize", updateUserIconPos);
  }, [updateUserIconPos]);

  // Ref for the User Menu, used to check if the user is clicking within or outside it
  const userMenuRef = useRef<HTMLDivElement>(null);
  const closeUserMenu = useCallback(
    (e: any) => {
      if (isUserMenuOpen && !userMenuRef.current?.contains(e.target)) {
        setIsUserMenuOpen(false);
      }
    },
    [isUserMenuOpen]
  );

  useEffect(() => {
    window.addEventListener("mousedown", closeUserMenu);
    return () => window.removeEventListener("mousedown", closeUserMenu);
  }, [closeUserMenu]);

  /**
   * Initiate Stripe checkout process
   */
  const handleGiftPurchaseLink = (stripePlanType: string) => {
    setLoading(true);
    ga4Context.track("enter_stripe_flow", { stripePlanType: "GiftAnnual" });

    const priceId = config?.productConfig.giftingProductId.get(
      SubscriptionPeriod.GiftAnnual
    );

    let requestPayload: RequestPayload = {
      priceId: priceId,
      successUrl: `${window.location.origin}/gift-success`,
      cancelUrl: window.location.href,
    };

    if (stripePlanType === "Annual Gift Holiday") {
      const holidayPromoCode = config.couponCodes.holidayPromoCode;
      requestPayload = { ...requestPayload, promoCode: holidayPromoCode };
    }

    GenerateStripeGiftLink.post("", requestPayload)
      .then((res) => {
        const { checkoutUrl } = res.data;
        analyticsContext.track("Web: Payment-In-Stripe-Initiated", {
          plan: "GiftAnnual",
          price_id: requestPayload.priceId || "",
        });
        window.open(`${checkoutUrl}`, "_self");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          analyticsContext.track("Web: Payment-In-Stripe-Failed", {
            reason: "Session Timed Out",
          });
          Cookies.remove(Context.AuthContext);
          setLoading(false);
          navigate("/login");
        }

        if (err.response) {
          toast.info(err.response.data.statusMessage);
          setLoading(false);
        }
      });

    TagManager.dataLayer({
      dataLayer: {
        event: "plan_type_select",
        os_type: osType,
        plan_type: "GiftAnnual",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  };

  const renderPurchaseButton = () => {
    if (firebaseFlags.thanksgivingPriceDiscount2023) {
      return (
        <Button
          id="nav_gift_a_sub_purchase_btn"
          loading={
            firebaseFlags.thanksgivingPriceDiscount2023 === undefined || loading
          }
          onClick={() => handleGiftPurchaseLink("Annual Gift Holiday")}
          btnType="green"
          className="w-[181px] sm:w-[227px] md:!px-10"
        >
          Purchase Gift
        </Button>
      );
    }
    return (
      <Button
        id="nav_gift_a_sub_purchase_btn"
        loading={
          firebaseFlags.thanksgivingPriceDiscount2023 === undefined || loading
        }
        onClick={() => handleGiftPurchaseLink("Gift Annual")}
        btnType="green"
        className="w-[182px] sm:w-[228px] md:!px-10"
      >
        Purchase Gift
      </Button>
    );
  };

  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      {isAuthenticated && (
        <UserMenu
          isOpen={isUserMenuOpen}
          setIsOpen={setIsUserMenuOpen}
          userEmail={emailAddress}
          userIconRightCoord={{ right: userIconPositions.current?.right }}
          menuRef={userMenuRef}
        />
      )}
      <div className="relative flex h-[104px] items-center justify-between p-6 md:h-[114px]">
        <div className="flex flex-1 items-center">
          <div className="cursor-pointer" onClick={() => navigateTo("/")}>
            <img
              src={TruPlayLogo}
              alt="Truplay-logo"
              className={
                "w-[7.5rem] sm:w-[9.5rem] lg:w-48" +
                (isUserMenuOpen ? " hidden md:!inline" : " block")
              }
              id="unauth_tru_logo"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 sm:gap-4">
          {renderPurchaseButton()}
          {isAuthenticated && (
            <div
              id="active_user_menu_option"
              className={
                "relative flex min-h-12 min-w-12 cursor-pointer items-center justify-center bg-user_menu_icon" +
                " rounded-full duration-200 hover:scale-110 hover:shadow-[0_3px_20px_rgba(56,166,213,0.6)]"
              }
              onClick={() => {
                setIsUserMenuOpen(true);
              }}
              ref={userIconRef}
            >
              <img
                src={UserIcon}
                alt="User Icon"
                className={
                  "h-[1.1rem]" + (isUserMenuOpen ? " hidden" : " block")
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GiftASubNavbar;
