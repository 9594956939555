import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import SliderArrow from "../../global-assets/assets/slider-arrow-small.svg";
import { useLocation } from "react-router-dom";

interface IFeaturedCarouselProps {
  id: string;
  initialState: any[]; // Initial state of cards for the carousel
  cardWidth?: number; // Width of each card in the carousel
  cardMargin: number; // Margin between each card in the carousel
  cardHeight?: number; // Height of each card in the carousel
  initialCardCount?: number; // Initial count of the list of cards for the carousel
  responsive?: boolean; // The number of cards in the carousel will update based on screen width
  focus?: boolean; // The designated card will have focusStyles applied (could add a focus threshold in the future)
  focusStyles?: string; // Focused card styles
  cardStyles: string; // Regular card styles
  hoverStyles?: string; // All card hover styles
  animated?: boolean; // The cards will animate based on the shift styles
  shiftLeftStyles?: string; // Animation for shifting 1 card to the left
  shiftRightStyles?: string; // Animation for shifting 1 card to the right
  visibleCount: number; // Number of cards that are currently visible
  lightBackground?: boolean; // True if the background is lighter
  arrowType?: string; // Dictates the arrow background styles
  arrowCornerType?: string; // Dictates the corner styles of the arrow background
  selection?: boolean; // True if there is selection logic for the cards
  paddingTop?: number;
  paddingBottom?: number;
  overlayStyles?: string; // Styles for an element overlaying the card
  overlayFocusStyles?: string; // Styles for the overlaid element when the card is focused
  overlayHoverStyles?: string;
  setDisplayedCard?: (featuredCard: any) => void;
}

/**
 * Large featured preview with carousel selection underneath
 * The images/videos within the carousel must have the same dimensions/aspect ratio
 * since the cards are dynamically sized based on the parent component
 * @returns Carousel component
 */
export default function FeaturedCarousel({
  id,
  initialState,
  cardWidth = 0,
  cardMargin,
  cardHeight = 0,
  focus = false,
  animated = false,
  lightBackground = false,
  focusStyles = "",
  cardStyles,
  hoverStyles = "",
  arrowType = "standard",
  arrowCornerType = "hard-edge",
  selection = false,
  paddingTop = 0,
  paddingBottom = 0,
  visibleCount,
  setDisplayedCard,
}: IFeaturedCarouselProps) {
  const sliderId = "tru-slider_" + id;
  const sliderInnerId = "tru-slider-inner_" + id;
  const featureId = "tru-feature_" + id;
  const [cards, setCards] = useState<any[]>(initialState);
  const [featuredCard, setFeaturedCard] = useState<any>(cards[0]);
  const [shiftingLeft, setShiftingLeft] = useState<boolean>(false);
  const [shiftingRight, setShiftingRight] = useState<boolean>(false);
  const [startTouch, setStartTouch] = useState<number>(0);
  const [visibleCardCount, setVisibleCardCount] =
    useState<number>(visibleCount);
  const [carouselShift, setCarouselShift] = useState<number>(0);
  const [currentCardWidth, setCurrentCardWidth] = useState<number>(cardWidth);
  const [currentCardHeight, setCurrentCardHeight] =
    useState<number>(cardHeight);
  const [currentCardMargin, setCurrentCardMargin] =
    useState<number>(cardMargin);
  const [translateDistance, setTranslateDistance] = useState<number>(
    cardWidth + cardMargin
  );

  const [sliderWidth, setSliderWidth] = useState<number | null>(null);
  const [sliderInnerWidth, setSliderInnerWidth] = useState<number | null>(null);
  const [sliderInnerHeight, setSliderInnerHeight] = useState<number | null>(
    null
  );
  const [featureHeight, setFeatureHeight] = useState<number | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoToShow: string | null = params.get("video");

  useEffect(() => {
    if (videoToShow) {
      cards.forEach((card, index) => {
        if (card.videoId == videoToShow) {
          setFeaturedCard(cards[index]);
          return;
        }
      });
    }
  }, [videoToShow]);

  /**
   * Checks if the number of cards is equal to the set visible count
   * @returns True if the number of cards is equal to the set visible count
   */
  const checkVisibleCardCount = () => {
    if (cards.length === visibleCardCount) {
      return true;
    }
    return false;
  };

  /**
   * Fetches the dynamic slider DOM related values
   * @returns Object containing the slider related widths and height
   */
  const fetchDOMValues = () => {
    const currentSliderWidth = Number(
      document.getElementById(sliderId)?.getBoundingClientRect().width
    );
    const currentSliderInnerWidth = Number(
      document.getElementById(sliderInnerId)?.getBoundingClientRect().width
    );
    const currentSliderInnerHeight = Number(
      document.getElementById(sliderInnerId)?.getBoundingClientRect().height
    );
    const currentFeatureHeight = Number(
      document.getElementById(featureId)?.getBoundingClientRect().height
    );
    return {
      currentSliderWidth,
      currentSliderInnerWidth,
      currentSliderInnerHeight,
      currentFeatureHeight,
    };
  };

  /**
   * Calculates and sets element values based on the dynamic slider DOM related values
   * @param carouselWidth Slider container width
   * @param cardsWidth Cards conatiner width
   * @param cardsHeight Cards container height
   */
  const handleDynamicValues = (
    carouselWidth: number,
    cardsWidth: number,
    cardsHeight: number
  ) => {
    setCarouselShift((-1 * carouselWidth) / 4 + 5);
    const cardWidth =
      cards.length !== visibleCardCount
        ? cardsWidth / (visibleCardCount + 2)
        : cardsWidth / visibleCardCount;
    setCurrentCardWidth(cardWidth);
    setCurrentCardHeight(cardsHeight);
    setTranslateDistance(cardWidth + 3);
    if (window.innerWidth < 480) {
      const newCardMargin = cardMargin * 0.5;
      setCurrentCardMargin(newCardMargin);
    } else if (window.innerWidth < 768) {
      const newCardMargin = cardMargin / 0.75;
      setCurrentCardMargin(newCardMargin);
    } else {
      const newCardMargin = cardMargin;
      setCurrentCardMargin(newCardMargin);
    }
  };

  const handleResize = () => {
    const DOMValues = fetchDOMValues();
    if (
      DOMValues.currentSliderWidth &&
      DOMValues.currentSliderInnerWidth &&
      DOMValues.currentSliderInnerHeight
    ) {
      handleDynamicValues(
        DOMValues.currentSliderWidth,
        DOMValues.currentSliderInnerWidth,
        DOMValues.currentSliderInnerHeight
      );
    }
    setFeatureHeight(DOMValues.currentFeatureHeight);
  };

  /**
   * Handles a screen resize event. When the screen size changes, the number
   * of visible cards must be calculated
   */
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sliderWidth && sliderInnerWidth && sliderInnerHeight) {
      handleDynamicValues(sliderWidth, sliderInnerWidth, sliderInnerHeight);
      setLoading(false);
    }
  }, [sliderWidth, sliderInnerWidth, sliderInnerHeight]);

  useEffect(() => {
    const DOMValues = fetchDOMValues();
    setSliderWidth(DOMValues.currentSliderWidth);
    setSliderInnerWidth(DOMValues.currentSliderInnerWidth);
    setSliderInnerHeight(DOMValues.currentSliderInnerHeight);
    setFeatureHeight(DOMValues.currentFeatureHeight);
  });

  /**
   * Applies the appropriate styles to the focus card(s)
   * @param cardNodes List of card elements within the carousel
   */
  const handleFocusCard = (currentCards: any[]) => {
    currentCards.forEach((card) => {
      if (featuredCard) {
        if (card.id === featuredCard.id) {
          card.focus = true;
        } else {
          card.focus = false;
        }
      }
    });
    return currentCards;
  };

  /**
   * Updates the current card state.
   * @param currentState Current state of the carousel cards
   * @param visibleCardCount Number of visible cards
   */
  const updateCards = (carouselCards: any[], selectedCard?: any) => {
    const currentCards = [...carouselCards];

    if (focus) {
      const appliedFocusCards = handleFocusCard(currentCards);
      setCards([...appliedFocusCards]);
      return;
    }

    if (selectedCard) {
      const appliedSelectionCards = handleFocusCard(currentCards);
      setCards([...appliedSelectionCards]);
    }

    setCards([...currentCards]);
  };

  useEffect(() => {
    updateCards(cards, featuredCard);
    // Missing deps will cause unneccasary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredCard]);

  /**
   * Shifts the carousel cards to the left
   * Advances to the next card to the right
   */
  const handleRightClick = () => {
    const currentState = [...cards];
    const shifted = currentState[0];
    currentState.shift();
    currentState.push(shifted);
    if (animated) {
      setShiftingLeft(true);
      setTimeout(() => {
        currentState.forEach((card, index) => {
          if (index < visibleCardCount) {
            card.visible = true;
          } else {
            card.visible = false;
          }
        });
        updateCards(currentState);
        setShiftingLeft(false);
      }, 800);
    } else {
      updateCards(currentState);
    }
  };

  /**
   * Shifts the carousel cards to the right
   * Advances to the next card to the left
   */
  const handleLeftClick = () => {
    const currentState = [...cards];
    const popped = currentState[currentState.length - 1];
    currentState.pop();
    currentState.unshift(popped);
    if (animated) {
      setShiftingRight(true);
      setTimeout(() => {
        currentState.forEach((card, index) => {
          if (index < visibleCardCount) {
            card.visible = true;
          } else {
            card.visible = false;
          }
        });
        updateCards(currentState);
        setShiftingRight(false);
      }, 800);
    } else {
      updateCards(currentState);
    }
  };

  const handleTouchStart = (e: any) => {
    const touchDown = e.touches[0].clientX;
    setStartTouch(touchDown);
  };

  /**
   * Mobile functionality for swipping the carousel
   * @param e touch event on mobile
   */
  const handleTouchMove = (e: any) => {
    if (!startTouch) return;

    const touchDown = startTouch;
    const touchUp = e.touches[0].clientX;
    const touchDiff = touchDown - touchUp;

    if (touchDiff > 5) {
      handleRightClick();
    }

    if (touchDiff < -5) {
      handleLeftClick();
    }

    setStartTouch(0);
  };

  /**
   * Handles applying translation styles for animation
   * @returns A string containing the inline styles applied depending on carousel translation direction
   */
  const handleTranslate = () => {
    if (shiftingLeft) {
      return `translateX(-${translateDistance}px)`;
    } else if (shiftingRight) {
      return `translateX(${translateDistance}px)`;
    } else {
      return "";
    }
  };

  /**
   * Handles carousel arrow rendering
   * @param type General style of the arrows
   * @param direction left or right
   * @param cornerType rounded or sharp-edge
   * @returns a rendered arrow, left or right, with appropriate styles for the type of carousel
   */
  const handleArrows = (
    type: string,
    direction: string,
    cornerType: string
  ) => {
    const commonStandardStyles =
      "hidden absolute z-[3] cursor-pointer select-none items-center justify-center bg-indigo-900/50 hover:bg-indigo-900/65 duration-300 ease-in-out md:flex";
    const commonUniqueStyles =
      "group/arrow opacity-100 md:opacity-0 group-hover:opacity-100";
    const featuredArrowStyles = {
      width: currentCardWidth / 3 + "px",
      height: currentCardHeight + "px",
      top: paddingTop,
    };
    if (direction === "left") {
      if (type === "featured") {
        const leftArrow = (
          <img
            className="-ml-2 rotate-180 group-hover/arrow:h-12 group-hover/arrow:w-12"
            src={SliderArrow}
            alt="Previous"
          />
        );
        if (cornerType === "rounded") {
          return (
            <div
              className={
                "left-1.5 rounded-l-[12px] lg:rounded-l-[20px] " +
                commonStandardStyles +
                " " +
                commonUniqueStyles +
                (checkVisibleCardCount() ? " !hidden" : "") +
                (shiftingLeft ? " pointer-events-none" : "")
              }
              style={featuredArrowStyles}
              onClick={handleLeftClick}
            >
              {leftArrow}
            </div>
          );
        }
      }
      return (
        <div
          className={
            "invisible left-4 top-[25%] h-12 w-12 rotate-90 rounded-full md:!visible md:left-[5%] md:h-24 md:w-24 " +
            commonStandardStyles +
            (lightBackground
              ? " bg-indigo-900/20 hover:bg-indigo-900/40"
              : "") +
            (checkVisibleCardCount() ? " !hidden" : "")
          }
          onClick={handleLeftClick}
        >
          <img src={SliderArrow} alt="Previous" />
        </div>
      );
    } else if (direction === "right") {
      if (type === "featured") {
        const rightArrow = (
          <img
            className="-ml-2 group-hover/arrow:h-12 group-hover/arrow:w-12"
            src={SliderArrow}
            alt="Previous"
          />
        );
        if (cornerType === "rounded") {
          return (
            <div
              className={
                "right-1.5 rounded-r-[12px] lg:rounded-r-[20px] " +
                commonStandardStyles +
                " " +
                commonUniqueStyles +
                (checkVisibleCardCount() ? " !hidden" : "") +
                (shiftingRight ? " pointer-events-none" : "")
              }
              style={featuredArrowStyles}
              onClick={handleRightClick}
            >
              {rightArrow}
            </div>
          );
        }
      }
      return (
        <div
          className={
            "invisible right-4 top-[25%] h-12 w-12 -rotate-90 rounded-full md:!visible md:right-[5%] md:h-24 md:w-24 " +
            commonStandardStyles +
            (lightBackground
              ? " bg-indigo-900/20 hover:bg-indigo-900/40"
              : "") +
            (checkVisibleCardCount() ? " !hidden" : "")
          }
          onClick={handleRightClick}
        >
          <img src={SliderArrow} alt="Next" />
        </div>
      );
    }
  };

  const handleFeaturedCard = (card: any) => {
    setFeaturedCard(card);
    if (typeof setDisplayedCard === "function") {
      setDisplayedCard(card);
    }
  };

  const cardComponent = (
    card: any,
    index: number | string,
    forceVisible?: boolean
  ) => {
    if (!card.visible && !forceVisible) {
      return null;
    }
    return (
      <div
        key={"card_" + index}
        className={
          "group/card-hover relative" +
          (shiftingLeft || shiftingRight ? " duration-[0.8s]" : "") +
          (card.focus ? " group/card-focus" : "") +
          (checkVisibleCardCount() && index === cards.length - 1
            ? " mr-1"
            : "") +
          (checkVisibleCardCount() && index === 0 ? " ml-1" : "")
        }
        onClick={() => handleFeaturedCard(card)}
        style={{
          WebkitTransform: handleTranslate(),
          transform: handleTranslate(),
        }}
      >
        {selection && card.focus && (
          <div
            className={
              focusStyles +
              " absolute bottom-0 left-0 right-0 top-0 z-[1] " +
              hoverStyles
            }
          />
        )}
        <div
          id={"card_" + index}
          className={
            "relative " +
            cardStyles +
            (card.focus ? " !opacity-100 " : "") +
            " " +
            hoverStyles
          }
        >
          {card.component}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 sm:gap-6 md:gap-8 lg:gap-10">
      <div
        style={{
          minHeight: featureHeight + "px" || "none",
        }}
      >
        {featuredCard.display}
      </div>
      <div
        id={"tru-slider_" + id}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        className="group relative flex items-center"
        style={{
          minHeight: currentCardHeight + paddingTop + paddingBottom + "px",
          paddingTop: paddingTop + "px",
          paddingBottom: paddingBottom + "px",
        }}
      >
        {handleArrows(arrowType, "left", arrowCornerType)}
        <>
          <div
            id={"tru-slider-inner_" + id}
            className="absolute flex flex-row items-center justify-center"
            style={{
              gap: currentCardMargin + "px",
              left: !checkVisibleCardCount() ? carouselShift : "unset",
              right: !checkVisibleCardCount() ? carouselShift : "unset",
            }}
          >
            {!checkVisibleCardCount() &&
              cardComponent(
                cards[cards.length - 1],
                "outer-card-" + Number(cards.length - 1),
                true
              )}
            {cards.map((card: any, index: number) => {
              return cardComponent(card, index);
            })}
            {!checkVisibleCardCount() &&
              cardComponent(cards[visibleCardCount], "outer-card-" + 0, true)}
          </div>
          {loading && (
            <div className="absolute flex w-full items-center justify-center">
              <PulseLoader color="#ffffff" />
            </div>
          )}
        </>
        {handleArrows(arrowType, "right", arrowCornerType)}
      </div>
    </div>
  );
}
