import { useState } from "react";
import paperPlane from "../../../../global-assets/assets/paperPlane.png";
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  GetEmailAddress,
  SecureCookieAttributes,
  navigateTo,
} from "../../../../config/UserAuthContext";
import AccountServiceClient from "../../../../lib/ApiFunction";
import { toast } from "react-toastify";
import { ErrorCodeMap } from "../../../../lib/Constants";
import { InlineErrorMessage } from "../../../notification/InlineErrorMessage/InlineErrorMessage";
import Button from "../../../common/Button";

/**
 * During account registration process, one time code is sent to the email address on file
 * to confirm the ownership and access to the email address.
 * @constructor
 */
const VerifyEmailAddress = () => {
  const { GetEmailToResetPassword, VerifyOTPToResetPassword } =
    AccountServiceClient();

  const email_address: string = GetEmailAddress() || "";
  const navigate = useNavigate();

  const [otp, setOtp] = useState<number>();
  const [isButtonActivate, setIsButtonActivate] = useState<boolean>(false);
  const [, setCodeSendFlag] = useState<boolean>(false);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const verifyUserHandler = (e: any) => {
    setVerifying(true);
    let data = {
      emailAddress: email_address,
      resetCode: otp,
    };

    VerifyOTPToResetPassword.post("", data)
      .then((response) => {
        setError(null);
        toast.success("Email address has been verified successfully", {
          position: "top-center",
        });
        let { token } = response.data;
        Cookies.set("token", token, SecureCookieAttributes);
        navigate("/set-new-password", { replace: true });
      })
      .catch((error) => {
        const errorMessage = ErrorCodeMap[error.response?.data.statusCode]
          ? ErrorCodeMap[error.response?.data.statusCode]
          : error.response?.data.statusMessage;
        setError(errorMessage);
        setVerifying(false);
        if (error.response?.data.statusCode === "AS350") {
          toast.warn("Session timeout. Navigating back to the Log In page. ", {
            position: "top-center",
          });
          setTimeout(() => {
            navigateTo("/login");
          }, 5000);
        }
      });
  };

  let resendOtpHandler = () => {
    let data = {
      emailAddress: email_address,
    };

    GetEmailToResetPassword.post("", data)
      .then((response) => {
        toast.success(
          "A secure code has been sent to your registered email address",
          {
            position: "top-center",
          }
        );
        setTimeout(() => {
          setCodeSendFlag(false);
        }, 5000);
      })
      .catch(() => {
        setIsButtonActivate(false);
      });
  };

  const OTPHandler = (e: any) => {
    setOtp(e.target.value);
    setError(null);
    e.target.value ? setIsButtonActivate(true) : setIsButtonActivate(false);
  };

  return (
    <div className="relative flex h-full min-h-[calc(100vh-272px)] items-center overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 md:pt-32 lg:px-12 lg:py-36">
      <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-60" />
      <div className="m-auto flex max-w-[1200px] justify-center">
        <div className="flex flex-col items-center gap-4 rounded-[30px] bg-white p-6 shadow-[0px_15px_10px_-15px_#38a6d5] md:p-8">
          <h5 className="text-center font-display text-xl font-semibold capitalize text-indigo-500 md:text-3xl">
            Verification Email Sent!
          </h5>
          <div className="flex justify-around">
            <img
              src={paperPlane}
              alt="Truplay-logo"
              className="h-[110px] w-[184px]"
            />
          </div>
          <h4 className="text-center font-display text-2xl font-semibold capitalize text-indigo-500 md:text-3xl">
            Verify your Email
          </h4>
          <p className="m-0 text-center text-base">
            We've sent an email to: <br /> <br />
            <span className="break-words px-4 text-raspberry-500">
              {email_address}
            </span>
            <br />
            <br />
            To verify your email, please input the verification code below.
          </p>
          <input
            className={
              "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
              " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
              " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
              " focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
              (error ? " ring-2 ring-raspberry-500" : "")
            }
            value={otp || ""}
            onChange={(e) => {
              OTPHandler(e);
            }}
            placeholder="Verification Code"
          />
          {error && <InlineErrorMessage errorMessage={error} />}
          <Button
            id="verify_email_btn"
            loading={verifying}
            disabled={!isButtonActivate}
            onClick={(e: any) => {
              verifyUserHandler(e);
            }}
            btnType="green"
            className="w-full"
          >
            Verify email
          </Button>
          <div className="flex items-center justify-center">
            <p className="m-0 text-base font-normal">Did not get the code?</p>
            <Button
              id="modal_resend_code_btn"
              btnType="tertiaryAlt"
              onClick={() => resendOtpHandler()}
              className="ml-2 !text-base hover:underline"
            >
              Resend Code
            </Button>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default VerifyEmailAddress;
