import Button from "./Button";

interface IThreeBlockGraphicProps {
  block1Title: string;
  block1ImageSrc?: string;
  block1SubTitle?: string | JSX.Element;
  block1Subtext?: string | JSX.Element;
  block1Description: string;
  block1CTAText?: string;
  block1CTAOnClick?: () => void;
  block1CTAVariant?:
    | "green"
    | "orange"
    | "blue"
    | "purple"
    | "red"
    | "secondary"
    | "secondaryAlt"
    | "tertiary"
    | "tertiaryAlt"
    | "googleSSO";
  block1CTAId: string;
  block1Featured?: boolean;
  block2Title: string;
  block2ImageSrc?: string;
  block2SubTitle?: string | JSX.Element;
  block2Subtext?: string | JSX.Element;
  block2Description: string;
  block2CTAText?: string;
  block2CTAOnClick?: () => void;
  block2CTAVariant?:
    | "green"
    | "orange"
    | "blue"
    | "purple"
    | "red"
    | "secondary"
    | "secondaryAlt"
    | "tertiary"
    | "tertiaryAlt"
    | "googleSSO";
  block2CTAId: string;
  block2Featured?: boolean;
  block3Title: string;
  block3ImageSrc?: string;
  block3SubTitle?: string | JSX.Element;
  block3Subtext?: string | JSX.Element;
  block3Description: string;
  block3CTAText?: string;
  block3CTAOnClick?: () => void;
  block3CTAVariant?:
    | "green"
    | "orange"
    | "blue"
    | "purple"
    | "red"
    | "secondary"
    | "secondaryAlt"
    | "tertiary"
    | "tertiaryAlt"
    | "googleSSO";
  block3CTAId: string;
  block3Featured?: boolean;
}

export default function ThreeBlockGraphic({
  block1Title,
  block1ImageSrc,
  block1SubTitle,
  block1Subtext,
  block1Description,
  block1CTAText,
  block1CTAOnClick,
  block1CTAVariant = "green",
  block1CTAId,
  block1Featured,
  block2Title,
  block2ImageSrc,
  block2SubTitle,
  block2Subtext,
  block2Description,
  block2CTAText,
  block2CTAOnClick,
  block2CTAVariant = "green",
  block2CTAId,
  block2Featured,
  block3Title,
  block3ImageSrc,
  block3SubTitle,
  block3Subtext,
  block3Description,
  block3CTAText,
  block3CTAOnClick,
  block3CTAVariant = "green",
  block3CTAId,
  block3Featured,
}: IThreeBlockGraphicProps) {
  const featuredBlockStyles =
    "!bg-[linear-gradient(180deg,rgba(168,74,212,0.03)0%,rgba(168,74,212,0.21)100%)] border-4 border-solid border-white !shadow-[0px_0px_48px_0px_rgba(56,166,213,0.50),0px_0px_80px_0px_rgba(225,175,250,0.15)]";

  return (
    <div className="flex flex-col flex-wrap items-center justify-center gap-6 lg:flex-row lg:gap-8">
      <div
        className={
          "mx-auto flex flex-col items-center justify-between gap-7 self-stretch rounded-[40px] bg-white/[6%] p-8 shadow-[0px_0px_80px_0px_#001B36] sm:h-[425px] sm:w-[425px]" +
          (block1Featured ? " " + featuredBlockStyles : "")
        }
      >
        <div className="flex flex-col gap-7">
          <h3 className="font-display text-4xl font-bold text-white">
            {block1Title}
          </h3>
          {block1SubTitle && (
            <div className="flex flex-col items-center justify-start gap-2 font-display text-6xl font-bold text-white">
              {block1SubTitle}
              {block1Subtext && (
                <div className="font-body text-2xl font-normal text-cerulean-500">
                  {block1Subtext}
                </div>
              )}
            </div>
          )}
          {block1ImageSrc && (
            <img
              src={block1ImageSrc}
              alt={block1Title}
              loading="lazy"
              className="mx-auto max-w-[140px]"
            />
          )}
        </div>
        <div className="flex flex-col gap-7">
          {block1Description && (
            <div className="mx-auto max-w-[325px] text-center font-body text-3xl font-normal text-indigo-200">
              {block1Description}
            </div>
          )}
          {block1CTAText && (
            <Button
              id={block1CTAId}
              onClick={block1CTAOnClick}
              btnType={block1CTAVariant}
              className="w-full"
            >
              {block1CTAText}
            </Button>
          )}
        </div>
      </div>

      <div
        className={
          "mx-auto flex flex-col items-center justify-between gap-7 self-stretch rounded-[40px] bg-white/[6%] p-8 shadow-[0px_0px_80px_0px_#001B36] sm:h-[425px] sm:w-[425px]" +
          (block2Featured ? " " + featuredBlockStyles : "")
        }
      >
        <div className="flex flex-col gap-7">
          <h3 className="font-display text-4xl font-bold text-white">
            {block2Title}
          </h3>
          {block2SubTitle && (
            <div className="flex flex-col items-center justify-start gap-2 font-display text-6xl font-bold text-white">
              {block2SubTitle}
              {block2Subtext && (
                <div className="font-body text-2xl font-normal text-cerulean-500">
                  {block2Subtext}
                </div>
              )}
            </div>
          )}
          {block2ImageSrc && (
            <img
              src={block2ImageSrc}
              alt={block2Title}
              loading="lazy"
              className="mx-auto max-w-[140px]"
            />
          )}
        </div>
        <div className="flex flex-col gap-7">
          {block2Description && (
            <div className="mx-auto max-w-[325px] text-center font-body text-3xl font-normal text-indigo-200">
              {block2Description}
            </div>
          )}
          {block2CTAText && (
            <Button
              id={block2CTAId}
              onClick={block2CTAOnClick}
              btnType={block2CTAVariant}
              className="w-full"
            >
              {block2CTAText}
            </Button>
          )}
        </div>
      </div>

      <div
        className={
          "mx-auto flex flex-col items-center justify-between gap-7 self-stretch rounded-[40px] bg-white/[6%] p-8 shadow-[0px_0px_80px_0px_#001B36] sm:h-[425px] sm:w-[425px]" +
          (block3Featured ? " " + featuredBlockStyles : "")
        }
      >
        <div className="flex flex-col gap-7">
          <h3 className="font-display text-4xl font-bold text-white">
            {block3Title}
          </h3>
          {block3SubTitle && (
            <div className="flex flex-col items-center justify-start gap-2 font-display text-6xl font-bold text-white">
              {block3SubTitle}
              {block3Subtext && (
                <div className="font-body text-2xl font-normal text-cerulean-500">
                  {block3Subtext}
                </div>
              )}
            </div>
          )}
          {block3ImageSrc && (
            <img
              src={block3ImageSrc}
              alt={block3Title}
              loading="lazy"
              className="mx-auto max-w-[140px]"
            />
          )}
        </div>
        <div className="flex flex-col gap-7">
          {block3Description && (
            <div className="mx-auto max-w-[325px] text-center font-body text-3xl font-normal text-indigo-200">
              {block3Description}
            </div>
          )}
          {block3CTAText && (
            <Button
              id={block3CTAId}
              onClick={block3CTAOnClick}
              btnType={block3CTAVariant}
              className="w-full"
            >
              {block3CTAText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
