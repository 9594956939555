import { useEffect } from "react";
import closeIcon from "../../global-assets/assets/icon-close-gray.svg";

interface ModalClosableProps {
  isOpen: boolean;
  setIsOpen: Function;
  children: JSX.Element;
}

export default function ModalClosable({
  isOpen,
  setIsOpen,
  children,
}: ModalClosableProps) {
  useEffect(() => {
    // Prevents body from being scrollable while the modal is open
    document.body.style.overflow = "hidden";
  });

  const handleClose = () => {
    document.body.style.overflow = "unset";
    setIsOpen(false);
  };

  return (
    <div
      className={
        "fixed bottom-0 left-0 right-0 top-0 z-[9999] hidden items-center justify-center p-4" +
        (isOpen ? " !flex " : "")
      }
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[rgba(0,32,63,0.95)]" />
      <div
        className="tru-about-modal-purple-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-70"
        onClick={() => handleClose()}
      />
      <div className="relative flex w-full max-w-[785px] flex-col items-stretch justify-between gap-x-6 overflow-auto rounded-[20px] bg-white p-4 sm:p-8 md:rounded-[30px] lg:p-9">
        {children}
      </div>
      <div
        id="tru_modal_close_btn"
        className="absolute right-0 top-0 cursor-pointer pb-2 pl-2 pr-6 pt-6 text-white hover:opacity-100 md:opacity-70"
        role="button"
        onClick={() => handleClose()}
      >
        <img src={closeIcon} loading="lazy" alt="" />
      </div>
    </div>
  );
}
