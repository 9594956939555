import { useEffect, useState } from "react";
import { ClearAllCookies, navigateTo } from "../../../config/UserAuthContext";
import { GetContinueToQueryString } from "../../../lib/PassedParams";
import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.png";
import UserIcon from "../../../global-assets/assets/User_Icon.svg";
import closeIcon from "../../../global-assets/assets/icon-close-white.svg";
import { GetOrCreateSessionAuthContext } from "../../../config/UserAuthContext";
import AccountServiceClient from "../../../lib/ApiFunction";

interface UserMenuProps {
  setIsOpen: Function;
  isOpen: boolean;
  userEmail: string;
  userIconRightCoord?: { right: number | undefined };
  menuRef: any;
}

const menuItemStyles =
  "hover:bg-menu_active flex cursor-pointer items-center justify-start p-6 text-[1.4rem]" +
  "font-medium leading-5 text-white hover:drop-shadow-[0px_0px_16px_#9CD7D4]";

export default function UserMenu({
  setIsOpen,
  isOpen,
  userEmail,
  userIconRightCoord,
  menuRef,
}: UserMenuProps) {
  const userIconRight = userIconRightCoord?.right;
  const isVScrollBarVisible =
    document.body.scrollHeight > document.body.clientHeight;
  const vScrollBarWidth = window.innerWidth - document.body.clientWidth;
  const userContext = GetOrCreateSessionAuthContext();
  const subscriptionInfo = userContext?.subscription?.sources || [];
  const profiles: string | any[] = [];
  const { ManageStripeSubscription } = AccountServiceClient();
  const { GetProfileInfo } = AccountServiceClient();
  const fetchProfileInfo = async () => {
    try {
      const response = await GetProfileInfo.get("", {
        headers: { Authorization: `Bearer ${userContext.token}` },
      });

      const profiles = response.data.profiles;
      console.log("Profiles:", profiles);
    } catch (error) {
      console.error("Error fetching profile info:", error);
    }
  };

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const handleManageSubscription = async () => {
    let continueUrlVar = "https://www-dev";

    if (window.location.hostname === "localhost") {
      const port = window.location.port ? `:${window.location.port}` : "";
      continueUrlVar = `http://localhost${port}`;
    } else {
      switch (process.env.REACT_APP_ENVIRONMENT) {
        case "stg":
          continueUrlVar = "https://www-stg";
          break;
        case "prod":
          continueUrlVar = "https://www";
          break;
      }
    }

    try {
      const res = await ManageStripeSubscription.post("", {
        returnUrl: `${continueUrlVar}.truplaygames.com/account-settings`,
        returnOnSuccessUrl: `${continueUrlVar}.truplaygames.com/account-settings`,
      });
      window.open(res.data.portalUrl);
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
    }
  };

  return (
    <div
      ref={menuRef}
      className={
        "tru-menu-blur absolute right-0 top-0 z-[1000] hidden w-[calc(100%-81px)] rounded-es-3xl bg-nav_manu" +
        " border-2 border-r-0 border-t-0 border-solid border-indigo-400/80 sm:w-[calc(100%-85px)] md:w-[28rem]" +
        (isOpen ? " !block" : "")
      }
      style={
        typeof userIconRight === "number" &&
        window.innerWidth - userIconRight > 389
          ? {
              minWidth:
                window.innerWidth -
                userIconRight +
                74 -
                (isVScrollBarVisible ? vScrollBarWidth : 0) +
                "px",
            }
          : {}
      }
    >
      <div className="flex h-[104px] items-center justify-between px-4 py-6 sm:px-6 md:hidden md:h-[114px]">
        <div className="cursor-pointer" onClick={() => navigateTo("/")}>
          <img
            src={TruPlayLogo}
            alt="Truplay-logo"
            className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
            id="user_auth_tru_logo"
          />
        </div>
        <div
          role="button"
          id="user_nav_icon_close"
          className="-mb-1 -mr-2 ml-2 flex w-[56px] cursor-pointer items-center justify-center"
          onClick={() => setIsOpen(false)}
        >
          <img src={closeIcon} loading="lazy" alt="" className="w-[30px]" />
        </div>
      </div>
      <div className="flex items-center justify-start px-6 py-[33px] text-[1.4rem] font-medium text-white">
        <div
          className={`relative flex min-h-12 min-w-12 cursor-pointer items-center justify-center 
        rounded-full duration-200 hover:scale-110 hover:shadow-[0_3px_20px_rgba(56,166,213,0.6)]`}
          style={{
            backgroundImage: `radial-gradient(197.41% 100% at 50% 100%, ${
              profiles[0]?.bgColor1 || "#7ac9c5"
            } 0%, ${profiles[0]?.bgColor2 || "#38a6d5"} 100%)`,
          }}
          onClick={() => setIsOpen(false)}
        >
          <img
            src={UserIcon}
            alt="User Icon"
            className="h-[1.1rem] stroke-black"
          />
        </div>
        <div className="max-w-[calc(100% - 60px)] ml-4 flex flex-wrap">
          <div className="overflow-hidden text-ellipsis">
            {userEmail.substring(0, userEmail.indexOf("@"))}
          </div>
          <div className="overflow-hidden text-ellipsis">
            {userEmail.substring(userEmail.indexOf("@"), userEmail.length)}
          </div>
        </div>
      </div>
      <div>
        <div
          id="user_account_settings_link"
          role="button"
          onClick={() => navigateTo("/account-settings")}
          className={
            menuItemStyles +
            (window.location.pathname === "/account-settings"
              ? " pointer-events-none bg-dropdown_active"
              : "")
          }
        >
          Account Settings
        </div>
        <div
          id="user_subscription_link"
          role="button"
          onClick={() => navigateTo("/select-plan")}
          className={
            menuItemStyles +
            (window.location.pathname === "/select-plan"
              ? " pointer-events-none bg-dropdown_active"
              : "") +
            (subscriptionInfo.length && subscriptionInfo[0].isActive
              ? " hidden"
              : "")
          }
        >
          Subscription
        </div>
        <div
          id="user_subscription_link"
          role="button"
          onClick={handleManageSubscription}
          className={
            menuItemStyles +
            (subscriptionInfo.length && subscriptionInfo[0].isActive
              ? ""
              : " !hidden")
          }
        >
          Manage Subscription
        </div>
        <div
          id="user_redeem_gift_link"
          role="button"
          onClick={() => navigateTo("/redeem")}
          className={
            menuItemStyles +
            (window.location.pathname === "/redeem"
              ? " pointer-events-none bg-dropdown_active"
              : "")
          }
        >
          Redeem Gift
        </div>
        <div
          id="user_download_app_link"
          role="button"
          onClick={() => navigateTo("/download-app")}
          className={
            menuItemStyles +
            (window.location.pathname === "/download-app"
              ? " pointer-events-none bg-dropdown_active"
              : "")
          }
        >
          Download App
        </div>
        <div
          id="mobile_log_out_btn"
          role="button"
          className={
            "border-t-solid flex items-center justify-start hover:bg-dropdown_active" +
            " border-t-2 border-t-indigo-700 p-6 font-display text-xl font-bold leading-5" +
            " tracking-wide text-cerulean-500 hover:rounded-es-3xl hover:text-white"
          }
          onClick={() => {
            ClearAllCookies();
            navigateTo(`/login${GetContinueToQueryString()}`);
          }}
        >
          <span>Log Out</span>
        </div>
      </div>
    </div>
  );
}
