import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface FeatureFlagContextState {
  [key: string]: {
    variationId?: string;
  };
}

const FeatureFlagContext = createContext<FeatureFlagContextState | undefined>(
  undefined
);

interface FeatureFlagProviderProps {
  children: ReactNode;
}

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const [flags, setFlags] = useState<FeatureFlagContextState>({});

  useEffect(() => {
    const handleVWOChange = (event: CustomEvent) => {
      const { experimentId, variationId, abTestName, variationName } =
        event.detail;

      console.log(`Experiment ID: ${experimentId}`);
      console.log(`Variation ID: ${variationId}`);
      console.log(`A/B Test Name: ${abTestName}`);
      console.log(`Variation Name: ${variationName}`);

      setFlags((prevFlags) => ({
        ...prevFlags,
        [abTestName]: {
          variationId,
        },
      }));
    };

    window.addEventListener("VWOChange", handleVWOChange as EventListener);

    return () => {
      window.removeEventListener("VWOChange", handleVWOChange as EventListener);
    };
  }, []);

  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = (): FeatureFlagContextState => {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error("useFeatureFlags must be used within FeatureFlagProvider!");
  }
  return context;
};
