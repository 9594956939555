import { useContext, useEffect, useState } from "react";
import GiftImageDesktop from "../../global-assets/assets/Gift_Image.png";
import AccentStars from "../../global-assets/assets/Accent_Stars_02.svg";
import ApiInstance from "../../lib/ApiFunction";
import {
  isAuthenticatedWithValidToken,
  navigateTo,
} from "../../config/UserAuthContext";
import { GetContinueToQueryString } from "../../lib/PassedParams";
import { toast } from "react-toastify";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import { Helmet } from "react-helmet";
import { ErrorCodeMap } from "../../lib/Constants";
import Button from "../../components/common/Button";

/**
 * Subscription Page
 * @constructor
 */
const RedeemCode = () => {
  const analyticsContext = useContext(MixPanelAnalyticsContext);

  const urlParams = new URLSearchParams(window.location.search);
  const passedRedeemCode = decodeURIComponent(urlParams.get("code") || "");

  const DEFAULT_ERROR = "Error processing code. Please, try again.";

  const getErrorMessageForCode = (code: string) => {
    if (ErrorCodeMap[code]) {
      return ErrorCodeMap[code];
    }
    return DEFAULT_ERROR;
  };

  useEffect(() => {
    if (!isAuthenticatedWithValidToken()) {
      navigateTo("/user-registration" + GetContinueToQueryString());
    }
  });

  const { RedeemGift } = ApiInstance();
  const [userInputValidationErrorMsg, setUserInputValidationErrorMsg] =
    useState<string>("");

  const [uploading, setUploading] = useState<boolean>(false);
  const [code, setCode] = useState<string>(passedRedeemCode);

  const onCodeChange = (e: any) => {
    setCode(e.target.value.replace(/[^a-zA-Z0-9]/g, ""));
  };

  let submitCode = async (e: any) => {
    setUploading(true);
    const sanitizedCode = code.replace(/[^a-zA-Z0-9]/g, "");
    const data = {
      redemptionCode: sanitizedCode,
    };
    setUserInputValidationErrorMsg("");
    RedeemGift.post("", data, {})
      .then(() => {
        analyticsContext.track("Web: Redeem-Gift");
        navigateTo("/download-app");
      })
      .catch((error) => {
        setUploading(false);
        analyticsContext.track("Web: Redeem-Gift-Failed");
        const errorCode = error.response.data.statusCode || "Unknown_Error";
        const errorMsg = getErrorMessageForCode(errorCode);
        setUserInputValidationErrorMsg(errorMsg);
        toast.error(errorMsg, {
          position: "top-center",
        });
      });
  };

  return (
    <div id="test_data_redeem_page" className="min-h-screen">
      <Helmet>
        <link rel="canonical" href="https://www.truplaygames.com/redeem" />
      </Helmet>
      <div className="tru-redeem-background-blue absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-50" />
      <div className="tru-redeem-background-green-purple absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-30" />
      <div className="relative overflow-hidden px-5 py-12 pt-32 sm:px-6 sm:pb-24 md:px-7 md:pb-32 lg:px-12">
        <div className="mx-auto flex max-w-[1440px] flex-col gap-6">
          <div className="flex flex-col gap-8 lg:flex-row">
            <div className="flex flex-col justify-center gap-4 lg:flex-1">
              <div className="flex flex-col gap-4 lg:gap-12">
                <div className="flex flex-col gap-4">
                  <h2 className="text-transparent relative whitespace-nowrap bg-gradient_text bg-clip-text text-center lg:max-w-[443px] lg:text-left">
                    Redeem Your Gift
                    <img
                      src={AccentStars}
                      alt="Accent Stars"
                      className="absolute -right-[90px] -top-[35px] hidden lg:block"
                    />
                  </h2>
                  <p className="m-0 text-center text-white lg:text-left">
                    Enter the Gift Code you received to start enjoying Christian
                    games and content for the whole family!
                  </p>
                </div>
                <img
                  src={GiftImageDesktop}
                  alt="Maple Gift"
                  loading="lazy"
                  className="max-w-[500px] sm:self-center lg:hidden"
                />
                <div className="flex w-full flex-col gap-6 lg:flex-1">
                  <input
                    id="redeem_code_input"
                    className={
                      "relative flex w-full rounded-lg border-0 bg-black/25" +
                      " px-2.5 py-4 text-left text-base text-white/60 outline-none" +
                      " placeholder:text-white/60 focus:bg-black/25" +
                      " focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-white/80" +
                      (userInputValidationErrorMsg
                        ? " ring-2 ring-raspberry-500"
                        : "")
                    }
                    placeholder="Enter Code"
                    value={code}
                    onChange={onCodeChange}
                  />
                  {userInputValidationErrorMsg && (
                    <div
                      id="tru-input-error-message"
                      className="px-1 text-xs font-medium text-raspberry-500"
                    >
                      {userInputValidationErrorMsg}
                    </div>
                  )}
                  <Button
                    id="redeem_gift_btn"
                    loading={uploading}
                    onClick={submitCode}
                    btnType="green"
                    className="w-full"
                  >
                    Redeem Gift
                  </Button>
                </div>
              </div>
            </div>
            <div className="hidden items-center justify-center lg:order-1 lg:flex lg:flex-1">
              <img src={GiftImageDesktop} alt="Maple Gift" loading="lazy" />
            </div>
          </div>
          <div className="mx-auto flex flex-col items-center justify-center gap-2 text-center">
            <p className="m-0 text-white">Don't have a Gift Code?</p>
            <Button id="choose_a_subscription" btnType="tertiaryAlt">
              <a
                id="redeem_choose_subscription"
                href="/select-plan"
                className="font-display font-semibold hover:underline"
              >
                Choose a Subscription
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemCode;
