import { useEffect, useState } from "react";
import accentStars2 from "../../../global-assets/assets/Accent_Stars_02.svg";

interface LogoImg {
  id: string;
  image: string;
}

interface Logos {
  [key: string]: LogoImg;
}

interface EndorsementsProps {
  title: string;
  mobileTitle: string;
  logos: Logos;
  noBackground?: boolean;
}

export default function EndorsementsLogos({
  title,
  mobileTitle,
  logos,
  noBackground,
}: EndorsementsProps) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [intervalActive] = useState<boolean>(true);

  useEffect(() => {
    if (intervalActive) {
      let interval1 = setInterval(() => {
        const newIndex = (activeIndex + 1) % Object.keys(logos).length;
        setActiveIndex(newIndex);
      }, 5000);
      return () => clearInterval(interval1);
    }
  }, [activeIndex, intervalActive, logos]);

  const activeLogo = Object.values(logos)[activeIndex];

  return (
    <div
      className={
        "relative z-[3] mx-auto flex w-full flex-col items-center justify-center gap-4 rounded-[30px] px-8 py-10 shadow-[0_-15px_80px_rgba(0,0,0,0.5)] lg:rounded-[40px] lg:p-12" +
        (noBackground ? " !bg-none !shadow-none" : "")
      }
    >
      <div className="flex w-full flex-col items-center justify-center text-center">
        <h2 className="text-transparent relative mb-4 hidden bg-gradient_text bg-clip-text lg:block">
          {title}
          <img
            src={accentStars2}
            loading="lazy"
            alt=""
            className="absolute -right-[95px] -top-[30px]"
          />
        </h2>
        <h2 className="text-transparent mb-4 bg-gradient_text bg-clip-text lg:hidden">
          {mobileTitle}
        </h2>
      </div>
      <div className="hidden w-full flex-1 flex-row items-center gap-16 lg:flex">
        {Object.entries(logos).map(([index, logo]) => (
          <div
            className={
              "mx-auto flex max-w-[250px] flex-1 items-center justify-center sm:max-w-[300px] md:max-w-[400px] lg:max-w-none" +
              (index === "christianityToday" ? " lg:!flex-[2_1_0%]" : "") +
              (index === "candace" ? " lg:!flex-[0.5_1_0%]" : "")
            }
            key={index}
          >
            <img
              loading="eager"
              alt=""
              src={logo.image}
              className={
                index === "christianityToday" || index === "candace"
                  ? " max-h-none"
                  : ""
              }
            />
          </div>
        ))}
      </div>
      <div
        key={activeLogo.id}
        className="tru-slide-in-anim relative inline-block h-full w-full whitespace-normal text-left align-top lg:!hidden"
      >
        <div className="flex h-[250px] w-full flex-col items-center justify-start gap-5 text-center">
          <div
            className={
              "mx-auto flex max-w-[250px] flex-1 items-center justify-center sm:max-w-[300px] md:max-w-[400px] lg:max-w-none" +
              (activeLogo.id === "candace"
                ? " !max-w-[150px] sm:!max-w-[200px]"
                : "")
            }
          >
            <img loading="eager" alt="" src={activeLogo.image} />
          </div>
        </div>
      </div>
    </div>
  );
}
