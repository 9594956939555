import { useState } from "react";
import Video from "../../common/Video";

// Assets //
import caratarrow from "../../../global-assets/assets/caratarrow.svg";
import curvewhite from "../../../global-assets/assets/curvewhite.svg";
import ThumbRhymverse from "../../../global-assets/assets/thumbnail_rhymverse.webp";

const VIDEO_ID = 917223101;

export default function GamesMoreRhymverse() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative overflow-visible pb-40 pt-24 md:pb-64">
      <div className="mx-auto flex w-full flex-col items-center gap-6 px-4 md:flex-row md:gap-8 lg:gap-20 2xl:max-w-[1440px]">
        <div className="mb-4 w-full overflow-hidden rounded-[40px] shadow-video_block md:mb-0">
          <Video
            url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/welcome_to_rhym%20(720p).mp4"
            videoId={VIDEO_ID}
            shareUrl={"https://vimeo.com/" + VIDEO_ID}
            playsInline
            thumbnail={ThumbRhymverse}
            width="100%"
            height="100%"
            controls
            iPadView
            borderColor="bg-[#ffffff0f]"
            autoPlay={false}
            loop={true}
            muted={true}
            shareSmall
          />
        </div>
        <div className="w-full">
          <div className="text-center md:text-left">
            <h2 className="text-transparent mb-4 bg-gradient_text bg-clip-text font-bold">
              Discover the World of Rhym
            </h2>
            <div className="relative">
              <div
                style={{
                  height: isOpen ? "730px" : "143px",
                }}
                className="ease overflow-hidden transition-height duration-1000"
              >
                <p className="mb-0 text-white">
                  The RhymVerse is a place of endless adventure. Players will
                  journey from the depths of the sea to outer space and
                  everywhere in between. They will traverse scorching deserts
                  and explore forgotten forests. There is no limit to the
                  storytelling and gameplay of the RhymVerse.
                  <br />
                  <br />
                  Our games do more than entertain. We directly touch users'
                  minds and hearts by embedding Biblical truths into exciting
                  and creative storylines. Our characters follow the same path
                  as our players, facing real, 'down-to-earth' problems. They
                  mature, grow, experience grace, and understand God at their
                  own pace.
                  <br />
                  <br />
                  Over the course of several games, animations, and digital
                  comics, all intentionally intertwined into the RhymVerse
                  meta-narrative, our players will get to know Maple, Lucas,
                  Ava, Benjamin, and the others, as they fight to save their
                  world from villains who seek to rule over it.
                </p>
              </div>
              <div
                className="mt-6 flex cursor-pointer items-center justify-end font-display text-lg font-bold uppercase tracking-widest text-white"
                onClick={toggleFAQ}
              >
                <div>{isOpen ? "Less" : "More"}</div>
                <img
                  src={caratarrow}
                  loading="lazy"
                  alt=""
                  style={{
                    transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
                  }}
                  className="ml-2 transition-transform duration-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={curvewhite}
        loading="lazy"
        alt=""
        className="absolute bottom-0 w-full"
      />
    </div>
  );
}
