import { useState } from "react";
import { ClearAllCookies, navigateTo } from "../../../config/UserAuthContext";
import { GetContinueToQueryString } from "../../../lib/PassedParams";
import TruPlayLogo from "../../../global-assets/assets/truplay-logo-large.png";
import CloseIcon from "../../../global-assets/assets/icon-close-white.svg";
import CaretDown from "../../../global-assets/assets/icon-caret-down.svg";
import Button from "../../common/Button";

interface MobileLeftNavMenuProps {
  isAuthenticated: any;
  closeMenu: Function;
  isOpen: boolean;
  menuRef: any;
}

const dropdownItemStyles =
  "hover:bg-dropdown_active whitespace-nowrap px-6 py-4 text-left text-lg font-normal" +
  " leading-6 text-white duration-200";

export default function MobileLeftNavMenu({
  isAuthenticated,
  closeMenu,
  isOpen,
  menuRef,
}: MobileLeftNavMenuProps) {
  const [isMoreMenuOpen, setMoreMenuOpen] = useState<boolean>(false);

  const handleTeamLinkClick = () => {
    if (window.location.pathname === "/about") {
      const section = document.getElementById("team-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigateTo("/about#team-section");
    }
  };

  const logIn = () => {
    navigateTo(`/login${GetContinueToQueryString()}`);
  };

  const logOut = () => {
    ClearAllCookies();
    navigateTo(`/login${GetContinueToQueryString()}`);
  };

  return (
    <div
      ref={menuRef}
      className={
        "tru-menu-blur absolute left-0 top-0 z-[1000] hidden min-w-[18rem] rounded-ee-3xl border-2 border-l-0 border-t-0 border-solid border-indigo-400/80 bg-nav_manu" +
        (isOpen ? " !block lg:!hidden" : "")
      }
    >
      <div className="flex h-[104px] items-center p-6">
        <div
          role="button"
          id="mobile_nav_icon_close"
          className="-mb-1 -ml-2 mr-2 flex w-[56px] cursor-pointer items-center justify-center sm:mr-4"
          onClick={() => closeMenu(false)}
        >
          <img src={CloseIcon} loading="lazy" alt="" className="w-[30px]" />
        </div>
        <div className="cursor-pointer" onClick={() => navigateTo("/")}>
          <img
            src={TruPlayLogo}
            alt="Truplay-logo"
            className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
            id="mobile_unauth_tru_logo"
          />
        </div>
      </div>
      <div>
        <div className="flex justify-center px-6 py-4">
          <Button
            id="give_as_gift_btn"
            onClick={() => {
              navigateTo("/gift");
            }}
            btnType="secondary"
            className="w-full !py-3"
          >
            Give as Gift
          </Button>
        </div>
        {/* <div
          id="mobile_kickstarter_link"
          role="button"
          onClick={() => navigateTo("/support-our-mission")}
          className={
            "flex cursor-pointer items-center justify-start p-6 font-display text-xl font-bold leading-5 tracking-wide text-white hover:bg-dropdown_active" +
            (window.location.pathname.indexOf("/support-our-mission") !== -1
              ? " pointer-events-none bg-dropdown_active"
              : "")
          }
        >
          Kickstarter
        </div> */}
        <div
          id="mobile_games_more_link"
          role="button"
          onClick={() => navigateTo("/games")}
          className={
            "flex cursor-pointer items-center justify-start p-6 font-display text-xl font-bold leading-5 tracking-wide text-white hover:bg-dropdown_active" +
            (window.location.pathname.indexOf("/games") !== -1
              ? " pointer-events-none bg-dropdown_active"
              : "")
          }
        >
          Games
        </div>
        <div
          id="mobile_about_dropdown"
          role="button"
          onClick={() => setMoreMenuOpen(!isMoreMenuOpen)}
          className={
            "flex cursor-pointer items-center justify-start p-6 font-display text-xl font-bold leading-5 tracking-wide text-white hover:bg-menu_active hover:drop-shadow-[0px_0px_16px_#9CD7D4]" +
            (isMoreMenuOpen
              ? " bg-menu_active drop-shadow-[0px_0px_16px_#9CD7D4]"
              : "")
          }
        >
          <div>More</div>
          <div className="absolute right-4 flex w-[56px] items-center justify-center">
            <img
              src={CaretDown}
              alt="caret down"
              loading="lazy"
              className="w-[28px]"
            />
          </div>
        </div>
        {isMoreMenuOpen && (
          <div className="relative flex max-h-[200px] w-full cursor-pointer flex-col flex-nowrap justify-start overflow-y-scroll bg-indigo-900/50">
            <a
              id="mobile_merchandise_link"
              href="https://truplaygames.shop/"
              rel="noreferrer"
              target="_blank"
              className={dropdownItemStyles}
            >
              Merchandise
            </a>
            <div
              id="mobile_free_content_link"
              role="button"
              onClick={() => navigateTo("/free-content")}
              className={
                dropdownItemStyles +
                (window.location.pathname.indexOf("/free-content") !== -1
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              Free Content
            </div>
            <div
              id="mobile_who_we_are_link"
              role="button"
              onClick={() => navigateTo("/about")}
              className={
                dropdownItemStyles +
                (window.location.pathname.indexOf("/about") !== -1
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              About Us
            </div>
            <a
              id="mobile_news_link"
              href="https://www.truplaygames.com/resources/news"
              rel="noreferrer"
              target="_blank"
              className={dropdownItemStyles}
            >
              News
            </a>
            <div
              id="mobile_affiliate_link"
              role="button"
              onClick={() => navigateTo("/affiliate")}
              className={
                dropdownItemStyles +
                (window.location.pathname === "/affiliate"
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              Refer A Friend
            </div>
            <div
              id="mobile_contact_link"
              role="button"
              onClick={() => navigateTo("/contact")}
              className={
                dropdownItemStyles +
                (window.location.pathname.indexOf("/contact") !== -1
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              Contact
            </div>
          </div>
        )}
        <div
          className="border-t-solid group flex cursor-pointer items-center justify-start rounded-ee-3xl border-t-2 border-t-indigo-700 p-6 hover:bg-dropdown_active"
          onClick={isAuthenticated ? () => logOut() : () => logIn()}
        >
          <div
            id="mobile_log_in_out_btn"
            role="button"
            className="font-display text-xl font-bold leading-5 tracking-wide text-cerulean-500 group-hover:!text-white"
          >
            {isAuthenticated ? "Log Out" : "Log In"}
          </div>
        </div>
      </div>
    </div>
  );
}
