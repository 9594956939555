import ActionBibleSlider from "../ActionBibleSlider/ActionBibleSlider";
import "./GamesMoreActionBible.css";
// Assets //
import ActionBibleLogo from "../../../global-assets/assets/logo-action-bible.png";

export default function GamesMoreActionBible() {
  return (
    <div className="relative flex items-center justify-center overflow-hidden px-5 py-12">
      <div className="absolute inset-0 z-[-3]">
        <div className="tru-action-bible-section-bg-overlay absolute bottom-0 left-0 right-0 top-0 !bg-cover !bg-no-repeat opacity-25" />
      </div>
      <div className="ml-auto mr-auto max-w-[1440px]">
        <div className="2xl:gap-40 flex flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-16 lg:gap-20">
          <div className="flex flex-[0.5] flex-col gap-4">
            <img
              className="max-w-[120px] lg:max-w-[180px]"
              src={ActionBibleLogo}
              alt="Action Bible"
              loading="lazy"
            />
            <h2 className="m-0 text-white">The Action Bible</h2>
            <div className="max-w-[1040px]">
              <p className="m-0 text-white">
                Introducing your gateway to the most complete picture Bible
                ever! This exclusive TruPlay digital comic series boasts a
                captivating and visually rich art style that will captivate
                readers of all ages. Immerse yourself in a one-of-a-kind
                experience in which Bible stories come to life through rich,
                engaging narration and captivating performances. Explore the
                Bible like never before with The Action Bible.
              </p>
            </div>
          </div>
          <div className="mx-auto flex w-full max-w-[350px] flex-[0.5] flex-col gap-4 md:max-w-[500px]">
            <ActionBibleSlider />
          </div>
        </div>
      </div>
    </div>
  );
}
