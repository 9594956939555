// Images
import AppleQrCode from "../../../global-assets/assets/AppleQrCode.png";
import GoogleQrCode from "../../../global-assets/assets/GplayQRCode.png";
import AppStore from "../../../global-assets/assets/AStore.png";
import GooglePlay from "../../../global-assets/assets/GPlay.png";

export default function AccountShare() {
  return (
    <div
      className={
        "tru-accinfo-container-bg rounded-3xl border-[3px] border-solid" +
        " border-x-[rgba(32,86,139,0.7)] border-b-[rgba(32,86,139,1)]" +
        " bg-gradient_acc_info border-t-[rgba(32,86,139,0.2)] p-4" +
        " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
      }
    >
      <div>
        <div className="self-baseline">
          <h2 className="mb-8 text-2xl tracking-wide text-white sm:text-4xl">
            Share Truplay
          </h2>
        </div>
        <div className="flex flex-col items-center justify-center gap-24 md:flex-row">
          <div className="text-center xs:w-[100%] sm:w-[50%] md:w-[25%]">
            <img
              src={AppStore}
              alt="Apple QR code"
              className="mb-4"
              style={{ maxWidth: "217px" }}
            />
            <img
              src={AppleQrCode}
              alt="Truplay QR code"
              style={{ maxWidth: "217px" }}
              className="rounded-md"
            />
          </div>
          <div className="text-center xs:w-[100%] sm:w-[50%] md:w-[25%]">
            <img
              src={GooglePlay}
              alt="Google QR code"
              className="mb-4"
              style={{ maxWidth: "217px" }}
            />
            <img
              src={GoogleQrCode}
              alt="Truplay QR code"
              style={{ maxWidth: "217px" }}
              className="rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
