import { useContext } from "react";
import { MixPanelAnalyticsContext } from "../../analytics/Analytics";
import {
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../../config/UserAuthContext";
import TagManager from "react-gtm-module";
import Benjamin from "../../../global-assets/assets/benjamin.png";
import Button from "../../common/Button";
import Cookies from "js-cookie";
import BenjaminBG from "./assets/benjamin_bg.jpg";

interface BenjaminCTAProps {
  title: string;
  mobileTitle: string;
  buttonText: string;
  mobileButtonText: string;
  ctaAction: string | (() => void);
  lightBackground?: boolean;
}

const BenjaminCTA = ({
  title,
  mobileTitle,
  buttonText,
  mobileButtonText,
  ctaAction,
  lightBackground,
}: BenjaminCTAProps) => {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");

  const handleCTAClick = () => {
    if (typeof ctaAction !== "string") {
      return;
    }
    mixPanelAnalytics.track("Web: LandingPage-Page-Ben-CTA-Clicked");

    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        os_type: osType,
        content_type: "button cta",
        link_name: "Ben",
        link_url: ctaAction,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo(ctaAction);
  };

  return (
    <>
      <div
        className="relative hidden h-48 w-full items-center justify-center rounded-[40px] bg-cover bg-[100%] bg-no-repeat p-5 sm:h-56 homemd:h-64 md:h-72 md:p-8 lg:flex lg:h-80 xl:h-[22rem]"
        style={{ backgroundImage: `url('${BenjaminBG}')` }}
      >
        <div className="h-full w-full flex-1">
          <img
            src={Benjamin}
            loading="lazy"
            alt=""
            className="absolute -left-[30px] bottom-[10px] w-[270px] sm:w-[320px] homemd:-left-[20px] md:bottom-[20px] md:w-[400px] lg:left-0 lg:w-auto xl:left-[10%]"
          />
        </div>
        <div className="z-[3] flex flex-1 flex-col items-center justify-center">
          <div className="flex max-w-[30rem] flex-col gap-6">
            <div className="text-center font-display text-[20px] font-bold text-white sm:text-[25px] md:text-[40px] lg:text-[54px]">
              {title}
            </div>
            <Button
              id="games_more_benjamin_btn"
              onClick={
                typeof ctaAction === "function" ? ctaAction : handleCTAClick
              }
              btnType="green"
              className="w-full"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
      <div className="mx-auto flex max-h-[368px] max-w-[1440px] flex-col gap-8 lg:hidden">
        <div
          className={
            "text-center font-display text-[20px] font-bold sm:text-[25px] md:text-[40px]" +
            (!lightBackground ? " text-white" : "")
          }
        >
          {mobileTitle}
        </div>
        <Button
          id="games_more_benjamin_btn_mobile"
          onClick={typeof ctaAction === "function" ? ctaAction : handleCTAClick}
          btnType="green"
          className="w-full"
        >
          {mobileButtonText}
        </Button>
      </div>
    </>
  );
};

export default BenjaminCTA;
