import "./CookieConsentModal.css";
import closeIcon from "../../../global-assets/assets/icon-close-gray.svg";
import Button from "../../common/Button";
interface CookieConsentProps {
  onClose: any;
}

export function CookieConsentModal({ onClose }: CookieConsentProps) {
  return (
    <div className="cookie-consent-container">
      <img
        className="close-button"
        src={closeIcon}
        onClick={onClose}
        loading="lazy"
        alt=""
      />
      <div className="cookie-consent-inner-text">
        <p>
          Most cookies we eat, but TruPlay uses cookies on our website to
          deliver a better experience.
        </p>
        <a href="/privacy-policy#third-party-cookies" target="blank">
          Learn More
        </a>
      </div>
      <Button id="cookie_consent_btn" onClick={onClose} btnType="blue">
        Okay!
      </Button>
    </div>
  );
}
