import { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";

// Images //
import ShareIcon from "./assets/icon-share.svg";
import CloseIcon from "../../../global-assets/assets/icon-close-gray.svg";
import FacebookLogo from "./assets/facebook-logo.svg";
import XLogo from "./assets/x-logo.svg";
import PinterestLogo from "./assets/pinterest-logo.svg";
import EmailIcon from "./assets/icon-email-blue.svg";
import LinkIcon from "./assets/icon-link.svg";
import Button from "../Button";

interface ShareButtonProps {
  shareUrl: string;
  pinterestMedia: string;
  small?: boolean; // True if the small styles are desired
  video?: boolean; // True if sharing a video
}

export default function ShareButton({
  shareUrl,
  pinterestMedia,
  small = false,
  video = false,
}: ShareButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const title = "Check out this video!";

  const shareData = {
    url: shareUrl,
  };

  const handleShare = () => {
    if (navigator.share && navigator.canShare(shareData)) {
      navigator.share(shareData);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  return (
    <div>
      <Button
        id="open_share_ui_btn"
        onClick={() => handleShare()}
        className={
          "absolute right-6 top-6 z-10 !rounded-md bg-black/75 p-1.5 hover:bg-cerulean-500 md:right-8 md:top-8 md:!p-2" +
          (isOpen ? " hidden" : " block")
        }
      >
        <img
          className="md:h-unset h-5 w-5 md:w-[unset]"
          src={ShareIcon}
          loading="eager"
          alt="Share"
        />
      </Button>
      <div
        className={
          "absolute left-0 top-0 z-10 hidden h-full w-full items-center justify-center rounded-[40px] bg-black/40" +
          (isOpen ? " lg:flex" : " ")
        }
      >
        <div
          className={
            "relative flex min-w-[40rem] flex-col items-center justify-center gap-6 rounded-[40px] bg-white p-8" +
            (small ? " !min-w-[unset] !gap-4 !p-6" : "")
          }
        >
          <div
            className="absolute right-6 top-6 cursor-pointer opacity-80 hover:opacity-100"
            onClick={() => setIsOpen(false)}
          >
            <img
              className="lg:h-6 lg:w-6"
              src={CloseIcon}
              loading="lazy"
              alt=""
            />
          </div>
          <h5
            className={
              "text-center font-display text-lg font-bold capitalize text-indigo-500 sm:text-2xl md:text-3xl lg:text-4xl" +
              (small ? " !text-2xl" : "")
            }
          >
            Share
          </h5>
          <div
            className={
              "flex w-full items-center justify-between rounded-full bg-cool-gray-200 p-2 pl-6" +
              (small ? " h-14 gap-4 !pl-4 text-base" : "")
            }
          >
            <div className="flex items-center gap-4">
              <img
                src={LinkIcon}
                alt="Link"
                loading="lazy"
                className={small ? " h-6 w-6" : ""}
              />
              <div>{shareUrl}</div>
            </div>
            <Button
              id="copy_share_url_btn"
              onClick={handleCopyUrl}
              btnType="green"
              className={small ? "!h-10 !px-4 !text-base" : ""}
            >
              {copySuccess ? "Copied!" : "Copy"}
            </Button>
          </div>
          <div className="flex items-center justify-center gap-5">
            <div className="flex flex-col items-center">
              <FacebookShareButton url={shareUrl}>
                <div
                  className={
                    "mb-4 flex hover:drop-shadow-share_btn_hover" +
                    " h-24 w-24 items-center justify-center rounded-full" +
                    " bg-white drop-shadow-lg hover:border-2 hover:border-cerulean-500" +
                    (small ? " !h-16 !w-16" : "")
                  }
                >
                  <img
                    src={FacebookLogo}
                    loading="lazy"
                    alt="Facebook"
                    className={small ? " h-8 w-8" : ""}
                  />
                </div>
              </FacebookShareButton>
              <div
                className={
                  "font-medium text-cool-gray-900" + (small ? " text-base" : "")
                }
              >
                Facebook
              </div>
            </div>
            <div className="flex flex-col items-center">
              <TwitterShareButton url={shareUrl}>
                <div
                  className={
                    "mb-4 flex hover:drop-shadow-share_btn_hover" +
                    " h-24 w-24 items-center justify-center rounded-full" +
                    " bg-white drop-shadow-lg hover:border-2 hover:border-cerulean-500" +
                    (small ? " !h-16 !w-16" : "")
                  }
                >
                  <img
                    src={XLogo}
                    loading="lazy"
                    alt="X"
                    className={small ? " h-8 w-8" : ""}
                  />
                </div>
              </TwitterShareButton>
              <div
                className={
                  "font-medium text-cool-gray-900" + (small ? " text-base" : "")
                }
              >
                X
              </div>
            </div>
            <div className="flex flex-col items-center">
              <PinterestShareButton url={shareUrl} media={pinterestMedia}>
                <div
                  className={
                    "mb-4 flex hover:drop-shadow-share_btn_hover" +
                    " h-24 w-24 items-center justify-center rounded-full" +
                    " bg-white drop-shadow-lg hover:border-2 hover:border-cerulean-500" +
                    (small ? " !h-16 !w-16" : "")
                  }
                >
                  <img
                    src={PinterestLogo}
                    loading="lazy"
                    alt="Pinterest"
                    className={small ? " h-8 w-8" : ""}
                  />
                </div>
              </PinterestShareButton>
              <div
                className={
                  "font-medium text-cool-gray-900" + (small ? " text-base" : "")
                }
              >
                Pinterest
              </div>
            </div>
            <div className="flex flex-col items-center">
              <EmailShareButton url={shareUrl} subject={title} body="body">
                <div
                  className={
                    "mb-4 flex hover:drop-shadow-share_btn_hover" +
                    " h-24 w-24 items-center justify-center rounded-full" +
                    " bg-white drop-shadow-lg hover:border-2 hover:border-cerulean-500" +
                    (small ? " !h-16 !w-16" : "")
                  }
                >
                  <img
                    src={EmailIcon}
                    loading="lazy"
                    alt="Email"
                    className={small ? " h-8 w-8" : ""}
                  />
                </div>
              </EmailShareButton>
              <div
                className={
                  "font-medium text-cool-gray-900" + (small ? " text-base" : "")
                }
              >
                Email
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
