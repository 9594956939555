import { useEffect, useState } from "react";
import { GetEmailAddress } from "../../../config/UserAuthContext";

// Assets
import GoogleLogo from "./assets/logo-google.svg";

// Packages
import { toast } from "react-toastify";

// Components
import CreatePassword from "../../auth/AuthModals/CreatePasswordModal/CreatePasswordModal";
import Button from "../../common/Button";

// Context
import { Context } from "../../../config/UserAuthContext";

// Firebase
import { ErrorCodeMap } from "../../../lib/Constants";
import {
  GoogleAuthProvider,
  linkWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../../firebase";
import { useRemoteConfig } from "../../../config/FBRemoteConfigContext";

interface AccountInfoProps {
  setAuth: any;
  setLoginData: any;
}

export default function AccountSignInOptions({
  setAuth,
  setLoginData,
}: AccountInfoProps) {
  const firebaseFlags = useRemoteConfig();
  // const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const EmailAddress: String = GetEmailAddress() || "";
  const [open, setOpen] = useState<boolean>(false);
  const [hasEmailPasswordProvider, setHasEmailPasswordProvider] =
    useState(false);
  const [hasGoogleProvider, setHasGoogleProvider] = useState(false);

  useEffect(() => {
    const checkFirebaseUser = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          await user.reload();
          const emailPasswordProvider = user.providerData.some(
            (p) => p.providerId === "password"
          );
          const googleProvider = user.providerData.some(
            (p) => p.providerId === "google.com"
          );
          setHasEmailPasswordProvider(emailPasswordProvider);
          setHasGoogleProvider(googleProvider);
        } catch (error) {
          console.error("Error reloading user:", error);
          setHasEmailPasswordProvider(false);
          setHasGoogleProvider(false);
        }
      } else {
        setHasEmailPasswordProvider(false);
        setHasGoogleProvider(false);
      }
    });

    return () => checkFirebaseUser();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoogleSignUp = async () => {
    const currentUser = auth.currentUser;
    try {
      setIsCallingAPI(true);

      if (!currentUser) {
        throw new Error("No user is currently signed in");
      }

      const result = await linkWithPopup(currentUser, provider);

      if (result.user.email !== EmailAddress) {
        throw new Error(
          "The Google account email must match your current account email"
        );
      }

      setHasGoogleProvider(true);
      toast.success("Google sign-in successfully enabled");
    } catch (error: any) {
      if (error.code === "auth/credential-already-in-use") {
        toast.error(
          "This Google account is already linked to another account."
        );
      } else if (error.code === "auth/user-token-expired") {
        toast.error(
          "Session expired. Please sign in again to link your Google account."
        );
      } else {
        toast.error(error.message);
      }
      setHasGoogleProvider(false);
      console.error("Error details:", error);
    } finally {
      setIsCallingAPI(false);
    }
  };

  const renderSignInOption = () => {
    if (hasGoogleProvider && hasEmailPasswordProvider) {
      // If both SSO and email verification are enabled, show "Options Enabled" section
      return (
        <div className="flex flex-col justify-between font-body text-base md:flex-row md:items-center md:text-2xl">
          <div>
            <div className="mb-2 text-lg font-semibold text-white md:text-2xl">
              Sign-In Options
            </div>
            <div className="mb-2 flex items-center">
              <input
                id="password_enabled_checkbox"
                type="checkbox"
                checked={true}
                readOnly
              />
              <label
                style={{ color: "#ffffff80" }}
                className="ml-4 text-lg font-semibold text-white md:text-2xl"
              >
                Password sign-in enabled
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="sso_enabled_checkbox"
                type="checkbox"
                checked={true}
                readOnly
              />
              <label
                style={{ color: "#ffffff80" }}
                className="ml-4 text-lg font-semibold text-white md:text-2xl"
              >
                SSO enabled
              </label>
            </div>
          </div>
        </div>
      );
    } else if (hasGoogleProvider && !hasEmailPasswordProvider) {
      // If SSO is enabled but email verification is not, show "Enable Password Sign-in" option
      return (
        <div className="mt-6">
          <div className="mb-4 flex items-center">
            <input
              id="password_enabled_checkbox"
              type="checkbox"
              checked={true}
              readOnly
            />
            <label className="ml-4 text-lg font-semibold text-white md:text-2xl">
              SSO Sign-in Enabled
            </label>
          </div>
          <div className="mb-4 text-lg font-semibold text-white md:text-2xl">
            Enable Password Sign-in:
          </div>
          <Button
            id="open_verify_popup_btn"
            btnType="green"
            onClick={() => setOpen(true)}
          >
            Create Password
          </Button>
          <CreatePassword
            handleClose={handleClose}
            open={open}
            setAuth={setAuth}
          />
        </div>
      );
    } else if (!hasGoogleProvider && hasEmailPasswordProvider) {
      // If email verification is enabled but SSO is not, show "Enable Google" option
      return (
        <div className="mt-6">
          <div className="mb-4 flex items-center">
            <input
              id="password_enabled_checkbox"
              type="checkbox"
              checked={true}
              readOnly
            />
            <label className="ml-4 text-lg font-semibold text-white md:text-2xl">
              Password sign-in enabled
            </label>
          </div>
          <div className="mb-4 text-lg font-semibold text-white md:text-2xl">
            Enable Google Sign-in:
          </div>
          <Button
            id="enable_google_btn"
            onClick={handleGoogleSignUp}
            btnType="googleSSO"
            leftIcon={
              <img
                src={GoogleLogo}
                alt="Google"
                className="h-7 w-7 regmd:h-8 regmd:w-8"
              />
            }
          >
            Continue With Google
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div>
        <div
          className={
            "tru-accinfo-container-bg rounded-3xl border-[3px] border-solid" +
            " border-x-[rgba(32,86,139,0.7)] border-b-[rgba(32,86,139,1)]" +
            " bg-gradient_acc_info border-t-[rgba(32,86,139,0.2)] p-4" +
            " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
          }
        >
          <h2 className="mb-8 text-2xl tracking-wide text-white sm:text-4xl">
            Manage Sign-In Options
          </h2>

          <div className="font-body text-base md:items-center md:text-2xl">
            <div>
              <div className="mb-2 text-lg font-semibold text-white md:text-2xl">
                Username:
              </div>
              <div>
                <div className="mb-4 font-normal text-[#ffffff4d]">
                  <div className="mr-2 flex flex-row flex-wrap">
                    <div>
                      {EmailAddress.substring(0, EmailAddress.indexOf("@"))}
                    </div>
                    <div>
                      {EmailAddress.substring(
                        EmailAddress.indexOf("@"),
                        EmailAddress.length
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderSignInOption()}
          </div>
        </div>
      </div>
    </>
  );
}
