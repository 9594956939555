import { useContext } from "react";
import { Link } from "react-router-dom";
import { EnvironmentConfigContext } from "../../../config/Environment";
import { GetOrCreateSessionAuthContext } from "../../../config/UserAuthContext";
import AppStore from "../../../global-assets/assets/AStore.png";
import GooglePlay from "../../../global-assets/assets/GPlay.png";
import TagManager from "react-gtm-module";
import Cookies from "js-cookie";

export default function AccountDownloadApp() {
  const config = useContext(EnvironmentConfigContext);
  const appleURL = config?.AppStoreURL();
  const googleURL = config?.PlayStoreURL();
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const subscriptionInfo = userContext.subscription.sources;
  const GlobalUserId = Cookies.get("GlobalUserId");

  const handleDownloadClick =
    (platform: string, linkURL: string) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const linkName = e.currentTarget.innerText;

      TagManager.dataLayer({
        dataLayer: {
          event: "select_content",
          content_type: "link",
          os_type: osType,
          link_name: linkName,
          link_url: linkURL,
          plan_type: subscriptionInfo[0] || "none",
          platform: platform,
          custom_user_id: GlobalUserId || undefined,
        },
      });
    };

  return (
    <div
      className={
        "tru-accinfo-container-bg rounded-3xl border-[3px] border-solid" +
        " border-x-[rgba(32,86,139,0.7)] border-b-[rgba(32,86,139,1)]" +
        " bg-gradient_acc_info border-t-[rgba(32,86,139,0.2)] p-4" +
        " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
      }
    >
      <div className="font-base flex flex-col justify-between md:flex-row md:items-center md:text-2xl">
        <div className="self-baseline">
          <h2 className="mb-8 text-2xl tracking-wide text-white sm:text-4xl">
            Download App
          </h2>
          <div className="mb-4 text-lg font-semibold text-white md:text-2xl">
            Available on the App Store or Google Play
          </div>
        </div>
        <div>
          <div className="mt-2 flex flex-row items-center justify-center md:flex md:flex-col md:items-end">
            <Link
              to={appleURL}
              target="_blank"
              onClick={() => handleDownloadClick("apple", appleURL)}
              className="pr-2 md:pb-6 md:pr-0"
            >
              <img src={AppStore} alt="Apple store" />
            </Link>
            <Link
              to={googleURL}
              target="_blank"
              onClick={() => handleDownloadClick("google", googleURL)}
              className="pl-1 md:pl-0 md:pt-6"
            >
              <img src={GooglePlay} alt="Google play" />
            </Link>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
