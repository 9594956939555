import title_stars from "../../../global-assets/assets/title_stars.webp";

const StepDetails = [
  {
    title: "Start Your Membership",
    image: require("../../../global-assets/assets/step1_image.png"),
    star: require("../../../global-assets/assets/step1_star.png"),
  },
  {
    title: "Download & Play",
    image: require("../../../global-assets/assets/step2_image.png"),
    star: require("../../../global-assets/assets/step2_star.png"),
  },
  {
    title: "Share with Family & Friends",
    image: require("../../../global-assets/assets/step3_image.png"),
    star: require("../../../global-assets/assets/step3_star.png"),
  },
];

export default function TruPlaySteps() {
  return (
    <div className="flex flex-col gap-12">
      <div className="mb-0 flex max-w-full flex-col items-center text-center">
        <div className="relative">
          <h2 className="text-transparent mb-[16px] bg-gradient_text bg-clip-text">
            Start Enjoying Family Time in 3 Easy Steps
          </h2>
          <img
            src={title_stars}
            loading="lazy"
            alt=""
            className="absolute right-0 top-0 -mr-[100px] -mt-[30px] hidden md:block"
          />
        </div>
        <div className="max-w-[1040px] sm:mb-[32px]">
          <h3 className="text-white">
            Watch your child's faith grow with TruPlay
          </h3>
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-y-[50px] sm:gap-y-12 md:flex-row md:items-start md:gap-x-[5%]">
        {StepDetails.map((step, index) => (
          <div
            className="relative flex max-w-[425px] flex-col items-center"
            key={index}
          >
            <div className="mb-[17px] w-full">
              <img src={step.image} loading="lazy" alt="" className="w-full" />
            </div>
            <div className="text-center font-display text-[19px] font-bold leading-[1.2] text-white md:text-[25px]">
              {step.title}
            </div>
            <img
              src={step.star}
              loading="lazy"
              alt=""
              className="absolute left-0 top-0 -mt-[11%] ml-[-10%] w-[36%] max-w-[153px] md:-ml-[14%]"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
