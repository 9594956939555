import RadioGreenChecked from "./assets/radio-green-checked.svg";

interface PlanRadioBtnProps {
  id: string;
  planOption: string;
  displayName: string;
  price: string | number;
  originalPrice?: string | number | null;
  annualPrice?: string | number;
  planSubtext: string | null;
  featuredText?: string | null;
  checked: boolean;
  selectPlan: (plan: string) => void;
}

export default function PlanRadioBtn({
  id,
  planOption,
  displayName,
  price,
  originalPrice,
  annualPrice,
  planSubtext,
  featuredText,
  checked,
  selectPlan,
}: PlanRadioBtnProps) {
  return (
    <div
      id={id}
      className={
        "group relative flex cursor-pointer items-center gap-2 rounded-xl border-4 border-solid border-cool-gray-200 p-4 pl-1 hover:border-cool-gray-700" +
        (checked ? " !border-cerulean-500" : "")
      }
      onClick={() => selectPlan(planOption)}
    >
      <div className="relative flex flex-[0.2_1_0%] cursor-pointer select-none items-center justify-center">
        <div className="h-10 w-10" onClick={() => selectPlan(planOption)}>
          {checked ? (
            <img src={RadioGreenChecked} alt="Checked" loading="eager" />
          ) : (
            <div className="h-10 w-10 rounded-full border-4 border-solid border-cool-gray-200 bg-white group-hover:border-cool-gray-700" />
          )}
        </div>
      </div>
      <div className="flex-1">
        <h3 className="text-2xl leading-normal text-cool-gray-900">
          {displayName + " Plan"}
        </h3>
        <div className="max-w-[240px] sm:max-w-[unset]">
          <span className="font-display text-base font-bold text-cool-gray-900">
            {"$" + price}
            {annualPrice && "/mo"}&nbsp;
          </span>
          <span className="text-base font-normal text-cool-gray-900">
            {originalPrice ? (
              <>
                (
                <span className="font-display text-base font-semibold text-cool-gray-900 line-through decoration-2 opacity-50">
                  ${originalPrice}
                </span>{" "}
                <span className="font-display text-base font-medium text-cool-gray-900">
                  ${annualPrice}
                </span>{" "}
                {planSubtext})
              </>
            ) : (
              <>{planSubtext}</>
            )}
          </span>
        </div>
      </div>
      {featuredText && (
        <div className="absolute -right-3 -top-3 rounded-md bg-tangerine-500 px-2 py-1 font-display text-sm font-semibold uppercase text-white">
          {featuredText}
        </div>
      )}
    </div>
  );
}
