import { Link } from "react-router-dom";

const FooterAlt = () => {
  return (
    <div className="flex h-[250px] bg-indigo-500 px-5 py-12 sm:px-6 md:px-7 lg:px-12 lg:py-16">
      <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-4 text-center">
        <h1 className="text-white">Have Questions?</h1>
        <div className="text-white">
          Contact us at{" "}
          <Link
            id="contact_mailto_tru_support"
            target="_blank"
            to="mailto:support@truplaygames.com"
            rel="noreferrer"
            className="cursor-pointer text-cerulean-500 hover:underline"
          >
            support@truplaygames.com
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterAlt;
