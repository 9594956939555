import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import Cookies from "js-cookie";

// Contexts
import { navigateTo } from "../../config/UserAuthContext";
import { GetOrCreateSessionAuthContext } from "../../config/UserAuthContext";

// Components
import YesLadderProgressBar from "../../components/registration/YesLadderProgressBar";
import RegNavUnauth from "../../components/nav/RegNavUnauth/RegNavUnauth";
import YesLadderReview from "../../components/registration/YesLadderReview";
import Button from "../../components/common/Button";

// Assets
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import YesLadderOption from "../../components/registration/YesLadderOption";

interface IYesLadderFlowProps {
  setAuth: (authenticated: boolean) => void;
  setLoginData: (userData: any) => void;
}

interface IAnswer {
  text: string;
  active: boolean;
}

interface IStage {
  [key: string]: IAnswer;
}

const stage1Defaults = {
  answer1: { text: "Kids 4 & Below", active: false },
  answer2: { text: "Kids 5-7", active: false },
  answer3: { text: "Kids 8-10", active: false },
  answer4: { text: "Kids 11+", active: false },
  answer5: { text: "Prefer Not To Say", active: false },
};

const stage2Defaults = {
  answer1: { text: "Safe Trustworthy Content", active: false },
  answer2: { text: "High Quality Games", active: false },
  answer3: { text: "Values of Love, Courage & Hope", active: false },
  answer4: { text: "Guilt Free Play-time", active: false },
  answer5: { text: "Biblical Learning", active: false },
};

const review1 = {
  title: "Wholesome Quality Games",
  content:
    '"As a long time gaming advocate, I saw mention of this collection on the news and' +
    " wanted to see if it actually contained quality games. I was pleasantly surprised" +
    " with how well developed they were. This service makes me smile knowing kids have" +
    'something wholesome to experience that even as an adult, I enjoy playing."',
  reviewer: "hashdhgd",
};

const review2 = {
  title: "Highly recommend",
  content:
    '"As a father, I am delighted with this app. It offers engaging gameplay and valuable' +
    " lessons that align with our faith. I appreciate how it promotes positive values in a" +
    ' fun and interactive way. Highly recommended!"',
  reviewer: "Tim McFrol",
};

const review3 = {
  title: "I can't say how grateful I am that this app exists.",
  content:
    '"As a mother to an 11 year old with a very creative mind, its all i can do to try and' +
    " introduce him to entertainment that kids his age enjoy while still being connected to" +
    " Christ. I asked him to try these games and unsurprisingly, he was reluctant and annoyed." +
    " But now I find him playing it all the time and he admitted to me he really likes it. Thank" +
    " you for helping a mother keep her son engaged in God's message!\"",
  reviewer: "Vloh77",
};

const review4 = {
  title: "No more guilt during iPad time",
  content:
    '"This game is tons of fun and engaging for my son. I typically feel like a bad parent' +
    " during iPad time but when he's playing TruPlay I know that he's learning about God." +
    ' Really good excited for future updates!"',
  reviewer: "KUKUMR",
};

export default function YesLadderFlow({ setAuth }: IYesLadderFlowProps) {
  const { stage } = useParams();
  const navigate = useNavigate();
  const [stage1Answers, setStage1Answers] = useState<IStage>(stage1Defaults);
  const [stage2Answers, setStage2Answers] = useState<IStage>(stage2Defaults);

  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");
  const osType = navigator.userAgent;
  const subscriptionInfo = userContext.subscription.sources;

  const handleAnswerSelection = (
    stageAnswers: IStage,
    answerSelected: string,
    setter: (answers: IStage) => void
  ) => {
    const currentAnswers: IStage = { ...stageAnswers };
    currentAnswers[answerSelected].active =
      !currentAnswers[answerSelected].active;

    setter({ ...currentAnswers });
  };

  const handleDisabled = (stage: number) => {
    if (stage === 1) {
      if (
        stage1Answers.answer1.active ||
        stage1Answers.answer2.active ||
        stage1Answers.answer3.active ||
        stage1Answers.answer4.active ||
        stage1Answers.answer5.active
      ) {
        return false;
      }
      return true;
    } else if (stage === 2) {
      if (
        stage2Answers.answer1.active ||
        stage2Answers.answer2.active ||
        stage2Answers.answer3.active ||
        stage2Answers.answer4.active ||
        stage2Answers.answer5.active
      ) {
        return false;
      }
      return true;
    }
  };

  let currentStage = (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex w-full items-center justify-center">
        <YesLadderProgressBar progress={0.4} />
      </div>
      <h4 className="mx-auto max-w-[250px] text-center text-indigo-500 sm:max-w-[300px] md:max-w-[380px]">
        What is the age range of your kids?
      </h4>
      <p className="m-0 text-center">Select all that apply.</p>
      <div className="flex flex-1 flex-col gap-1.5">
        {Object.entries(stage1Answers).map(([index, answer]) => {
          return (
            <div
              key={answer.text}
              onClick={() =>
                handleAnswerSelection(stage1Answers, index, setStage1Answers)
              }
            >
              <YesLadderOption text={answer.text} active={answer.active} />
            </div>
          );
        })}
      </div>
      <div className="flex w-full flex-col">
        <Button
          id="yes_ladder_stage_1_continue_btn"
          btnType="green"
          onClick={() => navigate("/user-registration-questions/2")}
          disabled={handleDisabled(1)}
          className="w-full"
        >
          Continue
        </Button>
      </div>
    </div>
  );

  if (stage === "2") {
    currentStage = (
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex w-full items-center justify-center">
          <YesLadderProgressBar progress={0.6} />
        </div>
        <h4 className="mx-auto max-w-[250px] text-center text-indigo-500 sm:max-w-[300px] md:max-w-[380px]">
          What do you hope to provide your child(ren) with TruPlay?
        </h4>
        <p className="m-0 text-center">Select all that apply.</p>
        <div className="flex flex-1 flex-col gap-1.5">
          {Object.entries(stage2Answers).map(([index, answer]) => {
            return (
              <div
                key={answer.text}
                onClick={() =>
                  handleAnswerSelection(stage2Answers, index, setStage2Answers)
                }
              >
                <YesLadderOption text={answer.text} active={answer.active} />
              </div>
            );
          })}
        </div>
        <div className="flex w-full flex-col">
          <Button
            id="yes_ladder_stage_2_continue_btn"
            btnType="green"
            onClick={() => navigate("/user-registration-questions/3")}
            disabled={handleDisabled(2)}
            className="w-full"
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }

  const handleStage2Selection = () => {
    if (stage2Answers.answer5.active) {
      // Biblical Learning
      return <YesLadderReview reviewInfo={review3} />;
    } else if (stage2Answers.answer3.active) {
      // Values of love, courage, and hope
      return <YesLadderReview reviewInfo={review2} />;
    } else if (stage2Answers.answer1.active) {
      // Safe trustworthly content
      return <YesLadderReview reviewInfo={review1} />;
    } else if (stage2Answers.answer4.active) {
      // Guilt free play-time
      return <YesLadderReview reviewInfo={review4} />;
    } else {
      // High quality games
      return <YesLadderReview reviewInfo={review1} />;
    }
  };

  const handleSignUp = () => {
    setAuth(true);
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        os_type: osType,
        link_name: "Create Account",
        link_url: "/user-registration-questions",
        plan_type: subscriptionInfo[0] || "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
    navigateTo("/select-plan");
  };

  if (stage === "3") {
    currentStage = (
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex w-full items-center justify-center">
          <YesLadderProgressBar progress={0.8} />
        </div>
        <h4 className="mx-auto max-w-[250px] text-center text-indigo-500 sm:max-w-[300px] md:max-w-[380px]">
          Many of our users have similar goals!
        </h4>
        {handleStage2Selection()}
        <div className="flex w-full flex-col">
          <Button
            id="yes_ladder_stage_3_continue_btn"
            btnType="green"
            onClick={handleSignUp}
            className="w-full"
          >
            Let's Get Started
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <RegNavUnauth showUserMenu={false} />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] min-h-screen bg-white regmd:hidden" />
      <div className="tru-register-flow-purple-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-50 regmd:block" />
      <div className="tru-register-flow-blue-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-40 regmd:block" />
      <div className="tru-register-flow-middle-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-50 regmd:block" />
      <div
        id="test_yes_ladder_flow"
        className="relative min-h-screen regmd:flex regmd:items-center regmd:justify-center regmd:py-44"
      >
        <div
          className={
            "relative mx-auto flex min-h-screen flex-col px-6 pb-4 pt-20 sm:pt-28 regmd:min-h-[unset]" +
            " regmd:w-[625px] regmd:rounded-[2rem] regmd:bg-white regmd:px-10 regmd:py-8"
          }
        >
          {currentStage}
        </div>
        <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
          <img
            src={BGLandingImage}
            loading="eager"
            sizes="100vw"
            alt=""
            className="relative -z-[1] w-full min-w-[1920px]"
          />
        </div>
      </div>
    </>
  );
}
