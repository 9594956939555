import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  useEffect(() => {
    if (window.location.hash.indexOf("third-party-cookies") !== -1) {
      const section = document.getElementById("third-party-cookies");
      if (section) {
        const offset = 100;
        const sectionPosition = section.getBoundingClientRect().top;
        const offsetPosition = sectionPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy | TruPlay Games</title>
        <link
          rel="canonical"
          href="https://www.truplaygames.com/privacy-policy"
        />
        <meta
          name="description"
          content="Your privacy matters at TruPlay. Review our privacy policy to understand how we protect and use the information of our users while they enjoy our Christian content and games."
        />
      </Helmet>
      <div className="relative w-full overflow-hidden bg-[#00203f] p-[50px_20px] pt-[100px] md:p-[75px_20px] md:pt-[125px] lg:pb-[75px]">
        <div className="mx-auto max-w-[1440px]">
          <div className="mx-auto mb-0 flex max-w-[820px] flex-col items-center justify-start text-center">
            <div className="relative mb-0">
              <h2 className="text-[#37a6d4]">Privacy Policy</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden p-[50px_20px] md:p-[125px_50px] lg:pt-[75px]">
        <div className="mx-auto max-w-[1440px]">
          <div>
            <div className="mx-auto max-w-[960px]">
              <div className="font-lato mx-auto w-full text-left text-[1rem] font-normal leading-[24px] text-[#415382]">
                <p className="text-[1.4rem]">
                  Last Modified: Friday, April 10<sup>th,</sup> 2024
                </p>
                <h1 className="mb-[30px] text-[25px] text-[#00203f] md:text-[5rem]">
                  TruPlay Games, Inc. Privacy Policy
                </h1>
                <h2 className="mb-[30px] text-[#00203f]">Introduction</h2>
                <p className="text-[1.4rem]">
                  This Privacy Policy (this “<strong>Policy</strong>”) describes
                  the types of information TruPlay Games, Inc. (“Company,” “we,”
                  or “us”) may collect from you or that you may provide when you
                  visit our websites and mobile applications (our "Sites") and
                  our practices for collecting, using, maintaining, protecting,
                  and disclosing that information.
                </p>
                <p className="text-[1.4rem]">
                  This Policy applies to information we collect:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    On our Sites.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    In email, text, and other electronic messages between you
                    and us.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Through mobile and desktop applications you download from
                    our Sites, including those which provide dedicated
                    non-browser-based interaction between you and this Sites.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    When you interact with our advertising and applications on
                    third-party sites, if those applications or advertising
                    include links to this Policy.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  It does not apply to information collected by:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Us offline or through any other means; or
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Any third party (including our affiliates and subsidiaries),
                    including through any application or content (including
                    advertising) that may link to or be accessible from or
                    through our Sites.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  Please read this Policy carefully to understand our policies
                  and practices regarding your information and how we will treat
                  it. If you do not agree with our policies and practices, you
                  should not use our Sites. By accessing or using our Sites, you
                  agree to this Policy. This Policy may change from time to time
                  (see Changes to Our Privacy Policy). Your continued use of our
                  Sites after we make changes is deemed to be acceptance of
                  those changes, so please check this Policy periodically for
                  updates.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Children's Privacy & COPPA
                </h2>
                <p className="text-[1.4rem]">
                  We are committed to protecting the privacy of children who use
                  our Sites. This Section explains our information collection,
                  disclosure, and parental consent practices with respect to
                  information provided by children under the age of 13 (“child”
                  or “children”). Children may not provide any information to or
                  on our Sites. However, we do recognize that, under the
                  registration of others, children can access many parts of our
                  Sites and tcontent without providing us with personal
                  information. We do not knowingly collect personal information
                  from children.
                </p>
                <p className="text-[1.4rem]">
                  We offer a range of Sites and applications, many of which are
                  primarily targeted at children, and others that are intended
                  for users of all ages and their families. Below we summarize
                  potential instances of collection and outline how and when we
                  will provide parental notice and/or seek parental consent. In
                  any instance that we collect personal information from a
                  child, we will retain that information only so long as
                  reasonably necessary to fulfill the activity request or allow
                  the child to continue to participate in the activity and
                  ensure the security of our users and our services, or as
                  required by law. In the event we discover we have collected
                  information from a child in a manner inconsistent with COPPA's
                  requirements, we will either delete the information or
                  immediately seek the parent's consent for that collection.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Registration
                </h3>
                <p className="text-[1.4rem]">
                  Children may view content, play games, and engage in special
                  features, among other things. However, during the registration
                  process, we do not permit the child to register for the Sites
                  or applications. Some of the features of our Sites and
                  applications, including registration, are age-gated so that
                  they are not available for use by children, and we do not
                  knowingly collect personal information from children in
                  connection with those features. We require a parent or
                  guardian to register for the Site. If we learn we have
                  collected or received personal information from children
                  without verification of parental consent, we will delete that
                  information. If you believe your child is participating in an
                  activity that collects personal information and you or another
                  parent/guardian have NOT received an email providing notice or
                  seeking your consent, please contact us at{" "}
                  <Link
                    id="privacy_policy_support_link_1"
                    target="_blank"
                    to="mailto:support@truplaygames.com"
                    rel="noreferrer"
                    style={{ color: "#38a6d5", cursor: "pointer" }}
                  >
                    support@truplaygames.com
                  </Link>
                  .
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Usernames
                </h3>
                <p className="text-[1.4rem]">
                  We strongly advise children never to provide any personal
                  information in their usernames. If we learn we have collected
                  or received personal information from children in connection
                  with a username without verification of parental consent, we
                  will delete that information. Usernames are not shared with
                  any third party, are not accessible or disclosed to other
                  users, or used by us for contact purposes. We will only
                  contact the email registered with the account (i.e., the
                  persons that are over the age of 13) and not individual users
                  associated with the account.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Content Generated by a Child
                </h3>
                <p className="text-[1.4rem]">
                  Currently no activities on our Sites allow children to create
                  or manipulate content and save it with us. Therefore, our
                  Sites do not require children to provide any personal
                  information and therefore do not result in notice to the
                  parent or require parental consent. If an activity potentially
                  allows a child to insert personal information in any created
                  content, we will either pre-screen the submission to delete
                  any personal information, or we will seek verifiable parental
                  consent by email for the collection. If, in addition to
                  collecting content that includes personal information, Company
                  also plans to post the content publicly or share it with a
                  third party for the third party's own use, we will obtain a
                  higher level of parental consent. In the future, if Company
                  employs a chat or communication system that does not filter
                  all or nearly all personal information, we will first seek
                  high-level consent from the parent.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Chat Features
                </h3>
                <p className="text-[1.4rem]">
                  Our games and activities that are directed to children do not
                  allow users to communicate directly with other users,
                  including via messaging, voice, sharing images or content, or
                  other means of communications.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">Email</h3>
                <p className="text-[1.4rem]">
                  Any email communications with respect to an account will be
                  sent to the registered account owner. We do not request a
                  child's email address or contact information. For more
                  information regarding our communications with account owners
                  or measures taken to prevent a child's email from being
                  registered, please see the subsection above titled
                  “Registration”.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Push Notifications
                </h3>
                <p className="text-[1.4rem]">
                  Push notifications are notifications on mobile and other
                  devices that are typically associated with downloaded
                  applications, and which can communicate to the device holder
                  even when the application is not in use. We do not use push
                  notifications and therefore no push notifications will be sent
                  to children in connection with their use of the Sites.
                </p>
                <p className="text-[1.4rem]">
                  We will not share your opt-in to an SMS campaign with any
                  third party for purposes unrelated to providing you with the
                  services of that campaign. We may share your Personal Data,
                  including your SMS opt-in or consent status, with third
                  parties that help us provide our messaging services, including
                  but not limited to platform providers, phone companies, and
                  any other vendors who assist us in the delivery of text
                  messages.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Geolocation Data
                </h3>
                <p className="text-[1.4rem]">
                  We do not collect geolocation data that allows us to identify
                  a user's street name. If a child-directed Site or application
                  collects geolocation information that is specific enough to
                  equate to the collection of a street address, we will first
                  seek parental consent via email.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Persistent Identifiers
                </h3>
                <p className="text-[1.4rem]">
                  We generally use certain technologies to collect personal
                  information in connection with the Sites (e.g., cookies, flash
                  cookies, web beacons, and other unique identifiers). However,
                  we do not use any advertising cookies in connection with the
                  applications. We obtain non-personal information such as data
                  related to errors and crashes, the type of operating system,
                  the web browser, page visits, etc. However, we do not obtain
                  personal information via any such tracking technology through
                  the use of the applications. All mobile device identifiers
                  and/or IP addresses are hashed so as not to provide
                  identifying personal information. In the event we collect (or
                  allow others to collect) personal information from children on
                  our Sites and applications for other purposes, we will notify
                  parents and obtain consent prior to such collection.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Educational Services
                </h3>
                <p className="text-[1.4rem]">
                  Some of our Sites are available for teachers, schools,
                  churches, and other religious groups or institutions
                  (“Schools”) to use as part of an educational curriculum under
                  the oversight of the School. These Sites are designed to
                  minimize the collection of personal information from children
                  and to comply with student data privacy laws. When used by
                  Schools, usernames, identifiers that may identify you or your
                  device, and other information may be provided to us by a
                  child's School and associated with a class or grade level. The
                  School will be able to see information regarding the child's
                  use of the Sites in connection with a class and make changes
                  to the child's username and other information. In some
                  circumstances, teachers and other authorized personnel may
                  create accounts for their students.
                </p>
                <p className="text-[1.4rem]">
                  The School is solely responsible for providing notices and
                  obtaining consents required by applicable student data privacy
                  laws, including providing all required notices to parents,
                  obtaining all necessary parent or guardian consents, or
                  complying with applicable requirements of an exemption or
                  exception from parental consent requirements. The School is
                  also responsible for activities such as removing a child from
                  class roster, deleting a class, or submitting a deletion
                  request when a child's personal information is no longer
                  needed for School purposes.
                </p>
                <p className="text-[1.4rem]">
                  If the Sites are downloaded on a personal device, a parent or
                  guardian may be able to view their child's personal
                  information (such as username and activities completed) by
                  logging into the child's class account. The parent can delete
                  the application providing the Sites from the child's device,
                  but the child's personal information may continue to be
                  associated with the School's class account until the School
                  deletes that personal information. If you are a parent and
                  have questions about reviewing, modifying, or deleting your
                  child's personal information, please contact your child's
                  School directly.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Questions or Assistance
                </h3>
                <p className="text-[1.4rem]">
                  If you believe your child is participating in an activity that
                  collects personal information and you or another
                  parent/guardian have NOT received an email providing notice or
                  seeking your consent, please contact us at{" "}
                  <Link
                    id="privacy_policy_support_link_2"
                    target="_blank"
                    to="mailto:support@truplaygames.com"
                    rel="noreferrer"
                    style={{ color: "#38a6d5", cursor: "pointer" }}
                  >
                    support@truplaygames.com
                  </Link>{" "}
                  or follow the process below in the Section titled “Your
                  Information Protection Rights” to delete your account (and all
                  associated personal information).
                </p>
                <h2
                  id="third-party-cookies"
                  className="mb-[30px] text-[#00203f]"
                >
                  Information We Collect About You and How We Collect It
                </h2>
                <p className="text-[1.4rem]">
                  We collect several types of information from and about users
                  of our Sites, including information:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    By which you may be personally identified, such as name,
                    postal address, email address, telephone number, or other
                    identifiers by which you may be contacted online or offline
                    ("personal information");
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    That is about you but individually does not identify you,
                    such as a user name; and/or
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    About your internet/cellular/data connection, the equipment
                    you use to access our Sites, and usage details.
                  </li>
                </ul>
                <p className="text-[1.4rem]">We collect this information:</p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Directly from you when you provide it to us.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Automatically as you navigate through our Sites. Information
                    collected automatically may include usage details, coarse
                    geolocation data, and information collected through cookies,
                    web beacons and other tracking technologies.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    From third parties, for example, business partners such as
                    our point-of-sale vendors.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  <i>Information You Provide to Us</i>
                </p>
                <p className="text-[1.4rem]">
                  The information we collect on or through our Sites may
                  include:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Information that you provide by filling in forms on our
                    Sites. This includes information provided at the time of
                    registering to use our Sites (including the code or name
                    attached to the organization referring you to us),
                    subscribing to our services and content, posting material or
                    requesting further services. We may also ask you for
                    information when you enter a contest or promotion sponsored
                    by us, and when you report problems with our Sites.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Records and copies of your correspondence (including email),
                    if you contact us.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Your responses to surveys that we might ask you to complete.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Details of transactions you carry out through our Sites
                    (including your subscription) and of the fulfillment of your
                    orders. You may be required to provide financial information
                    before beginning any such transactions through our Sites.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Your search queries on our Sites.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  You acknowledge that this information may be personal to you,
                  and by creating an account on the Site and providing personal
                  information to us, you allow us to identify you.
                </p>
                <p className="text-[1.4rem]">
                  <i>
                    Information We Collect Through Automatic Data Collection
                    Technologies
                  </i>
                </p>
                <p className="text-[1.4rem]">
                  As you navigate through and interact with our Sites, we may
                  use automatic data collection technologies to collect certain
                  information about your equipment, browsing actions, and
                  patterns, including:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Details of your visits to our Sites, including traffic data,
                    coarse location data, logs and other communication data and
                    the resources that you access and use on our Sites.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Information about your computer and internet connection,
                    including your operating system, and browser type.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  The information we collect automatically may include personal
                  information or we may maintain it, combine it, or associate it
                  with personal information we collect in other ways or receive
                  from third parties as described above. We use this data for
                  our internal customer analytics, to identify prospective
                  customer marketing opportunities, and to helps us to improve
                  our Sites and to deliver a better and more personalized
                  service, including by enabling us to:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Estimate our audience size and usage patterns.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Store information about your preferences, allowing us to
                    customize our Sites according to your individual interests.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Speed up your searches.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Recognize you when you return to our Sites.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  The technologies we use for this automatic data collection may
                  include:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    Cookies (or browser cookies). Cookies are alphanumeric
                    identifiers that we transfer to your web browser when you
                    visit the Site. A cookie file is stored in your web browser
                    or your computer's hard drive (through your web browser) and
                    allows the Site or a third-party to recognize you and make
                    your next visit easier and the Site more useful to you.
                    Cookies can be "persistent" or "session" cookies. Persistent
                    cookies remain on your personal computer or mobile device
                    when you go offline, while session cookies are deleted as
                    soon as you close your web browser. When you use and access
                    the Site, we may place a number of cookie files in your web
                    browser. We use cookies for the following purposes:
                    <ul>
                      <li className="mb-[10px] mt-[10px] list-dash">
                        To enable certain functions of the Site
                      </li>
                      <li className="mb-[10px] mt-[10px] list-dash">
                        To provide analytics
                      </li>
                      <li className="mb-[10px] mt-[10px] list-dash">
                        To store your preferences
                      </li>
                      <li className="mb-[30px] mt-[10px] list-dash">
                        To enable advertisements delivery, including behavioral
                        advertising.
                      </li>
                    </ul>
                    We use both session and persistent cookies on the Site and
                    we use different types of cookies to run the Site:
                    <ul className="mb-[30px]">
                      <li className="mt-[10px] list-dash">
                        <strong>Essential cookies.</strong> We may use essential
                        cookies to authenticate users and prevent fraudulent use
                        of user accounts.
                      </li>
                      <li className="mt-[10px] list-dash">
                        <strong>Preference cookies.</strong> We may use
                        preference cookies to remember your preferences and
                        various settings.
                      </li>
                      <li className="mt-[10px] list-dash">
                        <strong>Analytics cookies.</strong> We may use analytics
                        cookies to track information about how the Site is used
                        so that we can make improvements.
                      </li>
                      <li className="mt-[10px] list-dash">
                        <strong>Advertising cookies.</strong> These types of
                        cookies are used to make advertising messages more
                        relevant to you. They perform functions like preventing
                        the same ad from continuously reappearing, ensuring that
                        ads are properly displayed for advertisers, and in some
                        cases selecting advertisements that are based on your
                        interests.
                      </li>
                    </ul>
                    In addition to our own cookies, we may also use various
                    third-parties' cookies to report usage statistics of the
                    Site, deliver advertisements on and through the Site, and so
                    on. Unless you have adjusted your browser setting so that it
                    will refuse cookies or decline cookies when provided the
                    option upon a visit to our Sites, our system will issue
                    cookies when you direct your browser to our Sites. Most
                    browsers have settings to disable or limit cookies. Please
                    note that if you select this setting, you may not be able to
                    use all the features we offer, you may not be able to store
                    your preferences, and some pages of the Site may not display
                    properly.
                    <ul className="mb-[30px] mt-[10px]">
                      <li className="mt-[10px] list-dash">
                        For the Chrome web browser, please visit this page from
                        Google:{" "}
                        <Link
                          id="chrome_cookies_link"
                          target="_blank"
                          to="https://support.google.com/accounts/answer/32050"
                          rel="noreferrer"
                          style={{ color: "#38a6d5", cursor: "pointer" }}
                        >
                          Chrome
                        </Link>
                      </li>
                      <li className="mt-[10px] list-dash">
                        For the Internet Explorer web browser, please visit this
                        page from Microsoft:{" "}
                        <Link
                          id="ie_cookies_link"
                          target="_blank"
                          to="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
                          rel="noreferrer"
                          style={{ color: "#38a6d5", cursor: "pointer" }}
                        >
                          Internet Explorer
                        </Link>
                      </li>
                      <li className="mt-[10px] list-dash">
                        For the Firefox web browser, please visit this page from
                        Mozilla:{" "}
                        <Link
                          id="firefox_cookies_link"
                          target="_blank"
                          to="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=[…]te-cookies-remove-info-websites-stored&redirectlocale=en-US"
                          rel="noreferrer"
                          className="cursor-pointer break-words break-all text-[#38a6d5]"
                        >
                          Firefox
                        </Link>
                      </li>
                      <li className="mt-[10px] list-dash">
                        For the Safari web browser, please visit this page from
                        Apple:{" "}
                        <Link
                          id="safari_cookies_link"
                          target="_blank"
                          to="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
                          rel="noreferrer"
                          className="cursor-pointer break-words break-all text-[#38a6d5]"
                        >
                          Safari
                        </Link>
                      </li>
                      <li className="mt-[10px] list-dash">
                        For any other web browser, please visit your web
                        browser's official web pages, where you can find more
                        information about cookies.
                      </li>
                    </ul>
                    You can learn more about cookies at:{" "}
                    <Link
                      id="all_about_cookies_link"
                      target="_blank"
                      to="http://www.allaboutcookies.org/"
                      rel="noreferrer"
                      className="cursor-pointer break-words break-all text-[#38a6d5]"
                    >
                      http://www.allaboutcookies.org/
                    </Link>{" "}
                    and{" "}
                    <Link
                      id="network_advertising_link"
                      target="_blank"
                      to="http://www.networkadvertising.org/"
                      rel="noreferrer"
                      className="cursor-pointer break-words break-all text-[#38a6d5]"
                    >
                      http://www.networkadvertising.org/
                    </Link>
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    <i>Web Beacons</i>. Pages of our Sites and emails may
                    contain small electronic files known as web beacons (also
                    referred to as clear gifs, pixel tags, and single-pixel
                    gifs) that permit the Company, for example, to count users
                    who have visited those pages or opened an email and for
                    other related website statistics (for example, recording the
                    popularity of certain website content and verifying system
                    and server integrity). Our Sites do not respond to “do not
                    track” signals.
                  </li>
                  <li className="text-[#626d7d]z mb-[10px] list-disc text-[1.4rem] leading-[1.75]">
                    <i>Analytics</i>. We may use third-party analytics services
                    such as Google Analytics, which help us understand how users
                    are finding and using our Sites. Google Analytics collects
                    information such as how often users visit the Sites, what
                    pages they visit when they do so, and what other sites they
                    used prior to coming to the Sites. We use the information we
                    get from Google Analytics to improve our Sites, and other
                    offerings and to provide more relevant advertising. To learn
                    more about Google Analytics' privacy practices, you can
                    visit{" "}
                    <Link
                      id="google_analytics_support_link"
                      target="_blank"
                      to="https://support.google.com/analytics/answer/6004245?hl=en"
                      rel="noreferrer"
                      className="cursor-pointer break-words break-all text-[#38a6d5]"
                    >
                      https://support.google.com/analytics/answer/6004245?hl=en
                    </Link>
                    . To opt out of Google Analytics' metrics, you can follow
                    the instructions at{" "}
                    <Link
                      id="google_analytics_opt_out_link"
                      target="_blank"
                      to="https://tools.google.com/dlpage/gaoptout"
                      rel="noreferrer"
                      className="cursor-pointer break-words break-all text-[#38a6d5]"
                    >
                      https://tools.google.com/dlpage/gaoptout
                    </Link>
                    .
                  </li>
                </ul>
                <h2 className="mb-[30px] text-[#00203f]">
                  Third-Party Use of Cookies and Other Tracking Technologies
                </h2>
                <p className="text-[1.4rem]">
                  Some content or applications, including advertisements, on our
                  Sites are served by third parties, including advertisers, ad
                  networks and servers, content providers, and application
                  providers. These third parties may use cookies alone or in
                  conjunction with web beacons or other tracking technologies to
                  collect information about you when you use our Sites. The
                  information they collect may be associated with your personal
                  information or they may collect information, including
                  personal information, about your online activities over time
                  and across different websites and other online services. They
                  may use this information to provide you with interest-based
                  (behavioral) advertising or other targeted content.
                </p>
                <p className="text-[1.4rem]">
                  We do not control these third parties' tracking technologies
                  or how they may be used. If you have any questions about an
                  advertisement or other targeted content, you should contact
                  the responsible provider directly. For information about how
                  you can opt out of receiving targeted advertising from many
                  providers, see Choices About How We Use and Disclose Your
                  Information.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  How Do We Store Your Information
                </h2>
                <p className="text-[1.4rem]">
                  We store your information about your account and email
                  preferences in a secure cloud-based database. We use third
                  party processors to collect payment information (such as a
                  valid credit card number, type, expiration date or other
                  financial information) to process payments for customer
                  transactions. We do not store credit card information and we
                  limit the amount of personal informationstored in these
                  locations. The use and storage of that information is governed
                  by the payment processor's applicable terms of service and
                  privacy policy. To learn more, visit our “Data Security”
                  section.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  How We Use Your Information
                </h2>
                <p className="text-[1.4rem]">
                  We use information that we collect about you or that you
                  provide to us, including any personal information:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To present our Sites and their contents to you.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To provide you with information, products, or services.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To fulfill any other purpose for which you provide it. For
                    example, if you provide data to us in a “Contact Us” form,
                    we will use your data to respond to the request.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To provide you with notices about your account, including
                    subscription expiration and renewal notices.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To notify you about changes to our Sites or any products or
                    services we offer or provide though it.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To allow you to participate in interactive features on our
                    Sites.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To understand how our Sites and services are being used and
                    to make improvements. For example, we may solicit your
                    feedback about your experience using our Sites, and ways
                    that we can improve those Sites.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To track the use of our Sites to diagnose website technical
                    problems, as well as to prevent, detect, mitigate, and
                    investigate potential security issues, as well as fraudulent
                    or illegal activity.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    In any other way we may describe when you provide the
                    information.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    For any other purpose with your consent.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  We may also use your information to contact you about our own
                  and third-parties' goods and services that may be of interest
                  to you. If you do not want us to use your information in this
                  way, please adust your user proferences in your account
                  settings. For more information, see Choices About How We Use
                  and Disclose Your Information.
                </p>
                <p className="text-[1.4rem]">
                  We may use the information we have collected from you to
                  enable us to display advertisements to our advertisers' target
                  audiences. Even though we do not disclose your personal
                  information for these purposes without your consent, if you
                  click on or otherwise interact with an advertisement, the
                  advertiser may assume that you meet its target criteria.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Disclosure of Your Information
                </h2>
                <p className="text-[1.4rem]">
                  We may disclose aggregated information about our users, and
                  information that does not identify any individual, without
                  restriction. We may use aggregated or de-identified data for
                  any lawful business purpose (including, without limitation, to
                  develop and improve the products or services and to create and
                  distribute reports and other materials). Once such data has
                  been aggregated and/or de-identified so that it is no longer
                  considered personal data under applicable data protection law,
                  this Policy does not apply.
                </p>
                <p className="text-[1.4rem]">
                  We may disclose personal information that we collect or you
                  provide as described in this Policy:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To our subsidiaries and affiliates.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To contractors, service providers, and other third parties
                    we use to support our business (such as email fulfillment
                    providers, customer support providers, and payment service
                    providers). These third-party service providers or vendors
                    may use data we provide to them only as instructed by
                    Company.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To a buyer or other successor in the event of a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of our assets, whether
                    as a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which personal information held by us
                    about users of our Sites is among the assets transferred.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To fulfill the purpose for which you provide it.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    For any other purpose disclosed by us when you provide the
                    information.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    With your consent.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  We may also disclose your personal information:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To comply with any court order, law, or legal process,
                    including to respond to any government or regulatory
                    request.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    To enforce or apply our Terms of Use and other agreements,
                    including for billing and collection purposes.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    If we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of the Company, our
                    customers, or others. This includes exchanging information
                    with other companies and organizations for the purposes of
                    fraud protection and credit risk reduction.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  We does not sell personal information that we collect or
                  process under this Policy. We may use other information (which
                  does not identify you personally) for any manner we deem
                  appropriate, including without limitation to improve,
                  maintain, and operate the Sites and our services and products.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Choices About How We Use and Disclose Your Information
                </h2>
                <p className="text-[1.4rem]">
                  We strive to provide you with choices regarding the personal
                  information you provide to us. We have created mechanisms to
                  provide you with the following control over your information:
                </p>
                <ul className="mb-[30px]">
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    <strong>Tracking Technologies and Advertising.</strong> You
                    can set your browser to refuse all or some browser cookies,
                    or to alert you when cookies are being sent.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    <strong>
                      Disclosure of Your Information for Third-Party
                      Advertising.
                    </strong>{" "}
                    If you do not want us to share your personal information
                    with unaffiliated or non-agent third parties for promotional
                    purposes, you can opt-out by sending us an email with your
                    request to{" "}
                    <Link
                      id="privacy_policy_support_link_3"
                      target="_blank"
                      to="mailto:support@truplaygames.com"
                      rel="noreferrer"
                      className="cursor-pointer break-words break-all text-[#38a6d5]"
                    >
                      support@truplaygames.com
                    </Link>
                    .
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    <strong>Promotional Offers from the Company.</strong> If you
                    do not wish to have your contact information used by the
                    Company to promote our own or third parties' products or
                    services, you can opt-out by sending us an email stating
                    your request to{" "}
                    <Link
                      id="privacy_policy_support_link_4"
                      target="_blank"
                      to="mailto:support@truplaygames.com"
                      rel="noreferrer"
                      className="cursor-pointer break-words break-all text-[#38a6d5]"
                    >
                      support@truplaygames.com
                    </Link>
                    . If we have sent you a promotional email, you may send us a
                    return email asking to be omitted from future email
                    distributions. This opt-out does not apply to information
                    provided to the Company as a result of a product purchase,
                    warranty registration, product service experience or other
                    transactions.
                  </li>
                  <li className="mb-[10px] list-disc text-[1.4rem] leading-[1.75] text-[#626d7d]">
                    <strong>Targeted Advertising.</strong> If you do not want us
                    to use information that we collect or that you provide to us
                    to deliver advertisements according to our advertisers'
                    target-audience preferences, you can opt-out by you can
                    opt-out by sending us an email with your request to{" "}
                    <Link
                      id="privacy_policy_support_link_5"
                      target="_blank"
                      to="mailto:support@truplaygames.com"
                      rel="noreferrer"
                      className="cursor-pointer break-words break-all text-[#38a6d5]"
                    >
                      support@truplaygames.com
                    </Link>
                    .
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  The Sites may include links to third party sites and services.
                  If you visit or use such third-party sites and services,
                  please be mindful that the relevant third party controls the
                  cookies and other technologies it uses on its sites and how it
                  collects, uses, and shares your personal information. We do
                  not control third parties' collection or use of your
                  informationg. This Policy only governs our own privacy
                  practices - by clicking on a link to a third party's website,
                  you have left our Site and this Policy no longer applies.
                  Please review the applicable third-party privacy policy when
                  visiting or using any third-party sites or services. These
                  third parties may provide you with ways to choose not to have
                  your information collected or used in certain ways. We are not
                  responsible for any content provided by third parties or for
                  how they collect, use, share, or otherwise process any
                  information you provide to them.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Accessing and Correcting Your Information
                </h2>
                <p className="text-[1.4rem]">
                  You can review and change your personal information by logging
                  into truplaygames.com and visiting your account “Settings”
                  page.
                </p>
                <p className="text-[1.4rem]">
                  You may also send us an email at{" "}
                  <Link
                    id="privacy_policy_support_link_6"
                    target="_blank"
                    to="mailto:support@truplaygames.com"
                    rel="noreferrer"
                    className="cursor-pointer break-words break-all text-[#38a6d5]"
                  >
                    support@truplaygames.com
                  </Link>{" "}
                  to request access to, correct or delete any personal
                  information that you have provided to us. We may not
                  accommodate a request to change information if we believe the
                  change would violate any law or legal requirement or cause the
                  information to be incorrect.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">Data Security</h2>
                <p className="text-[1.4rem]">
                  The safety and security of your information also depends on
                  you. Where we have given you (or where you have chosen) a
                  password for access to certain parts of our Sites, you are
                  responsible maintaining the confidentiality of your password.
                </p>
                <p className="text-[1.4rem]">
                  We use commercially reasonable technical and organizational
                  measures that provide a level of security appropriate to the
                  risk of processing your personal information. This includes
                  physical, administrative, and technical safeguards, to protect
                  your personal information against loss or theft, as well as
                  unauthorized access, disclosure, copying, use or modification.
                  Unfortunately, the transmission of information via the
                  Internet is not completely secure.
                </p>
                <p className="text-[1.4rem]">
                  Although we do our best to protect your information, we cannot
                  guarantee the security of your information transmitted to or
                  through our Sites. Any transmission of information is at your
                  own risk. We are not responsible for circumvention of any
                  privacy settings or security measures contained on our Sites.
                  If you have reason to believe that your personal information
                  may no longer be secure (for example, if you feel that the
                  security of an account has been compromised), please contact
                  us at{" "}
                  <Link
                    id="privacy_policy_support_link_7"
                    target="_blank"
                    to="mailto:support@truplaygames.com"
                    rel="noreferrer"
                    className="cursor-pointer break-words break-all text-[#38a6d5]"
                  >
                    support@truplaygames.com
                  </Link>
                  .
                </p>
                <p className="text-[1.4rem]">
                  We are based in the United States. By using the Services, you
                  consent to your personal information being transferred to and
                  stored in the United States. We retain personal information
                  for as long as needed or permitted in context of the purpose
                  for which it was collected and consistent with applicable
                  laws, including to perform a service for you, comply with
                  applicable laws or regulations, resolve disputes, and enforce
                  our agreements or as is otherwise necessary for the purpose of
                  a legitimate interest pursued by Company or a third party that
                  is not overridden by your personal rights and freedoms. We
                  intend to retain personal information for as long as you
                  remain a user of our Sites, and remove personal data upon your
                  request (as set forth in this Policy), unless we need to keep
                  it longer (e.g., for a legal obligation or compliance with
                  laws to which we are subject, or to protect against legal
                  claims).
                </p>
                <p className="text-[1.4rem]">
                  Once this time period has expired, we will delete your
                  information.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Your Information Protection Rights
                </h2>
                <p className="text-[1.4rem]">
                  We would like to make sure you are fully aware of all of your
                  information protection rights. Depending where you are
                  located, you may have certain legal rights over the personal
                  information we process about you, subject to local privacy
                  laws. These may include the following rights:
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to access</i> - You have the right to request
                  copies of the personal information in an electronic and
                  machine-readable format.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to rectification</i> - You have the right to
                  request that we correct any information you believe is
                  inaccurate. You also have the right to request us to complete
                  information you believe is incomplete.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to erasure</i> - You have the right to request
                  that we erase your personal data, under certain conditions.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to restrict processing</i> - You have the right
                  to request that we restrict the processing of your personal
                  information, under certain conditions.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to object to processing</i> - You have the right
                  to object to the processing of your personal information,
                  under certain conditions.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to portability</i> - You have the right to
                  request that we transfer the information that we have
                  collected to another organization, or directly to you, under
                  certain conditions.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to withdraw consent</i> - If we have collected
                  and processed your personal information with your consent,
                  then you can withdraw your consent at any time. Withdrawing
                  your consent will not affect the lawfulness of any processing
                  we conducted prior to your withdrawal, nor will it affect the
                  processing of your personal information conducted in reliance
                  on lawful processing grounds other than consent.
                </p>
                <p className="text-[1.4rem]">
                  You have the right to inquire whether we have disclosed your
                  personal information to third parties in the past 12 months
                  (and if so, what categories of personal information we have
                  disclosed, and what categories of third parties we have
                  disclosed it to)
                </p>
                <p className="text-[1.4rem]">
                  You have the right to not be subject to a decision based
                  solely on automated processing, including profiling, which
                  produces legal effects or otherwise significantly affects you
                  (“Automated Decision-Making”). Company does not perform
                  Automated Decision-Making as part of the processing activities
                  covered by this Policy.
                </p>
                <p className="text-[1.4rem]">
                  You have the right to receive the categories of sources from
                  whom we collected your personal information.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to appeal</i> - You have the right to appeal any
                  decision or indecision related to the exercise of any right
                  you are granted under any applicable data protection law.
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to opt-out</i> - You have the right to opt-out of
                  marketing communications at any time by clicking on the
                  “Unsubscribe” or “Opt- out” link in marketing emails we send
                  you or by contacting us (note that note we may still contact
                  you in connection with your account or any transactions
                  involving you (e.g., password resets, etc.))
                </p>
                <p className="text-[1.4rem]">
                  <i>The right to complain</i> - You have the right to lodge a
                  complaint with a regulator or data protection authority about
                  our collection and use of your personal information. For more
                  information, please contact your local data protection
                  authority.
                </p>
                <p className="text-[1.4rem]">
                  Company will not discriminate against you for exercising your
                  rights.
                </p>
                <p className="text-[1.4rem]">
                  If you would like to exercise any of these rights, please
                  contact us using the contact details at the bottom of this
                  Policy or by submitting your request here:{" "}
                  <Link
                    id="delete_account_link"
                    target="_blank"
                    to="https://truplaygames.com/delete-account"
                    rel="noreferrer"
                    className="cursor-pointer break-words break-all text-[#38a6d5]"
                  >
                    https://truplaygames.com/delete-account
                  </Link>
                  . We cannot delete your personal information until you have
                  canceled any active subscriptions or memberships.
                </p>
                <p className="text-[1.4rem]">
                  For deletion requests submitted via email, we may require
                  verification of your identity before further processing your
                  request. In certain instances, we may be permitted by law to
                  decline some or all of such request. Upon verifying your
                  identity, we will respond within 45 days of receipt of your
                  request, or if reasonably necessary, within 90 days, unless
                  applicable law requires a shorter timeline in which case we
                  will comply with applicable law. In the event we decline to
                  take action on your request, we will inform you (via our
                  response to your request) of your right to appeal the
                  decisions with instructions for how to submit your appeal.
                </p>
                <p className="text-[1.4rem]">
                  You of course retain all rights to file complaints with the
                  appropriate supervising authorities in the event we have
                  breached this Policy or applicable law. For more information,
                  please contact the appropriate supervising authority.
                </p>
                <p className="text-[1.4rem]">
                  In the 12 months immediately preceding the posting of this
                  updated Policy, we have not sold or shared personal
                  information of individuals. We may have disclosed certain
                  categories of personal information pursuant to an individual's
                  consent or under a written contract with a service provider
                  for a business purpose.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Consent to Processing & International Transfers
                </h2>
                <p className="text-[1.4rem]">
                  We are a global business and may process, transfer and store
                  information about our users on servers located in the United
                  States. As a result, your personal information may be subject
                  to data protection and other laws that may differ from your
                  country of residence. Your personal information may be
                  disclosed in response to inquiries or requests from government
                  authorities or to respond to judicial process in the countries
                  in which we operate. By using the Sites, or by providing us
                  with any information, you consent to the collection,
                  processing, maintenance and transfer of such information in
                  and to the United States and other applicable territories in
                  which the privacy laws may not be as comprehensive as, or
                  equivalent to, those in the country where you reside and are a
                  citizen. We endeavor to safeguard your information consistent
                  with the requirements of applicable laws. If your personal
                  information is transferred to a country other than the country
                  in which you reside, we will take measures to protect your
                  personal information with appropriate contract clauses to
                  facilitate the lawful transfer and adequate protection of
                  personal information across jurisdictions as required by
                  applicable data protection laws. To obtain more information
                  about Company's policies and practices with respect to service
                  providers outside your country, please contact us as set forth
                  at the end of this Policy.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Regions Requiring a Legal Basis
                </h2>
                <p className="text-[1.4rem]">
                  If you are from a region that requires a legal basis for
                  processing personal information, our legal basis for
                  collecting and using the personal information described above
                  will depend on the personal information concerned and the
                  specific context in which we collect it.
                </p>
                <p className="text-[1.4rem]">
                  However, we will normally collect personal information from
                  you only where we need the personal information to perform a
                  contract with you (or a third party service provider), or
                  where the processing is in our legitimate interests and not
                  overridden by your data protection interests or fundamental
                  rights and freedoms, or where we have your consent to do so.
                  In some cases, we may also have a legal obligation to collect
                  personal information from you or may otherwise need the
                  personal information to protect your vital interests or those
                  of another person, such as in the case where we request
                  personal information from you in the context of a government
                  audit or in response to a request from law enforcement.
                </p>
                <p className="text-[1.4rem]">
                  If you have questions about or need further information
                  concerning the legal basis on which we collect and use your
                  personal information, please contact us as set forth at the
                  end of this Policy.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Changes to Our Privacy Policy
                </h2>
                <p className="text-[1.4rem]">
                  This Policy may be amended or revised from time to time at the
                  discretion of Company. Changes to this Policy will be posted
                  on this page (or a successor page available through the Site).
                  The date this Policy was last revised is identified at the top
                  of the page. You are responsible for ensuring we have an
                  up-to-date active and deliverable email address for you, and
                  for periodically visiting truplaygames.com and this Policy to
                  check for any changes.
                </p>
                <h2 className="mb-[30px] text-[#00203f]">
                  Contact Information
                </h2>
                <p className="text-[1.4rem]">
                  To ask questions or comment about this Policy and our privacy
                  practices, or to register a complaint or concern, please
                  contact us at{" "}
                  <Link
                    id="privacy_policy_support_link_8"
                    target="_blank"
                    to="mailto:support@truplaygames.com"
                    rel="noreferrer"
                    className="cursor-pointer break-words break-all text-[#38a6d5]"
                  >
                    support@truplaygames.com
                  </Link>
                  . You may also contact us at the mailing addresses below:
                </p>
                <p className="text-[1.4rem]">
                  TruPlay Games, Inc.
                  <br />
                  Attn.: Support
                  <br />
                  7004 Bee Cave Rd
                  <br />
                  West Lake Hills, TX 78746
                  <br />
                  USA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-[0%] left-[0%] right-[0%] top-[0%] -z-[3]">
          <div className="absolute bottom-[0%] left-[0%] right-[0%] top-[0%] -z-[1] bg-white" />
        </div>
      </div>
    </>
  );
}
