import { Helmet } from "react-helmet";

export default function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | TruPlay Games</title>
        <link
          rel="canonical"
          href="https://www.truplaygames.com/terms-and-conditions"
        />
        <meta
          name="description"
          content="Read the terms and conditions for using TruPlay. Ensure you understand the rules and guidelines for accessing our Christian games, comics, and videos for a safe gaming experience."
        />
      </Helmet>
      <div className="relative w-full overflow-hidden bg-[#00203f] p-[50px_20px] pt-[100px] md:p-[75px_20px] md:pt-[125px] lg:pb-[75px]">
        <div className="mx-auto max-w-[1440px]">
          <div className="mx-auto mb-0 flex max-w-[820px] flex-col items-center justify-start text-center">
            <div className="relative mb-0">
              <h2 className="text-[#37a6d4]">Terms and Conditions</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden p-[50px_20px] md:p-[125px_50px] lg:pt-[75px]">
        <div className="mx-auto max-w-[1440px]">
          <div>
            <div className="mx-auto max-w-[960px]">
              <div className="font-lato mx-auto w-full text-left text-[1rem] font-normal leading-[24px] text-[#415382]">
                <p className="text-[1.4rem]">
                  Last Modified: Friday, June 22<sup>nd</sup>, 2023
                </p>
                <h1 className="mb-[30px] text-[25px] text-[#00203f] md:text-[48px]">
                  TruPlay Games, Inc. Website and Mobile Application Terms of
                  Use
                </h1>
                <h2 className="mb-[30px] text-[22px] text-[#00203f] md:text-[36px]">
                  Acceptance of the Terms of Use
                </h2>
                <p className="text-[1.4rem]">
                  These terms of use are entered into by and between You and
                  TruPlay Games, Inc. ("Company," "we," or "us"). The following
                  terms and conditions, together with any documents they
                  incorporate by reference (collectively and expressly including
                  our Privacy Policy, "Terms of Use"), govern your access to and
                  use of truplaygames.com, including any content, functionality,
                  and services offered on or through truplaygames.com, the
                  TruPlay application or any of our other websites or mobile
                  applications (our "Sites"), whether as a guest or a registered
                  user.
                </p>
                <p className="text-[1.4rem]">
                  Please read these Terms of Use carefully before using our
                  Sites. By using our Sites, you accept and agree to be bound
                  and abide by these Terms of Use and our Privacy Policy, found
                  at https://www.truplaygames.com/privacy-policy. If you do not
                  want to agree to these Terms of Use, you must not access or
                  use our Sites.
                </p>
                <p className="text-[1.4rem]">
                  Registration on our Sites are offered and available to users
                  who 13 years of age or older, and reside in the United States
                  or any of its territories or possessions. By using our Sites,
                  you represent and warrant that you meet all of the foregoing
                  eligibility requirements. If you do not meet all of these
                  requirements, you must not access or use our Sites.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Changes to the Terms of Use
                </h3>
                <p className="text-[1.4rem]">
                  We may revise and update these Terms of Use from time to time
                  in our sole discretion. All changes are effective immediately
                  when posted, and apply to all access to and use of our Sites
                  thereafter.
                </p>
                <p className="text-[1.4rem]">
                  Your continued use of our Sites following the posting of
                  revised Terms of Use means that you accept and agree to the
                  changes. You are expected to check this page each time you
                  access our Sites so you are aware of any changes, as they are
                  binding on you.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Accessing Our Sites and Account Security
                </h3>
                <p className="text-[1.4rem]">
                  We reserve the right to withdraw or amend our Sites and any
                  service or material we provide on our Sites, in our sole
                  discretion without notice. We will not be liable if for any
                  reason all or any part of our Sites is unavailable at any time
                  or for any period. From time to time, we may restrict access
                  to some parts of our Sites, or to the entirety of one or more
                  of our Sites, to users, including registered users.
                </p>
                <p className="text-[1.4rem]">
                  <strong>You are responsible for both:</strong>
                </p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Making all arrangements necessary for you to have access to
                    our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Ensuring that all persons who access our Sites through your
                    internet/cellular/data connections are aware of these Terms
                    of Use and comply with them.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  To access our Sites or some of the resources it offers, you
                  may be asked to provide certain registration details or other
                  information. It is a condition of your use of our Sites that
                  all the information you provide on our Sites is correct,
                  current, and complete. You agree that all information you
                  provide to register with our Sites or otherwise, including,
                  but not limited to, through the use of any interactive
                  features on our Sites, is governed by our Privacy Policy
                  https://www.truplaygames.com/privacy-policy, and you consent
                  to all actions we take with respect to your information
                  consistent with our Privacy Policy.
                </p>
                <p className="text-[1.4rem]">
                  If you choose, or are provided with, a user name, password, or
                  any other piece of information as part of our security
                  procedures, you must treat such information as confidential,
                  and you must not disclose it to any other person or entity.
                  You also acknowledge that your account is personal to you and
                  agree not to provide any other person with access to our Sites
                  or portions of them using your user name, password, or other
                  security information. You agree to notify us immediately of
                  any unauthorized access to or use of your user name or
                  password or any other breach of security. You should use
                  particular caution when accessing your account from a public
                  or shared computer so that others are not able to view or
                  record your password or other personal information.
                </p>
                <p className="text-[1.4rem]">
                  We have the right to disable any user name, password, or other
                  identifier, whether chosen by you or provided by us, at any
                  time in our sole discretion for any or no reason, including
                  if, in our opinion, you have violated any provision of these
                  Terms of Use.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Intellectual Property Rights
                </h3>
                <p className="text-[1.4rem]">
                  Our Sites and their contents, features, and functionality
                  (including but not limited to all information, software, text,
                  displays, images, video, and audio, and the design, selection,
                  and arrangement thereof) are owned by the Company, its
                  licensors, or other providers of such material and are
                  protected by United States and international copyright,
                  trademark, patent, trade secret, and other intellectual
                  property or proprietary rights laws.
                </p>
                <p className="text-[1.4rem]">
                  These Terms of Use permit you to use our Sites for your
                  personal, non-commercial use only. You must not reproduce,
                  distribute, modify, create derivative works of, publicly
                  display, publicly perform, republish, download, store, or
                  transmit any of the material on our Sites, except as follows:
                </p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Your computer may temporarily store copies of such materials
                    in RAM incidental to your accessing and viewing those
                    materials.
                  </li>
                  <li className="mb-[10px]">
                    You may store files that are automatically cached by your
                    browser for display enhancement purposes.
                  </li>
                  <li className="mb-[10px]">
                    You may download a single copy of any applications we
                    provide solely for your own personal, non-commercial use,
                    provided you agree to be bound by any of our agreements to
                    use such applications.
                  </li>
                  <li className="mb-[10px]">
                    If we provide social media features with certain content,
                    you may take such actions as are enabled by such features.
                  </li>
                </ul>
                <p className="text-[1.4rem]">You must not:</p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Modify copies of any materials from our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Use any illustrations, photographs, video or audio
                    sequences, or any graphics separately from the accompanying
                    text.
                  </li>
                  <li className="mb-[10px]">
                    Delete or alter any copyright, trademark, or other
                    proprietary rights notices from copies of materials from our
                    Sites.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  You must not access or use for any commercial purposes any
                  part of our Sites or any services or materials available
                  through our Sites.
                </p>
                <p className="text-[1.4rem]">
                  If you wish to make any use of material on our Sites other
                  than that set out in this section, please address your request
                  to: support@truplaygames.com.
                </p>
                <p className="text-[1.4rem]">
                  If you print, copy, modify, download, or otherwise use or
                  provide any other person with access to any part of our Sites
                  in breach of the Terms of Use, your right to use our Sites
                  will stop immediately and you must, at our option, return or
                  destroy any copies of the materials you have made. No right,
                  title, or interest in or to our Sites or any content on our
                  Sites is transferred to you, and all rights not expressly
                  granted are reserved by the Company. Any use of our Sites not
                  expressly permitted by these Terms of Use is a breach of these
                  Terms of Use and may violate copyright, trademark, and other
                  laws.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Trademarks
                </h3>
                <p className="text-[1.4rem]">
                  The Company name, its trademarks, logos and all related names,
                  logos, product and service names, designs, and slogans are
                  trademarks of the Company or its affiliates or licensors. You
                  must not use such marks without the prior written permission
                  of the Company. All other names, logos, product and service
                  names, designs, and slogans on our Sites are the trademarks of
                  their respective owners.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Prohibited Uses
                </h3>
                <p className="text-[1.4rem]">
                  You may use our Sites only for lawful purposes and in
                  accordance with these Terms of Use. You agree not to use our
                  Sites:
                </p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    In any way that violates any applicable federal, state,
                    local, or international law or regulation (including,
                    without limitation, any laws regarding the export of data or
                    software to and from the US or other countries).
                  </li>
                  <li className="mb-[10px]">
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content, asking for personally identifiable
                    information, or otherwise.
                  </li>
                  <li className="mb-[10px]">
                    To send, knowingly receive, upload, download, use, or re-use
                    any material that does not comply with the Content Standards
                    set out in these Terms of Use.
                  </li>
                  <li className="mb-[10px]">
                    To transmit, or procure the sending of, any advertising or
                    promotional material. including any "junk mail," "chain
                    letter," "spam," or any other similar solicitation.
                  </li>
                  <li className="mb-[10px]">
                    To impersonate or attempt to impersonate the Company, a
                    Company employee, another user, or any other person or
                    entity (including, without limitation, by using email
                    addresses or screen names associated with any of the
                    foregoing).
                  </li>
                  <li className="mb-[10px]">
                    To engage in any other conduct that restricts or inhibits
                    anyone's use or enjoyment of our Sites, or which, as
                    determined by us, may harm the Company or users of our
                    Sites, or expose them to liability.
                  </li>
                </ul>
                <p className="text-[1.4rem]">Additionally, you agree not to:</p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Use our Sites in any manner that could disable, overburden,
                    damage or impair our Sites or interfere with any other
                    party's use of our Sites, including their ability to engage
                    in real time activities through our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Use any robot, spider, or other automatic device, process,
                    or means to access our Sites for any purpose, including
                    monitoring or copying any of the material on our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Use any manual process to monitor or copy any of the
                    material on our Sites, or for any other purpose not
                    expressly authorized in these Terms of Use.
                  </li>
                  <li className="mb-[10px]">
                    Use any device, software, or routine that interferes with
                    the proper working of our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Introduce any viruses, Trojan horses, worms, logic bombs, or
                    other material that is malicious or technologically harmful.
                  </li>
                  <li className="mb-[10px]">
                    Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of our Sites, the server on
                    which our Sites are stored, or any server, computer, or
                    database connected to our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Attack our Sites via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </li>
                  <li className="mb-[10px]">
                    Otherwise attempt to interfere with the proper working of
                    our Sites.
                  </li>
                </ul>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  User Contributions
                </h3>
                <p className="text-[1.4rem]">
                  The our Sites may contain message boards, chat rooms, personal
                  web pages or profiles, forums, bulletin boards, gaming and
                  other interactive features (collectively, "Interactive
                  Services") that allow users to post, submit, publish, display,
                  or transmit to other users or other persons (hereinafter,
                  "post") content or materials (collectively, "User
                  Contributions") on or through our Sites.
                </p>
                <p className="text-[1.4rem]">
                  All User Contributions must comply with the Content Standards
                  set out in these Terms of Use.
                </p>
                <p className="text-[1.4rem]">
                  Any User Contribution you post to our Sites will be considered
                  non-confidential and non-proprietary. By providing any User
                  Contribution on our Sites, you grant us and our affiliates and
                  service providers, and each of their and our respective
                  licensees, successors, and assigns the right to use,
                  reproduce, modify, perform, display, distribute, and otherwise
                  disclose to third parties any such material for any purpose.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  You represent and warrant that:{" "}
                </h3>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    You own or control all rights in and to the User
                    Contributions and have the right to grant the license
                    granted above to us and our affiliates and service
                    providers, and each of their and our respective licensees,
                    successors, and assigns.
                  </li>
                  <li className="mb-[10px]">
                    All of your User Contributions do and will comply with these
                    Terms of Use.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  You understand and acknowledge that you are responsible for
                  any User Contributions you submit or contribute, and you, not
                  the Company, have full responsibility for such content,
                  including its legality, reliability, accuracy, and
                  appropriateness.
                </p>
                <p className="text-[1.4rem]">
                  We are not responsible or liable to any third party for the
                  content or accuracy of any User Contributions posted by you or
                  any other user of our Sites.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Monitoring and Enforcement; Termination
                </h3>
                <p className="text-[1.4rem]">We have the right to:</p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Remove or refuse to post any User Contributions for any or
                    no reason in our sole discretion.
                  </li>
                  <li className="mb-[10px]">
                    Take any action with respect to any User Contribution that
                    we deem necessary or appropriate in our sole discretion,
                    including if we believe that such User Contribution violates
                    the Terms of Use, including the Content Standards, infringes
                    any intellectual property right or other right of any person
                    or entity, threatens the personal safety of users of our
                    Sites or the public, or could create liability for the
                    Company.
                  </li>
                  <li className="mb-[10px]">
                    Disclose your identity or other information about you to any
                    third party who claims that material posted by you violates
                    their rights, including their intellectual property rights
                    or their right to privacy.
                  </li>
                  <li className="mb-[10px]">
                    Take appropriate legal action, including without limitation,
                    referral to law enforcement, for any illegal or unauthorized
                    use of our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Terminate or suspend your access to all or part of our Sites
                    for any or no reason, including without limitation, any
                    violation of these Terms of Use.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  Without limiting the foregoing, we have the right to cooperate
                  fully with any law enforcement authorities or court order
                  requesting or directing us to disclose the identity or other
                  information of anyone posting any materials on or through our
                  Sites. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
                  AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                  RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING
                  PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS
                  BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
                </p>
                <p className="text-[1.4rem]">
                  However, we may not review all material before it is posted on
                  our Sites, and cannot ensure prompt removal of objectionable
                  material after it has been posted. Accordingly, we assume no
                  liability for any action or inaction regarding transmissions,
                  communications, or content provided by any user or third
                  party. We have no liability or responsibility to anyone for
                  performance or nonperformance of the activities described in
                  this section.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Content Standards
                </h3>
                <p className="text-[1.4rem]">
                  These content standards apply to any and all User
                  Contributions and use of Interactive Services. User
                  Contributions must in their entirety comply with all
                  applicable federal, state, local, and international laws and
                  regulations. Without limiting the foregoing, User
                  Contributions must not:
                </p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Contain any material that is defamatory, obscene, indecent,
                    abusive, offensive, harassing, violent, hateful,
                    inflammatory, or otherwise objectionable.
                  </li>
                  <li className="mb-[10px]">
                    Promote sexually explicit or pornographic material,
                    violence, or discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation, or age.
                  </li>
                  <li className="mb-[10px]">
                    Infringe any patent, trademark, trade secret, copyright, or
                    other intellectual property or other rights of any other
                    person.
                  </li>
                  <li className="mb-[10px]">
                    Violate the legal rights (including the rights of publicity
                    and privacy) of others or contain any material that could
                    give rise to any civil or criminal liability under
                    applicable laws or regulations or that otherwise may be in
                    conflict with these Terms of Use and our Privacy Policy
                    https://www.truplaygames.com/privacy-policy.
                  </li>
                  <li className="mb-[10px]">
                    Be likely to deceive any person.
                  </li>
                  <li className="mb-[10px]">
                    Promote any illegal activity, or advocate, promote, or
                    assist any unlawful act.
                  </li>
                  <li className="mb-[10px]">
                    Cause annoyance, inconvenience, or needless anxiety or be
                    likely to upset, embarrass, alarm, or annoy any other
                    person.
                  </li>
                  <li className="mb-[10px]">
                    Impersonate any person, or misrepresent your identity or
                    affiliation with any person or organization.
                  </li>
                  <li className="mb-[10px]">
                    Involve commercial activities or sales, such as contests,
                    sweepstakes, and other sales promotions, barter, or
                    advertising.
                  </li>
                  <li className="mb-[10px]">
                    Give the impression that they emanate from or are endorsed
                    by us or any other person or entity, if this is not the
                    case.
                  </li>
                </ul>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Copyright Infringement
                </h3>
                <p className="text-[1.4rem]">
                  If you believe that any User Contributions violate your
                  copyright, send a notice of copyright infringement to
                  support@truplaygames.com. It is the policy of the Company to
                  terminate the user accounts of repeat infringers.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Reliance on Information Posted
                </h3>
                <p className="text-[1.4rem]">
                  The information presented on or through the our Sites is made
                  available solely for general information purposes. We do not
                  warrant the accuracy, completeness, or usefulness of this
                  information. Any reliance you place on such information is
                  strictly at your own risk. We disclaim all liability and
                  responsibility arising from any reliance placed on such
                  materials by you or any other visitor to our Sites, or by
                  anyone who may be informed of any of its contents.
                </p>
                <p className="text-[1.4rem]">
                  Our Sites may include content provided by third parties,
                  including materials provided by other users, bloggers, and
                  third-party licensors, syndicators, aggregators, and/or
                  reporting services. All statements and/or opinions expressed
                  in these materials, and all articles and responses to
                  questions and other content, other than the content provided
                  by the Company, are solely the opinions and the responsibility
                  of the person or entity providing those materials. These
                  materials do not necessarily reflect the opinion of the
                  Company. We are not responsible, or liable to you or any third
                  party, for the content or accuracy of any materials provided
                  by any third parties.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Changes to Our Sites
                </h3>
                <p className="text-[1.4rem]">
                  We may update the content on our Sites from time-to-time.
                  However, the content at any given moment is not necessarily
                  complete or up-to-date, and we are under no obligation to
                  update such material.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Information About You and Your Visits to Our Sites
                </h3>
                <p className="text-[1.4rem]">
                  All information we collect on our Sites is subject to our
                  Privacy Policy https://www.truplaygames.com/privacy-policy. By
                  using our Sites, you consent to all actions taken by us with
                  respect to your information in compliance with the Privacy
                  Policy.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Online Purchases and Other Terms and Conditions
                </h3>
                <p className="text-[1.4rem]">
                  All purchases through our site or other transactions for the
                  sale of products, services or information formed through our
                  Sites, or resulting from visits made by you, are governed by
                  our Terms of Sale https://www.truplaygames.com/terms-of-sale,
                  which are hereby incorporated into these Terms of Use.
                </p>
                <p className="text-[1.4rem]">
                  Additional terms and conditions may also apply to specific
                  portions, services, or features of our Sites. All such
                  additional terms and conditions are hereby incorporated by
                  this reference into these Terms of Use.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Linking to Our Sites and Social Media Features
                </h3>
                <p className="text-[1.4rem]">
                  You may link to our homepage, provided you do so in a way that
                  is fair and legal and does not damage our reputation or take
                  advantage of it, but you must not establish a link in such a
                  way as to suggest any form of association, approval, or
                  endorsement on our part.
                </p>
                <p className="text-[1.4rem]">
                  Our Sites may provide certain social media features that
                  enable you to:
                </p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Link from your own or certain third-party websites to
                    certain content on our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Send emails or other communications with certain content, or
                    links to certain content, on our Sites.
                  </li>
                  <li className="mb-[10px]">
                    Cause limited portions of content on our Sites to be
                    displayed or appear to be displayed on your own or certain
                    third-party websites or other applications.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  You may use these features solely as they are provided by us,
                  solely with respect to the content they are displayed with,
                  and otherwise in accordance with any additional terms and
                  conditions we provide with respect to such features. Subject
                  to the foregoing, you must not:
                </p>
                <ul className="mb-[30px] list-disc pl-[40px] text-[1.4rem] leading-[1.75] text-[#626d7d]">
                  <li className="mb-[10px]">
                    Establish a link from any website or application that is not
                    owned by you.
                  </li>
                  <li className="mb-[10px]">
                    Cause our Sites or portions of them to be displayed on, or
                    appear to be displayed by, any other site or application,
                    for example, framing, deep linking, or in-line linking.
                  </li>
                  <li className="mb-[10px]">
                    Link to any part of the our Sites other than their
                    respective homepages.
                  </li>
                  <li className="mb-[10px]">
                    Otherwise take any action with respect to the materials on
                    our Sites that is inconsistent with any other provision of
                    these Terms of Use.
                  </li>
                </ul>
                <p className="text-[1.4rem]">
                  The website from which you are linking, or on which you make
                  certain content accessible, must comply in all respects with
                  the Content Standards set out in these Terms of Use.
                </p>
                <p className="text-[1.4rem]">
                  You agree to cooperate with us in causing any unauthorized
                  framing or linking immediately to stop. We reserve the right
                  to withdraw linking permission without notice.
                </p>
                <p className="text-[1.4rem]">
                  We may disable all or any social media features and any links
                  at any time without notice in our discretion.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Links from Our Sites
                </h3>
                <p className="text-[1.4rem]">
                  If our Sites contains links to other sites or applications, or
                  other resources provided by third parties, these links are
                  provided for your convenience only. This includes links
                  contained in advertisements, including banner advertisements
                  and sponsored links. We have no control over the contents of
                  those sites or resources, and accept no responsibility for
                  them or for any loss or damage that may arise from your use of
                  them. If you decide to access any of the third-party websites
                  linked to our Sites, you do so entirely at your own risk and
                  subject to the terms and conditions of use for such websites.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Geographic Restrictions
                </h3>
                <p className="text-[1.4rem]">
                  We are the owner of our Sites and are based in the State of
                  Texas in the United States. We make no claims that our Sites
                  or any of its content is accessible or appropriate outside of
                  the United States. Access to our Sites may not be legal by
                  certain persons or in certain countries. If you access our
                  Sites from outside the United States, you do so on your own
                  initiative and are responsible for compliance with all local
                  laws.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Disclaimer of Warranties
                </h3>
                <p className="text-[1.4rem]">
                  You understand that we cannot and do not guarantee or warrant
                  that files available for downloading from the Internet or our
                  Sites will be free of viruses or other destructive code. You
                  are responsible for implementing sufficient procedures and
                  checkpoints to satisfy your particular requirements for
                  anti-virus protection and accuracy of data input and output,
                  and for maintaining a means external to our site for any
                  reconstruction of any lost data. TO THE FULLEST EXTENT
                  PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
                  CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
                  OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
                  COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
                  PROPRIETARY MATERIAL DUE TO YOUR USE OF OUR SITES OR ANY
                  SERVICES OR ITEMS OBTAINED THROUGH OUR SITES OR TO YOUR
                  DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE OR
                  OTHER APPLICATION LINKED TO IT.
                </p>
                <p className="text-[1.4rem]">
                  YOUR USE OF OUR SITES, THEIR CONTENT, AND ANY SERVICES OR
                  ITEMS OBTAINED THROUGH OUR SITES IS AT YOUR OWN RISK. OUR
                  SITES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
                  THROUGH OUR SITES ARE PROVIDED ON AN "AS IS" AND "AS
                  AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
                  EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
                  ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                  REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
                  RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF OUR SITES.
                  WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                  ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT OUR
                  SITES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
                  THROUGH OUR SITES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                  UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITES
                  OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR
                  OTHER HARMFUL COMPONENTS, OR THAT OUR SITES OR ANY SERVICES OR
                  ITEMS OBTAINED THROUGH OUR SITES WILL OTHERWISE MEET YOUR
                  NEEDS OR EXPECTATIONS.
                </p>
                <p className="text-[1.4rem]">
                  TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
                  DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                  IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                  ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND
                  FITNESS FOR PARTICULAR PURPOSE.
                </p>
                <p className="text-[1.4rem]">
                  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Limitation on Liability
                </h3>
                <p className="text-[1.4rem]">
                  TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
                  COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE
                  PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE
                  FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
                  OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OUR
                  SITES, ANY SITES OR OTHER APPLICATIONS LINKED TO IT, ANY
                  CONTENT ON OUR SITES OR SUCH OTHER SITES OR APPLICATIONS,
                  INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                  CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                  TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                  LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                  ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                  DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                  BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                </p>
                <p className="text-[1.4rem]">
                  THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Indemnification
                </h3>
                <p className="text-[1.4rem]">
                  You agree to defend, indemnify, and hold harmless the Company,
                  its affiliates, licensors, and service providers, and its and
                  their respective officers, directors, employees, contractors,
                  agents, licensors, suppliers, successors, and assigns from and
                  against any claims, liabilities, damages, judgments, awards,
                  losses, costs, expenses, or fees (including reasonable
                  attorneys' fees) arising out of or relating to your violation
                  of these Terms of Use or your use of our Sites, including, but
                  not limited to, your User Contributions, any use of our Sites’
                  content, services, and products other than as expressly
                  authorized in these Terms of Use, or your use of any
                  information obtained from our Sites.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Governing Law and Jurisdiction
                </h3>
                <p className="text-[1.4rem]">
                  All matters relating to our Sites and these Terms of Use, and
                  any dispute or claim arising therefrom or related thereto (in
                  each case, including non-contractual disputes or claims),
                  shall be governed by and construed in accordance with the
                  internal laws of the State of Texas without giving effect to
                  any choice or conflict of law provision or rule (whether of
                  the State of Texas or any other jurisdiction).
                </p>
                <p className="text-[1.4rem]">
                  Any legal suit, action, or proceeding arising out of, or
                  related to, these Terms of Use or our Sites shall be
                  instituted exclusively in the federal courts of the United
                  States or the courts of the State of Texas, in each case
                  located in the City of Austin and County of Travis, although
                  we retain the right to bring any suit, action, or proceeding
                  against you for breach of these Terms of Use in your country
                  of residence or any other relevant country. You waive any and
                  all objections to the exercise of jurisdiction over you by
                  such courts and to venue in such courts.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Arbitration
                </h3>
                <p className="text-[1.4rem]">
                  At Company's sole discretion, it may require You to submit any
                  disputes arising from these Terms of Use or use of our Sites,
                  including disputes arising from or concerning their
                  interpretation, violation, invalidity, non-performance, or
                  termination, to final and binding arbitration under the Rules
                  of Arbitration of the American Arbitration Association
                  applying Texas law.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Limitation on Time to File Claims
                </h3>
                <p className="text-[1.4rem]">
                  ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                  RELATING TO THESE TERMS OF USE OR OUR SITES MUST BE COMMENCED
                  WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
                  OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
                  BARRED.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Waiver and Severability
                </h3>
                <p className="text-[1.4rem]">
                  No waiver by the Company of any term or condition set out in
                  these Terms of Use shall be deemed a further or continuing
                  waiver of such term or condition or a waiver of any other term
                  or condition, and any failure of the Company to assert a right
                  or provision under these Terms of Use shall not constitute a
                  waiver of such right or provision.
                </p>
                <p className="text-[1.4rem]">
                  If any provision of these Terms of Use is held by a court or
                  other tribunal of competent jurisdiction to be invalid,
                  illegal, or unenforceable for any reason, such provision shall
                  be eliminated or limited to the minimum extent such that the
                  remaining provisions of the Terms of Use will continue in full
                  force and effect.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Entire Agreement
                </h3>
                <p className="text-[1.4rem]">
                  These Terms of Use constitute the sole and entire agreement
                  between you and the Company and its affiliates regarding our
                  Sites and supersede all prior and contemporaneous
                  understandings, agreements, representations, and warranties,
                  both written and oral, regarding our Sites.
                </p>
                <h3 className="mb-[30px] mt-[30px] text-[#00203f]">
                  Your Comments and Concerns
                </h3>
                <p className="text-[1.4rem]">
                  All feedback, comments, requests for technical support, and
                  other communications relating to our Sites should be directed
                  to: support@truplaygames.com.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 -z-[30]">
          <div className="absolute inset-0 -z-[10] bg-white" />
        </div>
      </div>
    </>
  );
}
