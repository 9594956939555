interface ModalContainerProps {
  isOpen: boolean;
  children: JSX.Element;
}

export default function ModalContainer({
  isOpen,
  children,
}: ModalContainerProps) {
  return (
    <div className={"fixed inset-0 z-[9999]" + (isOpen ? " block" : " hidden")}>
      <div className="fixed inset-0 -z-[1] flex items-center justify-center bg-black/50" />
      <div className="flex h-full items-center justify-center outline-none">
        <div
          className={
            "shadow-modal relative m-8 flex max-h-[calc(100%-64px)] min-w-[95%] max-w-[600px]" +
            " flex-col items-center justify-center rounded-[40px] bg-white p-5 md:min-w-[unset]"
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
}
