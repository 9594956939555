import { useEffect } from "react";
import ContactInfo, {InvestorInfo} from "../../components/contact/ContactInfo/ContactInfo";
import SocialLinks from "../../components/contact/SocialLinks/SocialLinks";
import { Helmet } from "react-helmet";

const Contact = () => {
  useEffect(() => {
    const cookiesAvailable = () => {
      try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        return true;
      } catch (e) {
        return false;
      }
    };

    if (cookiesAvailable() && process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);

  return (
    <div id="test_data_contact_page" className="min-h-screen">
      <Helmet>
        <title>Contact Us - Get Support or Feedback | TruPlay Games</title>
        <meta
          name="description"
          content="Need help? Contact TruPlay support at support@truplaygames.com for all your customer service and technical inquiries."
        />
        <link rel="canonical" href="https://www.truplaygames.com/contact" />
      </Helmet>
      <ContactInfo />
      <InvestorInfo />
      <SocialLinks />
    </div>
  );
};

export default Contact;
