import { useState } from "react";
import "./FAQSection.css";
import { Link } from "react-router-dom";

// Assets //
import faqq from "../../../global-assets/assets/faqq.svg";
import faq_arrow from "../../../global-assets/assets/faq_arrow.svg";
import faqa from "../../../global-assets/assets/faqa.svg";
import accent_stars_1 from "../../../global-assets/assets/Accent_Stars_01.svg";

interface faqs {
  [key: string]: {
    question: string;
    answer: any;
    open: boolean;
  };
}
interface FAQProps {
  faqs?: faqs;
}

export const DefaultFAQs = {
  faq1: {
    question: "What is the RhymVerse?",
    answer: (
      <>
        <p>
          The RhymVerse is a world where God is real and the Bible is true, but
          there is also real evil that must be overcome. Our heroes are
          anthropomorphic children, each with their own personalities,
          backstories, journeys, and relationships with God. In Rhym, a group of
          young children discover their giftings and use those gifts to restore
          truth and goodness to the many environs of Rhym. To face the various
          evils of Rhym, our heroes must use all their weapons, including their
          childlike faith, to win this fight.{" "}
        </p>
        <p>
          The RhymVerse is a place of endless adventure. Players will journey
          from the depths of the sea to outer space and everywhere in between.
          They will traverse scorching deserts and explore forgotten forests.
          There is no limit to the storytelling and gameplay of the RhymVerse.
          Over the course of several games, animations, and digital comics, all
          intentionally intertwined into the RhymVerse meta-narrative, our
          players will get to know Maple, Lucas, Ava, Oliver, Isabella,
          Benjamin, and the others as they fight to save their world from
          villains who would seek to rule over it.
        </p>
      </>
    ),
    open: false,
  },
  faq2: {
    question: "Why do your characters wear costumes of other animals?",
    answer: (
      <>
        <p>
          At TruPlay, we believe that children's identities are who God designed
          them to be.&nbsp; We want all children to know they are uniquely and
          wonderfully made by the Almighty God and God is the source of their
          true identities, not what the world or other people tell them.&nbsp;
          We believe God's Truth is revealed in the Holy Bible.
        </p>
        <p>
          When we created the RhymVerse, we set out to create a unique world of
          adventure, fun, and humor where God is the center of the story.&nbsp;
          We believe in the innocence of children and the humor that is involved
          when they wear costumes.&nbsp; Let's be honest, sometimes kids can be
          unintentionally hilarious. We wanted to reflect that in their designs.
          For example,&nbsp;
        </p>
        <ul>
          <li>
            Maple is a female bunny who is very strong-willed and courageous. In
            the beginning of her story, she feels that she is not given the
            respect she deserves and so dresses as a tiger because tigers are
            highly admired as fierce animals. However, Maple learns through her
            journey that her courage and boldness comes from God, not how she
            dresses.&nbsp;&nbsp;&nbsp;&nbsp;
          </li>
        </ul>
      </>
    ),
    open: false,
  },
  faq3: {
    question: "How do I know I can trust your content?",
    answer: (
      <>
        <p>
          First, and most importantly we are committed to offering entertainment
          products that convey content, morals and values based on the Holy
          Bible, which we hold as inspired, authoritative and inerrant. Some of
          our games, like Stained Glass, tell stories explicitly from the Bible.
          Our content in the world of Rhym was created with characters who have
          strengths and weaknesses, just like every human. They face many of the
          same struggles as today's children, but are on an adventure with a
          very real God.&nbsp; Prayer, Faith, Biblical truth, and God's Miracles
          are all part of their journey.
        </p>
        <p>
          We have been approved and endorsed by some of the most credible and
          leading organizations and leaders in the Christian community, such as
          the Billy Graham Evangelical Association, Promise Keepers, Sam
          Rodriguez, Pastor Jack Hayford, PluggedIn by Focus on the Family,
          Candace Cameron Bure, and many others. You can check out more of our
          endorsements and what others are saying about TruPlay on our homepage!
        </p>
      </>
    ),
    open: false,
  },
  faq4: {
    question: "Why do you charge for a subscription?",
    answer: (
      <>
        <p>
          By buying a subscription, you enable us to continue to make
          high-quality Christian content for your family.
        </p>
        <a
          id="faq_why_we_charge_link"
          className="cursor-pointer font-display text-[1.25rem] font-semibold capitalize tracking-[1.2px] text-cerulean-500 no-underline hover:underline"
          href="https://vimeo.com/920536083"
          target="_blank"
          rel="noopener noreferrer"
        >
          Please listen to our CEO discuss why TruPlay charges for a
          subscription
        </a>
        <p className="mt-4">
          Our mission is ultimately to share the Christian message of Jesus
          Christ and we are part of a greater movement to provide high quality,
          beautiful content to everyone who wants redemptive digital
          entertainment. In order to continually produce high quality content to
          our audience we invest in top talent and set a high bar for quality,
          which takes significant resources. We pay competitive salaries and use
          modern technology; all which costs money. We hope you will join us by
          investing in high quality, beautiful Christian content.&nbsp;
        </p>
      </>
    ),
    open: false,
  },
  faq5: {
    question: "How do you keep your platform and games safe?",
    answer: (
      <>
        <p>
          Hey, we get it, we're parents too! We know it's hard to find
          redemptive entertainment for your kids you can trust and that they
          also really enjoy. That's why we do not have additional in-app
          purchases beyond the subscription nor ads in our platform. TruPlay
          does not have any chat rooms where other people can access your
          children. All of our content is safe and fun for the whole family.
          Some of our games and stories are directly from the Bible. Other of
          our stories are from the RhymVerse where we embody Biblical meaning,
          and intent from the Scriptures.
        </p>
        <p>
          Our engineering team is top of class and our technology employs
          industry best practices in infrastructure security and data privacy.
        </p>
      </>
    ),
    open: false,
  },
  faq6: {
    question: "Are your games only for children?",
    answer: (
      <>
        <p>
          Our games are for kids of all ages, including adults!&nbsp; Don't tell
          anyone, but as we've tested over the past years, we had a lot of
          parents enjoy playing our games too. We hope TruPlay is an experience
          your entire family can enjoy together.&nbsp; We started TruPlay
          because we care about our children's mental health, future, and
          soul.&nbsp; You don't have to worry about your youngest children being
          exposed to anything inappropriate because we design our games to be
          understood and enjoyable for all ages.
        </p>
      </>
    ),
    open: false,
  },
  faq7: {
    question: "How do I manage my TruPlay Games account?",
    answer: (
      <>
        <p>
          <strong>
            Q1: How may I upgrade or downgrade my subscription from Monthly to
            Annual?
          </strong>
        </p>
        <p>
          <strong>A1:</strong> If you subscribed at{" "}
          <Link
            to="https://www.truplaygames.com"
            target="_blank"
            className="cursor-pointer text-cerulean-500"
          >
            www.truplaygames.com
          </Link>
          , email{" "}
          <Link
            id="faq_delete_contact_mailto_tru_support"
            target="_blank"
            to="mailto:support@truplaygames.com"
            rel="noreferrer"
            className="cursor-pointer text-cerulean-500"
          >
            {" "}
            support@truplaygames.com
          </Link>{" "}
          requesting the subscription change.
        </p>
        <p>
          If you subscribed through a third-party, the steps to
          upgrade/downgrade your subscription may vary.
        </p>
        <ul>
          <li>
            <p>
              <Link
                to="https://support.apple.com/en-us/118448"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                Apple Subscription Change
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link
                to="https://support.google.com/googlepay/answer/7644008?hl=en&co=GENIE.Platform%3DAndroid"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                Google Play Subscription Change
              </Link>
            </p>
          </li>
        </ul>
        <p>
          <strong>Q2: How may I cancel my subscription?</strong>
        </p>
        <p>
          <strong>A2:</strong> You may cancel your subscription at anytime.
        </p>
        <p>
          {" "}
          If you subscribed at{" "}
          <Link
            to="https://www.truplaygames.com"
            className="cursor-pointer text-cerulean-500"
            target="_blank"
          >
            www.truplaygames.com
          </Link>
          , email{" "}
          <Link
            id="faq_delete_contact_mailto_tru_support"
            target="_blank"
            to="mailto:support@truplaygames.com"
            rel="noreferrer"
            className="cursor-pointer text-cerulean-500"
          >
            {" "}
            support@truplaygames.com
          </Link>{" "}
          requesting a customer support team to cancel your account.
        </p>
        <p>
          If you subscribed through a third-party, the steps to cancel your
          subscription may vary. Navigate to your third-party's help center or
          contact them to learn more.
        </p>
        <ul>
          <li>
            <p>
              <Link
                to="https://support.apple.com/en-us/HT202039"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                Apple Cancellation
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link
                to="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                Google Play Cancellation
              </Link>
            </p>
          </li>
        </ul>
        <p>
          <strong>Q3: How do I delete my TruPlay Games account?</strong>
        </p>
        <p>
          <strong>A3:</strong> Once your subscription has been canceled: visit{" "}
          <Link
            to="https://www.truplaygames.com/account-settings"
            target="_blank"
            className="cursor-pointer text-cerulean-500"
          >
            www.truplaygames.com/account-settings
          </Link>
        </p>
        <ul>
          <li>
            <p>
              Log in using your account credentials &#x3E; Go to Profile &#x3E;
              Account &#x3E; Delete Account &#x3E; Follow the instructions to
              start the account delete process.
            </p>
          </li>
        </ul>
        <p>
          <strong>
            Q4: After canceling my subscription, can I still use TruPlayGames
            until the end of my billing period?
          </strong>
        </p>
        <p>
          <strong>A4:</strong> Yes, if you've paid for a subscription, you'll
          continue to have access to TruPlayGames until the end of your current
          billing period, even if you've initiated the deletion process.
        </p>
        <p>
          <strong>
            Q5: Where can I learn more about my rights regarding my personal
            information on TruPlayGames?
          </strong>
        </p>
        <p>
          <strong>A5:</strong> For detailed information about your rights and
          how we handle personal information, please review our{" "}
          <Link
            to="https://www.truplaygames.com/privacy-policy"
            target="_blank"
            className="cursor-pointer text-cerulean-500"
          >
            Privacy Policy
          </Link>
          .
        </p>
        <p>
          <strong>Q6: Is my account recoverable if I change my mind?</strong>
        </p>
        <p>
          <strong>A6:</strong> If the billing period has ended and your account
          is deleted, it will NOT be recoverable.
        </p>
        <p>
          <strong>Q7: May I request a refund?</strong>
        </p>
        <p>
          <strong>A7:</strong> You will need to request a refund from the same
          location you subscribed.
        </p>
        <p>
          <strong>TruPlayGames.com:</strong> You may request a refund within 14
          days of purchasing. After this time the TruPlay Games Customer Support
          Team will review and consider each case submitted.
        </p>
        <ul>
          <li>
            <p>
              <strong>Apple:</strong> You will need to{" "}
              <Link
                to="https://support.apple.com/en-us/HT202039"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                cancel
              </Link>{" "}
              and{" "}
              <Link
                to="https://support.apple.com/en-us/118223"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                request a refund
              </Link>
            </p>
          </li>
          <li>
            <p>
              <strong>Google Play:</strong> You will need to{" "}
              <Link
                to="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                cancel
              </Link>{" "}
              and{" "}
              <Link
                to="https://support.google.com/googleplay/workflow/9813244?sjid=10516304646148006934-NC&visit_id=638472026775534461-3440459003&p=refundAWF&rd=1"
                target="_blank"
                className="cursor-pointer text-cerulean-500"
              >
                request a refund
              </Link>
            </p>
          </li>
        </ul>
      </>
    ),
    open: false,
  },
  faq8: {
    question: "How do I gift a subscription to someone?",
    answer: (
      <>
        <p>
          <strong>Congratulations!</strong> You're giving great Christian
          content to your friends and making it possible for us to continue to
          create Gospel-centered entertainment. Here's a step by step way to
          bless someone else!
        </p>
        <p>As the 'gifter', please follow these steps:</p>
        <ol>
          <li>
            Step 1 - On the website, choose 'Gift a Subscription' when choosing
            a plan and go through checkout.
          </li>
          <li>
            Step 2 - You will receive an email with a receipt and instructions
            of how to 'Redeem Gift'. Simply forward this email to your friend!
          </li>
          <li>
            Step 3 - Enjoy the fact you blessed someone and supported Christian
            entertainment!
          </li>
        </ol>
        <p>As the 'receiver', please follow these steps:</p>
        <ol>
          <li>
            Step 1 - Open the email you received from your friend and click
            'Redeem Gift'.
          </li>
          <li>
            Step 2 - Create a TruPlay Account (save this info, you'll need it to
            log into the app in the next step) and checkout using the redemption
            code.
          </li>
          <li>
            Step 3 - Download the TruPlay Games app on your iOS or Android
            device and simply login and begin playing!
          </li>
        </ol>
        <p>
          If you have questions, please reach out to
          <Link
            id="faq_contact_mailto_tru_support"
            target="_blank"
            to="mailto:support@truplaygames.com"
            rel="noreferrer"
            className="cursor-pointer text-cerulean-500"
          >
            {" "}
            support@truplaygames.com
          </Link>
        </p>
      </>
    ),
    open: false,
  },
};

export default function FAQSection({ faqs = DefaultFAQs }: FAQProps) {
  const [isOpen, setIsOpen] = useState(faqs);

  const toggleFAQ = (index: any) => {
    const updatedOpenState = { ...isOpen };
    updatedOpenState[index].open = !updatedOpenState[index].open;
    setIsOpen({ ...updatedOpenState });
  };

  return (
    <div className="flex flex-col items-center justify-start gap-8 lg:gap-20">
      <div className="m-auto flex w-full flex-col items-center justify-start text-center">
        <div className="relative mb-4">
          <img
            src={accent_stars_1}
            loading="lazy"
            alt=""
            className="absolute -left-[68px] -top-[29px] bottom-auto right-auto hidden lg:inline"
          />
          <h2 className="hidden gradient-text-main lg:block">
            Frequently Asked Questions
          </h2>
          <h2 className="gradient-text-main lg:hidden">FAQs</h2>
        </div>
        <div className="!mb-0 w-full">
          <p className="m-0 text-white lg:text-[2rem] lg:font-normal lg:leading-relaxed">
            Find answers to your questions about TruPlay, subscription plans,
            games, and more.
          </p>
        </div>
      </div>
      <div className="w-full max-w-[1440px]">
        <div role="list" className="flex flex-col justify-start gap-[15px]">
          {Object.entries(faqs).map(([key, details]) => (
            <div key={key}>
              <div className="relative transform-gpu overflow-hidden rounded-[24px] bg-[rgba(255,255,255,0.1)] shadow-[0_4px_24px_rgba(0,27,54,0.5)] backdrop-blur-[5px]">
                <div
                  className="font-lato relative z-10 flex cursor-pointer items-center justify-start gap-[20px] p-[25px] px-[30px] text-[20px] font-bold text-white sm:text-[27px] md:p-[34px] md:px-[43px] md:text-[27px] lg:gap-[45px] lg:text-[36px]"
                  onClick={() => toggleFAQ(key)}
                >
                  <img
                    src={faqq}
                    loading="lazy"
                    style={{
                      opacity: isOpen[key] ? "1" : "0.1",
                    }}
                    alt="faq-q"
                    className="w-[40px] sm:w-[60px] lg:w-auto"
                  />
                  <div>{details.question}</div>
                  <img
                    src={faq_arrow}
                    loading="lazy"
                    alt="faq-arrow"
                    className={`ml-auto w-[25px] transform-gpu transition-transform duration-1000 md:w-[30px] ${
                      isOpen[key].open ? "-rotate-180" : "rotate-0"
                    }`}
                  />
                  <div
                    className={`absolute inset-0 -z-[1] transition-all duration-300 ${
                      isOpen[key].open
                        ? "bg-faq_gradient"
                        : "bg-indigo_transparent"
                    }`}
                  />
                </div>

                <div
                  className={`font-lato relative z-10 flex transform-gpu items-start justify-start gap-x-[45px] overflow-hidden px-[30px] text-[18px] font-normal leading-[1.75] text-[#00203f] transition-[max-height] duration-1000 ease-[ease] ${
                    isOpen[key].open
                      ? "faq-gradient-open"
                      : "faq-gradient-closed"
                  }`}
                >
                  <div className="flex flex-col items-start justify-start gap-x-5 gap-y-5 pb-6 pt-6 sm:flex-row lg:gap-x-[34px]">
                    <img
                      src={faqa}
                      loading="lazy"
                      alt="faq-a"
                      className="w-[40px] sm:w-[60px] lg:w-auto"
                    />
                    <div className="tru-faq-rich-text-block">
                      {details.answer}
                    </div>
                  </div>
                  <div className="absolute inset-0 -z-[1] bg-white"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
