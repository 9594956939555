import GreenCircleCheck from "../../global-assets/assets/green-circle-check.svg";

interface ValuesChecklistProps {
  points: any[];
}

export default function ValuesChecklist({ points }: ValuesChecklistProps) {
  return (
    <div>
      {points.map((point, index) => (
        <div key={index} className="mb-[0.6rem] flex items-start last:mb-0">
          <img
            src={GreenCircleCheck}
            loading="lazy"
            alt=""
            className="mr-3 mt-[0.1rem] h-6 w-6 md:mr-4 md:h-7 md:w-7 xl:mr-5 xl:h-8 xl:w-8"
          />
          <p className="m-0 text-white">{point}</p>
        </div>
      ))}
    </div>
  );
}
