import accentStars from "../../global-assets/assets/Accent_Stars_02.svg";

interface ReasonsProps {
  mainTitle: string;
  mainSubtext: string;
  block1Image: string;
  block1Title: string;
  block1Subtext: string;
  block2Image: string;
  block2Title: string;
  block2Subtext: string;
  block3Image: string;
  block3Title: string;
  block3Subtext: string;
}

const Reasons: React.FC<ReasonsProps> = ({
  mainTitle,
  mainSubtext,
  block1Image,
  block1Title,
  block1Subtext,
  block2Image,
  block2Title,
  block2Subtext,
  block3Image,
  block3Title,
  block3Subtext,
}) => {
  return (
    <div>
      <div className="flex flex-col justify-center gap-4">
        <h2 className="text-transparent relative mx-auto mb-0 mt-0 bg-gradient_text bg-clip-text text-center font-display text-[20px] font-bold leading-tight sm:max-w-[435px] sm:text-[26px] md:max-w-[620px] md:text-[38px] lg:max-w-[1120px] lg:text-left lg:text-[48px]">
          {mainTitle}
          <img
            src={accentStars}
            loading="lazy"
            alt=""
            className="absolute top-[-20px] hidden w-[55px] md:top-[-25px] md:w-[85px] lg:top-[-35px] lg:inline lg:w-auto"
          />
        </h2>
        <p className="text-center text-white lg:text-[2rem] lg:font-normal lg:leading-relaxed">
          {mainSubtext}
        </p>
      </div>
      <div className="mt-16 flex flex-col flex-wrap items-center justify-center gap-8 md:flex-row">
        <div className="mx-auto flex flex-col items-center justify-start gap-8 self-stretch rounded-[40px] bg-white/[6%] p-12 text-center shadow-[0_0_80px_0_#001b36] sm:w-[425px]">
          <img
            src={block1Image}
            loading="lazy"
            alt=""
            className="max-h-[105px] w-[25%] min-w-[120px] max-w-[175px]"
          />
          <h4 className="text-cerulean-500">{block1Title}</h4>
          <p className="m-0 text-indigo-200">{block1Subtext}</p>
        </div>
        <div className="mx-auto flex flex-col items-center justify-start gap-8 self-stretch rounded-[40px] bg-white/[6%] p-12 text-center shadow-[0_0_80px_0_#001b36] sm:w-[425px]">
          <img
            src={block2Image}
            loading="lazy"
            alt=""
            className="max-h-[105px] w-[25%] min-w-[120px] max-w-[175px]"
          />
          <h4 className="text-cerulean-500">{block2Title}</h4>
          <p className="m-0 text-indigo-200">{block2Subtext}</p>
        </div>
        <div className="mx-auto flex flex-col items-center justify-start gap-8 self-stretch rounded-[40px] bg-white/[6%] p-12 text-center shadow-[0_0_80px_0_#001b36] sm:w-[425px]">
          <img
            src={block3Image}
            loading="lazy"
            alt=""
            className="max-h-[105px] w-[25%] min-w-[120px] max-w-[175px]"
          />
          <h4 className="text-cerulean-500">{block3Title}</h4>
          <p className="m-0 text-indigo-200">{block3Subtext}</p>
        </div>
      </div>
    </div>
  );
};

export default Reasons;
