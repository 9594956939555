import React, { useState } from "react";
import VideoModal from "../../common/VideoModal";
import Button from "../../common/Button";

interface ChristianGamesCtaProps {
  backgroundImage: string;
  title: string;
  text: string;
  buttonLabel: string;
}

const ChristianGamesCta: React.FC<ChristianGamesCtaProps> = ({
  backgroundImage,
  title,
  text,
  buttonLabel,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-between p-10 pt-0 md:flex-row md:text-left">
      <div className="relative flex-1">
        <img
          src={backgroundImage}
          alt="Partnership background"
          className="h-auto w-full rounded-[20px]"
        />
      </div>
      <div className="flex-1 pl-0 pt-5 text-center text-white md:pl-10 md:pt-0 md:text-left">
        <div>
          <h2 className="mb-5 text-[36px] text-[#76cdd8]">{title}</h2>
          <p className="mb-10 text-[18px] font-medium leading-[175%] text-white">
            {text}
          </p>
        </div>
        <Button
          id="christian_games_cta_btn"
          onClick={() => setIsModalOpen(true)}
          btnType="green"
          className="w-full"
        >
          {buttonLabel}
        </Button>
      </div>

      {isModalOpen && (
        <VideoModal
          setIsOpen={setIsModalOpen}
          videoLink="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/welcome_to_truplay%20(720p).mp4"
        />
      )}
    </div>
  );
};

export default ChristianGamesCta;
