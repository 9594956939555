import { useEffect, useState } from "react";
import { useFeatureFlags } from "../../config/FeatureFlagContext";
import { navigateTo } from "../../config/UserAuthContext";
import FreeContentBanner from "./assets/free-content-hero.webp";
import Button from "../common/Button";

export default function FreeContentHero() {
  const featureFlags = useFeatureFlags();
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState<boolean>(false);

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [FreeTrial30]);

  return (
    <div className="tru-hero-mask-transition relative flex min-h-[600px] flex-col items-center justify-center gap-24 overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 lg:min-h-[800px] lg:gap-40 lg:px-12">
      <div className="z-10 mx-auto max-w-[1440px]">
        <div className="flex flex-col items-center justify-center gap-4 lg:gap-6">
          <h1 className="mx-auto max-w-[330px] text-center text-xl text-white drop-shadow-header_text_shadow sm:max-w-[400px] sm:text-2xl md:max-w-[500px] md:text-3xl lg:max-w-[800px] lg:text-5xl lg:!leading-snug 3xl:max-w-none">
            <span className="hidden lg:inline">
              Explore Samples of our Videos & Coloring Pages for Free
            </span>
            <span className="lg:hidden">
              Explore Videos & Coloring Pages for Free
            </span>
          </h1>
          <p className="mx-auto mb-0 max-w-[330px] text-center text-lg text-white drop-shadow-header_text_shadow md:max-w-[400px] md:text-2xl lg:max-w-none lg:text-3xl 2xl:max-w-none 2xl:text-4xl">
            <span className="hidden lg:inline">
              To experience the full adventure, start your free trial today!
            </span>
            <span className="lg:hidden">Experience the adventure today!</span>
          </p>
          <Button
            id="games_more_start_trial_btn"
            onClick={() => navigateTo("/user-registration")}
            btnType="green"
          >
            {freeTrial30 ? "Start 30 Day Free Trial" : "Redeem Your Free Week"}
          </Button>
        </div>
      </div>
      {/* Added another tru-hero-mask-transition class here for a more intense masking */}
      <div className="absolute bottom-0 left-0 right-0 top-0 overflow-auto bg-[radial-gradient(ellipse_at_50%_50%,rgba(0,0,0,25%)_10%,rgba(0,0,0,0%)_60%)] 3xl:top-[30%] 3xl:h-[300px] 3xl:bg-[radial-gradient(ellipse_at_50%_50%,rgba(0,0,0,15%)_40%,transparent_70%)]" />
      <div className="tru-hero-mask-transition absolute bottom-0 left-0 right-0 top-0 -z-[2]">
        <img
          src={FreeContentBanner}
          loading="eager"
          alt="Games & More"
          className="h-full w-full object-cover object-center"
        />
      </div>
    </div>
  );
}
