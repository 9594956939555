import { useState, useEffect } from "react";
import "./GamesMoreHero.css";
import { navigateTo } from "../../../config/UserAuthContext";
import { useFeatureFlags } from "../../../config/FeatureFlagContext";
import GamesMoreHeroBanner from "./assets/games-more-hero-banner.webp";
import Button from "../../common/Button";

export default function GamesMoreHero() {
  const featureFlags = useFeatureFlags();
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState(false);

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [FreeTrial30]);

  return (
    <div className="tru-hero-mask-transition relative flex min-h-[600px] flex-col items-center justify-center gap-24 overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 lg:min-h-[800px] lg:gap-40 lg:px-12">
      <div className="z-10 mx-auto max-w-[1440px]">
        <div className="flex flex-col items-center justify-center gap-6">
          <h1 className="mx-auto max-w-[540px] text-center text-white drop-shadow-header_text_shadow md:max-w-[620px]">
            Play Christian Games. Anytime. Anywhere.
          </h1>
          <Button
            id="games_more_start_trial_btn"
            onClick={() => navigateTo("/user-registration")}
            btnType="green"
          >
            {freeTrial30 ? "Start 30 Day Free Trial" : "Redeem Your Free Week"}
          </Button>
        </div>
      </div>
      {/* Added another tru-hero-mask-transition class here for a more intense masking */}
      <div className="tru-hero-mask-transition absolute bottom-0 left-0 right-0 top-0 -z-[1]">
        <img
          src={GamesMoreHeroBanner}
          loading="eager"
          alt="Games & More"
          className="h-full w-full object-cover object-center"
        />
      </div>
    </div>
  );
}
