import { useState } from "react";
import AboutModal from "../AboutModal/AboutModal";

// Assets //
import buttonarrow from "../../../global-assets/assets/buttonarrow.svg";
import headshotMarkMclain from "./assets/headshot-mark-mclain.jpg";
import headshotDougMcNamee from "./assets/headshot-doug-mcnamee.jpg";
import headshotHankStringer from "./assets/headshot-hank-stringer.jpg";
import headshotKarlRauscher from "./assets/headshot-karl-rauscher.jpg";
import headshotJimGreenwood from "./assets/headshot-jim-greenwood.jpg";

interface AdvisoryMember {
  name: string;
  imageSrc: string;
  bio: any;
}

export const aboutAdvisoryData = [
  {
    name: "Mark McClain",
    imageSrc: headshotMarkMclain,
    bio: (
      <>
        <p>
          Founder and CEO at SailPoint Technologies, Mark brings over 20 years
          of experience developing and leading innovative tech companies. Under
          his direction, SailPoint has become an industry leader in identity
          management software. Mark has a successful track record building and
          growing ground-breaking identity management companies. In 2000, Mark
          founded Waveset Technologies achieving revenue growth of 250%
          year-over-year, ultimately acquired by Sun Microsystems in 2003.
        </p>
      </>
    ),
  },
  {
    name: "Doug McNamee",
    imageSrc: headshotDougMcNamee,
    bio: (
      <>
        <p>
          Doug is the President of Magnolia, a content, retail and hospitality
          company founded by celebrity series entrepreneurs Chip and Joanna
          Gaines. In addition to overseeing one of Texas' fastest growing
          companies he guided the family owned business through the launch of
          Magnolia Network. He also served as the Senior Associate Athletic
          Director for External Affairs at Baylor University where he was
          instrumental in the funding and development of Baylor's landmark $270
          MM stadium.Doug is a long time Wacoan, and is heavily involved in the
          community and currently serves on the Kyle Lake Foundation Board of
          Directors, the Baylor Club Board of Governors, the Waco Chamber of
          Commerce Board of Directors, C12, the Bavlor "B" Association
          (Letterwinners) Board and the Waco Business League Board of Directors.
        </p>
      </>
    ),
  },
  {
    name: "Hank Stringer",
    imageSrc: headshotHankStringer,
    bio: (
      <>
        <p>
          Hank Stringer, former Head of Recruiting here at TruPlay, recruited
          the starting lineup of talent. We have Hank to thank for his
          tremendous efforts recruiting like-minded, top-notch new talent to
          build and launch our mission. Now serving as an advisor, Hank will
          still aid in the acquisition of talent here at TruPlay.
        </p>
        <p>
          Hank's history includes Executive Recruiter, Consultant, Author,
          Industry Speaker, Entrepreneur / Founder / and Visionary in creating
          Internet technology for the recruitment process. Hank brought over
          four decades of experience to global executive search and staff
          recruiting to TruPlay. Hank founded Hire.com, a pioneering SaaS
          internet recruiting technology (acquired by Authoria in 2006.) He
          co-authored Talent Force: A New Manifesto for the Human Side of
          Business with Rusty Rueff, the former EVP of Human Resources at
          Electronic Arts and a Founding Board Member of Glassdoor. Hank also
          has extensive experience recruiting sales executives for Dell
          Technologies.
        </p>
      </>
    ),
  },
  {
    name: "Karl Rauscher",
    imageSrc: headshotKarlRauscher,
    bio: (
      <>
        <p>
          Karl is a strategic advisor at the nexus of technology, business and
          policy. He has helped senior business and government leaders on five
          continents with seemingly intractable problems, including as Vice
          Chairman of the U.S. President's National Security Telecommunications
          Advisory Committee Industry Executive Committee. Karl led search and
          rescue efforts using wireless technology in the aftermath of the 9-11
          terrorist attacks and served as CTO and Distinguished Fellow of the
          EastWest Institute, where he led the first bilaterals on cybersecurity
          between both theU.S. and China and the U.S. and Russia. He is a Bell
          Labs Fellow and inventor with over 40 patents/pending, and has served
          on numerous boards - both governance andadvisory.
        </p>
      </>
    ),
  },
  {
    name: "Jim Greenwood",
    imageSrc: headshotJimGreenwood,
    bio: (
      <>
        <p>
          Jim is a mission-driven executive with demonstrated success in the
          health care services industry. A servant leader with a strong business
          development background, his passion is growing and developing future
          leaders. Jim has served as President to the Board of Directors in
          various prestigious organizations, currently Executive Chairman at
          Retina Consultants of America.
        </p>
      </>
    ),
  },
];

export const AboutData = {
  advisory: {
    title: "Advisory Board",
    description:
      "The Truplay team has curated a world-class advisory board - experts with decades of experience in their craft.",
    images: { buttonarrow },
  },
};

export default function AboutAdvisory() {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<AdvisoryMember | null>(
    null
  );

  const toggleModal = (member: AdvisoryMember) => {
    setSelectedMember(member);
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div className="relative overflow-hidden px-5 py-12 sm:px-6 sm:pb-24 md:px-7 md:pb-32 lg:px-12">
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-white" />
      <div className="mx-auto max-w-[1440px]">
        <div className="mx-auto mb-8 flex max-w-[820px] flex-col items-center justify-start text-center md:mb-12 lg:mb-20">
          <div className="relative mb-4">
            <h2 className="text-cerulean-500">Advisory Board</h2>
          </div>
          <div className="md:mx-auto md:max-w-[600px]">
            <p className="mb-0">
              The Truplay team has curated a world-class advisory board -
              experts with decades of experience in their craft.
            </p>
          </div>
        </div>
        <div>
          <div
            role="list"
            className="grid grid-flow-row grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-2 lg:gap-x-24 lg:gap-y-12"
          >
            {aboutAdvisoryData.map((member, index) => (
              <div key={index}>
                <div
                  onClick={() => toggleModal(member)}
                  className="flex cursor-pointer flex-col items-center justify-start gap-x-5 gap-y-[6%] text-center lg:flex-row lg:text-left"
                >
                  <div className="mb-4 rounded-[40px] bg-[rgba(255,255,255,0.1)] p-4 shadow-[0_0_75px_rgba(0,0,0,0.2)]">
                    <div className="overflow-hidden rounded-[30px] lg:min-w-[239px] lg:max-w-[328px]">
                      <img loading="lazy" alt="" src={member.imageSrc} />
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-start gap-x-2 lg:items-start">
                    <div className="mb-2">
                      <h4 className="text-transparent bg-gradient_text bg-clip-text">
                        {member.name}
                      </h4>
                    </div>
                    <div className="group flex gap-4">
                      <div className="font-display text-base font-bold uppercase tracking-widest text-cool-gray-900">
                        About
                      </div>
                      <img
                        id={`advisory_member_${member.name
                          .toLowerCase()
                          .replace(" ", "_")}`}
                        alt=""
                        loading="lazy"
                        src={buttonarrow}
                        className="duration-75 group-hover:translate-x-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {isModalVisible && selectedMember && (
              <AboutModal
                isOpen={isModalVisible}
                setIsOpen={setIsModalVisible}
                selectedMember={selectedMember}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
