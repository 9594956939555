import { useEffect, useState } from "react";
import Star from "../../../global-assets/assets/password-star.svg";
import Edit from "../../../global-assets/assets/btn-text-icon-edit.svg";
import ChangePassword from "../../auth/AuthModals/ChangePasswordModal/ChangePasswordModal";
import {
  Logout,
  isAuthenticatedWithValidToken,
  navigateTo,
} from "../../../config/UserAuthContext";
import Button from "../../common/Button";

interface AccountPasswordInfoProps {
  setAuth: any;
}
export const AccountPasswordInfo = ({ setAuth }: AccountPasswordInfoProps) => {
  const [open, setOpen] = useState<boolean>(false);

  let isAuthenticated = isAuthenticatedWithValidToken();

  useEffect(() => {
    if (!isAuthenticated) {
      Logout();
      navigateTo("/login");
    }
  }, [isAuthenticated]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={
          "tru-accinfo-container-bg mt-1 rounded-3xl rounded-t-none border-[3px]" +
          " border-t-0 border-solid border-x-[rgba(32,86,139,0.7)] border-b-[rgba(32,86,139,1)]" +
          " bg-gradient_acc_info p-4 shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
        }
      >
        <div className="flex flex-row items-center justify-between text-base">
          <div>
            <div className="mb-4 text-lg font-semibold text-white md:text-2xl">
              Password
            </div>
            <div className="flex gap-1">
              {Array(8)
                .fill(0)
                .map(() => (
                  <img alt="..." src={Star} key={Math.random()} />
                ))}
            </div>
          </div>
          <Button
            id="open_change_password_popup_btn"
            onClick={() => setOpen(true)}
            btnType="tertiaryAlt"
            rightIcon={
              <img
                className="ml-4 h-4 w-4 text-cerulean-500 md:h-6 md:w-6"
                src={Edit}
                alt="Edit"
              />
            }
          >
            Change
            <span className="hidden lg:inline-block">&nbsp;Password</span>
          </Button>
        </div>
      </div>
      {isAuthenticated && (
        <ChangePassword
          handleClose={handleClose}
          open={open}
          setAuth={setAuth}
        />
      )}
    </>
  );
};
