import { useEffect } from "react";
import TagManager from "react-gtm-module";
import {
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../config/UserAuthContext";
import Cookies from "js-cookie";
import Button from "../../components/common/Button";

export const NoMatch = () => {
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        page_name: "404",
        os_type: osType,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  }, [osType, userContext.subscription.sources, GlobalUserId]);

  return (
    <div
      id="test_404_page"
      className="flex h-[30em] min-h-[calc(100vh-272px)] w-full flex-col items-center justify-center gap-[1.25rem] px-[1.25rem] lg:h-full lg:gap-[2rem]"
    >
      <h1 className="text-[6rem] font-extrabold leading-[0.8] text-[white] sm:text-[8rem]">
        404
      </h1>
      <p className="m-0 text-center text-[1.2rem] text-[white] sm:text-[1.5rem] lg:text-[2rem] lg:font-normal lg:leading-[1.5]">
        We looked all over for this page <br /> but we couldn't find it.
      </p>
      <Button
        id="404_go_home_btn"
        btnType="secondary"
        onClick={() => navigateTo("/")}
      >
        Go Back Home
      </Button>
    </div>
  );
};
