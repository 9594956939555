// Components //
import Button from "./Button";

interface ImageInfoSectionProps {
  title: string;
  image: string;
  description: string;
  imagePosition: "left" | "right";
  buttonText?: string;
  onButtonClick?: () => void;
  imageWidth?: string; // Must be a Tailwind class name
  imageHeight?: string; // Must be a Tailwind class name
  buttonWidth?: string; // Must be a Tailwind class name
}

const ImageInfoSection = ({
  image,
  title,
  description,
  imagePosition,
  buttonText,
  onButtonClick,
  imageWidth,
  imageHeight,
  buttonWidth,
}: ImageInfoSectionProps) => {
  return (
    <div className="flex max-w-[2500px] flex-col items-center justify-center gap-12 p-6 pb-12 pt-20 md:flex-row">
      <img
        src={image}
        alt={title}
        className={
          "h-auto max-w-full md:max-w-[50%]" +
          (imagePosition === "left" ? " -order-1" : " order-1") +
          (imageWidth ? " " + imageWidth : "") +
          (imageHeight ? " " + imageHeight : "")
        }
      />
      <div className="flex basis-[45%] flex-col gap-4 p-5 pt-0">
        <h2 className="font-display text-2xl !leading-normal text-indigo-500 md:text-4xl">
          {title}
        </h2>
        <p className="m-0 text-lg font-normal !leading-normal text-cool-gray-900 md:text-xl">
          {description}
        </p>
        {buttonText && (
          <Button
            id="get_started_btn"
            onClick={onButtonClick}
            btnType="green"
            className={buttonWidth ? buttonWidth : ""}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ImageInfoSection;
