import { useState } from "react";
import AboutModal from "../AboutModal/AboutModal";
import PaulAnitaMayes from "./assets/Paul-Anita-Mayes.png";
import StuartMary from "./assets/Stuart-Mary.png";
import MaplePraying from "./assets/Maple_Pray.png";
import Button from "../../common/Button";

interface ChaplainMember {
  name: string;
  bio: string;
  img: any;
}

export const ChaplainData = [
  {
    name: "Stuart & MaryAnn",
    img: StuartMary,
    bio: "Stuart & MaryAnn have a passion to love the Lord with all their heart, challenge you in your pursuit of freedom and wholeness, and inspire you in fulfilling your God given destiny. They have served in various leadership capacities in both the marketplace and ministry for over 20 years. They specialize in training and equipping others to learn how to pray, do spiritual warfare, and experience God's love, recognizing His accompanying miracles, signs, and wonders. They believe that TruPlay has a unique role in the world and is partnered with the company to see God's plans, future, and hope fulfilled for the glory of God. ",
  },
  {
    name: "Paul & Anita",
    img: PaulAnitaMayes,
    bio: "Paul & Anita have a background in both business and church ministry.  Paul's degree in Biblical counseling combined with over 15 years of serving with Anita in various church roles, dovetails well with meeting the spiritual needs of TruPlay employees. They have a heart to minister to the employees and their families in the marriage, prayer, inner healing, and counseling/mentoring. They are standing in the gap for TruPlay, praying for the children of our nation, and of our world, for the leadership and employees of the company, and ministering to them as opportunity arises.  Having been associated with Brent Dusing for over a decade, this couple finds great joy being involved in helping to reach this current generation for Christ Jesus.",
  },
];

const AboutPrayerPartner = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<ChaplainMember | null>(
    null
  );

  const toggleModal = (chaplain: ChaplainMember) => {
    setSelectedMember(chaplain);
    setIsModalVisible(!isModalVisible);
  };
  return (
    <div className="bg-white p-6 pb-20">
      <h2 className="text-4l mb-4 rounded-[30px] text-center font-bold text-cerulean-500 lg:text-6xl">
        Team Chaplains
      </h2>
      <p className="mx-auto mb-8 max-w-2xl text-center">
        With decades of service to the LORD, our Chaplains provide a ministry of
        support and encouragement to the TruPlay team and our online family.
      </p>
      <div className="mx-auto grid max-w-7xl gap-4 pb-20 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
        {ChaplainData.map((chaplain) => (
          <div key={chaplain.name} className="flex flex-col items-center p-4">
            <img
              src={chaplain.img}
              alt={chaplain.name}
              className="chaplain-card-image mb-2 h-3/5 w-3/5 object-cover shadow"
            />
            <h4 className="mt-4 text-cerulean-500">{chaplain.name}</h4>
            <Button
              id={`${chaplain.name.toLowerCase().replace(/\s+/g, "_")}`}
              onClick={() => toggleModal(chaplain)}
              btnType="blue"
              className="mt-4 !px-11"
            >
              About
            </Button>
          </div>
        ))}
      </div>
      {isModalVisible && selectedMember && (
        <AboutModal
          isOpen={isModalVisible}
          setIsOpen={setIsModalVisible}
          selectedMember={selectedMember}
        />
      )}
      <h2 className="mb-4 flex flex-col items-center text-center text-4xl font-bold text-cerulean-500 lg:text-6xl">
        Are you a Prayer Warrior?
      </h2>
      <div className="flex w-full justify-center">
        <div className="flex flex-col items-center text-center lg:flex-row lg:items-center lg:justify-start lg:text-left">
          <img
            src={MaplePraying}
            alt="Prayer Warrior Bear"
            className="w-full max-w-xs lg:mr-5 lg:max-w-sm"
          />
          <div className="max-w-lg">
            <h5 className="mb-4 font-bold text-cool-gray-900">
              Join our World-Wide Prayer Team!
            </h5>
            <p>
              The TruPlay Prayer Warriors commit to going before the throne of
              God every week - lifting up the requests and needs of TruPlay, our
              team, and our subscribers.
            </p>
            <p>Your prayers make a difference.</p>
            <p>Join the Team Today!</p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSd_iUl5R7QUzQn3JqtzrF-NWCnRJcjh2WKauo0BmIXj-gHbkg/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                id="prayer_warrior_join_btn"
                btnType="blue"
                className="mx-auto w-[80%]"
              >
                Join
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPrayerPartner;
