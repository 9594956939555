import { useContext } from "react";
import { MixPanelAnalyticsContext } from "../../analytics/Analytics";
import {
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../../config/UserAuthContext";
import TagManager from "react-gtm-module";
import Button from "../../common/Button";
import Cookies from "js-cookie";
import MapleBG from "./assets/cta_maple_bg_image.jpg";

interface MapleCTAProps {
  image: string;
  gift?: boolean;
  title: string;
  mobileTitle: string;
  buttonText: string;
  mobileButtonText: string;
  ctaAction: string | (() => void);
}

const MapleCTA = ({
  image,
  gift,
  title,
  mobileTitle,
  buttonText,
  mobileButtonText,
  ctaAction,
}: MapleCTAProps) => {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");

  const handleCTAClick = () => {
    if (typeof ctaAction !== "string") {
      return;
    }
    mixPanelAnalytics.track("Web: LandingPage-Page-Maple-CTA-Clicked");

    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        os_type: osType,
        content_type: "button cta",
        link_name: "Maple-CTA",
        link_url: ctaAction,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo(ctaAction);
  };

  return (
    <div
      // Add tailwind styles for the gift image if the image is desired again
      className="relative flex h-48 w-full items-center justify-center rounded-[40px] bg-cover bg-[100%] bg-no-repeat p-5 sm:h-56 homemd:h-64 md:h-72 md:p-8 lg:h-80 xl:h-[22rem]"
      style={{ backgroundImage: `url('${MapleBG}')` }}
    >
      <div className="z-[3] flex flex-1 flex-col items-center justify-center">
        <div className="flex max-w-[30rem] flex-col gap-6">
          <div className="text-center font-display text-[20px] font-bold text-white sm:text-[25px] md:text-[40px] lg:text-[54px]">
            <div className="hidden text-left lg:block">{title}</div>
            <div className="lg:hidden">{mobileTitle}</div>
          </div>
          <Button
            id="maples_cta_btn"
            onClick={
              typeof ctaAction === "function" ? ctaAction : handleCTAClick
            }
            btnType="green"
            className="w-full"
          >
            <span className="hidden lg:block">{buttonText}</span>
            <span className="lg:hidden">{mobileButtonText}</span>
          </Button>
        </div>
      </div>
      <div className="h-full w-full flex-1">
        <img
          src={image}
          loading="lazy"
          alt=""
          className="absolute bottom-[20px] right-[125px] w-auto min-w-[375px] max-w-[375px] xl:max-w-[425px]"
        />
      </div>
    </div>
  );
};

export default MapleCTA;
