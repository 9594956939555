import { useCallback, useEffect, useState } from "react";

// Assets //
import SliderArrow from "../../global-assets/assets/faq_arrow.svg";
import Video from "./Video";
import BackCaret from "../../global-assets/assets/icon-caret-down-thick.svg";
import CloseIcon from "../../global-assets/assets/icon-close-gray.svg";
import { PulseLoader } from "react-spinners";
import Button from "./Button";
import { useLocation } from "react-router-dom";

interface ILeftAlignedCarouselProps {
  initialState: any[]; // Initial state of cards for the carousel
  cardWidth: number; // Width of each card in the carousel
  cardMargin: number; // Margin between each card in the carousel
  cardHeight: number; // Height of each card in the carousel
  initialCardCount?: number; // Initial count of the list of cards for the carousel
  responsive?: boolean; // The number of cards in the carousel will update based on screen width
  focus?: boolean; // The designated card will have focusStyles applied (could add a focus threshold in the future)
  focusStyles?: string; // Focused card styles
  cardStyles: string; // Regular card styles
  hoverStyles?: string; // All card hover styles
  animated?: boolean; // The cards will animate based on the shift styles
  shiftLeftStyles?: string; // Animation for shifting 1 card to the left
  shiftRightStyles?: string; // Animation for shifting 1 card to the right
  visibleCount?: number; // Number of cards that are currently visible
  lightBackground?: boolean; // True if the background is lighter
  arrowType?: string; // Dictates the arrow background styles
  arrowCornerType?: string; // Dictates the corner styles of the arrow background
  selection?: boolean; // True if there is selection logic for the cards
  paddingTop?: number;
  paddingBottom?: number;
  overlayStyles?: string; // Styles for an element overlaying the card
  overlayFocusStyles?: string; // Styles for the overlaid element when the card is focused
  overlayHoverStyles?: string;
}

const renderedScreenWidth = window.innerWidth;

export default function LeftAlignedCarousel({
  initialState,
  cardWidth,
  cardMargin,
  cardHeight,
  responsive = false,
  focus = false,
  animated = false,
  lightBackground = false,
  focusStyles = "",
  cardStyles,
  hoverStyles = "",
  arrowType = "standard",
  arrowCornerType = "hard-edge",
  selection = false,
  paddingTop = 0,
  paddingBottom = 0,
  overlayStyles,
  overlayFocusStyles,
  overlayHoverStyles,
}: ILeftAlignedCarouselProps) {
  const [cards, setCards] = useState<any[]>(initialState);
  const [startTouch, setStartTouch] = useState<number>(0);
  const [shiftingLeft, setShiftingLeft] = useState<boolean>(false);
  const [shiftingRight, setShiftingRight] = useState<boolean>(false);
  const [visibleCardCount, setVisibleCardCount] = useState<number | null>(null);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [modalType, setModalType] = useState<string | null>(null);
  const [leftShift, setLeftShift] = useState<string>(
    "-" + Number(2 * (cardWidth + cardMargin) - cardWidth / 4) + "px"
  );
  const [currentCardWidth, setCurrentCardWidth] = useState<number>(cardWidth);
  const [currentCardHeight, setCurrentCardHeight] =
    useState<number>(cardHeight);
  const [currentCardMargin, setCurrentCardMargin] =
    useState<number>(cardMargin);
  const [translateDistance, setTraslateDistance] = useState<number>(
    cardWidth + cardMargin
  );

  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoToShow: string | null = params.get("video");

  useEffect(() => {
    if (!visibleCardCount) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [visibleCardCount]);

  useEffect(() => {
    // Prevents body from being scrollable while the modal is open
    if (selectedCard && modalType) {
      document.body.style.overflow = "hidden";
    }
  }, [modalType, selectedCard]);

  useEffect(() => {
    if (videoToShow) {
      cards.forEach((card, index) => {
        if (card.videoId == videoToShow) {
          handleCardSelection(cards[index]);
          return;
        }
      });
    }
  }, [videoToShow]);

  /**
   * Applies the appropriate styles to the focus card(s)
   * @param cardNodes List of card elements within the carousel
   */
  const handleFocusCard = (currentCards: any[]) => {
    currentCards.forEach((card, index) => {
      if (focus && cards.length > 1) {
        if (index === 0) {
          card.focus = true;
        } else {
          card.focus = false;
        }
      }
      if (selectedCard) {
        if (card.id === selectedCard.id) {
          card.focus = true;
        } else {
          card.focus = false;
        }
      }
    });
    return currentCards;
  };

  /**
   * Updates the current card state.
   * @param currentState Current state of the carousel cards
   * @param visibleCardCount Number of visible cards
   */
  const updateCards = (carouselCards: any[], selectedCard?: any) => {
    const currentCards = [...carouselCards];

    if (focus) {
      const appliedFocusCards = handleFocusCard(currentCards);
      setCards([...appliedFocusCards]);
      return;
    }

    if (selectedCard) {
      const appliedSelectionCards = handleFocusCard(currentCards);
      setCards([...appliedSelectionCards]);
    }

    setCards([...currentCards]);
  };

  /**
   * Calculates the number of visible cards in the carousel
   * @returns the number of visible cards in the carousel
   */
  const calculateVisibleCards = () => {
    const sliderContainer = document.querySelector("#tru-slider");

    if (sliderContainer && responsive) {
      setLoading(false);
      return Math.floor(sliderContainer.clientWidth / (cardWidth + cardMargin));
    }
    setLoading(false);
    return 1;
  };

  const handleResize = () => {
    if (window.innerWidth !== renderedScreenWidth) {
      setVisibleCardCount(calculateVisibleCards());
    }
    handleDynamicStyles();
  };

  /**
   * Handles a screen resize event. When the screen size changes, the number
   * of visible cards must be calculated
   */
  useEffect(() => {
    if (window.innerWidth < 840) {
      setVisibleCardCount(1);
    } else {
      setVisibleCardCount(calculateVisibleCards());
    }
    handleDynamicStyles();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateCards(cards, selectedCard);
    // Missing deps will cause unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  /**
   * Shifts the carousel cards to the left
   * Advances to the next card to the right
   */
  const handleRightClick = () => {
    const currentState = [...cards];
    const shifted = currentState[0];
    currentState.shift();
    currentState.push(shifted);
    if (animated) {
      setShiftingLeft(true);
      setTimeout(() => {
        updateCards(currentState);
        setShiftingLeft(false);
      }, 800);
    } else {
      updateCards(currentState);
    }
  };

  /**
   * Shifts the carousel cards to the right
   * Advances to the next card to the left
   */
  const handleLeftClick = () => {
    const currentState = [...cards];
    const popped = currentState[currentState.length - 1];
    currentState.pop();
    currentState.unshift(popped);
    if (animated) {
      setShiftingRight(true);
      setTimeout(() => {
        updateCards(currentState);
        setShiftingRight(false);
      }, 800);
    } else {
      updateCards(currentState);
    }
  };

  const handleTouchStart = (e: any) => {
    const touchDown = e.touches[0].clientX;
    setStartTouch(touchDown);
  };

  /**
   * Mobile functionality for swipping the carousel
   * @param e touch event on mobile
   */
  const handleTouchMove = (e: any) => {
    if (!startTouch) return;

    const touchDown = startTouch;
    const touchUp = e.touches[0].clientX;
    const touchDiff = touchDown - touchUp;

    if (touchDiff > 5) {
      handleRightClick();
    }

    if (touchDiff < -5) {
      handleLeftClick();
    }

    setStartTouch(0);
  };

  /**
   * Handles applying translation styles for animation
   * @returns A string containing the inline styles applied depending on carousel translation direction
   */
  const handleTranslate = () => {
    if (shiftingLeft) {
      return `translateX(-${translateDistance}px)`;
    } else if (shiftingRight) {
      return `translateX(${translateDistance}px)`;
    } else {
      return "";
    }
  };

  /**
   * Checks the visible card count.
   * @returns True if all are visible.
   */
  const handleVisibleCountCheck = useCallback(() => {
    if (visibleCardCount && visibleCardCount >= initialState.length) {
      return true;
    }
    return false;
  }, [initialState.length, visibleCardCount]);

  /**
   * Handles carousel arrow rendering
   * @param type General style of the arrows
   * @param direction left or right
   * @param cornerType rounded or sharp-edge
   * @returns a rendered arrow, left or right, with appropriate styles for the type of carousel
   */
  const handleArrows = (
    type: string,
    direction: string,
    cornerType: string
  ) => {
    const commonStandardStyles =
      "hidden absolute z-[3] cursor-pointer select-none items-center justify-center bg-indigo-900/50 hover:bg-indigo-900/65 duration-300 ease-in-out md:flex";
    const commonNetflixStyles =
      "group/arrow opacity-100 md:opacity-0 group-hover:opacity-100";
    const netflixArrowWidth = {
      width: currentCardWidth / 4 + "px",
      height: currentCardHeight,
      top: paddingTop,
    };
    if (direction === "left") {
      if (type === "netflix") {
        const leftArrow = (
          <img
            className="-ml-2 rotate-90 group-hover/arrow:h-12 group-hover/arrow:w-12"
            src={SliderArrow}
            alt="Previous"
          />
        );
        if (cornerType === "rounded") {
          return (
            <div
              className={
                "left-0 rounded-r-[42px] " +
                commonStandardStyles +
                " " +
                commonNetflixStyles +
                (handleVisibleCountCheck() ? " !hidden" : "")
              }
              style={netflixArrowWidth}
              onClick={handleLeftClick}
            >
              {leftArrow}
            </div>
          );
        }
        return (
          <div
            className={
              "left-0 " +
              commonStandardStyles +
              " " +
              commonNetflixStyles +
              (handleVisibleCountCheck() ? " !hidden" : "")
            }
            style={netflixArrowWidth}
            onClick={handleLeftClick}
          >
            {leftArrow}
          </div>
        );
      }
      return (
        <div
          className={
            "invisible left-4 top-[25%] h-12 w-12 rotate-90 rounded-full md:!visible md:left-[5%] md:h-24 md:w-24 " +
            commonStandardStyles +
            (lightBackground
              ? " bg-indigo-900/20 hover:bg-indigo-900/40"
              : "") +
            (handleVisibleCountCheck() ? " !hidden" : "")
          }
          onClick={handleLeftClick}
        >
          <img src={SliderArrow} alt="Previous" />
        </div>
      );
    } else if (direction === "right") {
      if (type === "netflix") {
        const rightArrow = (
          <img
            className="-mr-2 -rotate-90 group-hover/arrow:h-12 group-hover/arrow:w-12"
            src={SliderArrow}
            alt="Next"
          />
        );
        if (cornerType === "rounded") {
          return (
            <div
              className={
                "right-0 rounded-l-[42px] " +
                commonStandardStyles +
                " " +
                commonNetflixStyles +
                (handleVisibleCountCheck() ? " !hidden" : "")
              }
              style={netflixArrowWidth}
              onClick={handleRightClick}
            >
              {rightArrow}
            </div>
          );
        }
        return (
          <div
            className={
              "right-0 " +
              commonStandardStyles +
              " " +
              commonNetflixStyles +
              (handleVisibleCountCheck() ? " !hidden" : "")
            }
            style={netflixArrowWidth}
            onClick={handleRightClick}
          >
            {rightArrow}
          </div>
        );
      }
      return (
        <div
          className={
            "invisible right-4 top-[25%] h-12 w-12 -rotate-90 rounded-full md:!visible md:right-[5%] md:h-24 md:w-24 " +
            commonStandardStyles +
            (lightBackground
              ? " bg-indigo-900/20 hover:bg-indigo-900/40"
              : "") +
            (handleVisibleCountCheck() ? " !hidden" : "")
          }
          onClick={handleRightClick}
        >
          <img src={SliderArrow} alt="Next" />
        </div>
      );
    }
  };

  const handleCardSelection = (card: any) => {
    setSelectedCard(card);
    setModalType(card.type);
  };

  const handleModalClose = () => {
    document.body.style.overflow = "unset";
    setModalType(null);
  };

  const cardComponent = (card: any, index: number | string) => {
    return (
      <div
        key={index}
        className={
          "group/card-hover relative" +
          (shiftingLeft || shiftingRight ? " duration-[0.8s]" : "") +
          (card.focus ? " group/card-focus" : "")
        }
        onClick={() => handleCardSelection(card)}
        style={{
          WebkitTransform: handleTranslate(),
          transform: handleTranslate(),
        }}
      >
        {selection && card.focus && (
          <div
            className={
              focusStyles +
              " absolute bottom-0 left-0 right-0 top-0 z-[1] " +
              hoverStyles
            }
          />
        )}
        <div
          className={
            "relative " +
            cardStyles +
            (card.focus ? " !opacity-100" : "") +
            " " +
            hoverStyles
          }
          style={{
            width: currentCardWidth + "px",
          }}
        >
          {card.component}
        </div>
        {card.overlay && (
          <div
            className={
              overlayStyles +
              " " +
              overlayHoverStyles +
              (card.focus ? " " + overlayFocusStyles : "")
            }
          >
            {card.overlay}
          </div>
        )}
      </div>
    );
  };

  /**
   * Callback function that will update necessary styles based on screen width
   * It will be called on load, resize, and when the visible card count changes
   */
  const handleDynamicStyles = useCallback(() => {
    if (window.innerWidth < 480) {
      // Mobile styles
      const newCardWidth = cardWidth / 2;
      const newCardHeight = cardHeight / 2;
      const newCardMargin = cardMargin / 2;
      setCurrentCardWidth(newCardWidth);
      setCurrentCardHeight(newCardHeight);
      setCurrentCardMargin(newCardMargin);
      setLeftShift(
        "-" +
          Number(2 * (newCardWidth + newCardMargin) - newCardWidth / 16) +
          "px"
      );
      setTraslateDistance(newCardWidth + newCardMargin);
    } else if (window.innerWidth < 768) {
      // Tablet styles
      const newCardWidth = cardWidth * 0.75;
      const newCardHeight = cardHeight * 0.75;
      const newCardMargin = cardMargin * 0.75;
      setCurrentCardWidth(newCardWidth);
      setCurrentCardHeight(newCardHeight);
      setCurrentCardMargin(newCardMargin);
      setLeftShift(
        "-" +
          Number(2 * (newCardWidth + newCardMargin) - newCardWidth / 10) +
          "px"
      );
      setTraslateDistance(newCardWidth + newCardMargin);
    } else {
      // Laptop and desktop styles
      setCurrentCardWidth(cardWidth);
      setCurrentCardHeight(cardHeight);
      setCurrentCardMargin(cardMargin);
      if (!handleVisibleCountCheck()) {
        setLeftShift(
          "-" + Number(2 * (cardWidth + cardMargin) - cardWidth / 4) + "px"
        );
      } else {
        setLeftShift(Number(cardWidth / 4) + "px");
      }
      setTraslateDistance(cardWidth + cardMargin);
    }
  }, [cardHeight, cardMargin, cardWidth, handleVisibleCountCheck]);

  useEffect(() => {
    handleDynamicStyles();
  }, [handleDynamicStyles, visibleCardCount]);

  return (
    <>
      <div
        id="tru-slider"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        className="group relative flex items-center"
        style={{
          minHeight: currentCardHeight + paddingTop + paddingBottom + "px",
          paddingTop: paddingTop + "px",
          paddingBottom: paddingBottom + "px",
        }}
      >
        {handleArrows(arrowType, "left", arrowCornerType)}
        {loading ? (
          <div className="flex w-full items-center justify-center">
            <PulseLoader color="#ffffff" />
          </div>
        ) : (
          <div
            className="absolute mx-6 flex flex-row items-center justify-start"
            style={{
              gap: currentCardMargin + "px",
              left: leftShift,
            }}
          >
            {!handleVisibleCountCheck() &&
              cardComponent(
                cards[cards.length - 2],
                "outer-card-" + Number(cards.length - 2)
              )}
            {!handleVisibleCountCheck() &&
              cardComponent(
                cards[cards.length - 1],
                "outer-card-" + Number(cards.length - 1)
              )}
            {cards.map((card: any, index: number) => {
              return cardComponent(card, index);
            })}
            {!handleVisibleCountCheck() &&
              cardComponent(cards[0], "outer-card-" + 0)}
            {!handleVisibleCountCheck() &&
              cardComponent(cards[1], "outer-card-" + 1)}
          </div>
        )}
        {handleArrows(arrowType, "right", arrowCornerType)}
      </div>
      {modalType === "video" && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-[999] flex items-center justify-center">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-indigo-900/80" />
          <div
            className="tru-free-content-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1]"
            onClick={() => handleModalClose()}
          />
          <div className="relative mx-auto flex-1 px-4 md:max-w-[75%] md:p-0">
            <Video
              url={selectedCard.videoLink}
              videoId={selectedCard.videoId}
              shareUrl={selectedCard.videoShareUrl}
              playsInline
              thumbnail={selectedCard.thumbnail}
              controls
              title={selectedCard.title}
              description={selectedCard.description}
            />
            <img
              id="video_modal_exit_btn"
              src={BackCaret}
              alt="Back"
              loading="eager"
              className={
                "absolute left-9 top-6 z-[1] h-8 w-8 rotate-90 cursor-pointer drop-shadow-xl" +
                " hover:drop-shadow-back_caret_glow md:left-5 md:top-8 md:h-10 md:w-10 md:hover:top-7 md:hover:h-12 md:hover:w-12 lg:left-7"
              }
              onClick={() => handleModalClose()}
            />
          </div>
        </div>
      )}
      {modalType === "image" && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-[999] flex items-center justify-center">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-indigo-900/80" />
          <div
            className="tru-free-content-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1]"
            onClick={() => handleModalClose()}
          />
          <div
            className={
              "relative flex max-h-screen flex-col items-center justify-center gap-8 overflow-auto p-4" +
              (!window.matchMedia("(orientation: portrait)").matches
                ? " !justify-start"
                : "")
            }
          >
            <img
              src={selectedCard.image}
              alt={selectedCard.title}
              loading="eager"
              className="max-h-screen"
            />
            {typeof selectedCard.downloadFunction === "function" && (
              <Button
                id="boi_download_btn"
                btnType="green"
                onClick={() => {
                  selectedCard.downloadFunction(
                    selectedCard.downloadLink,
                    selectedCard.title
                  );
                  handleModalClose();
                }}
                className="w-full"
              >
                Download / Print
              </Button>
            )}
          </div>
          <img
            id="pdf_modal_exit_btn"
            src={CloseIcon}
            alt="Close"
            loading="eager"
            className="absolute right-0 top-0 cursor-pointer pb-2 pl-2 pr-6 pt-6 hover:opacity-100 md:opacity-70"
            onClick={() => handleModalClose()}
          />
        </div>
      )}
    </>
  );
}
