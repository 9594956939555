interface IYesLadderOptionProps {
  text: string;
  active: boolean;
}

export default function YesLadderOption({
  text,
  active,
}: IYesLadderOptionProps) {
  return (
    <div
      className={
        "flex cursor-pointer items-center justify-center rounded-lg border-[3px]" +
        " border-solid border-cool-gray-200 bg-cool-gray-100 py-4 text-center" +
        " select-none font-display text-base font-semibold text-cool-gray-900" +
        (active ? " !border-cerulean-500 !text-cerulean-500" : "")
      }
    >
      {text}
    </div>
  );
}
