import { useEffect, useState } from "react";
import closeIcon from "../../../../global-assets/assets/icon-close-gray.svg";
import paperPlane from "../../../../global-assets/assets/paperPlane.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import UserAuthContext, {
  Context,
  GetEmailAddress,
  GetOrCreateSessionAuthContext,
  SecureCookieAttributes,
} from "../../../../config/UserAuthContext";
import React from "react";
import AccountServiceClient from "../../../../lib/ApiFunction";
import { ErrorCodeMap } from "../../../../lib/Constants";
import { InlineErrorMessage } from "../../../notification/InlineErrorMessage/InlineErrorMessage";
import ModalContainer from "../../../common/ModalContainer";
import Button from "../../../common/Button";

interface VerifyEmailPopupProps {
  handleClose: () => void;
  open: boolean;
}
const VerifyEmailPopup: React.FC<VerifyEmailPopupProps> = ({
  handleClose,
  open,
}) => {
  let navigate = useNavigate();
  const email_address: String = GetEmailAddress() || "";

  const [otp, setOtp] = useState<number>();
  const [userAuthCookie, setUserAuthCookie] = useState<UserAuthContext>();
  const { ResendOTPForVerification, VerifyEmailViaOTP } =
    AccountServiceClient();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const cookieData = GetOrCreateSessionAuthContext();
    if (cookieData) {
      setUserAuthCookie(cookieData);
    }
  }, []);

  const VerifyUserHandler = (e: any) => {
    e.preventDefault();
    setError(null);

    let data = {
      emailAddress: email_address,
      verifyCode: otp,
    };
    VerifyEmailViaOTP.post("", data)
      .then((res) => {
        if (res.data.isVerified) {
          if (userAuthCookie) {
            userAuthCookie.verifyEmail = res.data;
            Cookies.set(
              Context.UserContext,
              JSON.stringify(userAuthCookie),
              SecureCookieAttributes
            );
            setUserAuthCookie(userAuthCookie);
          }
          navigate("/account-settings");
          toast.success("Email address has been verified successfully.");
          handleClose();
          window.location.reload();
        } else {
          toast.warn("Unable to verify your email address. Please try again.");
          setTimeout(() => {
            navigate("/account-settings");
          }, 4000);
        }
      })
      .catch((error) => {
        const errorMessage = ErrorCodeMap[error.response?.data.statusCode]
          ? ErrorCodeMap[error.response?.data.statusCode]
          : error.response?.data.statusMessage;
        setError(errorMessage);
      });
  };

  const resendOtPHandler = () => {
    let data = {
      emailAddress: email_address,
    };
    ResendOTPForVerification.post("", data)
      .then((response) => {
        toast.success(
          "A secure code has been sent to your registered email address"
        );
      })
      .catch(() => {
        toast.warn(
          "Something went wrong! Please wait 5 minutes and try again."
        );
      });
  };

  return (
    <ModalContainer isOpen={open}>
      <div className="flex flex-col justify-center gap-4 bg-white">
        <div
          className="absolute right-[14px] top-[14px] cursor-pointer pr-0 pt-0 opacity-60 hover:opacity-100 md:right-5 md:top-5 lg:right-6 lg:top-6"
          onClick={handleClose}
        >
          <img src={closeIcon} loading="lazy" alt="" />
        </div>
        <h5 className="text-center font-display text-xl font-semibold capitalize text-indigo-500 md:text-2xl">
          Verification Email Sent!
        </h5>
        <div className="flex justify-around">
          <img
            src={paperPlane}
            alt="Truplay-logo"
            className="h-[110px] w-[184px]"
          />
        </div>
        <h4 className="text-center font-display text-2xl font-semibold capitalize text-indigo-500 md:text-3xl">
          Verify your Email
        </h4>
        <p className="m-0 text-center text-base leading-4">
          We've sent a verification code to your email address <br /> <br />
          <span className="break-words px-4 text-raspberry-500">
            {email_address}
          </span>
          <br />
          <br />
          Please type in the verification code below
        </p>
        <input
          className={
            "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
            " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
            " placeholder:text-cool-gray-900/70 focus:bg-cool-gray-300 " +
            " focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
            (error ? " ring-2 ring-raspberry-500" : "")
          }
          placeholder="Verification Code"
          value={otp || ""}
          onChange={(e: any) => {
            setOtp(e.target.value);
          }}
        />
        {error && <InlineErrorMessage errorMessage={error} />}
        <Button
          id="modal_verify_email_btn"
          onClick={(e: Event) => {
            VerifyUserHandler(e);
          }}
          btnType="green"
          className="w-full"
        >
          Verify email
        </Button>
        <div className="flex items-center justify-center">
          <p className="m-0 text-base font-normal">Did not get the code?</p>
          <Button
            id="modal_resend_code_btn"
            btnType="tertiaryAlt"
            onClick={() => resendOtPHandler()}
            className="ml-2 !text-base hover:underline"
          >
            Resend Code
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default VerifyEmailPopup;
