interface VideoInfo {
  [key: string]: {
    id: number;
    url: string;
    title: string;
    width?: string;
    height?: string;
    autoPlay?: boolean;
    visible: boolean;
    loop?: boolean; // Default is false
    muted?: boolean; // Default is false
    playsinline?: boolean; // Default is true
    shareLink?: string;
    thumbnail?: string;
    ref?: any;
  };
}

/**
 * Handles autoplay for any video passed in through the visibleVideos object
 * Will only allow 1 visible video to be played at a time
 * @param visibleVideos Object of videos with their visible state
 * @param currentAutoPlayVideos Object containing video details including the autoplay state
 * @param setCurrentAutoPlayVideos Setter for what videos details
 */
export const handleAutoPlay = (
  visibleVideos: { [key: string]: boolean },
  currentAutoPlayVideos: VideoInfo | null,
  setCurrentAutoPlayVideos: (autoPlayVideos: VideoInfo) => void
) => {
  const autoPlayVideos = { ...currentAutoPlayVideos };
  const currentVisibleVideos: any[] = [];
  Object.entries(visibleVideos).forEach(([id, isVisible]: any) => {
    if (isVisible) {
      currentVisibleVideos.push(id);
    }
    autoPlayVideos[id].visible = isVisible;
    autoPlayVideos[id].autoPlay = isVisible;
  });
  // Ensures only 1 video is being autoplayed, the lowest on the page, if there are multiple in view
  if (currentVisibleVideos.length > 1) {
    currentVisibleVideos.forEach((id, index) => {
      if (index !== currentVisibleVideos.length - 1) {
        autoPlayVideos[id].autoPlay = false;
      }
    });
  }
  setCurrentAutoPlayVideos({ ...autoPlayVideos });
};

/**
 * 
 * @param videoToShow 
 * @param videoRef 
 * @param videoId 
 */
export const scrollToVideo = (videoToShow: string | null, videoRef: any, videoId: string | number) => {
  if (
    videoToShow &&
    videoRef.current &&
    videoToShow === videoId.toString()
  ) {
    setTimeout(() => {
      if (videoRef.current) {
        const targetScrollPosition =
          videoRef.current.getBoundingClientRect().top +
          window.scrollY -
          120;
        window.scrollTo({
          top: targetScrollPosition,
          behavior: "smooth",
        });
      }
    }, 1000)
  }
}
