import "./InlineErrorMessage.css";

// @ts-ignore
interface InlineErrorMessageProps {
  errorMessage: string | React.ReactNode;
}
export function InlineErrorMessage({ errorMessage }: InlineErrorMessageProps) {
  return (
    <div
      id="inline-error"
      className="max-w-fit text-left text-sm font-normal leading-4 text-raspberry-500"
    >
      {errorMessage}
    </div>
  );
}
