import UAParser, { IDevice, IOS } from "ua-parser-js";

export class Campaign {
  //
  name: string = "";
  //
  platform: string = "";
  // e.g. google, meta, influencer, rumble, klaviyo, earned_media, e.t.c
  source: string = "";
  // video, email, website, 
  medium: string = "";
}

export class DeviceSessionContext {

  url: string;

  // userAgent = appCodeName/appVersion number (Platform; Security; OS-or-CPU;
  // Localization; rv: revision-version-number) product/productSub
  // Application-Name Application-Name-version
  userAgent: string;

  // The value is an empty string if the user navigated to the page directly 
  // (not through a link, but, for example, by using a bookmark).
  referrer: string;

  // Sample campaign ids are ugc, endorsement, copeland, homeschooling, referral
  campaign: string = "organic";

  //
  os: IOS = { name: "", version: "" };
  device: IDevice = { type: "", model: "", vendor: "" }

  constructor() {
    this.userAgent = window.navigator.userAgent;
    this.referrer = window.document.referrer;
    this.url = window.document.URL;
    let userDevice = new UAParser(this.userAgent).getResult();
    this.os = userDevice.os;
    this.device = userDevice.device;
  }
}

