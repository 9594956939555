import { useContext, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";

// Contexts //
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";
import {
  EnvironmentConfigContext,
  SubscriptionPeriod,
} from "../../config/Environment";
import { useFeatureFlags } from "../../config/FeatureFlagContext";

// Assets //
import EasterBanner from "../../global-assets/assets/Easter-Banner-Corner.png";

interface IPriceOptionsProps {
  annualPrice: number;
  monthlyPrice: number;
  ctaButton?: JSX.Element;
}

export default function PriceOptions({
  annualPrice,
  monthlyPrice,
  ctaButton,
}: IPriceOptionsProps) {
  const firebaseFlags = useRemoteConfig();
  const config = useContext(EnvironmentConfigContext);
  const featureFlags = useFeatureFlags();
  const AnnualPriceTest =
    featureFlags["2024-06 - Select Plan Page - Annual Pricing Test ($79.99)"]
      ?.variationId;
  const [annualPriceTest, setAnnualPriceTest] = useState<boolean>(false);

  const annualTestPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.AnnualTest
    ) || 0;

  useEffect(() => {
    if (AnnualPriceTest) {
      switch (AnnualPriceTest) {
        case "1":
          break;
        case "2":
          setAnnualPriceTest(true);
          break;
      }
    }
  }, [AnnualPriceTest]);

  if (
    firebaseFlags.easterDiscount === undefined &&
    firebaseFlags.springSale === undefined
  ) {
    return (
      <div className="flex min-h-[500px] items-center justify-center">
        <PulseLoader color="#ffffff" />
      </div>
    );
  }

  return (
    <>
      <div className="relateive flex w-full flex-col gap-4 homemd:max-w-[500px] md:max-w-[600px] lg:max-w-[800px] xl:w-full xl:max-w-none xl:flex-row">
        <div className="relative flex flex-1 flex-col items-center justify-center gap-3 self-stretch rounded-[40px] border-4 border-solid border-turquoise-500 bg-white/5 p-6 shadow-[0px_4px_80px_0px_rgba(122,201,197,0.1)_inset] lg:max-w-[800px] lg:px-16 lg:py-10">
          {firebaseFlags.easterDiscount && (
            <img
              className="absolute right-0 top-0 max-w-[100px]"
              src={EasterBanner}
              alt="Easter Sale Banner"
            />
          )}
          {firebaseFlags.springSale ? (
            <>
              <div className="absolute -right-[14px] -top-[53px] h-[160px] w-[80px] -rotate-45 overflow-hidden">
                <div className="relative -left-[147px] -top-[15px] h-[113px] w-[220px] rotate-45 rounded-se-[40px] bg-turquoise-500" />
              </div>
              <div className="absolute -right-[15px] top-[20px] max-w-[100px] rotate-45 font-display text-base font-bold uppercase !leading-[1.2] text-black">
                Spring Sale!
              </div>
            </>
          ) : (
            <>
              <div className="absolute -right-[14px] -top-[53px] h-[160px] w-[80px] -rotate-45 overflow-hidden">
                <div className="relative -left-[147px] -top-[15px] h-[113px] w-[220px] rotate-45 rounded-se-[40px] bg-turquoise-500" />
              </div>
              <div className="absolute -right-[15px] top-[15px] max-w-[100px] rotate-45 font-display text-base font-bold uppercase !leading-[1.2] text-black">
                Best Value
              </div>
            </>
          )}
          <h3 className="text-white">Annual Plan</h3>
          <h2 className="text-white drop-shadow-[0px_0px_16px_#38a6d5]">
            {annualPriceTest ? `$6.67 / month` : `$5.83 / month`}
          </h2>
          <p className="m-0 text-indigo-200/80 lg:text-[2rem] lg:font-normal lg:leading-normal">
            {
              <>
                (<span className="line-through">$155.88</span>
                &nbsp;
                {annualPriceTest
                  ? `$${annualTestPrice}`
                  : `$${annualPrice}`}{" "}
                billed annually)
              </>
            }
          </p>
          <p className="m-0 text-indigo-200/80 lg:text-[2rem] lg:font-normal lg:leading-normal">
            After your 7 day FREE trial
          </p>
        </div>
        <div className="relative flex flex-1 flex-col items-center gap-3 self-stretch lg:max-w-[800px] lg:px-16 lg:py-10">
          <h3 className="text-white">Monthly Plan</h3>
          <h2 className="text-white">${monthlyPrice} / month</h2>
          <p className="m-0 text-indigo-200/80 lg:text-[2rem] lg:font-normal lg:leading-normal">
            After your 7 day FREE trial
          </p>
        </div>
      </div>
      {ctaButton && (
        <div className="mt-8 flex w-full flex-col items-center justify-center gap-4">
          {ctaButton}
          <div className="font-body text-xl font-normal text-white">
            Cancel anytime.
          </div>
        </div>
      )}
    </>
  );
}
