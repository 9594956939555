import { useEffect } from "react";
import CareersCareersHero from "../../components/careers/CareersHero";
import CareersBenefits from "../../components/careers/CareersBenefits";
import { Helmet } from "react-helmet";

const Careers = () => {
  useEffect(() => {
    const cookiesAvailable = () => {
      try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        return true;
      } catch (e) {
        return false;
      }
    };

    if (cookiesAvailable() && process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);
  return (
    <div id="test_data_careers_page" className="min-h-screen">
      <Helmet>
        <title>Join Our Team - Christian Gaming Company | TruPlay Games</title>
        <link rel="canonical" href="https://www.truplaygames.com/careers" />
        <meta
          name="description"
          content="Explore career opportunities at TruPlay! Join a team dedicated to creating positive, Christian gaming experiences. Help us turn game time into God time for families around the world."
        />
      </Helmet>
      <CareersCareersHero />
      <CareersBenefits />
    </div>
  );
};

export default Careers;
