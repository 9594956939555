import { useContext, useEffect, useRef, useState } from "react";
import BenGames from "../../global-assets/assets/BenGames.png";
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import Utm from "../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import TagManager from "react-gtm-module";
import {
  Context,
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../config/UserAuthContext";
import Cookies from "js-cookie";
import {
  EnvironmentConfigContext,
  SubscriptionPeriod,
} from "../../config/Environment";

// Components //
import FeaturedCarousel from "../../components/common/FeaturedCarousel";
import PromiseKeepersNavBar from "../../components/promise-keepers/PromiseKeepersNavBar/PromiseKeepersNavBar";
import PromiseKeepersFooter from "../../components/promise-keepers/PromiseKeepersFooter/PromiseKeepersFooter";
import PlanFeatures from "../../components/home-landing/PlanFeatures/PlanFeatures";
import TruPlaySteps from "../../components/home-landing/TruPlaySteps/TruPlaySteps";
import AppReviewSlider from "../../components/common/AppReviewsSlider";
import PartnershipSection from "../../components/promise-keepers/PartnershipSection/PartnershipSection";
import ChristianGamesCta from "../../components/promise-keepers/ChristianGamesCta/ChristianGamesCta";
import Reasons from "../../components/common/Reasons";
import Video from "../../components/common/Video";
import EndorsementsLogos from "../../components/home-landing/Endorsements/EndorsementsLogos";
import FeaturedEndorsement from "../../components/common/FeaturedEndorsement";
import ThreeBlockGraphic from "../../components/common/ThreeBlockGraphic";
import Button from "../../components/common/Button";
import SectionHeader from "../../components/common/SectionHeader";

// Assets //
import ThumbKdb from "../../components/crowdfunding/assets/thumbnail_kdb.webp";
import ThumbBub from "../../components/crowdfunding/assets/thumbnail_bub.webp";
import ThumbMaple from "../../components/crowdfunding/assets/thumbnail_mpl.webp";
import ThumbBen from "../../components/crowdfunding/assets/thumbnail_ben.webp";
import LargePlayBtn from "../../components/crowdfunding/assets/branded_play_btn.svg";
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import safeTrusted from "../../global-assets/assets/reasons-safe-trusted-icon.svg";
import supportPk from "../../global-assets/assets/support-pk.svg";
import reasonsToGrowChild from "../../global-assets/assets/reasons-grow-your-child.png";
import AccentStars1 from "../../global-assets/assets/Accent_Stars_01.svg";
import HuckabeeLogo from "../../global-assets/assets/logo-huckabee-alt.png";
import CandacePodcastLogo from "../../global-assets/assets/logo-candace-bure-podcast.webp";
import ChristianityTodayLogo from "../../global-assets/assets/logo-christianity-today.png";
import PromiseKeepersLogo from "../../global-assets/assets/logo-promise-keepers.png";
import BillyGrahamLogo from "../../global-assets/assets/logo-billy-graham.svg";
import PluggedInLogo from "../../global-assets/assets/logo-plugged-in.webp";
import KenRHarrison from "../../components/promise-keepers/assets/Photo_KenHarrison.png";
import GiveAsGiftIcon from "../../global-assets/assets/icon-give.svg";
import DownArrow from "../../global-assets/assets/Down-arrow.svg";

interface IVideoTileList {
  id: string;
  tileImage: string; // Path to tile image
  videoLink: string; // URL to video (CDN link)
  thumbnail?: string;
  videoShareUrl?: string; // Link to shareable video (Vimeo link)
  component: JSX.Element | null;
  focus: boolean; // True if the carousel has focused tile logic
  type: string; // Type of tile
  title?: string | null;
  description?: string | null;
  visible: boolean; // True if within the container of visibility
  display: JSX.Element | null; // Element displayed when featured
}

const videoTileList: IVideoTileList[] = [
  {
    id: "tile_1",
    tileImage: ThumbKdb,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/kdb_trailer%20(720p).mp4",
    thumbnail: ThumbKdb,
    videoShareUrl: "https://vimeo.com/936032354",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
    title: "King David's Battle",
    description:
      "From a shepherd to a warrior to being crowned king, relive the greatest moments in David's life, and learn how he strengthened himself in the Lord to achieve victory.",
  },
  {
    id: "tile_2",
    tileImage: ThumbBub,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/chirp_song__wings_of_praise_-_official_trailer%20(720p).mp4",
    thumbnail: ThumbBub,
    videoShareUrl: "https://vimeo.com/953604909",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
    title: "Chirp Song: Wings of Praise",
    description:
      'This charming bubble shooter game set in the RhymVerse reveals the restorative power of praise as players free the "Chirps" in the Forest of Words from Axilla\'s corruption! “Let everything that has breath praise the Lord” (Psalm 150:6).',
  },
  {
    id: "tile_3",
    tileImage: ThumbMaple,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/maple_trailer%20(720p).mp4",
    thumbnail: ThumbMaple,
    videoShareUrl: "https://vimeo.com/823889094",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
    title: "Maple & The Forest of Words",
    description:
      "The mysterious, brooding Forest of Words holds unimaginable dangers. In this side-scrolling action-adventure, Maple the Tiger Bunny and her friend, Oliver the Biblical Fox, are on a rescue mission. Maple's journey will take her from the tree tops of the forest to the depths of the Berindium mines, outwitting ancient corruption, dangerous smudz, and the wicked Axilla herself. Can you help Maple before the power of words is lost in the corrupted forest forever?",
  },
  {
    id: "tile_4",
    tileImage: ThumbBen,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/ben_trailer%20(720p).mp4",
    thumbnail: ThumbBen,
    videoShareUrl: "https://vimeo.com/890078808",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
    title: "Benjamin Armor of Logos",
    description:
      "Benjamin is on a mission to find all of the pieces of the Armor of Logos, but vicious Smudz sent by the evil Queen Axilla stand in his way. He must rely on the power of prayer and courage that only comes from God to overcome his foes and recapture the lost armor.",
  },
];

videoTileList.forEach((tile) => {
  tile.component = (
    <img
      src={tile.tileImage}
      alt={tile.id}
      loading="eager"
      draggable="false"
      className="rounded-lg sm:rounded-2xl lg:rounded-[20px]"
    />
  );
  tile.display = (
    <div
      id="tru-feature_our_journey"
      key={tile.videoLink}
      className="relative w-full"
    >
      <Video
        id="our_journey_featured_video"
        url={tile.videoLink}
        shareUrl={tile.videoShareUrl}
        shareSmall
        playsInline
        thumbnail={tile.thumbnail}
        disableRemotePlayback
        customPlayBtn={LargePlayBtn}
        noShadow
      />
    </div>
  );
});

const ENDORSED_LOGOS = {
  christianityToday: { id: "christianityToday", image: ChristianityTodayLogo },
  candace: { id: "candace", image: CandacePodcastLogo },
  billy: { id: "billy", image: BillyGrahamLogo },
  pk: { id: "pk", image: PromiseKeepersLogo },
  pluggedIn: { id: "pluggedIn", image: PluggedInLogo },
  huckabee: { id: "huckabee", image: HuckabeeLogo },
};

const PromiseKeepers = () => {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const initialUserContext = GetOrCreateSessionAuthContext();

  const [userContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");

  const faqRef = useRef<HTMLDivElement>(null);
  const joinMissionRef = useRef<HTMLDivElement>(null);

  const tileMargin = 16;
  const carouselPaddingTop = 16;
  const carouselPaddingBottom = 32;

  const [featuredCard, setFeaturedCard] = useState<any>(videoTileList[0]);

  // const navigate = useNavigate();
  const config = useContext(EnvironmentConfigContext);
  const osType = deviceCtx.os.name;

  const monthlyPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Monthly
    ) || 0;
  const annualPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Annual
    ) || 0;

  const [originalPrice, setOriginalPrice] = useState<string | number | null>(
    (monthlyPrice * 12).toFixed(2)
  );
  const [currentAnnualPrice, setCurrentAnnualPrice] = useState<string | number>(
    annualPrice
  );
  const [annualMonthlyPrice, setAnnualMonthlyPrice] = useState<string | number>(
    (annualPrice / 12).toFixed(2)
  );
  const [currentMonthlyPrice, setCurrentMonthlyPrice] = useState<
    string | number
  >(monthlyPrice);

  Cookies.remove(Context.Pk);

  const handlePartnershipClick = () => {
    if (joinMissionRef.current) {
      const targetScrollPosition =
        joinMissionRef.current.getBoundingClientRect().top +
        window.scrollY -
        120;
      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handleFAQClick = () => {
    if (faqRef.current) {
      const targetScrollPosition =
        faqRef.current.getBoundingClientRect().top + window.scrollY - 120;
      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: Promise Keepers",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Promise-Keepers-Page-Viewed", deviceCtx);
  }, [
    deviceCtx,
    mixPanelAnalytics,
    userContext.subscription.sources,
    GlobalUserId,
  ]);

  const handleGiftPurchaseLink = () => {
    const priceId = "com_truplaygames_giftannual_6999";
    const redirectUrl = `/generate-gifting-code?configName=pk_annual_purchase&priceid=${encodeURIComponent(
      priceId
    )}&cancelurl=${encodeURIComponent(window.location.href)}&via=pk`;

    navigateTo(redirectUrl);

    TagManager.dataLayer({
      dataLayer: {
        event: "plan_type_select",
        os_type: osType,
        plan_type: "GiftAnnual",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  };

  const handlePurchaseClick = () => {
    navigateTo("/user-registration");

    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        os_type: osType,
        content_type: "subscribe cta",
        link_name: "Promise-Keepers",
        link_url: "/user-registration",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  };

  return (
    <>
      <div id="test_data_promise_keepers_page" className="min-h-screen">
        <PromiseKeepersNavBar handleScroll={handlePartnershipClick} />
        <div className="relative overflow-hidden">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_110%_-10%,#68e0cf_0%,transparent_20%)] opacity-10 lg:opacity-40" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_110%_10%,#38D58A_0%,transparent_10%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_70%_20%,#652c80_0%,transparent_20%)] opacity-30 lg:opacity-50" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_30%,#68e0cf_0%,transparent_15%)] opacity-15" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_40%,#652c80_0%,transparent_10%)] opacity-40" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_50%,#38D58A_0%,transparent_10%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_60%,#68e0cf_0%,transparent_20%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_0%_70%,#652c80_0%,transparent_20%)] opacity-50" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_80%,#38D58A_0%,transparent_10%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_100%,#68e0cf_0%,transparent_15%)] opacity-20" />
          <div className="mx-auto flex w-full max-w-[1440px] flex-col px-5 py-12 !pb-52 pt-6 sm:px-6 sm:py-16 md:px-7 md:py-24 lg:px-12 lg:pt-0">
            <PartnershipSection
              buttonLabel="Join the Mission"
              onButtonClick={handlePartnershipClick}
            />
            <div className="flex flex-col gap-8 lg:gap-20">
              <Reasons
                mainTitle="Why Partner With TruPlay?"
                mainSubtext="By gifting a subscription to friends and family you're helping grow their faith through play and making it possible for us to continue to create Gospel-centered entertainment."
                block1Image={safeTrusted}
                block1Title="Support Christian Entertainment"
                block1Subtext="Join the mission to create transformational, faith-filled content."
                block2Image={supportPk}
                block2Title="Support Promise Keepers"
                block2Subtext="Committed to building up Godly men for a better tomorrow."
                block3Image={reasonsToGrowChild}
                block3Title="Take a Stand For Our Children"
                block3Subtext="Only 31% of children in America believe in God today."
              />
            </div>
          </div>
          <div className="bg-white/5 px-4 py-12 !pt-28 shadow-[0_0_80px_0_#001b36] sm:px-6 sm:py-16 md:py-24 lg:pb-16">
            <div className="mx-auto w-full max-w-[1440px]">
              <div />
              <FeaturedEndorsement
                imageSrc={KenRHarrison}
                name="Ken R. Harrison"
                title="CEO - Promise Keepers"
                description="“In a world that actively preys on the vulnerable hearts and minds of our kids, we must be intentional in providing them with high-quality, faith-formative entertainment. TruPlay understands that those who obtain a solid foundation of faith from an early age are more likely to become God-fearing fathers and mothers, husbands, and wives. I'm grateful to TruPlay for making a bold choice to insert Christ into the gaming space and support the cultivation of healthy families in the future.”"
              />
            </div>
          </div>
          <div className="relative overflow-hidden" ref={joinMissionRef}>
            <div className="mx-auto flex w-full max-w-[1440px] flex-col gap-8 px-5 py-12 sm:px-6 sm:py-16 md:gap-16 md:px-7 md:py-24 lg:gap-28 lg:px-12 lg:pb-16 lg:pt-28">
              <SectionHeader
                headerType="h2"
                headerText="Save with an Annual Subscription"
                starLocation="right"
              />
              <div className="flex flex-col gap-16">
                <ThreeBlockGraphic
                  block1Title="Annual Plan"
                  block1SubTitle={
                    <div>
                      ${annualMonthlyPrice}
                      <span className="text-2xl font-medium">/mo</span>
                    </div>
                  }
                  block1Subtext={
                    <div>
                      <span className="line-through">${originalPrice}</span> $
                      {annualPrice}
                    </div>
                  }
                  block1Description="Billed annually after FREE trial"
                  block1CTAText="Redeem Your FREE Week"
                  block1CTAOnClick={handlePurchaseClick}
                  block1CTAId="pk_subscription_annual"
                  block1Featured
                  block2Title="Monthly Plan"
                  block2SubTitle={
                    <div>
                      ${monthlyPrice}
                      <span className="text-2xl font-medium">/mo</span>
                    </div>
                  }
                  block2Description="Billed monthly after FREE trial"
                  block2CTAText="Redeem Your FREE Week"
                  block2CTAOnClick={handlePurchaseClick}
                  block2CTAVariant="secondary"
                  block2CTAId="pk_subscription_monthly"
                  block3Title="Give as Gift"
                  block3ImageSrc={GiveAsGiftIcon}
                  block3Description="Share God's truth through entertainment!"
                  block3CTAText="Gift a Subscription"
                  block3CTAOnClick={handleGiftPurchaseLink}
                  block3CTAVariant="secondary"
                  block3CTAId="pk_subscription_gift"
                />
                <div className="flex flex-col items-center justify-center gap-8">
                  <div className="flex w-full items-center justify-center rounded-3xl border-[3px] border-dashed border-indigo-200/50 p-8">
                    <p className="m-0 text-center font-display text-4xl font-normal text-white">
                      Get an extra <span className="font-bold">20% OFF</span>{" "}
                      with your Event Code!
                    </p>
                  </div>
                  <Button
                    onClick={handleFAQClick}
                    btnType="tertiary"
                    rightIcon={
                      <img
                        src={DownArrow}
                        alt="Down Arrow"
                        className="ml-2 h-6 w-6 md:h-8 md:w-8"
                      />
                    }
                  >
                    Have Questions?
                  </Button>
                </div>
              </div>
              <ChristianGamesCta
                backgroundImage={BenGames}
                title="TruPlay: Christian Games & Content for the whole family"
                text="Provide your family with Bible-based games and Christian content they love, while you enjoy a safe platform with no ads, in-app purchases, or chat features to worry about."
                buttonLabel="Learn More"
              />
              <div className="flex flex-col justify-center gap-8 lg:flex-row lg:gap-20">
                <div className="w-full flex-1 overflow-hidden lg:flex-[0.6_1_0]">
                  <FeaturedCarousel
                    id="pk_games"
                    initialState={videoTileList}
                    cardMargin={tileMargin}
                    responsive
                    animated
                    cardStyles="shadow-lg rounded-lg sm:rounded-2xl lg:rounded-[20px] cursor-pointer duration-200 ease-in-out"
                    hoverStyles="md:group-hover/card-hover:shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5] md:group-hover/card-hover:ring-white md:group-hover/card-hover:ring-4"
                    focusStyles="rounded-lg sm:rounded-2xl lg:rounded-[20px] ring-[3px] sm:ring-4 ring-white cursor-pointer duration-200 ease-in-out shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5]"
                    arrowType="featured"
                    arrowCornerType="rounded"
                    selection
                    paddingBottom={carouselPaddingBottom}
                    paddingTop={carouselPaddingTop}
                    focus
                    visibleCount={4}
                    setDisplayedCard={setFeaturedCard}
                  />
                </div>
                <div className="flex w-full flex-1 flex-col gap-6 lg:mt-[5%] lg:flex-[0.4_1_0]">
                  <h3 className="hidden text-center text-4xl text-white lg:inline lg:text-left">
                    {featuredCard.title}
                  </h3>
                  <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl lg:font-semibold">
                    {featuredCard.description}
                  </p>
                </div>
              </div>
              <PlanFeatures />
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden bg-white/5 py-12 shadow-[0_0_80px_0_#001b36] sm:py-16 md:py-24 lg:py-32">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_0%,#68e0cf_0%,transparent_30%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_100%_100%,#652c80_0%,transparent_50%)] opacity-50" />
          <AppReviewSlider />
        </div>
        <div className="relative overflow-hidden px-[20px] py-[50px] sm:px-[25px] sm:py-[50px] md:px-[30px] md:py-[75px] lg:px-[50px] lg:py-[125px]">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_100%_0%,#652c80_0%,transparent_40%)] opacity-40" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_30%,#38D58A_0%,transparent_30%)] opacity-30" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_60%,#68e0cf_0%,transparent_30%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_0%_100%,#652c80_0%,transparent_50%)] opacity-30" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_50%_100%,#20568B_0%,transparent_20%)] opacity-20" />
          <div className="mx-auto flex max-w-[1440px] flex-col gap-[60px] sm:gap-[75px] md:gap-[150px]">
            <TruPlaySteps />
            <div className="max-w-[2000px mx-auto">
              <EndorsementsLogos
                title="TruPlay is Endorsed by:"
                mobileTitle="Endorsed by:"
                noBackground
                logos={ENDORSED_LOGOS}
              />
            </div>
            <div ref={faqRef}>
              <div className="relative mx-auto flex w-full flex-col gap-6 text-center">
                <h2 className="text-transparent relative mx-auto bg-gradient_text bg-clip-text">
                  Have Questions?
                  <img
                    src={AccentStars1}
                    loading="lazy"
                    alt=""
                    className="absolute -left-[70px] -top-[20px] hidden lg:block"
                  />
                </h2>
                <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <a
                    href="/faq"
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-1 cursor-pointer flex-col items-center gap-4 rounded-3xl bg-[rgba(255,255,255,0.06)] p-8 shadow-[0_25px_20px_-20px_#001b36] duration-100 hover:-translate-y-2 hover:shadow-[0px_4px_30px_5px_rgba(56,166,213,0.6)]"
                  >
                    <h4 className="text-white">
                      Visit our{" "}
                      <span className="text-cerulean-500 hover:underline">
                        FAQ
                      </span>{" "}
                      page
                    </h4>
                  </a>
                  <a
                    href="/contact"
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-1 cursor-pointer flex-col items-center gap-4 rounded-3xl bg-[rgba(255,255,255,0.06)] p-8 shadow-[0_25px_20px_-20px_#001b36] duration-100 hover:-translate-y-2 hover:shadow-[0px_4px_30px_5px_rgba(56,166,213,0.6)]"
                  >
                    <h4 className="text-white">
                      Need help?{" "}
                      <span className="text-cerulean-500 hover:underline">
                        Contact Us
                      </span>
                    </h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto flex max-w-none overflow-hidden">
          <img
            src={BGLandingImage}
            loading="eager"
            sizes="100vw"
            alt=""
            className="relative -z-[1] w-full min-w-[1920px]"
          />
        </div>
      </div>
      <PromiseKeepersFooter handleScroll={handlePartnershipClick} />
    </>
  );
};

export default PromiseKeepers;
