import React from "react";
import accentStars2 from "../../../global-assets/assets/Accent_Stars_02.svg";
import Video from "../../common/Video";
import ThumbPK from "../assets/thumbnail_pk.webp";
import Button from "../../common/Button";

interface PartnershipSectionProps {
  buttonLabel: string;
  onButtonClick: () => void;
}

const PartnershipSection: React.FC<PartnershipSectionProps> = ({
  buttonLabel,
  onButtonClick,
}) => {
  return (
    <div className="flex min-h-[700px] flex-col items-center justify-center gap-8 pb-0 sm:min-h-[750px] lg:min-h-[800px] lg:flex-row lg:gap-16">
      <div className="lg:hidden">
        <div className="relative flex flex-col gap-4">
          <h2 className="text-transparent relative mx-auto max-w-[280px] bg-gradient_text bg-clip-text text-center sm:max-w-[320px] md:max-w-[540px] lg:max-w-[320px] lg:text-left xl:max-w-[520px]">
            TruPlay & Promise Keepers Partnership
            <img
              src={accentStars2}
              loading="lazy"
              alt=""
              className="absolute right-[-15px] top-[-20px] hidden w-[60px] md:right-[-85px] md:top-[-30px] md:w-[95px] lg:right-[-35px] lg:top-[-30px] lg:inline lg:w-[95px] 2xl:right-[-35px] 2xl:top-[-30px] 2xl:w-[95px]"
            />
          </h2>
          <p className="m-0 text-center text-white lg:text-left">
            By partnering with TruPlay you are continuing to share God's Truth
            with the next generation. Every purchase impacts a family, and a
            portion of each subscription goes back to supporting Promise
            Keepers.
          </p>
        </div>
      </div>
      <div className="w-full lg:flex-[0.6_1_0]">
        <Video
          url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/promise_keepers%20(720p).mp4"
          shareUrl="https://vimeo.com/881918313"
          controls
          playsInline
          shareSmall
          thumbnail={ThumbPK}
        />
      </div>
      <div className="w-full lg:flex-[0.4_1_0]">
        <div className="flex flex-col gap-6">
          <div className="hidden flex-col gap-6 lg:flex">
            <h2 className="text-transparent relative max-w-[280px] bg-gradient_text bg-clip-text sm:max-w-[320px] md:max-w-[540px] lg:max-w-[320px] xl:max-w-[520px]">
              TruPlay & Promise Keepers Partnership
              <img
                src={accentStars2}
                loading="lazy"
                alt=""
                className="absolute right-[-15px] top-[-20px] w-[60px] md:right-[-85px] md:top-[-30px] md:w-[95px] lg:right-[-35px] lg:top-[-30px] lg:w-[95px] 2xl:right-[-35px] 2xl:top-[-30px] 2xl:w-[95px]"
              />
            </h2>
            <p className="m-0 text-white">
              By partnering with TruPlay you are continuing to share God's Truth
              with the next generation. Every purchase impacts a family, and a
              portion of each subscription goes back to supporting Promise
              Keepers.
            </p>
          </div>
          <Button
            id="partnership_section_cta_btn"
            onClick={onButtonClick}
            btnType="green"
            className="w-full"
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PartnershipSection;
