import { useContext, useEffect, useState } from "react";
import { DeviceSessionContext } from "../../../components/analytics/DeviceContext";
import Utm from "../../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../../components/analytics/Analytics";
import TagManager from "react-gtm-module";
import {
  navigateTo,
  GetOrCreateSessionAuthContext,
} from "../../../config/UserAuthContext";
import Cookies from "js-cookie";

// Images //
import indFam from "../../../global-assets/assets/Individual_Family.png";
import groupOrg from "../../../global-assets/assets/Group_Org.png";

// Components //
import Button from "../../../components/common/Button";

const AffiliateMain = () => {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const initialUserContext = GetOrCreateSessionAuthContext();

  const [userContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");

  const handleIndividualClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "Get Started Individual",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
    navigateTo("/affiliate_individual");
  };

  const handleGroupClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        link_name: "Get Started Group",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
    navigateTo("/affiliate_group");
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: Affiliate Main",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Affiliate-Main-Page-Viewed", deviceCtx);
  }, [
    deviceCtx,
    mixPanelAnalytics,
    userContext.subscription.sources,
    GlobalUserId,
  ]);

  return (
    <>
      <div
        id="test_data_affiliate_main_page"
        className="min-h-screen bg-cerulean-100"
      >
        <div className="flex flex-col gap-6 overflow-hidden px-5 py-32 md:gap-20 md:pt-40">
          <div className="flex flex-col gap-2 md:gap-6">
            <h1 className="text-center font-display text-3xl font-semibold text-indigo-500 md:text-5xl">
              Recommend TruPlay.
            </h1>
            <h1 className="text-center font-display text-3xl font-semibold text-indigo-500 md:text-5xl">
              Ignite Kids' Faith. Earn Commission.
            </h1>
          </div>
          <div className="flex flex-col flex-wrap items-center gap-6 md:flex-row md:justify-center lg:gap-12">
            <div className="flex max-w-[580px] flex-col justify-between gap-4 rounded-[40px] bg-white p-5 text-center shadow-[7px_17px_40px_0px_rgba(0,62,122,0.1)]">
              <div className="flex flex-col gap-5">
                <h1 className="text-transparent bg-gradient_text bg-clip-text font-display text-4xl md:text-5xl">
                  Individual
                </h1>
                <p className="m-0 min-h-12 font-body text-base font-medium leading-normal text-indigo-300">
                  (Actively involved individual in a parenting community,
                  <br className="hidden sm:block" /> influencer, blogger, etc.)
                </p>
                <img
                  src={indFam}
                  alt="Individual or Family"
                  className="h-auto w-full"
                />
              </div>
              <div className="flex w-full justify-center">
                <Button
                  id="affiliate_individual_btn"
                  onClick={handleIndividualClick}
                  btnType="green"
                  className="!px-16"
                >
                  Get Started
                </Button>
              </div>
            </div>

            <div className="flex max-w-[580px] flex-col justify-between gap-4 rounded-[40px] bg-white p-5 text-center shadow-[7px_17px_40px_0px_rgba(0,62,122,0.1)]">
              <div className="flex flex-col gap-5">
                <h1 className="text-transparent bg-gradient_text bg-clip-text font-display text-4xl md:text-5xl">
                  Church or Group
                </h1>
                <p className="m-0 min-h-12 font-body text-base font-medium leading-normal text-indigo-300">
                  (Organization, small groups, teams, etc.)
                </p>
                <img
                  src={groupOrg}
                  alt="Church or Group"
                  className="h-auto w-full"
                />
              </div>
              <div className="flex w-full justify-center">
                <Button
                  id="affiliate_group_btn"
                  onClick={handleGroupClick}
                  btnType="green"
                  className="!px-16"
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliateMain;
