// TODO: Resources for possible anmiation inspiration
// https://www.youtube.com/watch?v=Sj48KG3DMYA
// https://www.youtube.com/watch?v=5fcQPUEXVgk
// https://www.youtube.com/watch?v=PyjEHQ3N14A

import { useCallback, useEffect, useState } from "react";

// Assets //
import SliderArrow from "../../global-assets/assets/faq_arrow.svg";

interface VideoCarouselProps {
  initialState: any[]; // Initial state of cards for the carousel
  focus?: boolean; // The middle card will have focusStyles applied (could add a focus threshold in the future)
  focusStyles?: string; // Focused card styles
  unFocusStyles: string; // Regular card styles
  lightBackground?: boolean; // True if the background is lighter
}

function VideoCarousel({
  initialState,
  focus = false,
  lightBackground = false,
  focusStyles = "",
  unFocusStyles,
}: VideoCarouselProps) {
  // Vimeo Player for currently focused video in the carousel
  const [focusedVideoId, setFocusedVideoId] = useState<string | null>(null);
  // This is an HTMLVideoElement, but typing it as such does all getElementById()
  const [focusedVideoElement, setFocusedVideoElement] = useState<any | null>(
    document.getElementById(focusedVideoId || "")
  );

  /**
   * Applies the appropriate styles to the focus card(s)
   * @param cardNodes List of card elements within the carousel
   */
  const handleFocusSlide = useCallback(
    (cardNodes: Element[]) => {
      const focusIndex =
        window.screen.width < 768 ? 0 : Math.floor(cardNodes.length / 2);
      const focusStylesList = focusStyles.split(" ");
      const unFocusStylesList = unFocusStyles.split(" ");
      cardNodes.forEach((card, index) => {
        card.classList.add(...focusStylesList);
        card.classList.add(...unFocusStylesList);
        if (index === focusIndex) {
          card.classList.remove(...unFocusStylesList);
          // Keeps track of the focused video's id since the DOM is changing,
          // not the original array
          setFocusedVideoId(card.querySelector("video")?.id || "");
        }
      });
    },
    [focusStyles, unFocusStyles]
  );

  useEffect(() => {
    // Initialized focused styles on page load
    if (!focusedVideoId && !focusedVideoElement) {
      const carouselContainer = document.querySelector("#tru-video-carousel");
      if (carouselContainer) {
        const carouselCards = Array.from(carouselContainer.children);
        handleFocusSlide(carouselCards);
      }
    }
    // If the focused video changes, update the element
    if (focusedVideoId) {
      // Saves the focused element in state so we know which one to pause on carousel card swap
      setFocusedVideoElement(document.getElementById(focusedVideoId));
    }
  }, [focusedVideoId, focusedVideoElement, handleFocusSlide]);

  /**
   * Shifts the carousel cards to the left
   * Advances to the next card to the right
   */
  const handleRightClick = () => {
    if (focusedVideoElement) {
      focusedVideoElement.pause();
    }
    const carouselContainer = document.querySelector("#tru-video-carousel");
    if (carouselContainer) {
      const carouselCards = Array.from(carouselContainer.children);
      const firstChild = carouselCards[0];
      if (firstChild && firstChild.parentNode) {
        const shiftedChild = carouselContainer.removeChild(firstChild);
        carouselContainer.appendChild(shiftedChild);
      }

      const shiftedCarouselContainer = document.querySelector(
        "#tru-video-carousel"
      );
      if (shiftedCarouselContainer && focus) {
        const shiftedCarouselCards = Array.from(
          shiftedCarouselContainer.children
        );
        handleFocusSlide(shiftedCarouselCards);
      }
    }
  };

  /**
   * Shifts the carousel cards to the right
   * Advances to the next card to the left
   */
  const handleLeftClick = () => {
    if (focusedVideoElement) {
      focusedVideoElement.pause();
    }
    const carouselContainer = document.querySelector("#tru-video-carousel");
    if (carouselContainer) {
      const carouselCards = Array.from(carouselContainer.children);
      const lastChild = carouselCards[carouselCards.length - 1];
      if (lastChild && lastChild.parentNode) {
        const shiftedChild = carouselContainer.removeChild(lastChild);
        carouselContainer.insertBefore(shiftedChild, carouselCards[0]);
      }

      const shiftedCarouselContainer = document.querySelector(
        "#tru-video-carousel"
      );
      if (shiftedCarouselContainer && focus) {
        const shiftedCarouselCards = Array.from(
          shiftedCarouselContainer.children
        );
        handleFocusSlide(shiftedCarouselCards);
      }
    }
  };

  return (
    <>
      <div
        className={
          "absolute left-2 top-[40%] z-20 flex h-12 w-12 rotate-90 cursor-pointer select-none items-center justify-center rounded-full bg-indigo-900/60 hover:bg-indigo-900/80 sm:h-16 sm:w-16 md:visible md:left-[5%] md:h-24 md:w-24" +
          (lightBackground ? " !bg-indigo-900/20 hover:!bg-indigo-900/40" : "")
        }
        onClick={handleLeftClick}
      >
        <img
          src={SliderArrow}
          alt="Previous"
          className="h-4 sm:h-5 md:h-auto"
        />
      </div>
      <div
        id="tru-video-carousel"
        className={
          "flex flex-row items-center justify-center gap-10" +
          (initialState.length % 2 === 0
            ? " !justify-start md:-translate-x-[300px] md:!justify-center lg:-translate-x-[370px]"
            : "")
        }
      >
        {initialState.map((card: any, index: number) => {
          return (
            <div className="relative" key={index}>
              {card.component}
            </div>
          );
        })}
      </div>
      <div
        className={
          "absolute right-2 top-[40%] z-20 flex h-12 w-12 -rotate-90 cursor-pointer select-none items-center justify-center rounded-full bg-indigo-900/60 hover:bg-indigo-900/80 sm:h-16 sm:w-16 md:visible md:right-[5%] md:h-24 md:w-24" +
          (lightBackground ? " !bg-indigo-900/20 hover:!bg-indigo-900/40" : "")
        }
        onClick={handleRightClick}
      >
        <img src={SliderArrow} alt="Next" className="h-4 sm:h-5 md:h-auto" />
      </div>
    </>
  );
}

export default VideoCarousel;
