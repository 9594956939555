import { useState } from "react";

// Contexts / Support Libraries //
import {
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../../config/UserAuthContext";
import TagManager from "react-gtm-module";
import Cookies from "js-cookie";

// Components //
import Button from "../../../components/common/Button";
import FeaturedCarousel from "../../../components/common/FeaturedCarousel";
import SectionHeader from "../../../components/common/SectionHeader";
import TextWithContentSplit from "../../../components/common/TextWithContentSplit";
import Video from "../../../components/common/Video";
import ThreeBlocksSection from "../../../components/common/ThreeBlocksSection";
import CtaImageCard from "../../../components/common/CtaImageCard";
import GameTileCTA from "../../../components/common/GameTileCTA";
import BlurredSection from "../../../components/common/BlurredSection";

// Assets //
import GameCardBUB from "../../../global-assets/assets/game-card-bub.webp";
import GameIcon from "../../../global-assets/assets/game-icon1.svg";
import LucasFeature from "./assets/lucas-feature-img.webp";
import NoahFeature from "./assets/noah-feature-img.webp";
import ThumbLucasHeavenlyQuest from "./assets/thumbnail_lucas_heavenly_quest.webp";
import ThumbQuestionOfFaith from "./assets/thumbnail_question_of_faith.webp";
import ThumbBeliefInGod from "./assets/thumbnail_belief_in_god.webp";
import LargePlayBtn from "../../../components/crowdfunding/assets/branded_play_btn.svg";
import BGLandingImage from "./assets/lms-bg-landing.webp";
import BlueCaretDown from "../../../global-assets/assets/icon-caret-down-cerulean.svg";
import LucasCTABackgroundLayer from "./assets/lms-cta-top-layer-bg.webp";
import LMSGameTile from "./assets/game-tile-lms-shadow.webp";
import LMSImage1 from "./assets/lms-carousel-img-1.webp";
import LMSImage2 from "./assets/lms-carousel-img-2.webp";
import LMSImage3 from "./assets/lms-carousel-img-3.webp";
import LMSImage4 from "./assets/lms-carousel-img-4.webp";
import LMSImage5 from "./assets/lms-carousel-img-5.webp";
import LMSBoss from "./assets/lms-boss.webp";

interface ITileList {
  id: string;
  tileImage: string; // Path to tile image
  component: JSX.Element | null;
  focus: boolean; // True if the carousel has focused tile logic
  type: string; // Type of tile
  visible: boolean; // True if within the container of visibility
  display: JSX.Element | null; // Element displayed when featured
}

const tileList: ITileList[] = [
  {
    id: "tile_1",
    tileImage: LMSImage1,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_2",
    tileImage: LMSImage2,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_3",
    tileImage: LMSImage3,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_4",
    tileImage: LMSImage4,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_5",
    tileImage: LMSImage5,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: false,
  },
];

tileList.forEach((tile) => {
  tile.component = (
    <img
      src={tile.tileImage}
      alt={tile.id}
      loading="eager"
      draggable="false"
      className="rounded-lg sm:rounded-2xl lg:rounded-[20px]"
    />
  );
  tile.display = (
    <div
      id="tru-feature_bringing_joj_back"
      key={tile.id}
      className="w-full rounded-[40px] bg-white/[6%] p-3 align-middle md:p-4 lg:p-5"
    >
      <img
        src={tile.tileImage}
        alt={tile.id}
        loading="eager"
        draggable="false"
        className="rounded-[30px]"
      />
    </div>
  );
});

interface IVideoTileList {
  id: string;
  videoId: string;
  tileImage: string; // Path to tile image
  videoLink: string; // URL to video (CDN link)
  thumbnail?: string;
  videoShareUrl?: string; // Link to shareable video (Vimeo link)
  component: JSX.Element | null;
  focus: boolean; // True if the carousel has focused tile logic
  type: string; // Type of tile
  title?: string | null;
  description?: string | null;
  visible: boolean; // True if within the container of visibility
  display: JSX.Element | null; // Element displayed when featured
}

const videoTileList: IVideoTileList[] = [
  {
    id: "tile_1",
    videoId: "953689893",
    tileImage: ThumbLucasHeavenlyQuest,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/lucas_and_the_heavenly_quest%20(720p).mp4",
    thumbnail: ThumbLucasHeavenlyQuest,
    videoShareUrl: "https://vimeo.com/953689893",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
  },
  {
    id: "tile_2",
    videoId: "1002011234",
    tileImage: ThumbQuestionOfFaith,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_question_of_faith%20(720p).mp4",
    thumbnail: ThumbQuestionOfFaith,
    videoShareUrl: "https://vimeo.com/1002011234",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
  },
  {
    id: "tile_3",
    videoId: "1002032476",
    tileImage: ThumbBeliefInGod,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/belief_in_god%20(720p).mp4",
    thumbnail: ThumbBeliefInGod,
    videoShareUrl: "https://vimeo.com/1002032476",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
  },
];

videoTileList.forEach((tile) => {
  tile.component = (
    <img
      src={tile.tileImage}
      alt={tile.id}
      loading="eager"
      draggable="false"
      className="rounded-lg sm:rounded-2xl lg:rounded-[20px]"
    />
  );
  tile.display = (
    <div
      id="tru-lms_feature_related_content"
      key={tile.videoLink}
      className="relative w-full"
    >
      <Video
        id="lms_related_content_featured_video"
        url={tile.videoLink}
        videoId={tile.videoId}
        shareUrl={tile.videoShareUrl}
        shareSmall
        playsInline
        thumbnail={tile.thumbnail}
        disableRemotePlayback
        customPlayBtn={LargePlayBtn}
        noShadow
      />
    </div>
  );
});

const TILE_MARGIN = 16;
const CAROUSEL_PADDING_TOP = 0;
const VIDEO_CAROUSEL_PADDING_TOP = 16;
const CAROUSEL_PADDING_BOTTOM = 32;

export default function ChirpSong() {
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");

  const [readMore, setReadMore] = useState<boolean>(false);
  const handleReadMore = () => {
    setReadMore(!readMore);
  };

  const handleCTAClick = (cta: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        os_type: osType,
        content_type: "button cta",
        link_name: "LMS " + cta,
        link_url: "/user-registration",
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo("/user-registration");
  };

  return (
    <>
      <div className="min-h-screen">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_110%_-10%,#68e0cf_0%,transparent_10%)] opacity-40 lg:bg-[radial-gradient(circle_at_110%_-10%,#68e0cf_0%,transparent_20%)]" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_110%_10%,#38D58A_0%,transparent_10%)] opacity-15 lg:opacity-20" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_20%,#68e0cf_0%,transparent_8%)] opacity-15 lg:bg-[radial-gradient(circle_at_0%_20%,#68e0cf_0%,transparent_15%)]" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_25%,#652c80_0%,transparent_10%)] opacity-30 lg:opacity-40" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_35%,#38D58A_0%,transparent_10%)] opacity-10" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_48%,#68e0cf_0%,transparent_10%)] opacity-5 lg:bg-[radial-gradient(circle_at_100%_52%,#68e0cf_0%,transparent_20%)] lg:opacity-20" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_0%_62%,#652c80_0%,transparent_20%)] opacity-25 lg:bg-[radial-gradient(circle_at_0%_62%,#652c80_0%,transparent_20%)] lg:opacity-50" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_110%_72%,#38D58A_0%,transparent_10%)] opacity-10 lg:opacity-20" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_85%,#68e0cf_0%,transparent_10%)] opacity-15 lg:opacity-30" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_100%_100%,#652c80_0%,transparent_30%)] opacity-30" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_50%_100%,#20568B_0%,transparent_20%)] opacity-20" />
        <div className="mx-auto flex max-w-[1440px] flex-col gap-12 px-5 py-24 sm:px-6 sm:pb-16 md:px-7 lg:gap-32 lg:px-12 lg:py-32 lg:pt-36 2xl:px-6 3xl:px-0">
          <div className="flex flex-col gap-8 lg:gap-16">
            <SectionHeader
              headerType="h1"
              headerText="ChirpSong"
              starLocation="left"
            />
            <TextWithContentSplit
              headerText={
                <>
                  <div className="flex items-center gap-4">
                    <img src={GameIcon} alt="Game" loading="eager" />
                    <p className="m-0 text-xl text-indigo-200">
                      Bubble Shooter
                    </p>
                  </div>
                  <h3 className="text-transparent bg-gradient_text bg-clip-text">
                    ChirpSong
                  </h3>
                </>
              }
              subtext={
                <div className="flex flex-col gap-4">
                  <p className="mb-5 text-lg text-white lg:text-xl">
                    Long ago, words of praise resounded regularly throughout the
                    Forest of Words and the tree town of Traecraft. But the evil
                    queen Axilla imprisoned the colorful chirps and silenced
                    their praise. But all is not lost.
                  </p>
                  <Button
                    id="chirpsong_hero_cta"
                    btnType="green"
                    onClick={() => handleCTAClick("hero")}
                  >
                    Play ChirpSong
                  </Button>
                </div>
              }
              content={
                <Video
                  id="lms_game_trailer"
                  videoId="953689893"
                  url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/lucas_and_the_heavenly_quest%20(720p).mp4"
                  thumbnail={ThumbLucasHeavenlyQuest}
                  playsInline
                  controls
                  shareSmall
                  customPlayBtn={LargePlayBtn}
                />
              }
              subtextColor="text-white"
              contentLocation="left"
              textPositionOnMobile="below"
              contentPercentage={200}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="lg:hidden">
              <SectionHeader
                headerType="h2"
                headerText="Game Story"
                starLocation="right"
                headerTextAlignment="left"
              />
            </div>
            <TextWithContentSplit
              headerText={
                <SectionHeader
                  headerType="h2"
                  headerText="Game Story"
                  starLocation="right"
                  headerTextAlignment="left"
                />
              }
              hideHeaderOnMobile
              subtext={
                <div className="flex flex-col gap-12">
                  <div className="flex flex-col gap-6">
                    <p
                      className={
                        "m-0 text-center text-lg text-white lg:!line-clamp-none lg:text-left lg:text-xl" +
                        (readMore ? " line-clamp-none" : " line-clamp-6")
                      }
                    >
                      Long ago, words of praise resounded regularly throughout
                      the Forest of Words and the tree town of Traecraft. But
                      the evil queen Axilla imprisoned the colorful chirps and
                      silenced their praise. But all is not lost.
                      <br />
                      <br />
                      This charming bubble shooter game set in the RhymVerse
                      reveals the restorative power of praise as players free
                      the "Chirps" in the Forest of Words from Axilla’s
                      corruption! As the Narrator helps Maple, Oliver, and
                      Benjamin grasp the transformative power of praising God,
                      Axilla can only fume and fuss and vow revenge.
                    </p>
                    <div className="flex items-center justify-center gap-4 lg:hidden lg:justify-end">
                      <Button
                        id="lms_game_story_read_more_btn"
                        btnType="tertiaryAlt"
                        onClick={handleReadMore}
                        rightIcon={
                          <img
                            src={BlueCaretDown}
                            alt="Caret"
                            loading="lazy"
                            className={
                              "ml-4 h-6 w-6 duration-300" +
                              (readMore ? " rotate-180" : "")
                            }
                          />
                        }
                      >
                        {!readMore ? "Read More" : "Read Less"}
                      </Button>
                    </div>
                  </div>
                  <Button
                    id="game_story_cta"
                    btnType="green"
                    onClick={() => handleCTAClick("game story section")}
                    className="lg:w-[80%]"
                  >
                    Play ChirpSong
                  </Button>
                </div>
              }
              content={
                <img
                  src={LMSBoss}
                  loading="eager"
                  alt="LMS Boss"
                  className="rounded-[40px]"
                />
              }
              subtextColor="text-white"
              contentLocation="right"
              textPositionOnMobile="below"
              contentPercentage={50}
            />
          </div>
          <div className="flex flex-col gap-8 lg:gap-16">
            <SectionHeader
              headerType="h2"
              headerText="Primary Themes & Lessons"
              starLocation="left"
            />
            <div className="flex flex-col justify-center gap-8 lg:flex-row lg:gap-20">
              <div className="w-full flex-1 overflow-hidden lg:flex-[0.7_1_0]">
                <FeaturedCarousel
                  id="lucas_themes_and_lessons"
                  initialState={tileList}
                  cardMargin={TILE_MARGIN}
                  responsive
                  animated
                  cardStyles="shadow-lg rounded-lg sm:rounded-2xl lg:rounded-[20px] cursor-pointer duration-200 ease-in-out"
                  hoverStyles="md:group-hover/card-hover:shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5] md:group-hover/card-hover:ring-white md:group-hover/card-hover:ring-4"
                  focusStyles="rounded-lg sm:rounded-2xl lg:rounded-[20px] ring-[3px] sm:ring-4 ring-white cursor-pointer duration-200 ease-in-out shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5]"
                  arrowType="featured"
                  arrowCornerType="rounded"
                  selection
                  paddingBottom={CAROUSEL_PADDING_BOTTOM}
                  paddingTop={CAROUSEL_PADDING_TOP}
                  focus
                  visibleCount={4}
                />
              </div>
              <div className="flex w-full flex-1 flex-col gap-6 self-center lg:-mt-[10%] lg:flex-[0.3_1_0]">
                <h3 className="text-center text-4xl text-white lg:text-left">
                  When praise takes over, joy and beauty follow
                </h3>
                <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl lg:font-normal">
                  Praise silences our enemy and puts our attention where it
                  belongs–on God and his infinite power.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <BlurredSection rounded>
                <div className="flex flex-col items-center justify-center gap-4">
                  <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                    “Let everything that has breath praise the Lord”
                  </p>
                  <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                    - Psalm 150:6 NIV
                  </p>
                </div>
              </BlurredSection>
              <BlurredSection rounded>
                <div className="flex flex-col items-center justify-center gap-4">
                  <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                    “Sing unto the Lord: let us make a joyful noise!”
                  </p>
                  <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                    - Psalm 95:1 KJV
                  </p>
                </div>
              </BlurredSection>
            </div>
          </div>
          <div className="flex flex-col gap-8 lg:gap-16">
            <SectionHeader
              headerType="h2"
              headerText="Supporting Themes & Lessons"
              starLocation="right"
            />
            <ThreeBlocksSection
              blocks={[
                {
                  title: "When praise takes over, joy and beauty follow",
                  body: "",
                },
                {
                  title: "Praise silences our enemy",
                  body: "and puts our attention where it belongs–on God and his infinite power",
                },
                {
                  title: "",
                  body: "",
                },
              ]}
            />
          </div>
          <div className="flex flex-col gap-8 lg:gap-16">
            <SectionHeader
              headerType="h2"
              headerText="Main Characters"
              starLocation="left"
            />
            <div className="flex w-full flex-col gap-8 md:gap-8">
              <CtaImageCard
                image={LucasFeature}
                title="THE CHIRPS"
                description={`Lucas is a technologically-gifted skunk who has invented a crocodile robot suit for himself--and he's on a mission to find his brother, Chase, who (their mom says) has "gone to be with God." Along the way, he will be challenged by the harsh reality of his adventure and discover the power of prayer and the value of God's guidance.`}
              />
              <CtaImageCard
                image={NoahFeature}
                title="Noah"
                description={`Noah, a kangaroo who loves extreme sports such as rock climbing and hang gliding, is Lucas's best friend. He also loves God and tries to share his faith with Lucas, who doesn't always understand. Noah is learning that sharing God’s truth requires patience and perseverance, and that sometimes he has to trust God to speak in ways that only He can.`}
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-8 lg:gap-12">
            <SectionHeader
              headerType="h2"
              headerText="From the Developer"
              starLocation="right"
              headerTextAlignment="left"
            />
            <p className="m-0 max-w-[1300px] text-center text-lg font-normal text-white lg:text-2xl">
              In creating Lucas's game, we thought about how everyone struggles
              with faith issues, and how it's so easy for people of any age to
              rely on themselves rather than seeking and following guidance from
              God. Every day and every moment of our lives is uncharted
              territory, like Lucas is doing in the RSS StarHope. So, in Lucas
              and Mission StarHope, we want to show the perilous but rewarding
              journey of learning to trust in the Lord, leaning not on our own
              understanding, and letting Him direct our paths.
            </p>
          </div>
          <div className="flex flex-col gap-8 lg:gap-16">
            <SectionHeader
              headerType="h2"
              headerText="Related Content"
              starLocation="left"
            />
            <div className="mx-auto w-full max-w-[1000px] flex-1 overflow-hidden">
              <FeaturedCarousel
                id="lms_related_content"
                initialState={videoTileList}
                cardMargin={TILE_MARGIN}
                responsive
                animated
                cardStyles="shadow-lg rounded-lg sm:rounded-2xl lg:rounded-[20px] cursor-pointer duration-200 ease-in-out"
                hoverStyles="md:group-hover/card-hover:shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5] md:group-hover/card-hover:ring-white md:group-hover/card-hover:ring-4"
                focusStyles="rounded-lg sm:rounded-2xl lg:rounded-[20px] ring-[3px] sm:ring-4 ring-white cursor-pointer duration-200 ease-in-out shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5]"
                arrowType="featured"
                arrowCornerType="rounded"
                selection
                paddingBottom={CAROUSEL_PADDING_BOTTOM}
                paddingTop={VIDEO_CAROUSEL_PADDING_TOP}
                focus
                visibleCount={3}
              />
            </div>
          </div>
          <div className="flex flex-col gap-8 lg:gap-16">
            <SectionHeader
              headerType="h2"
              headerText="Additional Supporting Scripture"
              starLocation="right"
            />
            <div className="flex flex-col gap-4 md:gap-6">
              <BlurredSection rounded>
                <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                  "So do not fear, for I am with you; do not be dismayed, for I
                  am your God. I will strengthen you and help you; I will uphold
                  you with my righteous right hand."
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                  - Isaiah 41:10
                </p>
              </BlurredSection>
              <BlurredSection rounded>
                <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                  "Have I not commanded you? Be strong and courageous. Do not be
                  afraid; do not be discouraged, for the Lord your God will be
                  with you wherever you go."
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                  - Joshua 1:9
                </p>
              </BlurredSection>
              <BlurredSection rounded>
                <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                  "Trust in the Lord with all your heart and lean not on your
                  own understanding; in all your ways submit to him, and he will
                  make your paths straight."
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                  - Proverbs 3:5-6
                </p>
              </BlurredSection>
              <BlurredSection rounded>
                <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                  "I have told you these things, so that in me you may have
                  peace. In this world you will have trouble. But take heart! I
                  have overcome the world."
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                  - John 16:33
                </p>
              </BlurredSection>
              <BlurredSection rounded>
                <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                  "I can do all this through him who gives me strength."
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                  - Philippians 4:13
                </p>
              </BlurredSection>
              <BlurredSection rounded>
                <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
                  "No, in all these things we are more than conquerors through
                  him who loved us."
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl">
                  - Romans 8:37
                </p>
              </BlurredSection>
            </div>
          </div>
        </div>
        <GameTileCTA
          backgroundImage={LucasCTABackgroundLayer}
          backgroundGradient="bg-[linear-gradient(90deg,#003E7A_0%,#5A2E85_100%)]"
          gameTileImage={LMSGameTile}
          handleCTA={handleCTAClick}
        />
        <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 py-24 sm:px-6 sm:pb-16 md:px-7 lg:gap-12 lg:px-12 lg:py-32 lg:pt-0 2xl:px-6 3xl:px-0">
          <SectionHeader
            headerType="h2"
            headerText="Additional Info"
            starLocation="left"
          />
          <div className="flex flex-col gap-6 lg:flex-row">
            <div className="flex flex-1 flex-col gap-6 lg:gap-8">
              <div className="flex flex-col gap-1">
                <p className="m-0 text-center text-xl font-bold text-white lg:text-left lg:text-2xl">
                  Apple phone & iPad requirements
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl">
                  Minimum iOS version of 14.5 or higher with 3GB+ RAM
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="m-0 text-center text-xl font-bold text-white lg:text-left lg:text-2xl">
                  Android phone & tablet requirements
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl">
                  Version 4.4 or higher with 3GB+ RAM
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="m-0 text-center text-xl font-bold text-white lg:text-left lg:text-2xl">
                  Unsupported Devices
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl">
                  Not currently available on laptops, desktops, chrome books,
                  or&nbsp;
                  <br className="hidden lg:inline" />
                  Kindle Fire tablets.
                </p>
              </div>
            </div>
            <div className="flex flex-1 flex-col  gap-6 lg:gap-8">
              <div className="flex flex-col gap-1">
                <p className="m-0 text-center text-xl font-bold text-white lg:text-left lg:text-2xl">
                  Up to 10 profiles per subscription
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl">
                  Play on multiple devices with up to 10 profiles per
                  subscription
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="m-0 text-center text-xl font-bold text-white lg:text-left lg:text-2xl">
                  Compatible between iOS & Android
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl">
                  One subscription may be used between Apple and Android devices
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="m-0 text-center text-xl font-bold text-white lg:text-left lg:text-2xl">
                  Similar Games
                </p>
                <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl">
                  Star Fox , Galaxy on Fire 2, Stellar Wanderer, Strike Wings,
                  Raptor&nbsp;
                  <br className="hidden lg:inline" />
                  Rising, Star Horizon
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none justify-center overflow-hidden regmd:flex">
          <img
            src={BGLandingImage}
            loading="eager"
            sizes="100vw"
            alt=""
            className="relative -z-[1] w-full min-w-[1920px] opacity-70"
          />
        </div>
      </div>
    </>
  );
}
