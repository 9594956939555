import { useEffect, useRef, useState, useCallback } from "react";

// Context
import { navigateTo } from "../../config/UserAuthContext";

// Images
import UserMenu from "./UserMenu/UserMenu";
import logo from "../../global-assets/assets/truplay-logo-large.png";
import UserIcon from "../../global-assets/assets/User_Icon.svg";
import Button from "../common/Button";

interface IMinimalNavProps {
  isAuthenticated: any;
  emailAddress: any;
}

/**
 *
 * @param setAuth
 * @constructor
 */
export default function MinimalNav({
  isAuthenticated,
  emailAddress,
}: IMinimalNavProps) {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);

  // Ref for User Icon circle, used to anchor the menu at large screen widths
  const userIconRef = useRef<HTMLInputElement>(null);
  // User Icon circle element's positions, used to determine menu width
  const userIconPositions = useRef<DOMRect | undefined>();
  // Updates the User Icon circle position as the screen width changes
  const updateUserIconPos = useCallback(() => {
    userIconPositions.current = userIconRef.current?.getBoundingClientRect();
  }, []);

  useEffect(() => {
    if (userIconPositions.current === undefined) {
      userIconPositions.current = userIconRef.current?.getBoundingClientRect();
    }
    window.addEventListener("resize", updateUserIconPos);
    return () => window.removeEventListener("resize", updateUserIconPos);
  }, [updateUserIconPos]);

  // Ref for the User Menu, used to check if the user is clicking within or outside it
  const userMenuRef = useRef<HTMLDivElement>(null);
  const closeUserMenu = useCallback(
    (e: any) => {
      if (isUserMenuOpen && !userMenuRef.current?.contains(e.target)) {
        setIsUserMenuOpen(false);
      }
    },
    [isUserMenuOpen]
  );

  useEffect(() => {
    window.addEventListener("mousedown", closeUserMenu);
    return () => window.removeEventListener("mousedown", closeUserMenu);
  }, [closeUserMenu]);

  return (
    <>
      {isAuthenticated && (
        <UserMenu
          isOpen={isUserMenuOpen}
          setIsOpen={setIsUserMenuOpen}
          userEmail={emailAddress}
          userIconRightCoord={{ right: userIconPositions.current?.right }}
          menuRef={userMenuRef}
        />
      )}
      <div className="h-[104px] md:h-[114px]">
        <div className="relative flex items-center justify-between p-6">
          <div className="flex items-center">
            <div className="cursor-pointer" onClick={() => navigateTo("/")}>
              <img
                src={logo}
                alt="Truplay-logo"
                className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
                id="unauth_tru_logo"
              />
            </div>
          </div>
          <div className="flex items-center gap-2 sm:gap-4">
            <Button
              id="log_in_btn"
              btnType="tertiary"
              onClick={() => {
                navigateTo("/");
              }}
            >
              Home
            </Button>
            {isAuthenticated && (
              <div
                id="active_user_menu_option"
                className={
                  "relative flex min-h-12 min-w-12 cursor-pointer items-center justify-center rounded-full" +
                  " bg-user_menu_icon duration-200 hover:scale-110 hover:shadow-[0_3px_20px_rgba(56,166,213,0.6)]"
                }
                onClick={() => {
                  setIsUserMenuOpen(true);
                }}
                ref={userIconRef}
              >
                <img
                  src={UserIcon}
                  alt="User Icon"
                  className={
                    "h-[1.1rem]" + (isUserMenuOpen ? " hidden" : " block")
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
