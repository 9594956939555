// Components
import FreeContentVideos from "../../../components/free-content/FreeContentVideos";
import FreeContentBOIPages from "../../../components/free-content/FreeContentBOIPages";

export default function AccountFreeContent() {
  return (
    <div
      className={
        "tru-accinfo-container-bg rounded-3xl border-[3px] border-solid" +
        " border-x-[rgba(32,86,139,0.7)] border-b-[rgba(32,86,139,1)]" +
        " bg-gradient_acc_info border-t-[rgba(32,86,139,0.2)] p-4" +
        " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
      }
    >
      <FreeContentVideos />
      <FreeContentBOIPages />
    </div>
  );
}
