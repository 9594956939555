import React from "react";
import ReviewStar from "../../global-assets/assets/gold-review-star.png";
import CenteredCarousel from "./CenteredCarousel";

interface Review {
  title: string;
  content: string;
  reviewer: string;
  date: string;
  component: JSX.Element | null;
  visible: boolean;
  focus: boolean;
}

interface AppReviewsProps {
  noTitle?: boolean;
  lightBackground?: boolean;
}

const reviews: Review[] = [
  {
    title: "Best Gaming Decision Hands Down",
    content: `The other day we were out biking with our kids and my daughter began having a rough time with the bumps and puddles. I tried encouraging her, reminding her she can do hard things. Then she began pedaling and shouting "I am a brave fox, like my parents!" That's what one of the little characters says on TruPlay!`,
    reviewer: "JoMulv16",
    date: "Feb 21",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "Wholesome Quality Games",
    content:
      "As a long time gaming advocate, I saw mention of this collection on the news and wanted to see if it actually contained quality games. I was pleasently surprised with how well developed they were. This service makes me smile knowing kids have something wholesome to experience that even as an adult, I enjoy playing.",
    reviewer: "hashdhgd",
    date: "Aug 6",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "Kids Love it!",
    content:
      "My four grandkids and I sat on the couch together for an hour while they each explored the games on their own devices. They range in ages from 4 to 11 and they all found something they really enjoyed. There were giggles and 'oooooh! I'm in the leaderboard!' Great job, TruPlay!",
    reviewer: "candywrites",
    date: "Jun 12",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "Finally something for my kids!",
    content:
      "The art is beautiful and the games are fun. I don't have to worry about my kids getting into something they shouldn't be seeing. And they will absorb biblical truth while they play?! We love it!",
    reviewer: "aaron atkins foo",
    date: "Jul 28",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "Family friendly FUN",
    content:
      "This amazing app is a MUST HAVE for parents and grandparents who are concerned about the content that children are being exposed to through digital games and entertainment. Kids will absolutely LOVE this platform!!",
    reviewer: "JGDG1986",
    date: "Jun 25",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "FINALLY something my kids can play!!!",
    content:
      "As a parent I'm SO excited to see this!!! Games my kids can play that are safe for them! These games are really cool too, not cheesy or low quality and my kids keep asking to play ALL THE TIME. THANK YOU for making these games!!!",
    reviewer: "Wolf 3.6",
    date: "Jun 12",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "Wow, a treasure of games, comics, and videos",
    content:
      "Finally an app I can open up for my grandson's to play and not worry about what they will encounter in ads and pop-ups. Funny thing, I am loving the app too. Little Light and Stained Glass are my place of peace.",
    reviewer: "Just MomMom",
    date: "Jun 9",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "Highly Recommended",
    content:
      "As a father, I am delighted with this app. It offers engaging gameplay and valuable lessons that align with our faith. I appreciate how it promotes positive values in a fun and interactive way. Highly recommended!",
    reviewer: "Tim McFrol",
    date: "Aug 6",
    component: null,
    visible: true,
    focus: false,
  },
  {
    title: "No more guilt iPad time",
    content:
      "This game is tons of fun and engaging for my son. I typically feel like a bad parent during iPad time but when he's playing Truplay I know that he's learning about god. Really good excited for future updates!",
    reviewer: "KUKUMR",
    date: "Nov 27",
    component: null,
    visible: true,
    focus: false,
  },
];

function reviewStars() {
  const stars: any[] = [];
  for (let i = 0; i < 5; i++) {
    stars.push(<img key={i} alt="star" src={ReviewStar} className="max-w-8" />);
  }
  return stars;
}

reviews.forEach((review) => {
  review.component = (
    <>
      <div className="flex h-[150px] flex-col items-start gap-2">
        <div className="flex gap-1">{reviewStars()}</div>
        <div className="font-body text-[2rem] font-black text-cerulean-500">
          {review.title}
        </div>
      </div>
      <div className="flex flex-[2_1_0] flex-col justify-between gap-2 self-stretch">
        <div className="text-xl font-bold text-indigo-300">
          {review.content}
        </div>
        <div className="flex flex-col">
          <div className="font-black text-cerulean-500">{review.reviewer}</div>
          <div className="text-base font-normal text-cerulean-500">
            <i>App Store Review, {review.date}</i>
          </div>
        </div>
      </div>
    </>
  );
});

const unFocusedStyles =
  "min-w-[350px] flex h-[600px] w-[350px] flex-col gap-2 rounded-[40px] bg-white px-[1.75rem] py-10 shadow-[0px_35px_50px_0px_rgba(14,15,15,0.1)]";

const AppReviewSlider: React.FC<AppReviewsProps> = ({
  noTitle,
  lightBackground,
}) => {
  const cardWidth = 350;
  const cardMargin = 40;

  return (
    <div
      className={
        "relative mx-auto w-full overflow-hidden" +
        (lightBackground ? " pb-20" : "")
      }
    >
      {!noTitle && (
        <h2 className="mb-12 text-center text-white">What Our Customers Say</h2>
      )}
      <CenteredCarousel
        initialState={reviews}
        cardWidth={cardWidth}
        cardMargin={cardMargin}
        initialCardCount={reviews.length} // Must be hardcoded due to array
        responsive
        animated
        lightBackground={lightBackground}
        unFocusStyles={unFocusedStyles}
        // Translate must be cardWidth + cardMargin
        shiftLeftStyles="-translate-x-[390px] duration-500"
        shiftRightStyles="translate-x-[390px] duration-500"
      />
    </div>
  );
};

export default AppReviewSlider;
