import { MarketingContactEmails } from "../../../lib/MarketingData";
import { Link } from "react-router-dom";

export function InvestorInfo(){
    return (
        <div className="relative flex flex-col justify-end overflow-visible px-5 py-32 sm:px-6 md:px-7 md:py-40 lg:px-12 lg:py-48">
            <div className="mx-auto max-w-[1440px]">
                <div className="flex max-w-[820px] flex-col items-center justify-start gap-4 text-center">
                    <h1 className="text-transparent bg-gradient_text bg-clip-text">
                        Investor Inquiries
                    </h1>
                    <div className="max-w-[1040px]">
                        <p className="m-0 text-white">
                            For investor inquiries, contact
                            <Link
                                id="contact_mailto_tru_support"
                                target="_blank"
                                to="mailto:invest@truplaygames.com"
                                className="text-white"
                                rel="noreferrer"
                            >
                                {" "}
                                invest@truplaygames.com
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] overflow-hidden">
                <div className="absolute bottom-0 left-0 right-0 top-0 -z-[4] bg-indigo-800/85" />
                <div className="tru-hero-purple-light absolute -right-[100px] bottom-0 left-0 top-0 -z-[1] opacity-70" />
                <div className="tru-hero-blue-light absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-70" />
                <img
                    src={MarketingContactEmails.images.contactherobg}
                    loading="eager"
                    sizes="100vw"
                    alt=""
                    className="relative -z-[5] h-full w-full object-cover"
                />
            </div>
            <img
                src={MarketingContactEmails.images.curvewhite}
                loading="lazy"
                alt=""
                className="absolute bottom-0 left-0 right-0 top-auto z-[5] -mb-[1px] w-full max-w-none"
            />
        </div>
    );
}

export default function ContactInfo() {
  return (
    <div className="relative flex flex-col justify-end overflow-visible px-5 py-32 sm:px-6 md:px-7 md:py-40 lg:px-12 lg:py-48">
      <div className="mx-auto max-w-[1440px]">
        <div className="flex max-w-[820px] flex-col items-center justify-start gap-4 text-center">
          <h1 className="text-transparent bg-gradient_text bg-clip-text">
            Contact Support Today
          </h1>
          <div className="max-w-[1040px]">
            <p className="m-0 text-white">
              For customer or technical support, please reach out to
              <Link
                id="contact_mailto_tru_support"
                target="_blank"
                to="mailto:support@truplaygames.com"
                className="text-white"
                rel="noreferrer"
              >
                {" "}
                support@truplaygames.com
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] overflow-hidden">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[4] bg-indigo-800/85" />
        <div className="tru-hero-purple-light absolute -right-[100px] bottom-0 left-0 top-0 -z-[1] opacity-70" />
        <div className="tru-hero-blue-light absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-70" />
        <img
          src={MarketingContactEmails.images.contactherobg}
          loading="eager"
          sizes="100vw"
          alt=""
          className="relative -z-[5] h-full w-full object-cover"
        />
      </div>
      <img
        src={MarketingContactEmails.images.curvewhite}
        loading="lazy"
        alt=""
        className="absolute bottom-0 left-0 right-0 top-auto z-[5] -mb-[1px] w-full max-w-none"
      />
    </div>
  );
}
