import pf_img1 from "../../../global-assets/assets/pf_img1.svg";
import pf_img2 from "../../../global-assets/assets/pf_img2.svg";
import pf_img3 from "../../../global-assets/assets/pf_img3.svg";
import pf_img4 from "../../../global-assets/assets/pf_img4.svg";
import pf_img5 from "../../../global-assets/assets/pf_img5_1.svg";
import pf_img6 from "../../../global-assets/assets/pf_img6.svg";
import pf_img7 from "../../../global-assets/assets/pf_img7_1.svg";
import pf_img8 from "../../../global-assets/assets/pf_img8.svg";
import pf_img9 from "../../../global-assets/assets/pf_img9.svg";
import pf_img10 from "../../../global-assets/assets/pf_img10.svg";
import title_stars from "../../../global-assets/assets/title_stars.webp";

const TruPlayFeatures = [
  {
    icon: pf_img2,
    title: "Multiple Games",
    description:
      "Discover our collection of beautiful Christian games — all in one app.",
  },
  {
    icon: pf_img3,
    title: "No Ads or In-App Purchases",
    description:
      "Give yourself peace of mind knowing there are no intrusive ads or additional purchases.",
  },
  {
    icon: pf_img4,
    title: "Create Multiple Profiles",
    description:
      "Create up to 10 unique profiles for each account and track your individual game progress.",
  },
  {
    icon: pf_img5,
    title: "No Chat",
    description:
      "Protect your family from unwanted conversations and disruptions.",
  },
  {
    icon: pf_img6,
    title: "Multi-Platform",
    description: "A single subscription for iOS and Android devices.",
  },
  {
    icon: pf_img7,
    title: "New Games & Content",
    description:
      "Enjoy new Bible-based games, comics, and animation added frequently.",
  },
  {
    icon: pf_img8,
    title: "Play Off-Line",
    description:
      "Download your favorite games and trusted content to your device and play anytime.",
  },
  {
    icon: pf_img9,
    title: "Digital Comics",
    description:
      "Listen to or read Bible stories and more with our immersive, digital-first comics.",
  },
  {
    icon: pf_img10,
    title: "Videos",
    description:
      "Watch original content with characters who rely on their faith in God to fight their battles.",
  },
];

export default function PlanFeatures() {
  return (
    <div className="mx-auto w-full sm:mb-16 md:mb-24 lg:mb-[8rem]">
      <div className="mb-0 flex max-w-none flex-col items-center text-center">
        <div className="relative mb-[16px]">
          <h2 className="text-transparent bg-gradient_text bg-clip-text">
            Your Plan Features
          </h2>
          <img
            src={title_stars}
            loading="lazy"
            alt=""
            className="absolute right-0 top-0 -mr-[100px] -mt-[30px] hidden md:block"
          />
        </div>
        <p className="text-center text-white lg:text-[2rem] lg:font-normal lg:leading-relaxed">
          Safe and trusted screen time with content and features that bring you
          peace of mind
        </p>
      </div>
      <div className="mt-[30px] flex flex-wrap justify-center gap-[5%] gap-y-[50px] md:mt-[50px] md:gap-y-[96px]">
        <div className="flex w-[70%] flex-col items-center text-center sm:w-full">
          <div className="mb-[24px] flex w-[50%] max-w-[140px] items-center justify-center">
            <img src={pf_img1} loading="lazy" alt="" />
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-[12px] mt-auto">
              <h5 className="text-white">Biblical Content &amp; God's Truth</h5>
            </div>
            <p className="mb-0 text-indigo-200">
              Positive and engaging content for families to learn and deepen
              their faith and Christian values
            </p>
          </div>
        </div>
        {TruPlayFeatures.map((feature, index) => (
          <div
            className="flex w-full flex-col items-center text-center sm:w-[45%] md:w-[30%]"
            key={index}
          >
            <div className="mb-[24px] flex w-[50%] max-w-[140px] items-center justify-center md:w-[50%]">
              <img
                src={feature.icon}
                loading="lazy"
                alt=""
                className="h-auto"
              />
            </div>
            <div className="flex flex-col items-center">
              <div className="mb-[12px] mt-auto">
                <h5 className="text-white">{feature.title}</h5>
              </div>
              <p className="mb-0 text-indigo-200">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
