import { useEffect } from "react";
import VideoCarousel from "./VideoCarousel";
import Video from "./Video";

interface IVideo {
  idx: number;
  url: string;
  width: string | number;
  height: string | number;
  component: JSX.Element | null;
  visible: boolean;
  focus: boolean;
  title: string;
  thumbnail: string;
  shareLink: string;
  videoId?: string | number;
}

interface VideoReviewSliderProps {
  videos: IVideo[];
  noTitle?: boolean;
  lightBackground?: boolean;
}

const unFocusedStyles =
  "mx-auto mt-0 w-full min-w-full p-8 max-w-[850px] md:p-0 md:min-w-[575px] lg:min-w-[850px] z-10";
const focusedStyles =
  "md:!min-w-[575px] lg:!min-w-[700px] lg:!max-w-[700px] !z-0";

const VideoReviewSlider = ({
  videos,
  noTitle,
  lightBackground,
}: VideoReviewSliderProps) => {
  useEffect(() => {
    videos.forEach((video) => {
      video.component = (
        <Video
          id={"video_testimony_" + video.idx}
          videoId={video.videoId}
          url={video.url}
          shareUrl={video.shareLink}
          controls
          playsInline
          thumbnail={video.thumbnail}
          height={video.height}
          width={video.width}
          shareSmall
          noShadow
        />
      );
    });
  }, [videos]);

  return (
    <div className="relative mx-auto w-full overflow-hidden">
      {!noTitle && (
        <h2 className="mb-3 text-center text-white">What Our Customers Say</h2>
      )}
      <VideoCarousel
        initialState={videos}
        focus
        lightBackground={lightBackground}
        focusStyles={unFocusedStyles}
        unFocusStyles={focusedStyles}
      />
    </div>
  );
};

export default VideoReviewSlider;
