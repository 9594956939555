import { useContext, useState } from "react";
import appstore_img from "../../global-assets/assets/AppStore_img.svg";
import googleplay_img from "../../global-assets/assets/GooglePlay_img.svg";
import { Link } from "react-router-dom";
import { EnvironmentConfigContext } from "../../config/Environment";
import Utm from "../analytics/UTM";
import { MixPanelAnalyticsContext } from "../analytics/Analytics";
import { DeviceSessionContext } from "../analytics/DeviceContext";

interface AppStoreLinksProps {
  noLinks?: boolean;
  nowrap?: boolean;
}

const AppStoreLinks = ({ noLinks, nowrap }: AppStoreLinksProps) => {
  const env = useContext(EnvironmentConfigContext);
  const analyticsContext = useContext(MixPanelAnalyticsContext);

  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );

  const trackDownloads = (selectedStore: string) => {
    let utm = new Utm(analyticsContext);
    utm.track(
      `Web: Marketing-Page-Download-Clicked-${selectedStore}`,
      deviceCtx
    );
  };

  return (
    <div className="flex w-full max-w-[1440px] flex-col items-center justify-start">
      {noLinks ? (
        <div
          className={
            "flex w-full flex-col items-center justify-center gap-4 sm:gap-8 md:flex-row md:gap-12 lg:gap-24 2xl:gap-20" +
            (nowrap ? " !flex-row !flex-nowrap" : "")
          }
        >
          <img
            src={appstore_img}
            loading="lazy"
            alt=""
            className={
              nowrap ? "max-h-12 md:max-h-16" : "max-h-20 2xl:max-h-28"
            }
          />
          <img
            src={googleplay_img}
            loading="lazy"
            alt=""
            className={
              nowrap ? "max-h-12 md:max-h-16" : "max-h-20 2xl:max-h-28"
            }
          />
        </div>
      ) : (
        <div className="flex w-full max-w-[1440px] flex-row items-center justify-center gap-4 sm:gap-8 md:gap-12 lg:gap-24 2xl:gap-20">
          <Link
            id="apple_store_link"
            to={env.AppStoreURL()}
            onClick={() => {
              trackDownloads("AppleAppStore");
            }}
          >
            <img
              src={appstore_img}
              loading="lazy"
              alt=""
              className="max-h-20 2xl:max-h-28"
            />
          </Link>
          <Link
            id="google_play_store_link"
            to={env.PlayStoreURL()}
            onClick={() => {
              trackDownloads("GooglePlay");
            }}
          >
            <img
              src={googleplay_img}
              loading="lazy"
              alt=""
              className="max-h-20 2xl:max-h-28"
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default AppStoreLinks;
