import React from "react";
import "./DeleteModal.css";
import { isAuthenticatedWithValidToken } from "../../../config/UserAuthContext";
import WarningTriangleRed from "../../../global-assets/assets/warning-triangle-red.svg";
import { GetEmailAddress } from "../../../config/UserAuthContext";
import emailjs from "emailjs-com";
import ModalContainer from "../../common/ModalContainer";
import Button from "../../common/Button";

interface DeleteModalProps {
  handleClose: () => void;
  open: boolean;
  onSuccessDelete: (success: boolean) => void;
  onFailedDelete: (fail: boolean) => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  handleClose,
  open,
  onSuccessDelete,
  onFailedDelete,
}) => {
  const EmailAddress: String = GetEmailAddress() || "";
  const handelDelete = () => {
    const templateParams = {
      from_name: EmailAddress,
      message: "User has requested account deletion.",
    };

    emailjs
      .send(
        "service_0oql5rl",
        "template_6l3p2ae",
        templateParams,
        "UpBN0IWb6iVQFzUiy"
      )
      .then(
        (response: any) => {
          console.log("Email sent successfully");
          onSuccessDelete(true);
        },
        (error: any) => {
          console.log("Failed to send email", error);
          onFailedDelete(true);
        }
      );

    handleClose();
  };

  let isAuthenticated = isAuthenticatedWithValidToken();

  return (
    <div>
      {isAuthenticated && (
        <ModalContainer isOpen={open}>
          <>
            <h3 className="p-0 text-2xl font-bold text-indigo-500 sm:text-3xl lg:text-4xl">
              Are you sure?
            </h3>
            <h5 className="mb-6 mt-3 max-w-[400px] text-center font-medium leading-7 text-cool-gray-900">
              Do you really want to delete your TruPlay account?
            </h5>

            <div className="flex flex-col items-center justify-center">
              <img
                src={WarningTriangleRed}
                loading="lazy"
                alt="alert"
                className="w-[60px] lg:w-[100px]"
              />
              <h5 className="mb-6 mt-3 max-w-[400px] text-center text-raspberry-500">
                This does not cancel your subscription.
              </h5>
              <p className="max-w-[410px] text-center">
                By continuing your request you will lose access to your TruPlay
                account and all saved data.
              </p>
            </div>
            <div className="mt-6 flex w-full flex-col items-center">
              <Button
                id="delete_account_submit"
                onClick={() => handelDelete()}
                btnType="red"
                className="mb-4 w-full"
              >
                Yes, Delete
              </Button>
              <Button
                id="delete_account_cancel"
                onClick={() => handleClose()}
                btnType="secondaryAlt"
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </>
        </ModalContainer>
      )}
    </div>
  );
};

export default DeleteModal;
