import { useState, useEffect } from "react";
import "./AccountDelete.css";
import { toast } from "react-toastify";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ClearAllCookies, navigateTo } from "../../../config/UserAuthContext";
import { GetEmailAddress } from "../../../config/UserAuthContext";
import { GetContinueToQueryString } from "../../../lib/PassedParams";
import { DefaultFAQs } from "../../common/FAQSection/FAQSection";

// Components //
import Button from "../../common/Button";

// Assets //
import faq_arrow from "../../../global-assets/assets/faq_arrow.svg";

const DeleteAccount = () => {
  const [isFAQOpen, setIsFAQOpen] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [acceptUnderstandDelete, setAcceptUnderstandDelete] =
    useState<boolean>(false);
  const [isSuccessDelete, setIsSuccessDelete] = useState<boolean>(false);
  const [isFailedDelete, setIsFailedDelete] = useState<boolean>(false);
  const EmailAddress: String = GetEmailAddress() || "";

  const handleSuccessDelete = (success: boolean) => {
    setIsSuccessDelete(success);
  };

  const handleFailedDelete = (fail: boolean) => {
    setIsFailedDelete(fail);
  };

  const onContinueDeleteClick = () => {
    if (EmailAddress === "") {
      toast.error("Please log in to delete your account.", {
        position: "top-center",
      });

      setTimeout(() => {
        navigateTo(`/login${GetContinueToQueryString()}`);
      }, 4500);
    } else {
      setIsOpenDeleteModal(true);
    }
  };

  useEffect(() => {
    if (isSuccessDelete) {
      toast.success(
        "Success! Your account has been set for deletion within 30 Days.",
        {
          position: "top-center",
        }
      );
      setTimeout(() => {
        ClearAllCookies();
        navigateTo(`/login${GetContinueToQueryString()}`);
      }, 4500);
    } else if (isFailedDelete) {
      toast.error(
        "Account deletion failed. Please try again or contact customer support.",
        {
          position: "top-center",
        }
      );
    }
    setIsSuccessDelete(false);
    setIsFailedDelete(false);
  }, [isSuccessDelete, isFailedDelete]);

  return (
    <div
      id="test_data_delete_account_page"
      className="h-full min-h-[calc(100vh-272px)] text-white"
    >
      <div className="relative overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 md:pt-32 lg:px-12">
        <div className="mx-auto flex max-w-[1440px] flex-col gap-4 lg:gap-8">
          <h3 className="text-center font-display font-bold">
            What to know before you delete your TruPlay account:
          </h3>
          <p className="m-0 text-center font-display font-bold leading-6 text-cerulean-500">
            IMPORTANT:
            <br />
            You must cancel your subscription before deleting your account.
          </p>
          <div>
            <div className="flex flex-col rounded-2xl bg-[rgba(32,86,139,0.5)] p-4 lg:p-8">
              <div
                className="flex cursor-pointer flex-row items-center"
                onClick={() => setIsFAQOpen(!isFAQOpen)}
              >
                <p className="m-0 text-white">How to Delete your Account</p>
                <img
                  src={faq_arrow}
                  loading="lazy"
                  style={{
                    transform: isFAQOpen
                      ? "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-180deg) skew(0deg, 0deg)"
                      : "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  }}
                  alt="Open Directions"
                  className="ml-auto w-6 duration-1000 ease-in-out md:w-8"
                />
              </div>
              <div
                className={
                  "max-h-0 overflow-hidden duration-[1200ms] ease-in-out" +
                  (isFAQOpen ? " !max-h-[2500px]" : "")
                }
              >
                <div className="tru-delete-faq-answer pt-4">
                  <div>{DefaultFAQs.faq7.answer}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-4 lg:gap-8">
            <div className="flex items-center justify-center gap-2">
              <input
                id="terms-checkbox"
                type="checkbox"
                checked={acceptUnderstandDelete}
                onChange={() =>
                  setAcceptUnderstandDelete(!acceptUnderstandDelete)
                }
              />
              <label
                className="text-sm font-medium text-indigo-200 lg:text-xl"
                htmlFor="terms-checkbox"
              >
                I have read and understand the instructions.
              </label>
            </div>
            <div className="flex w-full justify-center">
              <Button
                id="delete_account_continue_btn"
                onClick={onContinueDeleteClick}
                disabled={!acceptUnderstandDelete}
                btnType="red"
                className="w-full max-w-[400px]"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        handleClose={() => setIsOpenDeleteModal(false)}
        open={isOpenDeleteModal}
        onSuccessDelete={handleSuccessDelete}
        onFailedDelete={handleFailedDelete}
      />
    </div>
  );
};

export default DeleteAccount;
