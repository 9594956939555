import { useState } from "react";
import VideoModal from "../../common/VideoModal";
// import Button from "../../common/Button";

// Assets //
import ComicsBG from "../../../global-assets/assets/comicsbg.webp";
import ComicsGraphic from "../../../global-assets/assets/comics-graphic.png";

export default function GamesMoreComics() {
  const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false);

  // const toggleVideoVisibility = () => {
  //   setIsVideoVisible(!isVideoVisible);
  // };

  return (
    <div className="relative overflow-visible pb-[12%] pt-[16%]">
      <div className="container mx-auto px-5">
        <div className="relative flex flex-col gap-5 md:flex-row md:items-center md:justify-between">
          <div className="z-10 w-full md:w-1/2">
            <div className="mb-4">
              <h2 className="w-full text-white">Interactive Digital Comics</h2>
            </div>
            <div className="mb-4">
              <p className=" text-white">
                Dive deeper into the Rhymverse and journey with our characters
                through interactive read-aloud digital comic books. Our stories
                feature Maple and Oliver as they venture into the forest and
                encounter obstacles in their quest to rescue others from danger.
                The striking art invites the reader into a fascinating new world
                while the voice-over featuring Maple, Oliver, and the Narrator
                brings depth and drama to the story, creating an exciting user
                experience.
              </p>
            </div>
            {/* Uncomment the following block if the button is needed */}
            {/* <div>
              <Button
                id="read_sample_cta_btn"
                btnType="green"
                onClick={() => setIsVideoVisible(true)}
              >
                Read Sample
              </Button>
            </div> */}
          </div>
          {isVideoVisible && (
            <VideoModal
              setIsOpen={setIsVideoVisible}
              videoLink="https://player.vimeo.com/video/825530283?h=7fca2f738e&app_id=122963"
            />
          )}
        </div>
      </div>
      <div className="absolute inset-0">
        <div className="opacity-84 absolute inset-0 bg-gradient_linear_dark_purple"></div>
        <img
          src={ComicsBG}
          loading="eager"
          sizes="100vw"
          alt=""
          className="h-full w-full object-cover"
        />
      </div>
      <div className="absolute inset-0 mx-auto hidden justify-end md:flex">
        <div className="relative mx-auto flex w-full max-w-[1440px] items-center justify-end overflow-hidden">
          <img
            src={ComicsGraphic}
            loading="lazy"
            alt="Comics Graphic"
            className="w-full max-w-screen-xl"
          />
        </div>
      </div>
    </div>
  );
}
