import GameIcon from "../../../global-assets/assets/game-icon1.svg";
import closebutton from "../../../global-assets/assets/icon-close-gray.svg";
import Video from "../../common/Video";

interface selectedGame {
  id?: number;
  title: string;
  imageSrc: string;
  category: string;
  videoSrc?: string | JSX.Element;
  shareSrc?: string;
  thumbnail?: string;
  description: string;
}
interface GameModalProps {
  selectedGame: selectedGame;
  setIsVideoVisible: Function;
}

export default function GameModal({
  selectedGame,
  setIsVideoVisible,
}: GameModalProps) {
  return (
    <div className="fixed inset-0 z-[999] flex items-center justify-center bg-[rgba(0,32,63,0.95)]">
      <div className="relative mx-5 max-h-[calc(100vh-4rem)] max-w-[768px] overflow-auto rounded-2xl bg-[rgba(255,255,255,0.05)] p-4 md:p-8">
        {typeof selectedGame.videoSrc === "string" ? (
          <Video
            url={selectedGame.videoSrc}
            shareUrl={selectedGame.shareSrc}
            playsInline
            thumbnail={selectedGame.thumbnail}
            controls
            shareSmall
          />
        ) : (
          selectedGame.videoSrc
        )}
        <div className="mt-4">
          <div className="mb-4">
            <div className="flex items-center gap-4 text-[rgba(255,255,255,0.5)]">
              <img src={GameIcon} loading="lazy" alt="" />
              <div>{selectedGame.category}</div>
            </div>
          </div>
          <h3 className="text-transparent to-teal-400 bg-gradient_text bg-clip-text font-bold">
            {selectedGame.title}
          </h3>
          <p className="mt-2 text-white">{selectedGame.description}</p>
        </div>
      </div>
      <div
        id="tru_modal_close_btn"
        className="absolute right-8 top-8 cursor-pointer"
        role="button"
        onClick={() => setIsVideoVisible(false)}
      >
        <img
          src={closebutton}
          loading="lazy"
          alt=""
          className="hover:opacity-100 lg:opacity-70"
        />
      </div>
      <div className="to-transparent absolute inset-0 z-[-1] bg-gradient_radial_purple from-purple-900 opacity-60"></div>
    </div>
  );
}
