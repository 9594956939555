import LeftAlignedCarousel from "../common/LeftAlignedCarousel";
import Tile1 from "./assets/tile-mov-wtr.webp";
import Tile1Thumbnail from "./assets/thumbnail_welcome_to_rhym.webp";
import Tile2 from "./assets/tile-mov-mpl.webp";
import Tile2Thumbnail from "./assets/thumbnail_maples_rescue_mission.webp";
import Tile3 from "./assets/tile-mov-lhq.webp";
import Tile3Thumbnail from "./assets/thumbnail_lucas_heavenly_quest.webp";
import Tile4 from "./assets/tile-movpwt1.webp";
import Tile4Thumbnail from "./assets/thumbnail_the_lords_prayer.webp";
import Tile5 from "./assets/tile-movpwt2.webp";
import Tile5Thumbnail from "./assets/thumbnail_the_forever_family.webp";
import Tile6 from "./assets/tile-movpwt3.webp";
import Tile6Thumbnail from "./assets/thumbnail_the_source_of_courage.webp";
import Tile7 from "./assets/tile-movpwt4.webp";
import Tile7Thumbnail from "./assets/thumbnail_the_shepherds_psalm.webp";
import Tile8 from "./assets/tile-movpwt5.webp";
import Tile8Thumbnail from "./assets/thumbnail_the_strength_of_god.webp";
import Tile9 from "./assets/tile-movpwt6.webp";
import Tile9Thumbnail from "./assets/thumbnail_the_darkest_valley.webp";
import Tile10 from "./assets/tile-mov-htc.webp";
import Tile10Thumbnail from "./assets/thumbnail_how_to_christmas.webp";
import Tile11 from "./assets/tile-mov-bge.webp";
import Tile11Thumbnail from "./assets/thumbnail_best_gift_ever.webp";
import Tile12 from "./assets/tile-mov-easaud1.webp";
import Tile12Thumbnail from "./assets/thumbnail_easter_is_amazelous.webp";
import Tile13 from "./assets/tile-mov-easaud2.webp";
import Tile13Thumbnail from "./assets/thumbnail_easter_more_than_baskets.webp";

interface IVideoTileList {
  id: string;
  videoId: string | number;
  tileImage: string; // Path to tile image
  videoLink: string; // URL to video (CDN link)
  thumbnail?: string;
  videoShareUrl?: string; // Link to shareable video (Vimeo link)
  component: JSX.Element | null;
  focus: boolean;
  type: string;
  title?: string | null;
  description?: string | null;
}

const videoTileList: IVideoTileList[] = [
  {
    id: "tile_1",
    videoId: "953693941",
    tileImage: Tile1,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/welcome_to_rhym_new%20(720p).mp4",
    thumbnail: Tile1Thumbnail,
    videoShareUrl: "https://vimeo.com/953693941",
    component: null,
    focus: false,
    type: "video",
    title: "Welcome to Rhym",
    description:
      "Meet the Rhym Squad, the enemies they face, and the incredible RhymVerse where faith" +
      " overcomes every adversity.",
  },
  {
    id: "tile_2",
    videoId: "953691387",
    tileImage: Tile2,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/maple's_rescue_mission%20(720p).mp4",
    thumbnail: Tile2Thumbnail,
    videoShareUrl: "https://vimeo.com/953691387",
    component: null,
    focus: false,
    type: "video",
    title: "Maple's Rescue Misson",
    description:
      "Can a Tiger Bunny and a Biblical Fox be friends? Watch and find out as Maple and Oliver try to escape the evil Axilla's Vindictive Vines!",
  },
  {
    id: "tile_3",
    videoId: "953689893",
    tileImage: Tile3,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/lucas_and_the_heavenly_quest%20(720p).mp4",
    thumbnail: Tile3Thumbnail,
    videoShareUrl: "https://vimeo.com/953689893",
    component: null,
    focus: false,
    type: "video",
    title: "Lucas and the Heavenly Quest",
    description:
      "Lucas must find his brother! Watch as the determined Skunk Robot-Crocodile searches earth and heaven for help.",
  },
  {
    id: "tile_4",
    videoId: "953734252",
    tileImage: Tile4,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_lord's_prayer%20(720p).mp4",
    thumbnail: Tile4Thumbnail,
    videoShareUrl: "https://vimeo.com/953734252",
    component: null,
    focus: false,
    type: "video",
    title: "Oliver and The Lord's Prayer",
    description:
      "Oliver the biblical fox is learning how to talk to God through a prayer called, “The Lord's Prayer.”  You can too!",
  },
  {
    id: "tile_5",
    videoId: "953734893",
    tileImage: Tile5,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_forever_family%20(720p).mp4",
    thumbnail: Tile5Thumbnail,
    videoShareUrl: "https://vimeo.com/953734893",
    component: null,
    focus: false,
    type: "video",
    title: "Oliver and the Forever Family",
    description:
      "Join Oliver the biblical fox as he talks to God about what it's like to be adopted into God's “forever family.”",
  },
  {
    id: "tile_6",
    videoId: "953736989",
    tileImage: Tile6,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_source_of_courage%20(720p).mp4",
    thumbnail: Tile6Thumbnail,
    videoShareUrl: "https://vimeo.com/953736989",
    component: null,
    focus: false,
    type: "video",
    title: "Maple and the Source of Courage",
    description:
      "Maple the tiger bunny wants to show you the Bible verses that help her to be “strong and courageous!”",
  },
  {
    id: "tile_7",
    videoId: "953735375",
    tileImage: Tile7,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_shepherd's_psalm%20(720p).mp4",
    thumbnail: Tile7Thumbnail,
    videoShareUrl: "https://vimeo.com/953735375",
    component: null,
    focus: false,
    type: "video",
    title: "Maple and the Shepherd Psalm",
    description:
      "Join Maple the tiger bunny so she can share with you how she knows God will lead her and provide for her… and you too!",
  },
  {
    id: "tile_8",
    videoId: "953739998",
    tileImage: Tile8,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_strength_of_god%20(720p).mp4",
    thumbnail: Tile8Thumbnail,
    videoShareUrl: "https://vimeo.com/953739998",
    component: null,
    focus: false,
    type: "video",
    title: "Benjamin and the Strength of God",
    description:
      "Benjamin wants to share with you how the Bible reminds him that no matter how strong or weak he feels, God is with him.",
  },
  {
    id: "tile_9",
    videoId: "953739247",
    tileImage: Tile9,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_darkest_valley%20(720p).mp4",
    thumbnail: Tile9Thumbnail,
    videoShareUrl: "https://vimeo.com/953739247",
    component: null,
    focus: false,
    type: "video",
    title: "Benjamin and the Darkest Valley",
    description:
      "Armed with key verses from the Twenty-third Psalm, Benjamin feels ready to face whatever comes at him. Are you ready?",
  },
  {
    id: "tile_10",
    videoId: "953689088",
    tileImage: Tile10,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/how_to_christmas%20(720p).mp4",
    thumbnail: Tile10Thumbnail,
    videoShareUrl: "https://vimeo.com/953689088",
    component: null,
    focus: false,
    type: "video",
    title: "How to Christmas",
    description: "Can Maple and Oliver agree on the baby Jesus scene?",
  },
  {
    id: "tile_11",
    videoId: "953687529",
    tileImage: Tile11,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_best_gift_ever%20(720p).mp4",
    thumbnail: Tile11Thumbnail,
    videoShareUrl: "https://vimeo.com/953687529",
    component: null,
    focus: false,
    type: "video",
    title: "The Best Gift Ever",
    description:
      "Maple and Oliver understand the real meaning of the season, even though Maple wants to change the name of Christmas!",
  },
  {
    id: "tile_12",
    videoId: "953732485",
    tileImage: Tile12,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/easter_is_amazelous%20(720p).mp4",
    thumbnail: Tile12Thumbnail,
    videoShareUrl: "https://vimeo.com/953732485",
    component: null,
    focus: false,
    type: "video",
    title: "Easter is Amazelous!",
    description:
      "In this short video, Maple explains the real meaning and joy of Easter to her friend Oliver.",
  },
  {
    id: "tile_13",
    videoId: "953692435",
    tileImage: Tile13,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/easter__more_than_baskets_and_bunnies%20(720p).mp4",
    thumbnail: Tile13Thumbnail,
    videoShareUrl: "https://vimeo.com/953692435",
    component: null,
    focus: false,
    type: "video",
    title: "More than Baskets and Bunnies?",
    description:
      "Lucas and Maple discuss Easter baskets and bunnies...and what Easter is really about.",
  },
];

videoTileList.forEach((tile) => {
  tile.component = (
    <img src={tile.tileImage} alt={tile.id} loading="eager" draggable="false" />
  );
});

export default function FreeContentVideos() {
  const tileWidth = 250;
  const tileMargin = 24;
  const tileHeight = 250;
  const carouselPaddingTop = 16;
  const carouselPaddingBottom = 56;

  return (
    <div className="flex flex-col justify-start gap-2 overflow-x-hidden">
      <h2 className="pl-8 text-3xl text-white sm:pl-10 md:pl-20 md:text-4xl">
        Free Christian Videos
      </h2>
      <p className="text-transparent m-0 bg-gradient_text bg-clip-text px-8 text-base font-medium sm:pl-10 sm:text-lg md:pl-20 md:text-2xl">
        <span className="hidden lg:inline">
          Watch Biblical and Christian Videos for Children!
        </span>
        <span className="lg:hidden">TruPlay Theatrical Treasures</span>
      </p>
      <div className="relative w-full">
        <LeftAlignedCarousel
          initialState={videoTileList}
          cardWidth={tileWidth}
          cardHeight={tileHeight}
          cardMargin={tileMargin}
          responsive
          animated
          cardStyles="shadow-carousel_tile rounded-[21px] sm:rounded-[42px] cursor-pointer duration-200 ease-in-out"
          hoverStyles="md:group-hover/card-hover:shadow-carousel_hover_tile md:group-hover/card-hover:scale-105 md:group-hover/card-focus:scale-105"
          focusStyles="rounded-[21px] sm:rounded-[42px] shadow-carousel_select_tile ring-[6px] ring-white cursor-pointer duration-200 ease-in-out"
          arrowType="netflix"
          arrowCornerType="rounded"
          selection
          paddingBottom={carouselPaddingBottom}
          paddingTop={carouselPaddingTop}
        />
      </div>
    </div>
  );
}
