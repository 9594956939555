import FAQSection from "../../components/common/FAQSection/FAQSection";

export default function FAQ() {
  return (
    <div id="test_faq_page" className="min-h-screen">
      <div className="relative overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 lg:px-12 lg:pt-32">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_0%,_#68e0cf_0%,transparent_50%)] opacity-40" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_50%,_#652c80_0%,transparent_70%)] opacity-30" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_100%,_#38d58a_0%,transparent_50%)] opacity-40" />
        <div className="mx-auto max-w-[1440px]">
          <FAQSection />
        </div>
      </div>
    </div>
  );
}
