import "./InlineInfoMessage.css";

// @ts-ignore
interface InlineInfoMessageProps {
  message: string | React.ReactNode;
}
export function InlineInfoMessage({ message }: InlineInfoMessageProps) {
  return (
    <div className="tru-inline-info">
      {message}
      <div className="tru-inline-info-icon">i</div>
    </div>
  );
}
