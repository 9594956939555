import { fetchAndActivate, getValue } from "firebase/remote-config";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { remoteConfig } from "../firebase";
import { localRemoteConfig } from "../lib/Constants";

interface FBRemoteConfigContextState {
  [key: string]: boolean;
}

const FBRemoteConfigContext = createContext<
  FBRemoteConfigContextState | undefined
>(undefined);

interface FBRemoteConfigProviderProps {
  children: ReactNode;
}

export const FBRemoteConfigProvider = ({
  children,
}: FBRemoteConfigProviderProps) => {
  const [flags, setFlags] = useState<FBRemoteConfigContextState>({});

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const easterDiscount = getValue(
          remoteConfig,
          "EasterDiscount"
        ).asBoolean();
        const enableSubscriptionUpgrade = getValue(
          remoteConfig,
          "EnableSubscriptionUpgrade"
        ).asBoolean();
        const firebaseAuth = getValue(remoteConfig, "FirebaseAuth").asBoolean();
        const firebaseSSO = getValue(remoteConfig, "SSO").asBoolean();
        const showBackgroundVideoInLandingPage = getValue(
          remoteConfig,
          "ShowBackgroundVideoInLandingPage"
        ).asBoolean();
        const showDiscountedPriceOffer = getValue(
          remoteConfig,
          "ShowDiscountedPriceOffer"
        ).asBoolean();
        const showTransparentNavigationBar = getValue(
          remoteConfig,
          "ShowTransparentNavigationBar"
        ).asBoolean();
        const springSale = getValue(remoteConfig, "SpringSale").asBoolean();
        const summerSale = getValue(remoteConfig, "SummerSale").asBoolean();
        const thanksgivingPriceDiscount2023 = getValue(
          remoteConfig,
          "ThanksgivingPriceDiscount2023Enabled"
        ).asBoolean();
        const useGoogleAnalyticsForUaTracking = getValue(
          remoteConfig,
          "UseGoogleAnalyticsForUaTracking"
        ).asBoolean();
        const useMixPanelForUATracking = getValue(
          remoteConfig,
          "UseMixPanelForUaTracking"
        ).asBoolean();
        const useSingularWebAnalyticsForAttribution = getValue(
          remoteConfig,
          "UseSingularWebAnalyticsForAttribution"
        ).asBoolean();

        if (process.env.REACT_APP_ENVIRONMENT === "local") {
          setFlags(localRemoteConfig);
        } else {
          setFlags({
            easterDiscount,
            enableSubscriptionUpgrade,
            firebaseAuth,
            firebaseSSO,
            showBackgroundVideoInLandingPage,
            showDiscountedPriceOffer,
            showTransparentNavigationBar,
            springSale,
            summerSale,
            thanksgivingPriceDiscount2023,
            useGoogleAnalyticsForUaTracking,
            useMixPanelForUATracking,
            useSingularWebAnalyticsForAttribution,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <FBRemoteConfigContext.Provider value={flags}>
      {children}
    </FBRemoteConfigContext.Provider>
  );
};

export const useRemoteConfig = (): FBRemoteConfigContextState => {
  const context = useContext(FBRemoteConfigContext);
  if (context === undefined) {
    throw new Error(
      "useRemoteConfig must be used within FBRemoteConfigProvider!"
    );
  }
  return context;
};
