interface YesLadderProgressBarProps {
  progress: number; // Percentage as a decimal
}

export default function YesLadderProgressBar({
  progress,
}: YesLadderProgressBarProps) {
  return (
    <div className="h-4 w-full rounded-full bg-cool-gray-200">
      <div
        className="h-full rounded-full bg-cerulean-500"
        style={{ width: progress * 100 + "%" }}
      />
    </div>
  );
}
