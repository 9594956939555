import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import EmailValidator from "../../../../config/UserInputValidator";
import {
  Context,
  GetEmailAddress,
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../../../config/UserAuthContext";
import { InlineErrorMessage } from "../../../notification/InlineErrorMessage/InlineErrorMessage";
import AccountServiceClient from "../../../../lib/ApiFunction";
import { toast } from "react-toastify";
import { ErrorCodeMap } from "../../../../lib/Constants";

// Firebase
import { sendPasswordResetEmail, ActionCodeSettings } from "firebase/auth";
import { auth } from "../../../../firebase";
import { useRemoteConfig } from "../../../../config/FBRemoteConfigContext";
import Button from "../../../common/Button";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [userInputError, setUserInputError] = useState<string | null>(null);
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const { GetEmailToResetPassword } = AccountServiceClient();

  const userLogs = GetOrCreateSessionAuthContext();
  const emailCookie: string = GetEmailAddress() || "";
  const [emailFlag, setEmailFlag] = useState(false);

  const firebaseFlags = useRemoteConfig();

  useEffect(() => {
    if (userLogs.uuId) {
      setEmail(emailCookie);
      setEmailFlag(true);
      setIsButtonActive(true);
    }
  }, [emailCookie, userLogs.uuId]);

  // Firebase Password Reset
  const handleFirebaseResetPass = () => {
    let error: ErrorObj = {};
    let validator = new EmailValidator(email);

    if (validator.ErrorMessage) {
      error.email = validator.ErrorMessage;
    }

    if (Object.keys(error).length > 0) {
      setUserInputError("No account found with that email." + error.email);
      return;
    }

    setUserInputError(null);
    setIsCallingAPI(true);

    let continueUrlVar = "www-dev";

    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "stg":
        continueUrlVar = "www-stg";
        break;
      case "prod":
        continueUrlVar = "www";
        break;
    }

    const actionCodeSettings: ActionCodeSettings = {
      url: `https://${continueUrlVar}.truplaygames.com/login`,
      handleCodeInApp: false,
    };

    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast.success(
          "An password reset email has been sent. Please follow the directions in that email to reset your password.",
          {
            position: "top-center",
          }
        );

        Cookies.set(Context.EmailAddress, email);

        setTimeout(() => {
          setIsCallingAPI(false);
          navigateTo("/login");
        }, 3000);
      })
      .catch((error) => {
        // TODO: Update error handling once Firebase and Account Services are fully integrated
        const fbErrorCode = error.code;
        const fbErrorMessage = error.message;
        setIsCallingAPI(false);
        const errorMessage = ErrorCodeMap[fbErrorCode]
          ? ErrorCodeMap[fbErrorCode]
          : fbErrorMessage;
        setUserInputError(errorMessage);
      });
  };

  const resetPassword = () => {
    let error: ErrorObj = {};
    let validator = new EmailValidator(email);

    if (validator.ErrorMessage) {
      error.email = validator.ErrorMessage;
    }

    if (Object.keys(error).length > 0) {
      setUserInputError("No account found with that email. " + error.email);
      return;
    }

    setUserInputError(null);
    let data = {
      emailAddress: email,
    };

    setIsCallingAPI(true);
    GetEmailToResetPassword.post("", data)
      .then(() => {
        toast.success(
          "A secure code has been sent to your registered email address.",
          {
            position: "top-center",
          }
        );

        Cookies.set(Context.EmailAddress, email);
        navigate("/verify-email-address", { replace: true });
      })
      .catch((error) => {
        setIsCallingAPI(false);
        const errorMessage = ErrorCodeMap[error.response?.data.statusCode]
          ? ErrorCodeMap[error.response?.data.statusCode]
          : error.response?.data.statusMessage;
        setUserInputError(errorMessage);
      });
  };

  interface ErrorObj {
    [key: string]: string;
  }

  return (
    <div className="relative flex h-full min-h-[calc(100vh-272px)] items-center overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 md:pt-32 lg:px-12 lg:py-36">
      <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-60" />
      <div className="m-auto flex max-w-[1200px] justify-center">
        <div className="flex flex-col items-center gap-4 rounded-[30px] bg-white p-6 shadow-[0px_15px_10px_-15px_#38a6d5] md:p-8">
          <h5 className="text-center font-display text-2xl font-semibold capitalize text-indigo-500 md:text-3xl">
            Forgot Your Password?
          </h5>
          <p className="m-0 text-[0.90rem] text-cool-gray-900 md:text-lg">
            Please enter the email that you use to log in.
          </p>
          <input
            className={
              "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
              " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
              " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
              " focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
              (userInputError ? " ring-2 ring-raspberry-500" : "")
            }
            value={email}
            placeholder="Email"
            onChange={(e: any) => {
              setEmail(e.target.value);
              setIsButtonActive(e.target.value.length > 0);
            }}
          />
          {userInputError && (
            <InlineErrorMessage errorMessage={userInputError} />
          )}
          <Button
            id="forgot_password_btn"
            loading={isCallingAPI}
            disabled={!isButtonActive}
            btnType="green"
            onClick={
              firebaseFlags.firebaseAuth
                ? handleFirebaseResetPass
                : resetPassword
            }
            className="w-full"
          >
            Send Email
          </Button>
          {!emailFlag && (
            <div className="flex items-center justify-center text-center">
              <span className="inline-block p-0.5 text-base font-medium text-cool-gray-900">
                Don't have an account?
              </span>
              <Link to="/user-registration">
                <Button
                  id="log_in_sign_up_cta"
                  btnType="tertiaryAlt"
                  className="ml-2 !text-base hover:underline active:!text-cerulean-500"
                >
                  Sign Up
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
