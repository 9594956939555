import Cookies, { CookieAttributes } from "js-cookie";
import { createContext } from "react";
import * as jose from "jose";
import CryptoJS from "crypto-js";

export enum Context {
  AuthContext = "AuthContext",
  UserContext = "UserContext",
  Token = "token",
  RememberMe = "RememberMe",
  EmailAddress = "EmailAddress",
  Pk = "Pk",
  FreeTrial = "FreeTrial",
  Subsplash = "Subsplash",
  GlobalUserId = "GlobalUserId"
}

export class EmailStatus {
  didSendNewVerificationEmail: string = "";
  isVerified: boolean = false;
  shouldShowEmailVerificationScreen: boolean = false;
}

export class SubscriptionPlan {
  expireTime: string = "";
  isActive: boolean = false;
  platform: string = "";
  productId: string = "";
}

export class Subscription {
  expireTime: string = "";
  isActive: boolean = false;
  sources: SubscriptionPlan[] = [];
}

export class UserAuthContext {
  communicationsOptIn: boolean = false;
  subscription: Subscription = new Subscription();
  token?: string;
  uuId: string = "";
  verifyEmail: EmailStatus = new EmailStatus();
  sso: boolean = false;

  get HasActiveSubscription(): boolean {
    return this.subscription.isActive;
  }

  generateGlobalUserId(): string {
    return CryptoJS.SHA256("truplaygames.com" + this.uuId).toString();
  }
}

export class AuthContext {
  token?: string;
  userID?: string;
}

export const ClearAllCookies = () => {
  Cookies.remove(Context.AuthContext);
  Cookies.remove(Context.UserContext);
  Cookies.remove(Context.Token);
  Cookies.remove(Context.EmailAddress);
  Cookies.remove(Context.Pk);
  Cookies.remove(Context.FreeTrial);
  Cookies.remove(Context.Subsplash);
};

export const Logout = () => {
  Cookies.remove(Context.AuthContext);
  Cookies.remove(Context.UserContext);
  Cookies.remove(Context.Token);
  Cookies.remove(Context.EmailAddress);
  Cookies.remove(Context.Pk);
  Cookies.remove(Context.FreeTrial);
  Cookies.remove(Context.Subsplash);
};

// @ts-ignore
export const navigateTo = (
  destination: string,
  openInNewTab: boolean = false
): string => {
  const newUrl = `${window.location.protocol}//${window.location.host}${destination}`;
  if (openInNewTab) {
    window.open(newUrl, "_blank");
  } else {
    window.location.href = newUrl;
  }
  return newUrl;
};

export const isAuthenticatedWithValidToken = (): boolean => {
  let authContext = Cookies.get(Context.AuthContext);

  if (authContext) {
    let authCtx: UserAuthContext =
      (JSON.parse(authContext) as UserAuthContext) || new UserAuthContext();

    if (authCtx?.token != null) {
      let decodedToken = jose.decodeJwt(authCtx?.token);
      let tokenExpiryDate = new Date((decodedToken.exp as number) * 1000);
      let currentTime = new Date();

      return currentTime < tokenExpiryDate;
    }
    return false;
  }
  return false;
};

export const GetEmailAddress = () => {
  return Cookies.get("EmailAddress");
};

export const GetauthToken = () => {
  return Cookies.get("token");
};

export const GetRememberMe = () => {
  return Cookies.get("RememberMe");
};

export const GetPkReferral = () => {
  return Cookies.get("Pk");
};

export const GetFreeTrial = () => {
  return Cookies.get("FreeTrial");
};

export const GetSubsplash = () => {
  return Cookies.get("Subsplash");
};

export const GetGlobalUserId = () => {
  return Cookies.get("GlobalUserId");
};

/**
 * User Auth Context if persisted
 */
export const GetOrCreateSessionAuthContext = (): UserAuthContext => {
  const userContextString: string = Cookies.get("UserContext") || "";
  let userContext: UserAuthContext = new UserAuthContext();
  if (userContextString) {
    Object.assign(userContext, JSON.parse(userContextString));
  }

  if (userContext.uuId) {
    const globalUserId = userContext.generateGlobalUserId();
    Cookies.set("GlobalUserId", globalUserId, { expires: 30, secure: true, sameSite: 'strict' });
  }

  return userContext;
};

export const SecureCookieAttributes: CookieAttributes = {
  secure: true,
  sameSite: "strict",
  path: "/",
  expires: 90
};

export const SetAuthTokenAsCookies = (
  userContextString: string,
  email: string
) => {
  let userContext: UserAuthContext = new UserAuthContext();
  if (userContextString) {
    Object.assign(userContext, JSON.parse(userContextString));
  }
  Cookies.set("UserContext", JSON.stringify(userContext), SecureCookieAttributes);
  Cookies.set(
    "AuthContext",
    JSON.stringify({ userID: userContext.uuId, token: userContext.token }),
    SecureCookieAttributes
  );
  Cookies.set("EmailAddress", email, SecureCookieAttributes);

  if (userContext.uuId) {
    const globalUserId = userContext.generateGlobalUserId();
    Cookies.set("GlobalUserId", globalUserId, { ...SecureCookieAttributes });
  }
};

/**
 * Expose user auth info to all downstream components
 */
export const SessionAuthContext = createContext(
  GetOrCreateSessionAuthContext()
);
export default UserAuthContext;
