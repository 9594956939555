export function GetContinueToQueryString(): string {
  const urlParams = new URLSearchParams(window.location.search);
  const continueTo = urlParams.get("continueto");
  const redeemCode = urlParams.get("code");

  let qs = "";
  if (continueTo && continueTo.length > 0)
    qs = `?continueto=${encodeURIComponent(continueTo)}`;
  else if (redeemCode && redeemCode.length > 0)
    qs = `?continueto=${encodeURIComponent("/redeem?code=" + redeemCode)}`;

  return qs;
}

export function ContinueTo(): string | false {
  const urlParams = new URLSearchParams(window.location.search);
  const continueTo = decodeURIComponent(urlParams.get("continueto") || "");
  return continueTo.length > 0 ? continueTo : false;
}
