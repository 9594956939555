import { useState, useEffect, RefObject } from "react";

/**
 * Determines if the passed in ref element is within the viewport
 * @param ref Element being checked if it's in view
 * @param threshold Threshold percentage of visibility to determine if the element is "visible"
 * @returns
 */
export function useIsVisible(
  ref: RefObject<HTMLElement | null>,
  threshold: number
) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const options = {
      threshold: threshold,
    };
    if (ref.current) {
      const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        options
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, threshold]);

  return isIntersecting;
}
