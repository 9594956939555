// Components //
import Button from "./Button";

interface PageHeaderButtonProps {
  backgroundImage: string;
  title: string;
  titleColor?: string;
  buttonLabel: string;
  onButtonClick: () => void;
  isActive: boolean;
  buttonWidth?: string; // Must be a Tailwind class name
}

const PageHeaderButton = ({
  backgroundImage,
  title,
  titleColor = "text-white",
  buttonLabel,
  onButtonClick,
  buttonWidth,
}: PageHeaderButtonProps) => {
  const headerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div
      className="relative flex h-[65vh] flex-col items-center justify-center gap-5 px-5 py-12"
      style={headerStyle}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 z-[1] bg-black/35" />
      <h1
        className={"z-[2] text-center" + (titleColor ? " " + titleColor : "")}
      >
        {title}
      </h1>
      <Button
        id="get_started_btn"
        onClick={onButtonClick}
        btnType="green"
        className={"z-[2] !px-16" + (buttonWidth ? " " + buttonWidth : "")}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default PageHeaderButton;
