import mixpanel from "mixpanel-browser";
import { createContext } from "react";
import { Analytics } from "firebase/analytics";
import {
  ActiveEnvironmentConfig,
  EnvironmentConfig,
} from "../../config/Environment";
import TagManager from "react-gtm-module";
import { analytics } from "../../firebase";

export type Properties = {
  [key: string]: boolean | string | number;
};

export interface TruAnalytics {
  initialize(): void;
  track(eventName: string, attributes: Properties): void;
}

export class MixPanelAnalytics {
  environmentConfig: EnvironmentConfig;

  constructor(_environmentConfig: EnvironmentConfig) {
    this.environmentConfig = _environmentConfig;
    this.initialize();
  }

  initialize = () => {
    mixpanel.init(this.environmentConfig.analytics.mixpanel_token, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  };

  track = (eventName: string, attributes: Properties = {}) => {
    mixpanel.track(eventName, attributes);
  };
}

export class GA4Analytics implements TruAnalytics {
  environmentConfig: EnvironmentConfig;
  fireAnalytics: Analytics;

  constructor(targetEnvConfig: EnvironmentConfig) {
    this.environmentConfig = targetEnvConfig;

    this.fireAnalytics = analytics;

    this.initialize();
  }

  initialize = () => {
    const tagManagerArgs = {
      gtmId: this.environmentConfig?.analytics.gtmID as string,
    };

    TagManager.initialize(tagManagerArgs);
  };

  track(eventName: string, attributes: Properties): void {
    //TODO: Fixme
    //this.fireAnalytics.logEvent(eventName);
  }
}

export const MixPanelAnalyticsContext = createContext(
  new MixPanelAnalytics(ActiveEnvironmentConfig())
);

export const GA4AnalyticsContext = createContext(
  new GA4Analytics(ActiveEnvironmentConfig())
);

export default MixPanelAnalytics;
