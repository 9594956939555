import { useCallback, useEffect, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { toast } from "react-toastify";
import { PasswordValidator } from "../../../../config/UserInputValidator";
import { InlineErrorMessage } from "../../../notification/InlineErrorMessage/InlineErrorMessage";
import { Logout, navigateTo } from "../../../../config/UserAuthContext";
import { ErrorCodeMap } from "../../../../lib/Constants";
import eyeShowIcon from "../../../../global-assets/assets/icon-eye-show.svg";
import eyeHideIcon from "../../../../global-assets/assets/icon-eye-hide.svg";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth } from "../../../../firebase";
import { useSearchParams } from "react-router-dom";
import Button from "../../../common/Button";

interface FBSetNewPasswordProps {
  setAuth: (authenticated: boolean) => void;
  oobCode?: string;
}

export default function FBSetNewPassword({
  setAuth,
  oobCode = "",
}: FBSetNewPasswordProps) {
  const [params] = useSearchParams();
  const actionCode = params.get("oobCode") || oobCode;
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const [confirmPass, setConfirmPass] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [hasPasswordValidationError, setHasPasswordValidationError] =
    useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
  const [passwordValidError, setPasswordValidError] = useState<string | null>(
    null
  );
  const [newPasswordSubmitError, setNewPasswordSubmitError] = useState<
    string | null
  >(null);
  const [validateEmailError, setValidateEmailError] = useState<string | null>(
    null
  );
  const [isResettingPassword, setIsResettingPassword] =
    useState<boolean>(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState<boolean>(true);

  const ValidateRegistrationDetails = async () => {
    let hasError: boolean = false;
    if (!(password === confirmPass)) {
      setNewPasswordError("Passwords do not match.");
      hasError = true;
      setHasPasswordValidationError(true);
    }

    let passwordValidator = new PasswordValidator(password);
    if (!hasError && passwordValidator.ErrorMessage) {
      setPasswordValidError(passwordValidator.ErrorMessage);
      hasError = true;
      setHasPasswordValidationError(true);
    }

    return hasError;
  };

  const handlePasswordReset = async () => {
    setHasPasswordValidationError(false);
    setNewPasswordError(null);
    setPasswordValidError(null);
    setNewPasswordSubmitError(null);
    const hasError = await ValidateRegistrationDetails();
    if (hasError) {
      return;
    }

    setIsResettingPassword(true);

    // Save the new password.
    if (actionCode && password) {
      confirmPasswordReset(auth, actionCode, password)
        .then(() => {
          toast.success(
            "Password has been reset successfully. Navigating back to the Log In page.",
            {
              position: "top-center",
            }
          );
          Logout();
          setAuth(false);
          setTimeout(() => {
            setIsResettingPassword(false);
            navigateTo("/login");
          }, 4000);
        })
        .catch((error) => {
          // TODO: Update error handling once Firebase and Account Services are fully integrated
          const fbErrorCode = error.code;
          const fbErrorMessage = error.message;
          setIsResettingPassword(false);
          const errorMessage = ErrorCodeMap[fbErrorCode]
            ? ErrorCodeMap[fbErrorCode]
            : fbErrorMessage;
          setNewPasswordSubmitError(errorMessage);
        });
    }
  };

  const validateEmail = useCallback(() => {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setIsVerifyingEmail(false);
        setEmail(email);
      })
      .catch((error) => {
        setIsVerifyingEmail(false);
        // TODO: Update error handling once Firebase and Account Services are fully integrated
        const fbErrorCode = error.code;
        const fbErrorMessage = error.message;
        const errorMessage = ErrorCodeMap[fbErrorCode]
          ? ErrorCodeMap[fbErrorCode]
          : fbErrorMessage;
        setValidateEmailError(errorMessage);
      });
  }, [actionCode]);

  useEffect(() => {
    if (actionCode) {
      validateEmail();
    }
  }, [actionCode, validateEmail]);

  return (
    <div className="relative flex h-full min-h-[calc(100vh-272px)] items-center overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 md:pt-32 lg:px-12 lg:py-36">
      <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-60" />
      <div className="m-auto flex max-w-[1200px] justify-center">
        <div
          className="flex max-w-[600px] flex-col items-center gap-4 rounded-[30px] bg-white p-6 shadow-[0px_15px_10px_-15px_#38a6d5] md:p-8"
          id="login_details"
        >
          <h5 className="text-center font-display text-lg font-semibold capitalize text-indigo-500 sm:text-2xl md:text-3xl">
            Reset Your Password
          </h5>
          {email ? (
            <p className="!mb-0 text-center text-cool-gray-900">
              Resetting password for: <br />
              <span className="text-cerulean-500">{email}</span>
            </p>
          ) : (
            <p className="!mb-0">
              {isVerifyingEmail ? (
                <span className="text-cool-gray-900">Verifying email...</span>
              ) : (
                <span className="text-raspberry-500">Email not verified</span>
              )}
            </p>
          )}
          <p className="m-0 text-center !text-sm">
            Create a new password to login to the TruPlay platform across all
            devices. Changing this password may log you out of your devices.
          </p>
          <div className="flex w-full flex-col gap-4">
            <div
              id="password_parameters"
              className="relative mb-2 flex w-full flex-col gap-1"
            >
              <div className="relative flex w-full">
                <input
                  className={
                    "relative flex w-full rounded-t-lg border-0 bg-cool-gray-200" +
                    " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                    " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                    " rounded-b-0 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                    (hasPasswordValidationError
                      ? " ring-2 ring-raspberry-500"
                      : "")
                  }
                  value={password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  disabled={!email}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <div className="absolute right-1.5 top-4">
                  <div
                    className="flex items-center justify-center"
                    role="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {password ? (
                      showPassword ? (
                        <img
                          src={eyeShowIcon}
                          alt="Show Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      ) : (
                        <img
                          src={eyeHideIcon}
                          alt="Hide Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="relative flex w-full">
                <input
                  className={
                    "relative flex w-full rounded-b-lg border-0 bg-cool-gray-200" +
                    " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                    " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                    " rounded-t-0 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                    (hasPasswordValidationError
                      ? " ring-2 ring-raspberry-500"
                      : "")
                  }
                  value={confirmPass}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setConfirmPass(e.target.value);
                  }}
                  disabled={!email}
                />
                <div className="absolute right-1.5 top-4">
                  <div
                    className="flex items-center justify-center"
                    role="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {confirmPass ? (
                      showConfirmPassword ? (
                        <img
                          src={eyeShowIcon}
                          alt="Show Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      ) : (
                        <img
                          src={eyeHideIcon}
                          alt="Hide Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {!passwordValidError && (
                <label className="w-full text-sm font-normal text-cool-gray-800">
                  Password requires: 8-16 characters, 1 uppercase letter, 1
                  lowercase letter, and 1 number.
                </label>
              )}
              {passwordValidError && (
                <InlineErrorMessage errorMessage={passwordValidError} />
              )}
              {newPasswordError && (
                <InlineErrorMessage errorMessage={newPasswordError} />
              )}
              {newPasswordSubmitError && (
                <InlineErrorMessage errorMessage={newPasswordSubmitError} />
              )}
              {validateEmailError && (
                <InlineErrorMessage errorMessage={validateEmailError} />
              )}
            </div>
          </div>
          <Button
            id="change_password_btn"
            loading={isResettingPassword}
            disabled={password.length && confirmPass.length ? false : true}
            onClick={handlePasswordReset}
            btnType="green"
          >
            Change Password
          </Button>
        </div>
      </div>
    </div>
  );
}
