import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AxiosInstance } from "axios";
import AccountServiceClient from "../../lib/ApiFunction";
import { ErrorCodeMap } from "../../lib/Constants";

interface StripeRedirectConfig {
  successUrl: string;
  endpoint: AxiosInstance;
}

interface GenerateImmediateStripeRedirectProps {
  configName: string;
}

const GenerateImmediateStripeRedirect = ({
  configName,
}: GenerateImmediateStripeRedirectProps) => {
  const { GenerateStripeGiftLink, StripeCheckout } = AccountServiceClient();

  const loc = useLocation();
  const urlParams = new URLSearchParams(loc.search);
  const priceId = urlParams.get("priceid");
  const [haveSentAPIRequest, setHaveSentAPIRequest] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [displayError, setDisplayError] = useState(false);
  const redirectConfigs = new Map<string, StripeRedirectConfig>();

  redirectConfigs.set("pk_gifting", {
    successUrl: `${window.location.origin}/gift-success`,
    endpoint: GenerateStripeGiftLink,
  } as StripeRedirectConfig);

  redirectConfigs.set("pk_annual_purchase", {
    successUrl: `${window.location.origin}/gift-success`,
    endpoint: StripeCheckout,
  } as StripeRedirectConfig);

  redirectConfigs.set("default", {
    successUrl: `${window.location.origin}/gift-success`,
    endpoint: StripeCheckout,
  } as StripeRedirectConfig);

  const successUrl =
    redirectConfigs.get(configName)?.successUrl ||
    redirectConfigs.get("default")?.successUrl;

  const endpoint = redirectConfigs.get(configName)?.endpoint || StripeCheckout;
  const cancelUrl = decodeURIComponent(urlParams.get("cancelurl") || "");
  let referralId: string = "none";
  const via = decodeURIComponent(urlParams.get("via") || "");
  const isRequestSent = useRef(false);

  const DEFAULT_ERROR = "Error contacting payment site.";

  const getErrorMessageForCode = (code: string) => {
    if (ErrorCodeMap[code]) {
      return ErrorCodeMap[code];
    }
    return DEFAULT_ERROR;
  };

  useEffect(() => {
    function waitForRewardful() {
      const referral = (window as any).Rewardful?.referral;
      if (typeof referral === "string") {
        referralId = referral;
      }
      if (referralId === "none") {
        window.setTimeout(waitForRewardful, 100);
      } else {
        sendRequest();
      }
    }
    if (via.length > 0 && !isRequestSent.current) {
      waitForRewardful();
    }
  }, [via]);

  /**
   * Catch all if something fails
   * This will most likely hit when a user enters this page manually w/o the rewardful query string
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!haveSentAPIRequest) {
        setDisplayError(true);
      }
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  function sendRequest() {
    const data = {
      priceId,
      successUrl,
      cancelUrl,
      referralId,
    };

    if (!haveSentAPIRequest) {
      setHaveSentAPIRequest(true);
      isRequestSent.current = true;
      endpoint
        .post("", data)
        .then((success: any) => {
          window.location.href = success.data.checkoutUrl;
        })
        .catch((error: any) => {
          try {
            if (error.response.status === 401)
              setErrorMsg("Please login again.");
            else if (error.response.data.statusCode)
              setErrorMsg(
                getErrorMessageForCode(error.response.data.statusCode)
              );
            else setErrorMsg(DEFAULT_ERROR);
          } catch {
            setErrorMsg(DEFAULT_ERROR);
          }
        });
    }
  }

  return (
    <div className="relative overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_100%,_#68e0cf_0%,_#1f9cff_15%,transparent_50%)] opacity-10" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_0%,_#652c80_0%,transparent_100%)] opacity-30" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_100%,_#20568b_0%,_#002952_35%,transparent_50%)] opacity-40" />
      <div className="mx-auto flex min-h-screen max-w-[1440px] flex-col items-center justify-center px-6 lg:px-0">
        <div className="flex flex-col items-center gap-12 text-center">
          {errorMsg && (
            <>
              <h2 className="text-center font-display text-5xl text-white">
                Oops!
              </h2>
              <p className="max-w-[600px] text-2xl text-white">{errorMsg}</p>
            </>
          )}
          {displayError && (
            <>
              <h2 className="text-center font-display text-5xl text-white">
                Oops!
              </h2>
              <p className="m-0 max-w-[650px] text-2xl text-white">
                Failed to load the gift checkout page.
                <span className="hidden lg:block">
                  Please try one of these steps below, then refresh the page.
                </span>
                <span className="lg:hidden">
                  &nbsp;Please try one of these steps below, then refresh the
                  page.
                </span>
              </p>
              <ul className="list-disc text-left text-white">
                <li className="lg:ml-5">
                  <p className="m-0 text-2xl text-white">
                    Unblock all cookies.
                  </p>
                </li>
                <li className="lg:ml-5">
                  <p className="m-0 text-2xl text-white">
                    Disable your ad blocker.
                  </p>
                </li>
                <li className="lg:ml-5">
                  <p className="m-0 text-2xl text-white">
                    Still stuck? It might be time to try a different browser.
                  </p>
                </li>
              </ul>
            </>
          )}
          {!errorMsg && !displayError && (
            <div className="flex items-center justify-center text-4xl text-white lg:text-6xl">
              Redirecting...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default GenerateImmediateStripeRedirect;
