import { useEffect, useState } from "react";
import UserAuthContext, {
  GetEmailAddress,
  GetOrCreateSessionAuthContext,
} from "../../../config/UserAuthContext";
import { useRemoteConfig } from "../../../config/FBRemoteConfigContext";
import AccountServiceClient from "../../../lib/ApiFunction";
import { toast } from "react-toastify";

// Components
import { AccountPasswordInfo } from "../AccountPasswordInfo/AccountPasswordInfo";
import VerifyEmailModal from "../../auth/AuthModals/VerifyEmailModal/VerifyEmailModal";
import Button from "../../common/Button";

// Assets
import WarningIcon from "../../../global-assets/assets/icon-warning-outline.svg";
import InfoIcon from "../../../global-assets/assets/icon-info-outline.svg";
import ChevronRight from "../../../global-assets/assets/chevron-right.svg";

// Firebase
import { auth } from "../../../firebase";
import { ActionCodeSettings, sendEmailVerification } from "firebase/auth";
import { ErrorCodeMap } from "../../../lib/Constants";

interface AccountInfoProps {
  setAuth: any;
}

export default function AccountInfo({ setAuth }: AccountInfoProps) {
  const firebaseFlags = useRemoteConfig();
  const [currentUser, setCurrentUser] = useState<UserAuthContext>();
  const [verifyOpen, setVerifyOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { ResendOTPForVerification } = AccountServiceClient();
  const EmailAddress: String = GetEmailAddress() || "";
  const { OptAPIout, OptAPIin, OptAPIstatus } = AccountServiceClient();
  const [verifyEmailLoading, setVerifyEmailLoading] = useState<boolean>(false);
  const [emailVerificationError, setEmailVerificationError] = useState<
    string | null
  >(null);
  const [isButtonActive, setIsButtonActive] = useState<boolean>(true);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);

  const fetchCommunicationsOptInStatus = async () => {
    try {
      const response = await OptAPIstatus.get("", {
        headers: { Authorization: `Bearer ${userContext.token}` },
      });

      const communicationsOptIn = response.data.communicationsOptIn;
      setIsChecked(communicationsOptIn);
    } catch (error) {
      console.error("Error fetching communications opt-in status:", error);
    }
  };

  const handleCheckboxChange = async (e: any) => {
    const isChecked = e.target.checked;

    try {
      if (isChecked) {
        setIsChecked(true);
        await OptAPIin.post("", {
          headers: { Authorization: `Bearer ${userContext.token}` },
        });

        toast.success("You have opted in to newsletters and promotions.", {
          position: "top-center",
        });
      } else {
        setIsChecked(false);
        await OptAPIout.post("", null, {
          headers: { Authorization: `Bearer ${userContext.token}` },
        });

        toast.error("You have opted out of newsletters and promotions.", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("API call error:", error);
      toast.error("An error occurred while updating your preferences.", {
        position: "top-center",
      });
    }
  };

  const userContext = GetOrCreateSessionAuthContext();
  const getUserContextFromCookie = () => {
    setCurrentUser(userContext);
  };

  const handleClose = () => {
    setVerifyOpen(false);
  };

  useEffect(() => {
    getUserContextFromCookie();
    fetchCommunicationsOptInStatus();
    // Missing deps would cause unnecessary rerenders, so they are not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openVerifyEmail = (e: any) => {
    e.preventDefault();

    if (userContext) {
      let data = {
        emailAddress: EmailAddress,
      };

      ResendOTPForVerification.post("", data, {
        headers: { Authorization: `Bearer ${userContext.token}` },
      }).then(() => {
        setVerifyOpen(true);
        toast.success(
          "A secure code has been sent to your registered email address",
          {
            position: "top-center",
          }
        );
      });
    }
  };

  const handleVerifyEmail = () => {
    const user = auth.currentUser;
    let continueUrlVar = "www-dev";

    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "stg":
        continueUrlVar = "www-stg";
        break;
      case "prod":
        continueUrlVar = "www";
        break;
    }

    const actionCodeSettings: ActionCodeSettings = {
      url: `https://${continueUrlVar}.truplaygames.com/account-settings`,
      handleCodeInApp: false,
    };

    if (user) {
      setVerifyEmailLoading(true);
      setIsButtonActive(false);
      sendEmailVerification(user, actionCodeSettings)
        .then(() => {
          setVerifyEmailLoading(false);
          setSuccessMessage(true);
          toast.success(
            "A secure code has been sent to your registered email address",
            {
              position: "top-center",
            }
          );
        })
        .catch((error) => {
          const fbErrorCode = error.code;
          const fbErrorMessage = error.message;
          const errorMessage = ErrorCodeMap[fbErrorCode]
            ? ErrorCodeMap[fbErrorCode]
            : fbErrorMessage;
          setEmailVerificationError(errorMessage);
          setVerifyEmailLoading(false);
          setIsButtonActive(true);
          toast.error(errorMessage, {
            position: "top-center",
          });
        });
    }
  };

  const handleFirebaseAuth = (e: Event) => {
    if (firebaseFlags.firebaseAuth) {
      handleVerifyEmail();
    } else {
      openVerifyEmail(e);
    }
  };

  return (
    <>
      <div>
        <div
          className={
            "tru-accinfo-container-bg rounded-3xl border-[3px]" +
            " border-solid border-x-[rgba(32,86,139,0.7)]" +
            " bg-gradient_acc_info border-t-[rgba(32,86,139,0.2)] p-4" +
            " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8" +
            (!currentUser?.sso
              ? " rounded-b-none border-b-0"
              : " border-b-[rgba(32,86,139,1)]")
          }
        >
          <h2 className="mb-8 text-2xl tracking-wide text-white sm:text-4xl">
            Account
          </h2>

          {!currentUser?.verifyEmail.isVerified && (
            <div className="mb-8 flex items-center justify-start rounded-xl bg-tangerine-500 p-4 shadow-lg">
              <img src={WarningIcon} alt="Warning" className="mr-4 w-10" />
              <div className="font-body text-base font-medium text-white">
                <b>Your email has not been verified.</b> In order to receive
                important notifications and information about your account
                please select “Verify Email” button below.
              </div>
            </div>
          )}

          {successMessage && (
            <div className="-mt-4 mb-8 flex items-center justify-start rounded-xl bg-cerulean-500 p-4 shadow-lg">
              <img src={InfoIcon} alt="Info" className="mr-4 w-10" />
              <div className="font-body text-base font-medium text-white">
                <b>An email has been sent.</b> If you need to request another,
                please wait 5 minutes, refresh the page, and try again.
              </div>
            </div>
          )}

          <div className="flex flex-col justify-between font-body text-base md:flex-row md:items-center md:text-2xl">
            <div>
              <div className="mb-4 text-lg font-semibold text-white md:text-2xl">
                Email Address
              </div>
              <div>
                <div className="mb-4 flex flex-row flex-wrap font-normal text-[#ffffff4d]">
                  <div className="mr-2 flex flex-row flex-wrap">
                    <div>
                      {EmailAddress.substring(0, EmailAddress.indexOf("@"))}
                    </div>
                    <div>
                      {EmailAddress.substring(
                        EmailAddress.indexOf("@"),
                        EmailAddress.length
                      )}
                    </div>
                  </div>
                  {!currentUser?.verifyEmail.isVerified && (
                    <div className="font-bold text-raspberry-500">
                      (Not Verified)
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <input
                  id="newsletter_checkbox"
                  // TODO: Convert checkbox styles to Tailwind if possible
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  checked={isChecked}
                />
                <label
                  style={{ color: isChecked ? "#ffff" : "#ffffff80" }}
                  className="ml-4 text-base"
                >
                  Send me Newsletters and Promotions
                </label>
              </div>
            </div>
            <div className="mt-4 w-full self-baseline md:mt-0 md:w-[unset]">
              {currentUser?.verifyEmail.isVerified === false && (
                <Button
                  id="open_verify_popup_btn"
                  onClick={(e: Event) => handleFirebaseAuth(e)}
                  btnType="green"
                  className="w-full md:w-[265px]"
                  rightIcon={
                    <img
                      src={ChevronRight}
                      alt="Right Arrow"
                      className="ml-4 h-5 w-5 sm:h-6 sm:w-6"
                    />
                  }
                  loading={verifyEmailLoading}
                  disabled={verifyEmailLoading || !isButtonActive}
                >
                  Verify Email
                </Button>
              )}
            </div>
          </div>
        </div>

        {!currentUser?.sso && <AccountPasswordInfo setAuth={setAuth} />}
      </div>

      {verifyOpen && (
        <VerifyEmailModal handleClose={handleClose} open={verifyOpen} />
      )}
    </>
  );
}
