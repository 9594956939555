import { Link } from "react-router-dom";
import { MarketingContactEmails } from "../../../lib/MarketingData";

// Social Icons //
import discordIcon from "../../../global-assets/assets/icon-discord.svg";
import instagramIcon from "../../../global-assets/assets/icon-instagram.svg";
import facebookIcon from "../../../global-assets/assets/icon-facebook.svg";
import twitterIcon from "../../../global-assets/assets/icon-twitter.svg";
import youTubeIcon from "../../../global-assets/assets/icon-youtube.svg";
import linkedInIcon from "../../../global-assets/assets/icon-linkedin.svg";

const socialIcons = [
  {
    href: "https://discord.com/invite/truplay",
    iconSrc: discordIcon,
    alt: "Discord",
  },
  {
    href: "https://www.instagram.com/truplaygames/",
    iconSrc: instagramIcon,
    alt: "Instagram",
  },
  {
    href: "https://www.facebook.com/truplaygames/",
    iconSrc: facebookIcon,
    alt: "Facebook",
  },
  {
    href: "http://www.twitter.com/TruPlayGames",
    iconSrc: twitterIcon,
    alt: "Twitter",
  },
  {
    href: "https://www.youtube.com/channel/UCutwcoaalmdoIoxaZ2coQhA",
    iconSrc: youTubeIcon,
    alt: "YouTube",
  },
  {
    href: "https://www.linkedin.com/company/truplay/",
    iconSrc: linkedInIcon,
    alt: "LinkedIn",
  },
];

export default function SocialLinks() {
  return (
    <div className="relative flex flex-col justify-end overflow-visible px-5 py-32 pt-16 sm:px-6 md:px-7 md:py-40 md:pt-20 lg:px-12 lg:py-48 lg:pt-24">
      <div className="mx-auto max-w-[1440px]">
        <div className="flex flex-col justify-center gap-5">
          <div className="flex max-w-[820px] flex-col items-center justify-start gap-4 text-center">
            <h2 className="text-cerulean-500">Join Our Community</h2>
            <div className="max-w-[1040px]">
              <p className="m-0">
                Get the latest scoop and connect with our people, our mission,
                and our characters.
                <br />
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-5 gap-x-6">
            {socialIcons.map((social) => (
              <Link
                key={social.alt}
                id={`social_media_${social.alt.toLowerCase()}`}
                to={social.href}
                target="_blank"
                className="w-full max-w-16 rounded-full"
                rel="noopener noreferrer"
              >
                <div>
                  <img
                    src={social.iconSrc}
                    loading="eager"
                    alt={social.alt}
                    className="rounded-full shadow-[0_0_20px_rgba(0,0,0,0.1)] duration-200 ease-in-out hover:scale-125 hover:shadow-[0_0_20px_rgba(56,166,213,0.5)]"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] overflow-hidden bg-white">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[linear-gradient(#fff,rgba(255,255,255,0.7))]" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2]">
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(#fff,rgba(255,255,255,0))]" />
          <img
            src={MarketingContactEmails.images.teambg}
            loading="lazy"
            sizes="100vw"
            alt=""
            className="w-full"
          />
        </div>
      </div>
      <img
        src={MarketingContactEmails.images.curveblue}
        loading="lazy"
        alt=""
        className="absolute bottom-0 left-0 right-0 top-auto z-[5] -mb-[1px] w-full max-w-none"
      />
    </div>
  );
}
