type GiftingSuccessCardProps = {
  step: string;
  title1: string;
  title2: string;
  description: string;
};

export default function GiftingSuccessStepCard({
  step,
  title1,
  title2,
  description,
}: GiftingSuccessCardProps) {
  return (
    <div className="flex w-[20rem] flex-col gap-4 self-stretch rounded-[30px] bg-white/[6%] p-8 shadow-[0px_0px_42px_9px_rgb(0,27,54)]">
      <div className="flex items-center gap-4">
        <h1 className="text-transparent inline-block bg-gradient_text bg-clip-text font-display text-8xl">
          {step}
        </h1>
        <h2 className="text-left font-display text-3xl text-white">
          {title1}
          <br />
          {title2}
        </h2>
      </div>
      <p className="m-0 text-left font-body text-xl font-light text-indigo-200">
        {description}
      </p>
    </div>
  );
}
