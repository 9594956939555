import { useEffect, useState, useContext } from "react";
import TruPlayLogo from "../../global-assets/assets/truplay-logo-large.png";
import GiftingSuccessStepCard from "../../components/gift-success/GiftingSuccessStepCard/GiftingSuccessStepCard";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import {
  GetOrCreateSessionAuthContext,
  UserAuthContext,
} from "../../config/UserAuthContext";
import {
  MixPanelAnalyticsContext,
  Properties,
} from "../../components/analytics/Analytics";
import {
  Context,
  isAuthenticatedWithValidToken,
  SecureCookieAttributes,
  SetAuthTokenAsCookies,
} from "../../config/UserAuthContext";

import AccountServiceClient from "../../lib/ApiFunction";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

/**
 * Subscription Complete Landing Page
 * @constructor
 */

const GiftPurchaseSuccess = () => {
  const location = useLocation();
  const osType = navigator.userAgent;
  const initialUserContext = GetOrCreateSessionAuthContext();
  const [userContext, setUserContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const { SubscriptionAPI } = AccountServiceClient();

  const getSubscription = async () => {
    if (!isAuthenticatedWithValidToken()) return;

    if (!userContext?.subscription.isActive) {
      try {
        const res = await SubscriptionAPI.get("");
        const updatedContext = new UserAuthContext();
        updatedContext.subscription = res.data;
        updatedContext.communicationsOptIn = userContext.communicationsOptIn;
        updatedContext.token = userContext.token;
        updatedContext.uuId = userContext.uuId;
        updatedContext.verifyEmail = userContext.verifyEmail;
        updatedContext.sso = userContext.sso;

        setUserContext(updatedContext);
        SetAuthTokenAsCookies(
          JSON.stringify(updatedContext),
          Cookies.get("EmailAddress") || ""
        );

        if (res.data.isActive) {
          Cookies.set(
            Context.UserContext,
            JSON.stringify(updatedContext),
            SecureCookieAttributes
          );

          toast.success(
            <div>
              <b>Success!</b> Your payment has been received and your account
              has been created successfully.
            </div>,
            {
              position: "top-center",
            }
          );
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    }
  };

  useEffect(() => {
    getSubscription();

    let attribution = localStorage.getItem("tru-attribution") as string;
    let campaignAttribution: Properties = {};
    if (attribution) {
      campaignAttribution = JSON.parse(attribution) as Properties;
    }
    analyticsContext.track("Web: Gift-Success-Page-View", campaignAttribution);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");
    if (sessionId) {
      // User came from Stripe;
      TagManager.dataLayer({
        dataLayer: {
          event: "subscription_success",
          page_name: "accounts: subscription success",
          page_type: "accounts",
          os_type: osType,
          plan_type: "GiftAnnual",
          subscription_type: "paid",
          custom_user_id: GlobalUserId || undefined,
        },
      });
      sessionStorage.setItem("hasSentSubscriptionSuccess", "true");
    } else {
      // User did not come from Stripe
      TagManager.dataLayer({
        dataLayer: {
          page_name: "accounts: subscription success",
          page_type: "accounts",
          os_type: osType,
          plan_type: userContext.subscription.sources[0]?.productId ?? "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
    }
  }, [location.search, osType, userContext, GlobalUserId]);

  return (
    <div id="text_gift_success_page" className="relative overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_100%,_#68e0cf_0%,_#1f9cff_15%,transparent_50%)] opacity-10" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_0%,_#652c80_0%,transparent_100%)] opacity-30" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_100%,_#20568b_0%,_#002952_35%,transparent_50%)] opacity-40" />
      <div className="mx-auto min-h-screen max-w-[900px] px-6 pb-12 pt-24 lg:px-0 lg:pt-32">
        <div className="flex flex-col items-center gap-16 text-center">
          <div className="flex flex-col items-center gap-8">
            <h1 className="text-transparent bg-gradient_text bg-clip-text text-center font-display text-4xl font-bold lg:text-5xl 2xl:max-w-[400px]">
              Welcome to
            </h1>
            <img
              src={TruPlayLogo}
              alt="Truplay-logo"
              className="w-[70%] lg:w-[60%]"
            />
            <div className="text-center font-body text-base font-normal text-white md:text-2xl lg:max-w-[700px] lg:text-[2rem] lg:leading-[3rem]">
              Thanks for helping us continue to spread God's truth through
              entertainment!
            </div>
          </div>
          <div className="flex flex-col items-center justify-stretch gap-8 text-center lg:flex-row">
            <GiftingSuccessStepCard
              step="1"
              title1="Check"
              title2="Your Email"
              description="You should have received an email with a promo code and instructions"
            />
            <GiftingSuccessStepCard
              step="2"
              title1="Forward"
              title2="Instructions"
              description="Forward that email to your loved one and tell them a bit about the gift!"
            />
            <GiftingSuccessStepCard
              step="3"
              title1="Redeem"
              title2="Code"
              description="Make sure your loved one redeems the promo code on our website and logs into one of our apps"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftPurchaseSuccess;
