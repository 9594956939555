import { createContext } from "react";
import { ServiceUrl } from "../services/ServiceUrl";

export enum SubscriptionPeriod {
  Annual = "annual",
  AnnualTest = "annual-test",
  AnnualDiscounted8999 = "annual-discounted-8999",
  AnnualDiscounted6999 = "annual-discounted-6999",
  Monthly = "monthly",
  MonthlyDiscounted = "monthly-discounted",
  Holiday = "annual-holiday",
  MonthlyHoliday = "monthly-holiday",
  GiftAnnual = "gift-annual",
  GiftHoliday = "gift-holiday",
  SixMonth = "six-month",
}

/**
 * Supports configuration for both Google Analytics and Mixpanel
 */
export class AnalyticsConfig {
  // GA4 Config
  enabled: boolean = false;
  gtmID: string = "";
  measurementID: string = "";
  // Mixpanel Config
  mixpanel_token: string = "";

  constructor(
    _enabled: boolean,
    _gtmID: string = "",
    _measurementID: string = "",
    _mixpanel_token: string = ""
  ) {
    this.enabled = _enabled;
    this.gtmID = _gtmID;
    this.measurementID = _measurementID;
    this.mixpanel_token = _mixpanel_token;
  }
}

export class ProductConfiguration {
  subscriptionProductId: Map<string, string | null | undefined>;
  subscriptionProductPrice: Map<string, number | null | undefined>;
  giftingProductId: Map<string, string | null | undefined>;
  giftingProductPrice: Map<string, number | null | undefined>;
  storeUrl: Map<string, string | null | undefined>;
  giftingSuccessRedirectUrl: string = "";

  constructor(
    _productIds: Map<string, string | null | undefined>,
    _priceIds: Map<string, number | null | undefined>,
    _giftingProductIds: Map<string, string | null | undefined>,
    _giftingPriceIds: Map<string, number | null | undefined>,
    _storeUrls: Map<string, string | null | undefined>,
    _giftingSuccessUrl: string = ""
  ) {
    this.subscriptionProductId = _productIds;
    this.subscriptionProductPrice = _priceIds;
    this.giftingProductId = _giftingProductIds;
    this.giftingProductPrice = _giftingPriceIds;
    this.storeUrl = _storeUrls;
    this.giftingSuccessRedirectUrl = _giftingSuccessUrl;
  }
}

interface CouponCode {
  holidayPromoCode: string | null | undefined;
  freeTrialPromoCode: string | null | undefined;
}

export class EnvironmentConfig {
  // Name of the deployment environment
  name: string = "";
  // Product configuration
  productConfig: ProductConfiguration;
  // Analytics
  analytics: AnalyticsConfig = new AnalyticsConfig(false);
  // Api dependencies
  accountsUrl: ServiceUrl = new ServiceUrl();
  featuresUrl: ServiceUrl = new ServiceUrl();
  contentUrl: ServiceUrl = new ServiceUrl();
  couponCodes: CouponCode;

  constructor(
    _name: string = "DEV",
    _productConfiguration: ProductConfiguration,
    _analytics: AnalyticsConfig = new AnalyticsConfig(false),
    _accountUrl: ServiceUrl,
    _featureUrl: ServiceUrl,
    _couponCodes: CouponCode
  ) {
    this.name = _name;
    this.productConfig = _productConfiguration;
    this.analytics = _analytics;
    this.accountsUrl = _accountUrl;
    this.featuresUrl = _featureUrl;
    this.couponCodes = _couponCodes;
  }

  AppStoreURL = (): string => {
    return this.productConfig.storeUrl.get("appleStoreUrl") as string;
  };

  PlayStoreURL = (): string => {
    return this.productConfig.storeUrl.get("googleStoreUrl") as string;
  };
}

const environment: string = process.env.REACT_APP_ENVIRONMENT || "local";

export const envConfig = new EnvironmentConfig(
  environment,
  new ProductConfiguration(
    new Map<string, string | null | undefined>([
      [
        SubscriptionPeriod.Annual,
        process.env.REACT_APP_DEFAULT_ANNUAL_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.AnnualTest,
        process.env.REACT_APP_ANNUAL_TEST_PRICE_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.Monthly,
        process.env.REACT_APP_DEFAULT_MONTHLY_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.SixMonth,
        process.env.REACT_APP_DEFAULT_SIX_MONTH_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.AnnualDiscounted8999,
        process.env.REACT_APP_ANNUAL_DISCOUNTED_8999_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.AnnualDiscounted6999,
        process.env.REACT_APP_ANNUAL_DISCOUNTED_6999_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.MonthlyDiscounted,
        process.env.REACT_APP_MONTHLY_DISCOUNTED_999_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.Holiday,
        process.env.REACT_APP_ANNUAL_HOLIDAY_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.MonthlyHoliday,
        process.env.REACT_APP_MONTHLY_HOLIDAY_STRIPE_ID,
      ],
    ]),
    new Map<string, number | null | undefined>([
      [
        SubscriptionPeriod.Annual,
        Number(process.env.REACT_APP_DEFAULT_ANNUAL_PRICE),
      ],
      [
        SubscriptionPeriod.AnnualTest,
        Number(process.env.REACT_APP_ANNUAL_TEST_PRICE),
      ],
      [
        SubscriptionPeriod.Monthly,
        Number(process.env.REACT_APP_DEFAULT_MONTHLY_PRICE),
      ],
      [
        SubscriptionPeriod.SixMonth,
        Number(process.env.REACT_APP_DEFAULT_SIX_MONTH),
      ],
      [
        SubscriptionPeriod.AnnualDiscounted8999,
        Number(process.env.REACT_APP_ANNUAL_DISCOUNTED_8999),
      ],
      [
        SubscriptionPeriod.AnnualDiscounted6999,
        Number(process.env.REACT_APP_ANNUAL_DISCOUNTED_6999),
      ],
      [
        SubscriptionPeriod.MonthlyDiscounted,
        Number(process.env.REACT_APP_MONTHLY_DISCOUNTED_999),
      ],
      [
        SubscriptionPeriod.Holiday,
        Number(process.env.REACT_APP_ANNUAL_MONTHLY_HOLIDAY_PRICE),
      ],
      [
        SubscriptionPeriod.MonthlyHoliday,
        Number(process.env.REACT_APP_MONTHLY_HOLIDAY_PRICE),
      ],
    ]),
    new Map<string, string | null | undefined>([
      [
        SubscriptionPeriod.GiftAnnual,
        process.env.REACT_APP_DEFAULT_GIFT_STRIPE_ID,
      ],
      [
        SubscriptionPeriod.GiftHoliday,
        process.env.REACT_APP_GIFT_HOLIDAY_STRIPE_ID,
      ],
    ]),
    new Map<string, number | null | undefined>([
      [
        SubscriptionPeriod.GiftAnnual,
        Number(process.env.REACT_APP_DEFAULT_GIFT_PRICE),
      ],
      [
        SubscriptionPeriod.GiftHoliday,
        Number(process.env.REACT_APP_GIFT_HOLIDAY_PRICE),
      ],
    ]),
    new Map([
      ["googleStoreUrl", process.env.REACT_APP_GOOGLE_STORE_URL],
      ["appleStoreUrl", process.env.REACT_APP_APPLE_STORE_URL],
    ]),
    process.env.REACT_APP_GIFT_SUCCESS_URL
  ),
  new AnalyticsConfig(
    true,
    process.env.REACT_APP_GTM_ID,
    process.env.REACT_APP_MEASUREMENT_ID,
    process.env.REACT_APP_MIXPANEL_TOKEN
  ),
  new ServiceUrl(process.env.REACT_APP_ACCOUNTS_SERVICE_URL),
  new ServiceUrl(
    process.env.REACT_APP_CONFIGURATION_SERVICE_URL,
    process.env.REACT_APP_CONFIGURATION_CONTEXT_PATH
  ),
  {
    holidayPromoCode: process.env.REACT_APP_HOLIDAY_PROMO_CODE,
    freeTrialPromoCode: process.env.REACT_APP_FREE_TRIAL_PROMO_CODE,
  }
);

export const EnvironmentConfigContext = createContext(envConfig);

export function ActiveEnvironmentConfig(): EnvironmentConfig {
  return envConfig as EnvironmentConfig;
}
