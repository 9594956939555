import ReviewStar from "./assets/yellow-review-star.png";
import ProfileIcon from "./assets/icon-profile-blue.svg";

interface IReview {
  [key: string]: string;
}

interface IYesLadderReviewProps {
  reviewInfo: IReview;
}

export default function YesLadderReview({ reviewInfo }: IYesLadderReviewProps) {
  function reviewStars() {
    const stars: any[] = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <img key={i} alt="star" src={ReviewStar} className="h-9 w-9" />
      );
    }
    return stars;
  }

  return (
    <div className="flex flex-1 flex-col gap-4 rounded-xl bg-cerulean-100 p-4 regmd:p-6">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-3">
          <img
            src={ProfileIcon}
            alt="Profile"
            loading="eager"
            className="h-[3.5rem] w-[3.5rem] regmd:h-16 regmd:w-16"
          />
          <h5 className="text-cool-gray-900">{reviewInfo.reviewer}</h5>
        </div>
        <div className="flex gap-1">{reviewStars()}</div>
      </div>
      <h5 className="text-cool-gray-900">{reviewInfo.title}</h5>
      <p className="font-bold leading-normal text-cool-gray-900">
        {reviewInfo.content}
      </p>
    </div>
  );
}
