import FreeContentHero from "../../components/free-content/FreeContentHero";
import FreeContentVideos from "../../components/free-content/FreeContentVideos";
import FreeContentBOIPages from "../../components/free-content/FreeContentBOIPages";

export default function FreeContent() {
  return (
    <div id="test_data_gamesFreeContent_page" className="relative">
      <div className="tru-free-content-purple-bg  absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-50" />
      <div className="tru-free-content-green-bg  absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-30" />
      <div className="tru-free-content-blue-bg  absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-30" />
      <div className="tru-free-content-blue-bg2  absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-50" />
      <FreeContentHero />
      <FreeContentVideos />
      <FreeContentBOIPages />
    </div>
  );
}
