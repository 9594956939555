// Resource: https://codesandbox.io/s/typescript-react-hooks-youtube-iframe-video-player-xpyqk?file=/src/components/YoutubeIframePlayer/index.tsx:1428-1698

const YOUTUBE_PUBLIC_URL = "https://www.youtube.com/embed/";

interface IYouTubeEmbedProps {
  videoId: string;
  title?: string;
  className?: string;
  privacyMode?: boolean;
  width?: string;
  height?: string;
  start?: number;
  controls?: boolean;
  disableFullscreen?: boolean;
  autoplay?: boolean;
  removeMoreVideos?: boolean;
  playsInline?: boolean;
  showInfo?: boolean;
}

export default function YouTubeEmbed({
  videoId,
  title,
  className,
  privacyMode = false,
  width = "100%",
  height = "100%",
  start = 0,
  controls = true,
  disableFullscreen = false,
  autoplay = false,
  removeMoreVideos = true,
  playsInline = false,
  showInfo = true,
}: IYouTubeEmbedProps) {
  const playerControls: string[] = [];
  if (autoplay) {
    playerControls.push("autoplay=1");
  }
  if (!controls) {
    playerControls.push("controls=0");
  }
  if (disableFullscreen) {
    playerControls.push("fs=0");
  }
  if (start > 0) {
    playerControls.push(`start=${start}`);
  }
  // Removes "More videos" on pause and at the end of the video
  if (removeMoreVideos) {
    playerControls.push("rel=0");
  }
  if (playsInline) {
    playerControls.push("playsInline=1");
  }
  if (!showInfo) {
    playerControls.push("showinfo=0");
  }
  const playerControlsStr =
    playerControls.length > 0 ? `?${playerControls.join("&")}` : "";

  const iFrameProps = {
    className,
    src: `${YOUTUBE_PUBLIC_URL}${videoId}${playerControlsStr}`,
    frameBorder: "0",
    allow:
      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
    width,
    height,
  };

  return <iframe title={title || "YouTube Embedded Video"} {...iFrameProps} />;
}
