import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

// Components //
import ShareButton from "./ShareButton/ShareButton";
import YouTubeEmbed from "./YouTubeEmbed";
import { scrollToVideo } from "../../lib/HelperFunctions";

interface IVideoProps {
  id?: string;
  videoId?: string | number; // This prop is required for scrollToVideo functionality
  url: string;
  controls?: boolean;
  thumbnail?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  width?: string | number;
  height?: string | number;
  shareUrl?: string;
  iPadView?: boolean;
  borderColor?: string;
  shareSmall?: boolean;
  title?: string;
  description?: string;
  download?: string;
  disableRemotePlayback?: boolean;
  customPlayBtn?: string | null;
  noShadow?: boolean;
  embedded?: string;
}

export default function Video({
  id,
  videoId,
  url,
  controls = true,
  thumbnail,
  autoPlay = false,
  loop = false,
  muted = false,
  playsInline = false,
  width = "100%",
  height = "100%",
  shareUrl,
  iPadView = false,
  borderColor = "bg-[#ffffff0f]",
  shareSmall = false,
  title,
  description,
  download = "nodownload",
  disableRemotePlayback = false,
  customPlayBtn,
  noShadow = false,
  embedded,
}: IVideoProps) {
  const videoPlayRef: any = useRef();
  const videoScrollRef: any = useRef();
  const [showCustomPlayBtn, setShowCustomPlayBtn] = useState<boolean>(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoToShow: string | null = params.get("video");

  const handlePlay = () => {
    setShowCustomPlayBtn(false);
    if (videoPlayRef.current) {
      videoPlayRef.current.play();
    }
  };

  useEffect(() => {
    if (videoToShow && videoId) {
      scrollToVideo(videoToShow, videoScrollRef, videoId);
    }
  }, [videoToShow]);

  return (
    <div
      ref={videoScrollRef}
      className={
        "relative rounded-[40px] p-3 align-middle md:p-4 lg:p-5 " +
        borderColor +
        (!noShadow ? " shadow-[0px_0px_80px_0px_#001B36]" : "")
      }
    >
      <div
        className={
          "relative w-full overflow-hidden p-0 pt-[56.25%]" +
          (iPadView ? " !pt-[62.5%]" : "") +
          (title || description ? " rounded-t-[30px]" : " rounded-[30px]")
        }
      >
        {embedded === "youTube" && (
          <YouTubeEmbed
            videoId={url}
            title={title}
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-[30px]"
            playsInline={playsInline}
          />
        )}
        {!embedded && (
          <video
            ref={videoPlayRef}
            id={id}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            playsInline={playsInline}
            poster={thumbnail}
            width={width}
            height={height}
            controls={customPlayBtn && showCustomPlayBtn ? false : controls}
            controlsList={download}
            disableRemotePlayback={disableRemotePlayback}
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full"
          >
            <source src={url} />
          </video>
        )}
      </div>
      {(title || description) && !embedded ? (
        <div className="flex flex-col gap-2 rounded-b-[40px] bg-black px-5 pb-4 pt-2">
          <h4 className="text-white">{title}</h4>
          <p className="m-0 leading-normal text-white">{description}</p>
        </div>
      ) : null}
      {shareUrl && thumbnail && (
        <ShareButton
          shareUrl={shareUrl}
          pinterestMedia={thumbnail}
          small={shareSmall}
          video
        />
      )}
      {showCustomPlayBtn && customPlayBtn && (
        <img
          id={id + "_play_btn"}
          role="button"
          src={customPlayBtn}
          loading="lazy"
          alt=""
          className={
            "absolute -bottom-[50px] left-0 right-0 top-auto z-[5] mx-auto w-[120px] cursor-pointer" +
            " rounded-full duration-150 hover:scale-105 sm:-bottom-[65px] sm:w-[140px] md:-bottom-[80px]" +
            " md:w-[200px] lg:-bottom-[100px] lg:w-[215px] xl:w-[225px]"
          }
          onClick={handlePlay}
        />
      )}
    </div>
  );
}
