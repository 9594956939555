import { DeviceSessionContext } from "./DeviceContext";
import { Properties, TruAnalytics } from "./Analytics";

/**
 * This component answers 3 questions about user traffic
 * 
 * 1. Where is the traffic coming from?
 * 2. How is it getting to me?
 * 3. Why is it coming to me?
 * 
 * When you’re looking through your Google Analytics, 
 * it’s not always clear where your visitors are coming from. 
 * UTM, or Urchin Tracking Module, parameters can be an invaluable tool for solving this issue.
 * 
 * Example
 * http://www.truplaygames.com/?utm_content=textlink&utm_source=facebook
 */
function safeLocalStorageGetItem(key: string): string | null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error("LocalStorage access is denied:", e);
    return null;
  }
}

function safeLocalStorageSetItem(key: string, value: string): void {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error("Failed to save to LocalStorage:", e);
  }
}


export class Utm {
  analytics: TruAnalytics;
  constructor(analyticsContext: TruAnalytics) {
    this.analytics = analyticsContext;
  }

  /**
   * Track UTM using the referral or the current URL if referrer is empty
   * @param eventName
   * @param userDeviceContext
   */
  track = (eventName: string, userDeviceContext: DeviceSessionContext) => {
    let campaignAttribution: Properties = this.parse(userDeviceContext.referrer ? userDeviceContext.referrer : userDeviceContext.url);
    this.analytics.track(eventName, campaignAttribution);

    let existingAttribution = safeLocalStorageGetItem("tru-attribution");
    if (existingAttribution) {
      let currentAttribution = JSON.parse(existingAttribution) as Properties;
      for (let campaignAttributionKey in campaignAttribution) {
        currentAttribution[campaignAttributionKey] = campaignAttribution[campaignAttributionKey];
      }
      safeLocalStorageSetItem("tru-attribution", JSON.stringify(currentAttribution));
    } else {
      safeLocalStorageSetItem("tru-attribution", JSON.stringify(campaignAttribution));
    }
  }

  /**
   * Example
   * "https://www.truplaygames.com/?utm_source=example-source&utm_medium=example-medium&utm_campaign=example-campaign&utm_term=example-term&utm_content=example-content";
   * @param targetUrl
   */
  parse = (targetUrl: string): Properties => {
    let campaignAttributes: Properties = {}
    let url = targetUrl;

    if (url && url.trim().length < 1) return campaignAttributes;
    let urlParts = url?.split("?");
    if (urlParts.length < 1) return campaignAttributes;

    let queryStringParams = urlParts[1];
    if (queryStringParams) {
      let params = queryStringParams.split("&");

      if (params.length < 1) return campaignAttributes;
      params.forEach((item) => {
        let kv = item.split("=");
        campaignAttributes[kv[0]] = kv[1];
      });
    }

    return campaignAttributes;
  }
}


export enum UtmParameters {
  // Tracks a particular campaign name to monitor its performance, such as “August launch” or “relaunch”
  // Example: &utm_campaign=relaunch
  utm_campaign,

  // Tracks the link that was clicked, in case you have multiple links to the same destination, 
  // such as when you have both a text link and a banner ad pointing to the same destination.
  // Example: &utm_content=textlink
  utm_content,

  // Tracks which medium the visitor used to reach your website, such as pay-per-click or social.
  // Example: &utm_medium=social
  utm_medium,

  // Tracks the website that sent the traffic source, such as Facebook or Amazon.
  // Example: &utm_source=facebook
  utm_source,

  // Tracks the search keyword the visitor typed, such as “dress shoes.”
  // Example: &utm_term=dress+shoes
  utm_term
}

export default Utm;