interface IFeaturedEndorsementProps {
  imageSrc: string;
  name: string;
  title: string;
  description: string;
}

export default function FeaturedEndorsement({
  imageSrc,
  name,
  title,
  description,
}: IFeaturedEndorsementProps) {
  return (
    <div className="relative flex flex-col items-center justify-center gap-4">
      <img
        src={imageSrc}
        alt={name}
        loading="lazy"
        className="absolute -top-[260px] h-[240px] w-[240px] flex-shrink-0 rounded-[30px] border-[16px] border-solid border-white/[6%]"
      />
      <p className="m-0 max-w-[1200px] text-center font-normal text-indigo-200">
        {description}
      </p>
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="font-display text-3xl font-bold text-white">
          - {name}
        </div>
        <div className="font-body text-xl font-normal text-indigo-200">
          {title}
        </div>
      </div>
    </div>
  );
}
