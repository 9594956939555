import { useEffect, useState } from "react";
import rhymversebgimage from "../../../global-assets/assets/rhymversebgimage.webp";
import BenjaminCTA from "../../home-landing/BenjaminCTA/BenjaminCTA";
import { useFeatureFlags } from "../../../config/FeatureFlagContext";

const GamesMoreBenjamin = () => {
  const featureFlags = useFeatureFlags();
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState(false);

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [FreeTrial30]);
  return (
    <div className="relative overflow-hidden p-12 md:p-28">
      <BenjaminCTA
        title="Ready to play?"
        mobileTitle="Ready to play?"
        buttonText={
          freeTrial30 ? "Start 30 Day Free Trial" : "Redeem Your Free Week"
        }
        mobileButtonText={freeTrial30 ? " Free Trial" : "Redeem Your Free Week"}
        ctaAction="/user-registration"
        lightBackground
      />
      <div className="absolute inset-0 z-[-3]">
        <div className="absolute inset-0 z-[-1] bg-[linear-gradient(#fff,rgba(255,255,255,0.85)35%)]"></div>
        <img
          src={rhymversebgimage}
          loading="lazy"
          sizes="100vw"
          alt=""
          className="absolute inset-0 z-[-2] h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default GamesMoreBenjamin;
