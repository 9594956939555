import { useEffect } from "react";
import {
  Logout,
  isAuthenticatedWithValidToken,
  navigateTo,
} from "../../../config/UserAuthContext";
import ChevronRightBlue from "../assets/chevron-right-blue.svg";
import Button from "../../common/Button";

export default function AccountCustomerSupport() {
  let isAuthenticated = isAuthenticatedWithValidToken();

  useEffect(() => {
    if (!isAuthenticated) {
      Logout();
      navigateTo("/login");
    }
  }, [isAuthenticated]);

  const handelmailto = () => {
    isAuthenticated = isAuthenticatedWithValidToken();
    if (isAuthenticated) {
      window.location.href = "mailto:support@truplaygames.com";
    } else {
      Logout();
      navigateTo("/login");
    }
  };

  return (
    <div
      className={
        "tru-accinfo-container-bg rounded-3xl border-[3px] border-solid" +
        " border-x-[rgba(32,86,139,0.7)] border-b-[rgba(32,86,139,1)]" +
        " bg-gradient_acc_info border-t-[rgba(32,86,139,0.2)] p-4" +
        " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
      }
    >
      <h2 className="mb-8 text-2xl tracking-wide text-white sm:text-4xl">
        Help
      </h2>
      <div className="flex flex-col justify-between text-base md:text-2xl lg:flex-row">
        <div className="mb-4 text-lg font-semibold text-white md:mb-0 md:text-2xl">
          Contact Customer Support
        </div>
        <div className="flex cursor-pointer flex-row items-center justify-end self-end text-center md:whitespace-nowrap">
          <Button
            id="mailto_support_link"
            onClick={() => handelmailto()}
            btnType="tertiaryAlt"
            rightIcon={
              <img
                src={ChevronRightBlue}
                alt="Right Arrow"
                className="ml-2 h-6 w-6 md:h-8 md:w-8"
              />
            }
          >
            support@truplaygames.com
          </Button>
        </div>
      </div>
    </div>
  );
}
