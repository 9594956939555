import { useEffect, useState } from "react";

// Assets //
import SliderArrow from "../../global-assets/assets/faq_arrow.svg";

interface ICenteredCarouselProps {
  initialState: any[]; // Initial state of cards for the carousel
  cardWidth: number; // Width of each card in the carousel
  cardMargin: number; // Margin between each card in the carousel
  initialCardCount: number; // Initial count of the list of cards for the carousel
  responsive?: boolean; // The number of cards in the carousel will update based on screen width
  focus?: boolean; // The middle card will have focusStyles applied (could add a focus threshold in the future)
  focusStyles?: string; // Focused card styles
  unFocusStyles: string; // Regular card styles
  animated?: boolean; // The cards will animate based on the shift styles
  shiftLeftStyles?: string; // Animation for shifting 1 card to the left
  shiftRightStyles?: string; // Animation for shifting 1 card to the right
  visibleCount?: number; // Number of cards that are currently visible
  lightBackground?: boolean; // True if the background is lighter
}

const renderedScreenWidth = window.innerWidth;

export default function CenteredCarousel({
  initialState,
  cardWidth,
  cardMargin,
  initialCardCount,
  responsive = false,
  focus = false,
  animated = false,
  lightBackground = false,
  focusStyles = "",
  unFocusStyles,
  shiftLeftStyles,
  shiftRightStyles,
}: ICenteredCarouselProps) {
  const [cards, setCards] = useState<any[]>(initialState);
  const [startTouch, setStartTouch] = useState<number>(0);
  const [shiftingLeft, setShiftingLeft] = useState<boolean>(false);
  const [shiftingRight, setShiftingRight] = useState<boolean>(false);

  /**
   * Updates the current card state flipping the first X (visibleCount) cards to be visible
   * and the rest to hidden.
   * @param currentState Current state of the carousel cards
   * @param visibleCardCount Number of visible cards
   */
  const updateCards = (carouselCards: any[], visibleCount: number) => {
    const currentCards = [...carouselCards];
    const numOfCards: number = currentCards.length;

    let numCardsToShow: number = visibleCount + 2;
    if (visibleCount % 2 === 0) {
      numCardsToShow = visibleCount + 1;
    }

    if (numCardsToShow >= numOfCards) {
      currentCards.forEach((card) => {
        // Creating dublicats to avoid linked referenced objects
        const dupeCard: any = {};
        Object.keys(card).forEach((id) => {
          dupeCard[id] = card[id];
        });
        currentCards.push(dupeCard);
      });
    }

    if (numCardsToShow <= initialCardCount && cards.length > initialCardCount) {
      currentCards.splice(initialCardCount, Math.ceil(cards.length / 2));
    }
    currentCards.forEach((card, index) => {
      card.visible = true;
      if (focus && cards.length > 1) {
        if (index === Math.floor(cards.length / 2)) {
          card.focus = true;
        } else {
          card.focus = false;
        }
      }
    });

    setCards([...currentCards]);
  };

  /**
   * Calculates the number of visible cards in the carousel
   * @returns the number of visible cards in the carousel
   */
  const calculateVisibleCards = () => {
    const sliderContainer = document.querySelector("#tru-slider");

    if (sliderContainer && responsive) {
      return (
        Math.floor(sliderContainer.clientWidth / (cardWidth + cardMargin)) || 1
      );
    }
    return 1;
  };

  const handleResize = () => {
    if (window.innerWidth !== renderedScreenWidth) {
      updateCards(cards, calculateVisibleCards());
    }
  };

  /**
   * Handles a screen resize event. When the screen size changes, the number
   * of visible cards must be calculated
   */
  useEffect(() => {
    if (window.innerWidth < 840) {
      updateCards(cards, 1);
    } else {
      updateCards(cards, calculateVisibleCards());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Shifts the carousel cards to the left
   * Advances to the next card to the right
   */
  const handleRightClick = () => {
    if (animated) {
      setShiftingLeft(true);
      setTimeout(() => {
        const currentState = [...cards];
        const shifted = currentState[0];
        currentState.shift();
        currentState.push(shifted);

        updateCards(currentState, calculateVisibleCards());
        // setCards([...currentState]);
        setShiftingLeft(false);
      }, 500);
    } else {
      const currentState = [...cards];
      const shifted = currentState[0];
      currentState.shift();
      currentState.push(shifted);

      updateCards(currentState, calculateVisibleCards());
    }
  };

  /**
   * Shifts the carousel cards to the right
   * Advances to the next card to the left
   */
  const handleLeftClick = () => {
    if (animated) {
      setShiftingRight(true);
      setTimeout(() => {
        const currentState = [...cards];
        const popped = currentState[currentState.length - 1];
        currentState.pop();
        currentState.unshift(popped);

        updateCards(currentState, calculateVisibleCards());
        // setCards([...currentState]);
        setShiftingRight(false);
      }, 500);
    } else {
      const currentState = [...cards];
      const popped = currentState[currentState.length - 1];
      currentState.pop();
      currentState.unshift(popped);

      updateCards(currentState, calculateVisibleCards());
    }
  };

  const handleTouchStart = (e: any) => {
    const touchDown = e.touches[0].clientX;
    setStartTouch(touchDown);
  };

  const handleTouchMove = (e: any) => {
    if (!startTouch) return;

    const touchDown = startTouch;
    const touchUp = e.touches[0].clientX;
    const touchDiff = touchDown - touchUp;

    if (touchDiff > 5) {
      handleRightClick();
    }

    if (touchDiff < -5) {
      handleLeftClick();
    }

    setStartTouch(0);
  };

  return (
    <>
      <div
        className={
          "invisible absolute left-4 top-[40%] z-[3] flex h-12 w-12 rotate-90 cursor-pointer select-none items-center justify-center rounded-full bg-indigo-900/60 hover:bg-indigo-900/80 sm:h-16 sm:w-16 md:visible md:left-[5%] md:h-24 md:w-24" +
          (lightBackground ? " !bg-indigo-900/20 hover:!bg-indigo-900/40" : "")
        }
        onClick={handleLeftClick}
      >
        <img
          src={SliderArrow}
          alt="Previous"
          className="h-4 sm:h-5 md:h-auto"
        />
      </div>
      <div
        id="tru-slider"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        className="flex flex-row items-center justify-center gap-10"
      >
        {cards.map((card: any, index: number) => {
          return (
            <div
              key={index}
              className={
                (!card.focus
                  ? focusStyles + " " + unFocusStyles
                  : focusStyles) +
                (shiftingLeft && shiftLeftStyles ? " " + shiftLeftStyles : "") +
                (shiftingRight && shiftRightStyles
                  ? " " + shiftRightStyles
                  : "")
              }
            >
              {card.component}
            </div>
          );
        })}
      </div>
      <div
        className={
          "invisible absolute right-4 top-[40%] z-[3] flex h-12 w-12 -rotate-90 cursor-pointer select-none items-center justify-center rounded-full bg-indigo-900/60 hover:bg-indigo-900/80 sm:h-16 sm:w-16 md:visible md:right-[5%] md:h-24 md:w-24" +
          (lightBackground ? " !bg-indigo-900/20 hover:!bg-indigo-900/40" : "")
        }
        onClick={handleRightClick}
      >
        <img src={SliderArrow} alt="Next" className="h-4 sm:h-5 md:h-auto" />
      </div>
    </>
  );
}
