// Assets //
import excellenceIcon from "./assets/icon-arrow-target.svg";
import funIcon from "./assets/icon-joy-jumping.svg";
import godsTruthIcon from "./assets/icon-open-bible.svg";
import beautyIcon from "./assets/icon-open-bible.svg";
import stewardShipIcon from "./assets/icon-open-palm-plant.svg";
import accentStars2 from "../../../global-assets/assets/Accent_Stars_02.svg";

export const ValuesIcons = [
  {
    icon: excellenceIcon,
    title: "Excellence",
    description: "“We make world-class entertainment.”",
  },
  {
    icon: funIcon,
    title: "Fun",
    description: "“We bring joy to all who encounter our products.”",
  },
  {
    icon: godsTruthIcon,
    title: "God's Truth",
    description: "“God's truth resonates through every product we create.”",
  },
  {
    icon: beautyIcon,
    title: "Beauty",
    description: "“We reflect God's beauty to the world.”",
  },
  {
    icon: stewardShipIcon,
    title: "Stewardship",
    description: "“We make wise use of everything God gives us.”",
  },
];

export default function AboutValues() {
  return (
    <div className="mx-auto max-w-[1440px]">
      <div className="mx-auto mb-8 flex max-w-[820px] flex-col items-center justify-center text-center md:mb-12 lg:mb-20">
        <div className="relative mb-4">
          <img
            className="absolute -left-[35px] -top-[28px] w-[60px] sm:-left-[42px] sm:-top-[32px] sm:w-[70px] md:-left-[52px] md:-top-[40px] md:w-[90px] lg:-left-[60px] lg:-top-[45px] lg:w-[unset]"
            src={accentStars2}
            loading="lazy"
            alt=""
          />
          <h2 className="text-cerulean-500">Our Values</h2>
        </div>
        <div className="max-w-[1040px]">
          <p className="mb-0 text-white">
            We are committed to living out these principles in every aspect of
            our work at TruPlay.
          </p>
        </div>
      </div>
      <div className="mx-auto grid max-w-[260px] grid-cols-1 items-start justify-center gap-x-10 gap-y-4 sm:flex sm:max-w-[unset] sm:flex-wrap md:grid-cols-5 md:flex-nowrap md:justify-start md:gap-x-4">
        {ValuesIcons.map((value) => (
          <div
            className="flex flex-col items-center justify-start text-center sm:w-[30%]"
            key={value.title}
          >
            <div className="mb-5 md:mb-6">
              <img
                src={value.icon}
                loading="lazy"
                alt=""
                className="max-w-[80%] sm:max-w-[unset]"
              />
            </div>
            <div className="mb-3">
              <h5 className="text-white">{value.title}</h5>
            </div>
            <div className="text-center text-base leading-6 text-cool-gray-600 opacity-80 md:text-sm lg:text-base">
              <div>{value.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
