export class ServiceUrl {

    baseUrl: string;
    contextPath: string;

    constructor(_baseUrl: string = "https://dev-gateway.truplayplatform.com/configuration/", _contextPath: string = "") {
        this.baseUrl = _baseUrl;
        this.contextPath = _contextPath;
    }
}

export default ServiceUrl;