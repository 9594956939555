import curvewhite from "../../global-assets/assets/curvewhite.svg";

export default function CareersHero() {
  return (
    <div className="relative flex justify-end overflow-visible p-[50px_20px] pt-[140px] sm:px-[25px] sm:pb-[75px] sm:pt-[125px] md:px-[30px] md:pb-[143px] md:pt-[250px] lg:px-[50px] lg:pb-[150px]">
      <div className="relative mx-auto flex w-full max-w-[1440px] flex-col items-center justify-between gap-[15px] sm:gap-[30px] lg:flex-row">
        <div className="flex-1">
          <div className="mb-0 flex max-w-[820px] flex-col items-center justify-start text-center sm:flex md:mb-[50px] lg:mb-[75px] lg:text-left">
            <div className="relative mb-4">
              <h1 className="gradient-text-main">
                Careers in Gaming at TruPlay
              </h1>
            </div>
            <div className=" mb-[22px] max-w-[1040px] md:mb-[32px]">
              <p className="mb-0 text-white">
                We don't make Christian games merely because we can, or because
                we want to. We make them because we need to. We're looking for
                the warriors with the same Drive and Passion.
                <br />
                <br />
                If you are looking to make a difference in the world you've come
                to the right place. We are hiring driven, talented individuals
                who find joy in working hard for a cause or mission.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1" />
      </div>
      <div className=" absolute bottom-0 left-0 right-0 top-0 -z-30 overflow-hidden">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-40 bg-[rgba(0,32,63,0.6)]" />
        <div className="absolute bottom-0 left-0 right-[-100px] top-0 -z-10 bg-[radial-gradient(circle_at_100%_50%,#652c80_0%,transparent_50%)] opacity-70" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-10 bg-[radial-gradient(circle_at_0%_50%,#20568b_0%,transparent_50%)] opacity-70" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[100] m-auto h-full w-full object-cover">
          <video
            autoPlay={true}
            loop={true}
            style={{
              backgroundImage: `url("https://assets-global.website-files.com/627c21c12fc47ae308412b80/6286ca39e5168c1fa9d4258b_truplayofficeloop_01 (2160p) copy-poster-00001.jpg")`,
            }}
            muted={true}
            playsInline={true}
            data-wf-ignore={true}
            data-object-fit="cover"
          >
            <source
              src="https://assets-global.website-files.com/627c21c12fc47ae308412b80/6286ca39e5168c1fa9d4258b_truplayofficeloop_01 (2160p) copy-transcode.mp4"
              data-wf-ignore={true}
            />
            <source
              src="https://assets-global.website-files.com/627c21c12fc47ae308412b80/6286ca39e5168c1fa9d4258b_truplayofficeloop_01 (2160p) copy-transcode.webm"
              data-wf-ignore={true}
            />
          </video>
        </div>
      </div>
      <img
        src={curvewhite}
        loading="lazy"
        alt=""
        className="absolute bottom-0 left-0 right-0 z-[5] mb-[-1px] w-full max-w-none"
      />
    </div>
  );
}
