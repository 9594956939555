import { useEffect } from "react";
import CloseIcon from "../../global-assets/assets/icon-close-gray.svg";
import ShareButton from "./ShareButton/ShareButton";

interface VideoModalProps {
  setIsOpen: Function;
  videoLink: string;
  shareLink?: string;
  thumbnail?: string;
}

export default function VideoModal({
  setIsOpen,
  videoLink,
  shareLink,
  thumbnail,
}: VideoModalProps) {
  useEffect(() => {
    // Prevents body from being scrollable while the modal is open
    document.body.style.overflow = "hidden";
  }, []);

  const handleClose = () => {
    document.body.style.overflow = "unset";
    setIsOpen(false);
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-[9999] flex items-center justify-center overflow-auto bg-black/90">
      <div className="relative w-[90%]">
        <video
          id="video_modal"
          controls
          poster={thumbnail}
          autoPlay
          controlsList="nodownload"
        >
          <source src={videoLink} />
        </video>
        {shareLink && thumbnail ? (
          <ShareButton shareUrl={shareLink} pinterestMedia={thumbnail} video />
        ) : null}
      </div>
      <div
        id="tru_modal_close_btn"
        className="absolute right-0 top-0 cursor-pointer pb-2 pl-2 pr-6 pt-6"
        role="button"
        onClick={() => handleClose()}
      >
        <img
          src={CloseIcon}
          loading="lazy"
          alt=""
          className="hover:opacity-100 lg:opacity-70"
        />
      </div>
    </div>
  );
}
