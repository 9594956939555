import LeftAlignedCarousel from "../common/LeftAlignedCarousel";
import BOIPage1 from "./assets/boi-page-1.webp";
import BOIPage2 from "./assets/boi-page-2.webp";
import BOIPage3 from "./assets/boi-page-3.webp";
import BOIPage4 from "./assets/boi-page-4.webp";
import BOIPage5 from "./assets/boi-page-5.webp";
import BOIPage6 from "./assets/boi-page-6.webp";
import BOIPage7 from "./assets/boi-page-7.webp";
import BOIPage8 from "./assets/boi-page-8.webp";

// Assets
import DownloadIcon from "../../global-assets/assets/icon-download.svg";

interface IBOIPagesList {
  id: string;
  title: string;
  image: string; // Path to BOI page image
  downloadLink: string;
  component: JSX.Element | null;
  focus: boolean;
  type: string;
  downloadFunction: (link: string, title: string) => void;
  overlay?: JSX.Element | null;
}

const handlePageDownload = (link: string, title: string) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = link;
  downloadLink.download = title + ".pdf";
  downloadLink.target = "_blank";
  downloadLink.click();
};

const boiPagesList: IBOIPagesList[] = [
  {
    id: "page_1",
    title: "BOI Page 1",
    image: BOIPage1,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheet_01.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
  {
    id: "page_2",
    title: "BOI Page 2",
    image: BOIPage2,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheets_02.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
  {
    id: "page_3",
    title: "BOI Page 3",
    image: BOIPage3,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheets_03.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
  {
    id: "page_4",
    title: "BOI Page 4",
    image: BOIPage4,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheets_04.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
  {
    id: "page_5",
    title: "BOI Page 5",
    image: BOIPage5,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheets_05.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
  {
    id: "page_6",
    title: "BOI Page 6",
    image: BOIPage6,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheets_06.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
  {
    id: "page_7",
    title: "BOI Page 7",
    image: BOIPage7,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheets_07.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
  {
    id: "page_8",
    title: "BOI Page 8",
    image: BOIPage8,
    downloadLink:
      "https://free-boi-pages.sfo3.cdn.digitaloceanspaces.com/BOI_Coloring%20Sheets_08.pdf",
    component: null,
    focus: false,
    type: "image",
    downloadFunction: handlePageDownload,
    overlay: null,
  },
];

boiPagesList.forEach((page) => {
  page.component = (
    <img src={page.image} alt={page.id} loading="eager" draggable="false" />
  );
  page.overlay = (
    <img
      src={DownloadIcon}
      alt="Download"
      loading="eager"
      draggable="false"
      className="h-5 w-5"
    />
  );
});

export default function FreeContentBOIPages() {
  const tileWidth = 250;
  const tileMargin = 24;
  const tileHeight = 323.5;
  const carouselPaddingTop = 16;
  const carouselPaddingBottom = 56;

  return (
    <div className="flex flex-col justify-start gap-2 overflow-x-hidden">
      <h2 className="pl-8 text-3xl text-white sm:pl-10 md:pl-20 md:text-4xl">
        Free Coloring Pages
      </h2>
      <p className="text-transparent m-0 bg-gradient_text bg-clip-text px-8 text-base font-medium sm:pl-10 sm:text-lg md:pl-20 md:text-2xl">
        <span className="hidden lg:inline">
          Download free children's coloring pages - printable and online
          options!
        </span>
        <span className="lg:hidden">Printable & Online Options!</span>
      </p>
      <div className="relative w-full">
        <LeftAlignedCarousel
          initialState={boiPagesList}
          cardWidth={tileWidth}
          cardHeight={tileHeight}
          cardMargin={tileMargin}
          responsive
          animated
          cardStyles="shadow-carousel_tile cursor-pointer duration-200 ease-in-out opacity-90 md:opacity-80"
          hoverStyles="md:group-hover/card-hover:shadow-carousel_hover_tile md:group-hover/card-hover:scale-105 md:hover:opacity-100 md:group-hover/card-focus:scale-105"
          focusStyles="!shadow-carousel_select_tile ring-[6px] ring-white cursor-pointer duration-200 ease-in-out opacity-100"
          arrowType="netflix"
          selection
          paddingBottom={carouselPaddingBottom}
          paddingTop={carouselPaddingTop}
          overlayStyles="bg-white absolute bottom-4 left-4 flex items-center justify-center rounded-full p-2 shadow-[0px_0px_11.852px_0px_rgba(0,0,0,0.25)] cursor-pointer"
          overlayFocusStyles="bg-white/35"
          overlayHoverStyles={
            "md:group-hover/card-focus:bg-white/35 md:group-hover/card-focus:scale-110 md:group-hover/card-hover:bg-white/35" +
            " md:hover:!bg-white duration-200 md:group-hover/card-hover:-translate-x-1 md:group-hover/card-hover:translate-y-1 md:group-hover/card-hover:scale-110"
          }
        />
      </div>
    </div>
  );
}
